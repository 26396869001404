import { MapPopup } from '@innovation-toolkit/mapbox';
import { MapPopupContents } from '@innovation-toolkit/ui';
import { useState, useEffect } from 'react';
// import './popup.css'

const formatDetails = (element) => {

    const formattedInformation = {     
        'District': element.District,
        'City': element.City,
        'FLOC': element.FUNCTIONALLOCATION,
    }

    Object.keys(formattedInformation).forEach(key => {
        if (!formattedInformation[key]) {
            formattedInformation[key] = '-'
        }
    })

    return formattedInformation
}


export const LSAPopup = ({isOpen, onClose, lngLat, feature, target}) => {
    const [popupState, setPopupState] = useState({
        lng: 0,
        lat: 0,
        children: null
    })

    const [children, setChildren] = useState(null)
    const  [data, setData] = useState({})

    useEffect(() => {

        if (lngLat === null || feature === null) return
        const popupFeature = feature.properties
        const formattedFeature = formatDetails(popupFeature)
        setData(formattedFeature)
        
        setPopupState({
            lng: lngLat.lng,
            lat: lngLat.lat,
        })

    }, [isOpen, lngLat, feature, children])



    return isOpen ?
                <MapPopup longitude={popupState.lng} latitude={popupState.lat} onClose={onClose} className='StreetImprovementPopup'>
                    <MapPopupContents
                        title='LSA Grid'
                        footNote='Source: GIS'
                        data={data}
                    />
                </MapPopup>
                : null
}