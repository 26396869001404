import React, { useEffect } from 'react';
import { Content } from 'antd/lib/layout/layout';
import { Layout } from 'antd/lib';
import { CityWorkAggregationMapLayers } from './layers/mapLayers';
import { CityWorkAggregationMenu } from './menu';
import { useDispatch } from 'react-redux';
import { setActiveApp } from '../../../../../shared/redux/apps';
import { CityWorkTable } from './CityWorkTable';
import MapboxReact from 'component/mapbox/mapbox';

export const CityWorkAggregation = () => {
    const dispatch = useDispatch();
    
    useEffect(() => {
        dispatch(setActiveApp('city-work-aggregation'));
    }, [dispatch]);

    return (
        <>
            <CityWorkAggregationMenu />
            <Layout style={{ maxHeight: '100vh' }}>
                <Content style={{
                    margin: 0,
                    position: "relative",
                    color: "white"
                }}>
                    <CityWorkTable />
                    <MapboxReact 
                        layers={<CityWorkAggregationMapLayers />} 
                        mapLoadingIndicatorArgs={{color: 'white', tip: 'Validating...'}} 
                    />
                </Content>
            </Layout>
        </>
    )
}

