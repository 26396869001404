import styled from "styled-components"
import { DelimiterLine } from ".."
import { theme } from "../../theme/theme"
import Button from "./Button"

const ButtonWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
    padding-bottom: 32px;
    margin-top: 32px;
`

const ActionBarWrapper = styled.div`
    position: absolute;
    bottom: 0;
    background-color: ${theme.color.prussianBlue};
`

/* Filter Action Buttons
    - Meant to be used at the bottom of each Filter tab as the Confirm and Clear selection buttons.
    
    - Properties:
        -> onConfirm:          Callback when confirm button is clicked
        -> isConfirmDisabled:  Boolean for whether the confirm button should be disabled
        -> onClear:            Callback when clear button is clicked
        -> isClearDisabled:    Boolean for whether the clear button should be disabled

        Optional:
        -> confirmText:        Text that appears on the confirm button
        -> clearText:          Text that appears on the clear button
*/

const FilterActionButtons = ({
    onConfirm,
    isConfirmDisabled,
    confirmText = "CONFIRM SELECTION",
    onClear,
    isClearDisabled,
    clearText = "CLEAR SELECTION",
}) => {
    return (
        <ActionBarWrapper>
            <DelimiterLine style={{ width: "224px" }} right={16} />
            <ButtonWrapper>
                <Button
                    title={confirmText}
                    size="L"
                    onClick={onConfirm}
                    disabled={isConfirmDisabled}
                />
                <Button
                    title={clearText}
                    size="L"
                    type="secondary"
                    onClick={onClear}
                    disabled={isClearDisabled}
                />
            </ButtonWrapper>
        </ActionBarWrapper>
    )
}

export default FilterActionButtons
