import { Layer, Source } from "react-map-gl"
import { APIGateways } from "@config/config";
import { useEffect, useState } from "react";
import { addToMapLoadingList, removeFromMapLoadingList, selectIsLayerActive, useActivateMapLayer, LegendItem, useLoadMapPattern, selectBeforeIDs, selectMapStyle } from "@innovation-toolkit/mapbox";
import { useSelector } from "react-redux";
import axios from "axios";
import { useDispatch } from "react-redux";

export const ASBESTOS_LAYER = 'ASBESTOS_LAYER';
export const ASBESTOS_SOURCE = 'ASBESTOS_SOURCE';

export const AsbestosLayerMetadata = {
    layerId: ASBESTOS_LAYER,
    name: 'Asbestos',
    color: '#8470A7'
}

const fetchAsbestosData = (callBack, filterOptions) => {

    const url = `${APIGateways.scgcore}/data/asbestos`;

    const filter = {}

    axios.get(url, filter)
        .then((response) => {
            // Returns as a presigned url, fetch the data here
            return fetch(response.data)
                .then((fetchResponse) => fetchResponse.json())
                .then((data) => {
                    callBack(data, false)
                })
        })
        .catch((err) => {
            callBack(null, true)
        })
}

export const AsbestosLayer = ({showLayer=false}) => {

    const dispatch = useDispatch()
    const [showAsbestosLayer] = useActivateMapLayer(AsbestosLayerMetadata, showLayer)
    const beforeIDs = useSelector(selectBeforeIDs)
    const mapStyle = useSelector(selectMapStyle)
    const [data, setData] = useState(null);

    try {
        useLoadMapPattern("asbestos-pattern", "diagonal-left", AsbestosLayerMetadata.color, 30, mapStyle);
    } catch (e){
        console.error("Failed to load map pattern for NFAB Layer: ", e);
    }

    useEffect(() => {
        // dispatch(addToMapLoadingList('Asbestos'))
        fetchAsbestosData((data, isError) => {
            if (!isError) {
                setData(data)
            } 
            // dispatch(removeFromMapLoadingList('Asbestos'))
        })
    }, []) // Add searchDates to filter on search

    const sourceConfig = {
        id: ASBESTOS_SOURCE,
        type: "geojson",
        data: data
    }

    const circleConfig = {
        id: ASBESTOS_LAYER,
        type: "fill",
        source: ASBESTOS_SOURCE,
        layout: {
            'visibility': showAsbestosLayer ? 'visible' : 'none'
        },
        paint: {
            // 'fill-opacity': 0.6,
            // 'fill-color': AsbestosLayerMetadata.color,
            'fill-pattern': 'asbestos-pattern'
        },
        beforeId: beforeIDs.circle
    }

    return (
        <Source {...sourceConfig}>
            <Layer {...circleConfig} />
        </Source>
    )
}

AsbestosLayer.layerName = 'Asbestos';
AsbestosLayer.legendColor = '#FFFFFF';

export const AsbestosLegendSection = () => {
    const showLegend = useSelector((state) => selectIsLayerActive(state, AsbestosLayerMetadata.layerId))

    if (!showLegend) {
        return <></>
    }

    return (
        <LegendItem color={AsbestosLayerMetadata.color} title={AsbestosLayerMetadata.name} small={true}/>
    )
}