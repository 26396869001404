import { MapPopup } from "@innovation-toolkit/mapbox";
import { MapPopupContents } from "@innovation-toolkit/ui";
import { useState, useEffect } from "react";

export const CharitableDonationPopup = ({ isOpen, onClose, lngLat, feature, target }) => {
  const [popupState, setPopupState] = useState({
    lng: 0,
    lat: 0,
    children: null,
  });

  const [data, setData] = useState({});

  useEffect(() => {
    if (lngLat === null || feature === null) return;

    let description = "";
    setData(feature);

    setPopupState({
      lng: lngLat.lng,
      lat: lngLat.lat,
      children: description,
    });
  }, [isOpen, lngLat, feature]);

  return isOpen ? (
    <MapPopup
      longitude={popupState.lng}
      latitude={popupState.lat}
      onClose={onClose}
      className="StreetImprovementPopup"
    >
      <MapPopupContents
        title="Contribution"
        data={data}
      />
    </MapPopup>
  ) : null;
};
