import { MapPopup } from '@innovation-toolkit/mapbox';
import { MapPopupContents } from '@innovation-toolkit/ui';
import { useState, useEffect } from 'react';
import moment from 'moment';
import './popup.css'

const toDateString = (date) => {
    if (!date) return '';

    return moment(date).format('MM/DD/YYYY');
}

const convertCPDProjectStatusToName = (status) => {
    if (!status) return '';

    const code = status.slice(0,2)
    const codeMap = {
        CZ: 'Pre-Initiation',
        IN: 'Initiation',
        IP: 'In Planning',
        PC: 'Pre-Construction',
        CO: 'Construction',
        RE: 'Reconciliation'
    }
    return codeMap[code] ?? status;
}

/* Available properties
CITY1 
DISTRICT 
HouseNumber 
NotificationNo 
NotificationSystemStatus 
NotificationUserStatus 
RequiredStartDate 
TaskNumber 
TaskPlannedFinishDate 
add 
index 
lat 
lon
*/
const formatInformation = (element) => {
    let contact_person = '-'
    if (element.planner_id) {
        if (element.planner_id.includes("[") && (typeof JSON.parse(element.planner_id) !== 'string')) {
            contact_person = JSON.parse(element.planner_id).join(", ")
        } else if (element.planner_id === "NaN") {
            contact_person = '-'
        } else {
            contact_person = element.planner_id
        }
    }

    const formattedInformation = {     
        'Type': element.type,
        'Notification Number': element.NotificationNo,
        'Notification Type': element.NotificationType,
        'Notification Status': element.NotificationSystemStatus,
        'Project Title': '-',
        'Required Start Date': toDateString(element.RequiredStartDate),
        'Planned End Date': toDateString(element.TaskPlannedFinishDate),
        'Master Notification': element.MasterNotificationNo,
        'CZ Related Notification' : element.cznotificationnumber,
        'District': element.district,
        'City': element.CITY1,
        'Job Location': element.address,
        'Cross Street': element.Street,
        'SCG Contact Person': contact_person
    }

    Object.keys(formattedInformation).forEach(key => {
        if (!formattedInformation[key]) {
            formattedInformation[key] = '-'
        }
    })

    return formattedInformation
}



export const CPDProjectsPopup = ({isOpen, onClose, lngLat, feature, target}) => {
    const [popupState, setPopupState] = useState({
        lng: 0,
        lat: 0,
        children: null
    })

    const [children, setChildren] = useState(null)
    const  [data, setData] = useState({})

    useEffect(() => {
        if (feature === null || target === null) return

        const clusterSource = target.getSource('CPD_PROJECTS_SOURCE');
        const clusterId = feature.properties.cluster_id;
        const pointCount = feature.properties.point_count;
        const getChildren = async () => {
            const childrenPromise = new Promise((resolve, reject) => {
                clusterSource.getClusterLeaves(clusterId, pointCount, 0, (error, features) => {
                    resolve(features);
                })
            })
            const childrenRes = await childrenPromise;
            
            if (!childrenRes) return
    
            setChildren(childrenRes)
        };
        getChildren();
    }, [target, feature])


    useEffect(() => {

        if (lngLat === null || feature === null) return
        const popupFeature = feature.properties

        const pointCount = popupFeature.point_count ?? 1;

        let description = ''
        if (pointCount === 1) {
            setData(formatInformation(popupFeature));
        } else {
            if (!children) return
            setData(children?.map(element => formatInformation(element.properties)))
        }
        
        setPopupState({
            lng: lngLat.lng,
            lat: lngLat.lat,
            children: description
        })

    }, [isOpen, lngLat, feature, children])



    return isOpen ?
                <MapPopup longitude={popupState.lng} latitude={popupState.lat} onClose={onClose} className='StreetImprovementPopup'>
                    <MapPopupContents
                        title='SoCalGas Work'
                        footNote='Source: CPD'
                        data={data}
                    />
                </MapPopup>
                : null
}

export const WipCloudPopup = ({isOpen, onClose, lngLat, feature, target}) => {
    const [popupState, setPopupState] = useState({
        lng: 0,
        lat: 0,
        children: null
    })

    const [data, setData] = useState({})

    useEffect(() => {
        if (lngLat === null || feature === null) return
        const popupFeature = feature.properties

        let description = '';

        let formattedData = formatInformation(popupFeature);
        formattedData.Type = "Notification Polygon";
        setData(formatInformation(popupFeature));
        
        setPopupState({
            lng: lngLat.lng,
            lat: lngLat.lat,
            children: description
        })

    }, [isOpen, lngLat, feature])



    return isOpen ?
                <MapPopup longitude={popupState.lng} latitude={popupState.lat} onClose={onClose} className='StreetImprovementPopup'>
                    <MapPopupContents
                        title='SoCalGas Work - WIP Cloud'
                        footNote='Source: SAP HANA'
                        data={data}
                    />
                </MapPopup>
                : null
}