import { Layer, Source } from "react-map-gl"
import { APIGateways } from "@config/config";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useActivateMapLayer, selectBeforeIDs } from "@innovation-toolkit/mapbox";
import axios from "axios";

export const RAILWAY_LAYER = 'RAILWAY_LAYER';
export const RAILWAY_SOURCE = 'RAILWAY_SOURCE';

export const RailwayLayerMetadata = {
    layerId: RAILWAY_LAYER,
    name: 'Railway',
    color: '#0E0A6D'
}

// TODO_ST
const fetchRailwayData = (callBack, filterOptions) => {

    const url = `${APIGateways.scgcore}/data/railway`;

    const filter = {}

    axios.get(url, filter)
        .then((response) => {
            // Returns as a presigned url, fetch the data here
            return fetch(response.data)
                .then((fetchResponse) => fetchResponse.json())
                .then((data) => {
                    callBack(data, false)
                })
        })
        .catch((err) => {
            callBack(null, true)
        })
}

export const RailwayLayer = ({showLayer=false}) => {

    const [showRailwayLayer] = useActivateMapLayer(RailwayLayerMetadata, showLayer)
    const beforeIDs = useSelector(selectBeforeIDs)

    const [data, setData] = useState(null);

    useEffect(() => {
        fetchRailwayData((data, isError) => {
            if (!isError) {
                setData(data)
            } 
        })
    }, []) 

    const sourceConfig = {
        id: RAILWAY_SOURCE,
        type: "geojson",
        data: data,
        // cluster: true,
        // clusterMaxZoom: 12,
        // clusterRadius: 15,
    }

    const lineConfig = {
        id: RAILWAY_LAYER,
        type: "line",
        source: RAILWAY_SOURCE,
        layout: {
            'visibility': showRailwayLayer ? 'visible' : 'none'
        },
        paint: {
            'line-width': ['step',
            ['zoom'],
                1,
                5, 2,
                10, 3,
                13, 5,
                14, 6,
                15, 7,
                16, 10,
                17, 15
        ],
            'line-color': RailwayLayerMetadata.color,
            // 'line-opacity': .6
        },
        beforeId: beforeIDs.line
    }

    return (
        <Source {...sourceConfig}>
            <Layer {...lineConfig} />
        </Source>
    )
}