import { createSelector, createSlice } from "@reduxjs/toolkit";

export const initialState = {
    emissionsType: 'combined',
    fleetDecarbonization: {
      fleetTable: false,
    },
    vehicleReplacement: {
      vehicleReplacementTable: false
    },
    valueMetricsTable: false
}

const fleetDecarbonization = createSlice({
  name: "fleetDecarbonization",
  initialState,
  reducers: {
    setEmissionsType(state, action) {
      state.emissionsType = action.payload;
    },
    setFleetDecarbonizationTable(state, action) {
      state.fleetDecarbonization = action.payload
    },
    setVehicleReplacementTable(state, action) {
      state.vehicleReplacement = action.payload
    },
    setValueMetricsTable(state, action) {
      state.valueMetricsTable = action.payload
    },
  },
});

export const { 
    setEmissionsType,
    setFleetDecarbonizationTable,
    setVehicleReplacementTable,
    setValueMetricsTable
} = fleetDecarbonization.actions;
const selectFleetDecarbonization = (state) => state.fleetDecarbonization;

export const selectEmissionsType = createSelector(
    selectFleetDecarbonization,
    select => {
        return select.emissionsType
    }
)

export const selectFleetDecarbonizationTable = createSelector(
    selectFleetDecarbonization,
    select => {
        return select.fleetDecarbonization
    }
)

export const selectVehicleReplacementTable = createSelector(
    selectFleetDecarbonization,
    select => {
        return select.vehicleReplacement
    }
)

export const selectValueMetricsTable = createSelector(
    selectFleetDecarbonization,
    select => {
        return select.valueMetricsTable
    }
)

export default fleetDecarbonization.reducer;

