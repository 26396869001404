// import { useEffect } from "react"
import { Layer, Source } from "react-map-gl"
import { APIGateways } from "@config/config"
import { useEffect, useState } from "react";
import { addActiveLayer, addToMapLoadingList, isActiveLayer, removeFromMapLoadingList, selectIsLayerActive, useActivateMapLayer, useLoadMapImage, selectBeforeIDs, useCallWebsocketEndpoint, selectWebsocketUrl, useMapEvent } from "@innovation-toolkit/mapbox";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import axios from "axios";
import cpd_p from './CPD_P.png';
import cpd_p_conflict from './CPD_P_Conflict.png';
import cpd_p_conflict_cluster from './CPD_P_Conflict_Cluster.png';
import cpd_p_highlighted_green from './CPD_P_Highlighted_Green.png';
import cpd_p_highlighted_orange from './CPD_P_Highlighted_Orange.png';
import { CPDProjectsPopup, WipCloudPopup } from "./popup";
import moment from 'moment';
import { selectSearchItems } from "redux/searchItems/selectors";
import { selectHighlightedMap, selectIsCPDDisabled, selectOverlapCPDIds, selectWorkTypes } from "redux/streetImprovements/selector";
import { setCPDOptions } from "redux/streetImprovements/action";
import { footToMeter } from "../MandIWork";

export const CPD_PROJECTS_LAYER = 'CPD_PROJECTS_LAYER';
export const CPD_PROJECTS_SOURCE = 'CPD_PROJECTS_SOURCE';
// Polygon layer includes work request polygons and wip clouds
export const POLYGON_LAYER = 'POLYGON_LAYER';
export const POLYGON_SOURCE = 'POLYGON_SOURCE';

export const CPDProjectsLayerMetadata = {
    layerId: CPD_PROJECTS_LAYER,
    name: 'CPD Project',
    color: '#286D36',
    layerSymbols: [
        { symbol: cpd_p, symbolName: 'cpd-p-icon' },
        { symbol: cpd_p_conflict, symbolName: 'cpd-p-conflict-icon' },
        { symbol: cpd_p_conflict_cluster, symbolName: 'cpd-p-conflict-cluster-icon' },
        { symbol: cpd_p_highlighted_green, symbolName: 'cpd-p-highlighted-green-icon' },
        { symbol: cpd_p_highlighted_orange, symbolName: 'cpd-p-highlighted-orange-icon' },
    ]
}

export const WIPCloudLayerMetadata = {
    layerId: POLYGON_LAYER,
    name: 'WIP Cloud',
    color: '#EA3323'
}

//500ft for CPD radius
const LEAK_RADIUS = 500

const separateOutGeometries = (data) => {
    const pointsData = {
        type: "FeatureCollection",
        features: []
    };
    const polygonData = {
        type: "FeatureCollection",
        features: []
    }

    for (const feature of data.features) {
        if (feature.geometry.type !== 'Point') {
            polygonData.features.push(feature);
        } else {
            pointsData.features.push(feature);
        }
    }

    return [pointsData, polygonData];
}

const generateComparisons = (array, value) => {
    return array.map(element => ['in', ['to-string', element], value])
}

/**
 * TEMP STREET IMPROVEMENTS
 * TODO_ST
 * 1. Sync with DS team on time frame format
 * 2. Confirm geojson return type works as expected
 */
const fetchCPDData = (callBack, filterOptions) => {

    const url = `${APIGateways.streetimprovements}/get_cpd_data`;

    const filter = {
        return_type: 'geojson'
    }

    // start_time and end_time
    if (filterOptions?.timeFrame?.startDate && filterOptions?.timeFrame?.endDate) {
        filter.end_timeframe = moment(filterOptions?.timeFrame.endDate).format('YYYY-MM-DD')
        filter.start_timeframe = moment(filterOptions?.timeFrame.startDate).format('YYYY-MM-DD')
    }

    const cpd_work_type = filterOptions?.workTypes?.filter((element) => {
        return element.includes("[CPD]")
    })
    // work type
    if (cpd_work_type && cpd_work_type.length > 0) {
        filter.id = cpd_work_type.map(element => parseInt(element.split(" ")[0]));
    }

    // district
    if (filterOptions?.districts && filterOptions.districts.length > 0) {
        filter.district = filterOptions.districts;
    }

    // project_status
    if (filterOptions?.projectStatus && filterOptions.projectStatus.length > 0 ) {
        filter.project_status = filterOptions.projectStatus;
    }

    axios.post(url, {"start_timeframe":"2021-01-01","end_timeframe":"2023-12-30"})
        .then((response) => {
            return fetch(response.data)
            .then(fetchResponse => fetchResponse.json())
            .then(data => {
                callBack(data, false)
            })  
        })
        .catch((err) => {
            callBack(null, true)
        })// NotificationType for work type
}

function filterData(data, workTypes) {

    if (workTypes.length === 0) {
        return data
    }

    const notificationNumbers = []
    let currentWorkType
    for (let i = 0; i < workTypes.length; i++) {
        currentWorkType = workTypes[i]

        if (workTypes[i].includes("[CPD]")) {
            currentWorkType = currentWorkType.substring(0, currentWorkType.indexOf("[CPD]"))

            notificationNumbers.push(Number(currentWorkType))
        }

    }

    const filteredData = {
        type: 'FeatureCollection',
        features: data.features.filter(feature => {
            return notificationNumbers.includes(Number(feature.properties.NotificationNo))
        })
    }

    return filteredData
}

export const CPDProjectsLayer = ({showLayer=false}) => {

    const dispatch = useDispatch()

    const [showCPDProjectsLayer] = useActivateMapLayer(CPDProjectsLayerMetadata, showLayer);
    const [showWipCloudLayer] = useActivateMapLayer(WIPCloudLayerMetadata, showLayer);
    const beforeIDs = useSelector(selectBeforeIDs)

    const showOverlaps = useSelector((state) => selectIsLayerActive(state, 'CPD_CITY_WORK_OVERLAP'))
    const showWIPOverlaps = useSelector((state) => selectIsLayerActive(state, 'WIP_CLOUD_OVERLAP'))
    const showOverlapRadius = useSelector((state) => selectIsLayerActive(state, 'CPD_CITY_WORK_OVERLAP_RADIUS'))
    const overlapCPDIds = useSelector(selectOverlapCPDIds) ?? [];
    const searchItems = useSelector(selectSearchItems);
    const workTypes = useSelector(selectWorkTypes)

    const isCPDDisabled = useSelector(selectIsCPDDisabled)

    useEffect(() => {
        dispatch(addActiveLayer('CPD_CITY_WORK_OVERLAP'))
        dispatch(addActiveLayer('WIP_CLOUD_OVERLAP'))
    }, [])

    const [data, setData] = useState(null);
    const [wipCloudData, setwipCloudData] = useState(null);

    useLoadMapImage('cpd-p-icon', cpd_p)
    useLoadMapImage('cpd-p-conflict-icon', cpd_p_conflict)
    useLoadMapImage('cpd-p-conflict-cluster-icon', cpd_p_conflict_cluster)
    useLoadMapImage('cpd-p-highlighted-green-icon', cpd_p_highlighted_green)
    useLoadMapImage('cpd-p-highlighted-orange-icon', cpd_p_highlighted_orange)

    const [showPopup, setShowPopup] = useState(false)
    const [showwipCloudPopup, setShowwipCloudPopup] = useState(false)
    const [popupFeature, setPopupFeature] = useState(null);
    const [lngLat, setLatLng] = useState(null);
    const [target, setTarget] = useState(null);

    const websocketUrl = useSelector(selectWebsocketUrl);

    const selectedCityWork = useSelector(selectHighlightedMap)

    const baseRequest = {
        action: 'scg-async',
        resource: '/get_cpd_data', 
       }

    const [cityWorkDataRequest, setCityWorkDataRequest] = useState(baseRequest)

    const [cpdDataWebsocketResult, isLoading, error] = useCallWebsocketEndpoint(websocketUrl, cityWorkDataRequest, true)

    useEffect(() => {
        const filter = {
            return_type: 'geojson'
        }
    
        // start_time and end_time
        if (searchItems?.timeFrame?.startDate && searchItems?.timeFrame?.endDate) {
            filter.end_timeframe = moment(searchItems?.timeFrame.endDate).format('YYYY-MM-DD')
            filter.start_timeframe = moment(searchItems?.timeFrame.startDate).format('YYYY-MM-DD')
        }

        setCityWorkDataRequest({
            ...baseRequest,
            ...filter
        })
        
    }, [searchItems])

    useEffect(() => {
        if (isLoading === true) {
            dispatch(addToMapLoadingList('CPD'))
        } else {
            dispatch(removeFromMapLoadingList('CPD'))
        }

        if (error) dispatch(removeFromMapLoadingList('CPD'))

    }, [isLoading, error, dispatch])

    useEffect(() => {

        if (!cpdDataWebsocketResult) return;

        if ( workTypes.length === 0 ) {
            let arr = []
            setCPDOptions(cpdDataWebsocketResult.features.forEach( element => {
                element.properties.NotificationNo && arr.push({notificationNumber: element.properties.NotificationNo + " [CPD]" , personID: element.properties.planner_id ? element.properties.planner_id : "unknown"}) // element.properties.plannerID
            }))
            dispatch(setCPDOptions(arr))
        }

        const [pointsData, polygonData] = separateOutGeometries(cpdDataWebsocketResult);

        setData(pointsData)
        setwipCloudData(polygonData);

    }, [cpdDataWebsocketResult, searchItems])
    
    useMapEvent('click', CPD_PROJECTS_LAYER, (e) => {
        if (!isActiveLayer(e, CPD_PROJECTS_LAYER)) return;

        setShowPopup(true);
        setPopupFeature(e.features[0]);
        setLatLng(e.lngLat);
        setTarget(e.target);
    })

    useMapEvent('click', POLYGON_LAYER, (e) => {
        if (!isActiveLayer(e, POLYGON_LAYER)) return;

        setShowwipCloudPopup(true);
        setPopupFeature(e.features[0]);
        setLatLng(e.lngLat);
        setTarget(e.target);
    })

    const sourceConfig = {
        id: CPD_PROJECTS_SOURCE,
        type: "geojson",
        data: filterData(data, workTypes),
        cluster: true,
        clusterMaxZoom: 12,
        clusterRadius: 60,
        clusterProperties: {
            NotificationNoList: ['concat', ['concat', ['to-number', ['get', 'NotificationNo']], ', '], ['accumulated']],
        },
    }

    // NotificationNo

    // Leak radius circle
    const circleConfig = {
        id: CPD_PROJECTS_LAYER + '_circle',
        type: "circle",
        source: CPD_PROJECTS_SOURCE,
        filter: ['!', ['has', 'point_count']],
        layout: {
            'visibility': (showOverlapRadius && showCPDProjectsLayer && !isCPDDisabled) ? 'visible' : 'none'
        },
        paint: {
            'circle-color': '#333333',
            'circle-stroke-color': '#bababa',
            'circle-stroke-width': 2,
            'circle-opacity': 0,
            // Formula to dra a circle a given radius around the point in meters
            // Found at https://stackoverflow.com/a/70458439
            'circle-radius': [
                'interpolate',
                ['exponential', 2],
                ['zoom'],
                0, 0,
                20, [
                  '/',
                  ['/', footToMeter(LEAK_RADIUS), 0.075],
                  ['cos', ['*', ['get', 'lat'], ['/', Math.PI, 180]]],
                ],
              ],
        },
        beforeId: beforeIDs.circle
    }

    // Pins
    const symbolConfig = {
        id: CPD_PROJECTS_LAYER,
        type: 'symbol',
        source: CPD_PROJECTS_SOURCE,
        layout: {
            visibility: showCPDProjectsLayer && !isCPDDisabled ? 'visible' : 'none',
            'icon-image': [
                'case',
                ['!', ['literal', showOverlaps]], 'cpd-p-icon',
                [
                    'all',
                    ['has', 'NotificationNoList'],
                    [
                        'any', 
                        ...generateComparisons(overlapCPDIds, ['get', 'NotificationNoList'])
                    ]
                ], 'cpd-p-conflict-cluster-icon',
                ['==', ['to-number', ['get', 'NotificationNo']], selectedCityWork ? selectedCityWork.sap_id : 10], 'cpd-p-highlighted-orange-icon',
                ['==', ['to-string', ['get', 'address']], selectedCityWork ? selectedCityWork.address : 'placeholderAddress'], 'cpd-p-highlighted-green-icon',
                ['in', ['to-number', ['get', 'NotificationNo']], ['literal', overlapCPDIds]], 'cpd-p-conflict-icon',
                'cpd-p-icon'
            ],
            'icon-size': [
                'case',
                ['==', ['to-number', ['get', 'NotificationNo']], selectedCityWork ? selectedCityWork.sap_id : 10], 0.5,
                ['==', ['to-string', ['get', 'address']], selectedCityWork ? selectedCityWork.address : 'placeholderAddress'], 0.5,
                1 // Default size for other icons
            ],
            'icon-allow-overlap': true,
        },
        paint: {
            'icon-translate': [0,-27],
        }
    };

    // Work Request Polygons
    const wipCloudSource = {
        id: POLYGON_SOURCE,
        type: 'geojson',
        data: wipCloudData
    }  

    const wipCloudFillLayer = {
        id: POLYGON_LAYER,
        type: 'fill',
        source: POLYGON_SOURCE,
        paint: {
            'fill-color': [
                'case',
                ['!', ['literal', showOverlaps]], '#EA3323',
                ['in', ['to-number', ['get', 'NotificationNo']], ['literal', overlapCPDIds]], '#D45D00',
                '#EA3323'
            ],
            'fill-opacity': 0.1
        } ,
        layout: {
            visibility: showWipCloudLayer ? 'visible' : 'none'
        },
        beforeId: beforeIDs.fill
    }

    const wipCloudLineLayer = {
        id: POLYGON_LAYER + '_LINE',
        type: 'line',
        source: POLYGON_SOURCE,
        paint: {
            "line-color": [
                'case',
                ['!', ['literal', showWIPOverlaps]], '#EA3323',
                ['in', ['to-number', ['get', 'NotificationNo']], ['literal', overlapCPDIds]], '#D45D00',
                '#EA3323'
            ],
            "line-width": 2,
            "line-dasharray": [3, 1]
        },
        layout: {
            visibility: showWipCloudLayer ? 'visible' : 'none'
        },
        beforeId: beforeIDs.fill
    }


    return (
        <>
        <Source {...sourceConfig}>
            <Layer {...circleConfig}/>
            <Layer {...symbolConfig} />
            <CPDProjectsPopup 
                isOpen={showPopup}
                onClose={() => setShowPopup(false)}
                lngLat={lngLat}
                feature={popupFeature}
                target={target}
            />
        </Source>
        <Source {...wipCloudSource}>
            <Layer {...wipCloudFillLayer}/>
            <Layer {...wipCloudLineLayer}/>
            <WipCloudPopup 
                isOpen={showwipCloudPopup}
                onClose={() => setShowwipCloudPopup(false)}
                lngLat={lngLat}
                feature={popupFeature}
                target={target}
            />
        </Source>
        </>
    )
}

export const CPDProjectsLegendSection = () => {
    const showLegend = useSelector((state) => selectIsLayerActive(state, CPDProjectsLayerMetadata.layerId))

    if (!showLegend) {
        return <></>
    }

    return (
        <div >
            <p style={{marginBottom: '8px'}}><img src={cpd_p} alt="CPD Project Logo" height="29px"/> CPD Project</p>
        </div>
    )
}

export const CPDProjectsOverlapLegendSection = () => {
    const showLegend = useSelector((state) => selectIsLayerActive(state, CPDProjectsLayerMetadata.layerId))

    if (!showLegend) {
        return <></>
    }

    return (
        <div >
            <p style={{marginBottom: '8px'}}><img src={cpd_p_conflict_cluster} alt="CPD Project Logo" height="29px"/> CPD Project (&gt;= 1 Overlaps)</p>
            <p style={{marginBottom: '8px'}}><img src={cpd_p_conflict} alt="CPD Project Logo" height="29px"/> CPD Project Overlap</p>
        </div>
    )
}