// main
export const DYNAMIC_CONFIG = 'DYNAMIC_CONFIG';

// endpoint
export const GET_TOP_IMPACTED_COMMUNITIES = 'emissions/tracts'
export const GET_EMISSIONS = 'emissions'
export const GET_REPLACEMENT_TABLE = 'vehicles/replace'
export const GET_SWAPPING_TABLE = 'vehicles/swap'

export const RESET_SELECTED_KEY = 'RESET_SELECTED_KEY';
export const SELECTED_KEY = 'SELECTED_KEY';
export const FLEET_COMPONENT = 'FLEET_COMPONENT';
export const TIME_FRAME_COMPONENT = 'TIME_FRAME_COMPONENT';
export const DISTRICT_COMPONENT = 'DISTRICT_COMPONENT';
export const QUALITY_AIR_COMPONENT = 'QUALITY_AIR_COMPONENT';

export const METHANE_COMPONENT = 'METHANE_COMPONENT';
export const PROJECT_STATUS_FILTER = 'PROJECT_STATUS_FILTER';
export const WORK_TYPE_COMPONENT = 'WORK_TYPE_COMPONENT';
export const LEAK_STATUS_COMPONENT = 'LEAK_STATUS_COMPONENT';
export const LEAK_CODE_COMPONENT = 'LEAK_CODE_COMPONENT';
export const CITY_FILTER_STATUS = 'CITY_FILTER_STATUS';
export const DISTRICT_FILTER = 'DISTRICT_FILTER';

export const FILTER_COMPONENT = 'FILTER_COMPONENT';
export const CENSUS_TRACTS_COMPONENT = 'CENSUS_TRACTS_COMPONENT';
export const SET_SELECT_STEPS = 'SET_SELECT_STEPS';
export const SET_MENU_CONTENT = 'SET_MENU_CONTENT';
export const ACTION_BUTTON = 'ACTION_BUTTON';
export const SET_EMISSION_TYPE = 'SET_EMISSION_TYPE';
export const SET_ALLOWED_SEARCH = 'SET_ALLOWED_SEARCH';
export const MAPBOX_IMPACTED_COMMUNITIES = 'MAPBOX_IMPACTED_COMMUNITIES';
export const SET_MENU_CENSUS_TRACTS_DACS = 'SET_MENU_CENSUS_TRACTS_DACS';
export const SET_MENU_TOP_IMPACTED_COMMUNITIES = 'SET_MENU_TOP_IMPACTED_COMMUNITIES';
export const SET_MENU_REPLACEMENT_VEHICLES = 'SET_MENU_REPLACEMENT_VEHICLES';
export const SET_MENU_SWAPPING_VEHICLES = 'SET_MENU_SWAPPING_VEHICLES';
export const SET_AUTH_USER = 'SET_AUTH_USER';
export const SET_SWAPPING_CSV_FILE = 'SET_SWAPPING_CSV_FILE';
export const SET_REPLACEMENT_CSV_FILE = 'SET_REPLACEMENT_CSV_FILE';
export const SET_SWAPPING_REQUEST = 'SET_SWAPPING_REQUEST';
export const SET_SWAPPING_LAST_REQUEST = 'SET_SWAPPING_LAST_REQUEST';
export const SET_REPLACEMENT_REQUEST = 'SET_REPLACEMENT_REQUEST';
export const SET_REPLACEMENT_LAST_REQUEST = 'SET_REPLACEMENT_LAST_REQUEST';

export const SET_SEARCH = 'SET_SEARCH';
export const RESET_SEARCH_DATE = 'RESET_SEARCH_DATE';
export const RESET_SEARCH_VEHICLES = 'RESET_SEARCH_VEHICLES';
export const RESET_SEARCH_COMMUNITIES = 'RESET_SEARCH_COMMUNITIES';
export const RESET_SEARCH_PARAMETERS = 'RESET_SEARCH_PARAMETERS';
export const RESET_SEARCH_VEHICLES_PARAMETERS = 'RESET_SEARCH_VEHICLES_PARAMETERS';
export const RESET_SEARCH_CENSUS_PARAMETERS = 'RESET_SEARCH_CENSUS_PARAMETERS';
export const RESET_CITY_FILTER = 'RESET_CITY_FILTER';
export const RESET_DISTRICT_FILTER = 'RESET_DISTRICT_FILTER';
export const RESET_MUNICIPALITIES_FILTER = 'RESET_MUNICIPALITIES_FILTER';
export const RESET_LEAK_STATUS_FILTER = 'RESET_LEAK_STATUS_FILTER';
export const RESET_LEAK_CODE_FILTER = 'RESET_LEAK_CODE_FILTER';

// Set Search params
export const START_SEARCH_DATE = 'START_SEARCH_DATE'
export const END_SEARCH_DATE = 'END_SEARCH_DATE'
export const REMOVE_SEARCH_CENSUS_TRACTS = 'REMOVE_SEARCH_CENSUS_TRACTS'
export const SET_SEARCH_FLEET = 'SET_SEARCH_FLEET'
export const SET_SEARCH_CENSUS_TRACTS = 'SET_SEARCH_CENSUS_TRACTS';
export const SET_SEARCH_COMMUNITIES = 'SET_SEARCH_COMMUNITIES';
export const SET_PROJECT_STATUS = 'SET_PROJECT_STATUS'
export const SET_CITIES = 'SET_CITIES';
export const SET_WORK_TYPES = 'SET_WORK_TYPES';
export const SET_DISTRICTS = 'SET_DISTRICTS';
export const SET_MUNICIPALITIES = 'SET_MUNICIPALITIES';
export const SET_LEAK_STATUSES = 'SET_LEAK_STATUSES';
export const SET_LEAK_CODES = 'SET_LEAK_CODES';


// Time Frame
export const SET_CENSUS_TRACTS = 'SET_CENSUS_TRACTS'
export const SET_SEARCH_ALL_CENSUS_TRACTS = 'SET_SEARCH_ALL_CENSUS_TRACTS'

// Map State
export const DAC_DISTRICTS = 'DAC_DISTRICTS'
export const ALL_DISTRICTS = 'ALL_DISTRICTS'
export const ALL_VEHICLES = 'ALL_VEHICLES'
export const SET_HEATMAP = 'SET_HEATMAP'
export const SET_DISTRICT = 'SET_DISTRICT'
export const SET_MAP_COMMUNITIES = 'SET_MAP_COMMUNITIES'
export const SET_MAP_SCENARIO = 'SET_MAP_SCENARIO'
export const SET_MAP_TABLES = 'SET_MAP_TABLES'
export const SET_MAP_CENSUS_TRACTS = 'SET_MAP_CENSUS_TRACTS'
export const RESET_MAP_CENSUS_TRACTS = 'RESET_MAP_CENSUS_TRACTS'
export const SET_MAP_REPLACEMENT_TABLE = 'SET_MAP_REPLACEMENT_TABLE'
export const SET_MAP_QUALITY_AIR = 'SET_MAP_QUALITY_AIR'
export const SET_MAP_FLEET_IMPACT = 'SET_MAP_FLEET_IMPACT'
export const RESET_MAP_POSITION = 'RESET_MAP_POSITION'
export const SET_MAP_POSITION = 'SET_MAP_POSITION'
export const SET_SIDE_BAR_VISIBILITY = "SET_SIDE_BAR_VISIBILITY"
export const SET_SIDE_BAR_CONTENT = "SET_SIDE_BAR_CONTENT"

//Fleet
export const VEHICLE_FILTER = 'VEHICLE_FILTER'
export const FILTERED_VEHICLES = 'FILTERED_VEHICLES'
export const SET_FLEET_VEHICLES_FILTER = 'SET_FLEET_VEHICLES_FILTER'
export const RESET_FLEET_VEHICLE_FILTER = 'RESET_FLEET_VEHICLE_FILTER'
export const SET_MENU_VEHICLE_LIST = 'SET_MENU_VEHICLE_LIST'
export const RESET_MENU_VEHICLE_LIST = 'RESET_MENU_VEHICLE_LIST'

// MapBox
export const MAPBOX_SOURCE = 'MAPBOX_SOURCE'
export const MAPBOX_REGION = 'MAPBOX_REGION'
export const MAPBOX_DISTRICTS = 'MAPBOX_DISTRICTS'
export const MAPBOX_METHANE = 'MAPBOX_METHANE'
export const MAPBOX_BOUNDARIES = 'MAPBOX_BOUNDARIES'
export const MAPBOX_EMISSIONS_SOURCE = 'MAPBOX_EMISSIONS_SOURCE'
export const MAPBOX_ZOOM = 'MAPBOX_ZOOM'

// Last Call
export const SET_LAST_CALL = 'SET_LAST_CALL';
export const SET_LAST_CALL_DATE = 'SET_LAST_CALL_DATE';


// constants
export const START = 'start';
export const END = 'end';

//sidebar

export const SET_SELECTED_KEY = "SET_SELECTED_KEY"

