import {
    VEHICLE_FILTER,
    FILTERED_VEHICLES,
    RESET_FLEET_VEHICLE_FILTER,
    SET_FLEET_VEHICLES_FILTER
} from '../../redux/actionTypes';

const initialState = {
    vehicles: [],
    filteredVehicles: null,
    fleetVehiclesFilter: {
        groupOwner: null,
        vehicleClass: null,
        energyType: null,
        districts: null,
        vehicleName: null
    }

};
const sidebar = (state = initialState, {type, payload}) => {
    switch (type) {
        case  VEHICLE_FILTER:
            return {...state, vehicles: payload};
        case FILTERED_VEHICLES:
            return {...state, filteredVehicles: payload};
        case SET_FLEET_VEHICLES_FILTER:
            return {...state, fleetVehiclesFilter: payload}
        case RESET_FLEET_VEHICLE_FILTER:
            return {...state, fleetVehiclesFilter: initialState.fleetVehiclesFilter}
        default:
            return state;
    }
};
export default sidebar;