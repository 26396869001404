import React from "react";
import styled from "styled-components";

const Title = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0;

  background:rgba(255, 255, 255, 0.1);
  border-radius: 4px;
  height: 48px;
  opacity: 1;
  width: ${ ({ width }) => width };
`

const TableTitles = ({ children, width, padding}) => {

  return (
    <Title width={width} padding={padding}>
      {children}
    </Title>
  )
}

export default TableTitles