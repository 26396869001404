import { Layer, Source } from "react-map-gl"
import { useEffect, useRef, useState } from "react";
import { selectBeforeIDs, isActiveLayer, selectIsLayerActive, useActivateMapLayer, useLoadMapImage, useMapEvent } from "@innovation-toolkit/mapbox";
import caution_yellow from 'assets/pattern/caution_yellow.png';
import caution_red from 'assets/pattern/caution_red.png';
import caution_blue from 'assets/pattern/caution_blue.png';
import caution_light_blue from 'assets/pattern/caution_light_blue.png';
import { useDispatch, useSelector } from "react-redux";
import { LAStreetImprovementPopup } from "./popup";
import { UNCLUTTERED_MIN_ZOOM } from "../../constants";
import { selectCityWorkData, selectDrawnGeometries, selectRowUpdateInfo, selectShowMoratoriumMapData, selectIsEditingRows, selectHighlightedMap } from "redux/streetImprovements/selector";
import { MORATORIUMS_LAYER } from "../Moratoriums";
import { setSideBarContent, setSideBarVisibility } from "redux/mapStateInternal/action";
import { openNotification } from "@innovation-toolkit/ui";

export const COMMITTED_PROJECTS_LAYER = 'COMMITTED_PROJECTS_LAYER';
export const COMMITTED_PROJECTS_SOURCE = 'COMMITTED_PROJECTS_SOURCE';

export const LAStreetImprovementLayerMetadata = {
    layerId: COMMITTED_PROJECTS_LAYER,
    name: 'City Projects',
    color: '#F9ED00',
    layerSymbols: [
        { symbol: caution_yellow, symbolName: 'caution_yellow' },
        { symbol: caution_red, symbolName: 'caution_red' },
        { symbol: caution_blue, symbolName: 'caution_blue' },
        { symbol: caution_light_blue, symbolName: 'caution_light_blue' }
    ]
}

export const LAStreetImprovementLayer = ({showLayer=false}) => {
    const dispatch = useDispatch()

    const [showLAStreetImprovementLayer] = useActivateMapLayer(LAStreetImprovementLayerMetadata, showLayer)
    const shouldShowMoratoriums = useSelector(selectShowMoratoriumMapData)

    const beforeIDs = useSelector(selectBeforeIDs)
    const patterns = { red: 'caution_red', yellow: 'caution_yellow', blue: 'caution_blue', light_blue: 'caution_light_blue' }
    
    const [showPopup, setShowPopup] = useState(false);
    const [popupFeature, setPopupFeature] = useState(null);
    const [lngLat, setLatLng] = useState(null);
    const [target, setTarget] = useState(null);

    const rowUpdateInfo = useSelector(selectRowUpdateInfo);
    const editingRows = useSelector(selectIsEditingRows)
    const cityWorkData = useSelector(selectCityWorkData);
    const tempProjectGeometry = useSelector(selectDrawnGeometries);
    const selectedCityWork = useSelector(selectHighlightedMap)
    const isEditingRows = useRef()

    useLoadMapImage(patterns.yellow, caution_yellow)
    useLoadMapImage(patterns.red, caution_red)
    useLoadMapImage(patterns.blue, caution_blue)
    useLoadMapImage(patterns.light_blue, caution_light_blue)

    useEffect(() => {
        isEditingRows.current = editingRows
    }, [editingRows])
    
    useMapEvent('click', COMMITTED_PROJECTS_LAYER, (e) => {
        if (!isActiveLayer(e, COMMITTED_PROJECTS_LAYER)) return;

        if (isEditingRows.current) {
            openNotification({
                placement: 'bottomRight',
                header: 'Work is not saved', 
                notificationMessage: 'Your latest edit was not saved properly. Please save or cancel your work before continuing.',
                type: 'warning'
            })
        } else {
            const popupData = { siPopup: { isOpen: true, feature: e.features[0], setPopupFeature: setPopupFeature, lngLat: e.lngLat, target: e.target}}
            dispatch(setSideBarContent(popupData))
            dispatch(setSideBarVisibility(true))
        }
    })

    useMapEvent('click', COMMITTED_PROJECTS_LAYER + "_symbol_red", (e) => {
        if (!isActiveLayer(e, COMMITTED_PROJECTS_LAYER)) return;

        if (isEditingRows.current) {
            openNotification({
                placement: 'bottomRight',
                header: 'Work is not saved', 
                notificationMessage: 'Your latest edit was not saved properly. Please save or cancel your work before continuing.',
                type: 'warning'
            })
        } else {
            const popupData = { siPopup: { isOpen: true, feature: e.features[0], setPopupFeature: setPopupFeature, lngLat: e.lngLat, target: e.target}}
            dispatch(setSideBarContent(popupData))
            dispatch(setSideBarVisibility(true))
        }
    })

    useMapEvent('click', COMMITTED_PROJECTS_LAYER + "_symbol_yellow", (e) => {
        if (!isActiveLayer(e, COMMITTED_PROJECTS_LAYER)) return;

        if (isEditingRows.current) {
            openNotification({
                placement: 'bottomRight',
                header: 'Work is not saved', 
                notificationMessage: 'Your latest edit was not saved properly. Please save or cancel your work before continuing.',
                type: 'warning'
            })
        } else {
            const popupData = { siPopup: { isOpen: true, feature: e.features[0], setPopupFeature: setPopupFeature, lngLat: e.lngLat, target: e.target}}
            dispatch(setSideBarContent(popupData))
            dispatch(setSideBarVisibility(true))
        }
    });

    const handlePopupClose = () => {
        if (rowUpdateInfo && rowUpdateInfo.isUpdating) return;
        setShowPopup(false);
    }

    const sourceConfig = {
        id: COMMITTED_PROJECTS_SOURCE,
        type: "geojson",
        data: cityWorkData
    }

    const tempProjectSourceConfig = {
        id: COMMITTED_PROJECTS_SOURCE + 'temp_project',
        type: "geojson",
        data: tempProjectGeometry
    }

    const lineConfig = {
        id: COMMITTED_PROJECTS_LAYER,
        type: "line",
        source: COMMITTED_PROJECTS_SOURCE,
        minzoom: UNCLUTTERED_MIN_ZOOM,
        layout: {
            'visibility': showLAStreetImprovementLayer ? 'visible' : 'none'
        },
        filter: [
                'any',
                // If item is a project and projects are toggled on
                ['all', ["==", ['get', 'current_status'], "project" ], showLAStreetImprovementLayer],
                // If item is a moratorium and moratoriums are toggled on
                ['all', ["==", ['get', 'current_status'], "moratorium" ], shouldShowMoratoriums],
        ],
        paint: {
            'line-width': 4,
            'line-pattern': [
                'case',
                //12.34 is just a placeholder number, it wouldn't match any lat from the data
                ["==", ['get', 'lat'], selectedCityWork ? selectedCityWork.lat : '12.34'], patterns.light_blue,
                ["==", ['get', 'current_status'], "project" ], patterns.yellow,
                patterns.red
            ]
            // [
            //     'case',
            //     ['get', 'is_project'], 'caution-tape',
            //     ['get', 'is_moratorium'], 'caution-red',
            //     'caution-tape'
            // ]
        },
        beforeId: beforeIDs.line
    }

    const tempLineConfig = {
        id: COMMITTED_PROJECTS_LAYER + "_line_blue",
        type: "line",
        source: COMMITTED_PROJECTS_SOURCE + 'temp_project',
        minzoom: UNCLUTTERED_MIN_ZOOM,
        layout: {
            'visibility': showLAStreetImprovementLayer ? 'visible' : 'none'
        },
        paint: {
            'line-width': 4,
            'line-pattern': [
                'case',
                //12.34 is just a placeholder number, it wouldn't match any lat from the data
                ["==", ['get', 'lat'], selectedCityWork ? selectedCityWork.lat : '12.34'], patterns.light_blue,
                patterns.blue
            ]
        },
        beforeId: beforeIDs.line
    }

    const redSymbolConfig = {
        id: COMMITTED_PROJECTS_LAYER + "_symbol_red",
        type: "symbol",
        minzoom: UNCLUTTERED_MIN_ZOOM,
        filter: [
            'all',
            ["==", ["geometry-type"], "Point"],
            ["!=", ['get', 'current_status'], "project" ]
        ],
        source: COMMITTED_PROJECTS_SOURCE,
        layout: {
            'icon-image': [
                'case',
                //12.34 is just a placeholder number, it wouldn't match any lat from the data
                ["==", ['get', 'lat'], selectedCityWork ? selectedCityWork.lat : '12.34'], patterns.light_blue,
                patterns.red
            ],
            'icon-size': 0.02,
            'visibility': showLAStreetImprovementLayer ? 'visible' : 'none'
        }
    }

    const yellowSymbolConfig = {
        id: COMMITTED_PROJECTS_LAYER + "_symbol_yellow",
        type: "symbol",
        minzoom: UNCLUTTERED_MIN_ZOOM,
        filter: [
            'all',
            ["==", ["geometry-type"], "Point"],
            ["==", ['get', 'current_status'], "project" ]
        ],
        source: COMMITTED_PROJECTS_SOURCE,
        layout: {
            'icon-image': [
                'case',
                //12.34 is just a placeholder number, it wouldn't match any lat from the data
                ["==", ['get', 'lat'], selectedCityWork ? selectedCityWork.lat : '12.34'], patterns.light_blue,
                patterns.yellow
            ],
            'icon-size': 0.02,
            'visibility': showLAStreetImprovementLayer ? 'visible' : 'none'
        }
    }

    return (
        <>
            <Source {...sourceConfig}>
                <Layer {...lineConfig} />
                {/* <LAStreetImprovementPopup 
                    isOpen={rowUpdateInfo.isUpdating || showPopup}
                    onClose={handlePopupClose}
                    lngLat={lngLat}
                    feature={popupFeature}
                    target={target}
                    setPopupFeature={setPopupFeature}
                    setLngLat={setLatLng}
                /> */}
                <Layer {...yellowSymbolConfig}/>
                <Layer {...redSymbolConfig}/>

            </Source>
            <Source {...tempProjectSourceConfig}>
                <Layer {...tempLineConfig}/>
            </Source>
        </>
    )
}

LAStreetImprovementLayer.layerName = 'Charitable';
LAStreetImprovementLayer.legendColor = '#FFFFFF';

const LineComponent = ({pattern, color, label}) => {
    return (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                gap: '10px',
                // marginBottom: '14px'
            }}
        >
            <div
                style={{
                    height: '5px',
                    width: '22px',
                    backgroundImage: `url(${pattern})`,
                    backgroundRepeat: 'repeat',
                    backgroundSize: 'contain',
                    border: '1px solid #FFFFFF50',
                    marginLeft: '3px'
                }}
            ></div>            
            <p
                style={{
                    padding: '0',
                    margin: '5px'
                }}
            >
                {label}
            </p>
        </div>
        
    )
}

export const LAStreetImprovementLegendSection = () => {
    const showLegend = useSelector((state) => selectIsLayerActive(state, LAStreetImprovementLayerMetadata.layerId))

    if (!showLegend) {
        return <></>
    }

    return (
        <LineComponent pattern={caution_yellow} label='Street Improvement Projects*'/>
    )
}