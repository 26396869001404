import React, { useEffect } from "react";
import { Checkbox as AntCheckbox, Spin } from 'antd';
import styled from "styled-components";
import { DelimiterLine } from "shared/components/delimiter";

const CheckboxGroup = AntCheckbox.Group;

const Wrapper = styled.div`
  padding: 0.5rem 0 0 1.5rem;

  label {
    color: white;
  }

  .ant-checkbox-group {

    & label {
      padding: 4px;
    }
  }
`

const StyledSpin = styled(Spin)`
  padding-top: ${({ spinning }) => spinning ? "35px" : '0'};
  position: relative !important;
  //
  //overflow: scroll;
  //height: calc(100% - 52px);
  //overflow-x: hidden;

`

export const StyledCheckbox = styled(AntCheckbox)`
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;

  p {
    padding-left: 4px;
  }
`

const StyledCheckboxGroup = styled(CheckboxGroup)`
  display: flex;
  flex-direction: column;
`

const CheckboxWrapper = styled.div`
  overflow: ${({ load }) => load ? 'hidden' : 'scroll'};
  height: ${({ load, selectAllDisabled }) => load ? '100%' : selectAllDisabled ? '100%' : 'calc(100% - 52px)'} ;
  overflow-x: ${({ load }) => load ? 'hidden' : 'hidden'};
  
  label {
    padding: .25rem 0 .25rem 0!important;
  }
`

const CheckboxFilter = ({
    options = [],
    defaultValue = [],
    checked = [],
    onChange,
    handleItem,
    handleCheckAll,
    selectAllLabel = "Select All",
    disableSelectAll = false
}) => {
  const [value, setValue] = React.useState(defaultValue);
  const [indeterminate, setIndeterminate] = React.useState(false);
  const [checkAll, setCheckAll] = React.useState(false);

  useEffect(() => handleCheckAll(checkAll), [checkAll])

  useEffect(() => {
    if (defaultValue?.length === 0 || !defaultValue) {
      setValue(defaultValue)
      setCheckAll(false)

    } else {
      setValue(defaultValue)
    }
  }, [defaultValue, setValue, setCheckAll])

  useEffect(() => {
    let newListValue = value;
    if (value !== null && value.length === 0) {
      newListValue = null;
    }
    handleItem(newListValue);
  }, [value])

  const handleOnChange = list => {
    setValue(list);
    setIndeterminate(!!list.length && list.length < options.length);
    setCheckAll(list.length === options.length);
  };
  const onCheckAllChange = e => {
    setValue(e.target.checked ? options.map(item => item.value) : []);
    setIndeterminate(false);
    setCheckAll(e.target.checked);
    onChange(e.target.checked)
  };

  return (
    <Wrapper>
      {!disableSelectAll && (
        <>
          <div>
            <StyledCheckbox
              indeterminate={indeterminate}
              onChange={onCheckAllChange}
              checked={checkAll}
              disabled={options.length === 0}
            >
              <div class='body-primary-text'>{selectAllLabel}</div>
            </StyledCheckbox>
          </div>
          <DelimiterLine
            right={0}
            top={16}
            style={{ width: "224px", marginLeft: "-8px" }}
          />
        </>
      )}

      <CheckboxWrapper load={options.length === 0} selectAllDisabled={disableSelectAll}>
        <StyledSpin spinning={options.length === 0} tip="Loading...">
          <StyledCheckboxGroup
            options={options}
            value={checked}
            defaultValue={value}
            onChange={handleOnChange}
            scroll={"true"}
          />
        </StyledSpin>
      </CheckboxWrapper>
    </Wrapper>
  )
};

export default CheckboxFilter;