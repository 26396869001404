import React from "react"
import { DelimiterLine } from "shared/components/delimiter"
import styled from "styled-components"
import { ChevronRightIcon } from "shared/style/iconography/chevronRight.jsx"

const HeaderWrapper = styled.div`
  height: calc(100vh - 162px); // full height minus height of filter action buttons
  background-color: ${({ theme }) => theme.color.prussianBlue};
  width: 256px;

  h2 {
    padding: 2.5px 16px 2.5px 16px;
    margin-bottom: 6px;
  }
`

const BackButtonContainer = styled.div`
  transform: rotate(180deg);
  display: inline-block;
  cursor: pointer;
`

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 6px;
  margin-left: 16px;
  margin-right: 16px;
`

const HeaderContent = ({ title = "", closeTabs, children, style }) => {
  return (
    <HeaderWrapper style={style}>
      <TitleWrapper>
        <BackButtonContainer onClick={closeTabs}>
          <ChevronRightIcon />
        </BackButtonContainer>
        <h2 style={{marginBottom: '0', paddingLeft: '10px', fontWeight: 700, fontSize: '16px'}}>
          {title}
        </h2>
      </TitleWrapper>
      <DelimiterLine right={16} top={0} />
      {children}
    </HeaderWrapper>
  )
}

export default HeaderContent
