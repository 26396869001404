import { Collapse } from 'antd'
import { useRef, useEffect } from 'react';
import styled from 'styled-components'
import { color, CloseIcon, Typography, DelimiterLine } from '@innovation-toolkit/ui'
const { Panel } = Collapse;

const StyledPanel = styled.div`
  background: ${ color.prussianBlue };
  border-left: ${({ highlightColor }) => `4px solid ${highlightColor}`};
  height: 100%;
  width: ${({ width }) => width };
  overflow-y: auto;
  position: relative;
  p {
    font-size: 14px;
    margin-top: 0;
    margin-bottom: 12px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
`
const StyledCloseButton = styled.div`
  cursor: pointer;

  svg, img {
    width: 14px;
    height: 14px;
  }
`;

const HeaderSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #fff;
  padding: 25px 16px 16px 16px;
`

const OverviewSection = styled.div`
  padding: 16px;
`

const DetailPanel = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-style: solid;
  border-width: 0 4px 1px 0;
  border-color: ${ ({ highlightColor }) => highlightColor };
  background-color: ${({highlightColor, isSelected}) => isSelected ? highlightColor : 'none'};

  .segment-panel-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    background-color: ${({highlightColor, isSelected}) => isSelected ? highlightColor : 'none'};

    .data-point-wrapper {
        color: #fff !important;
    }
    padding: 8px 16px 0px 12px;
  }

  .justify-right {
    text-align: right;
  }
`

const StyledCollapse = styled(Collapse)`
  .ant-collapse-header {
    padding: 8px 16px 8px 12px !important;
    border-radius: 0 !important;
    background-color: ${({highlightColor, isSelected}) => isSelected ? highlightColor : 'none'};

    &[aria-expanded="true"]{
      .ant-collapse-expand-icon {
        svg {
          transform: rotate(270deg) !important;
        }
      }
    }

    .ant-collapse-header-text {
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 22px;
      color: ${({isSelected, darkText}) => isSelected && darkText ? color.prussianBlue : '#fff'};
    }

    .ant-collapse-expand-icon {
      .ant-collapse-arrow {
        right: 16px;
      }
      svg {
        width: 16px;
        height: 16px;
        transform: rotate(90deg);
        color: ${({isSelected, darkText}) => isSelected && darkText ? color.prussianBlue : '#fff'};
      }
    }
  }

  .ant-collapse-content {
    color: #fff;
    border-top: 1px solid ${({highlightColor}) => highlightColor };
    border-radius: 0 !important;
    background-color: ${ color.prussianBlue };
  }
  
  .ant-collapse-content-box {
    overflow-y: auto;
    max-height: 256px;
    background-color: rgba(255, 255, 255, 0.1);
    padding: 8px 16px 8px 12px !important;
  }
`

export const highlightColorMap = {
    High: '#B62E06',
    Medium: '#F5C846',
    Low: '#32BF8A',
    Minimal: '#2166AC',
    NotPrioritized: '#5A5A5A',
    Orange: '#EF7215',
    Purple: '#AA4499',
    DarkBlue: '#06359D',
    Turquoise: '#005383',
    LightGreen: '#99C255'
}

export const RightSideBar = ({
    sidePanelTitle = "Side Panel Title",
    showSidePanel = false,
    highlightColor, // High | Medium | Low | Minimal | NotPrioritized | string | undefined
    width = '280px',
    onClose,
    overviewSectionChildren,
    detailItems = [{ 
        highlightColor: 'Low', 
        isTextDark: true,
        header: 'Public EV Chargers', 
        title: "1 Charger", 
        isSelected: false, 
        panelContent: "" /*, ranking: { title: "ZZZ", text: "ranking"}*/}]
}) => {
    // const [shouldShow, setShouldShow] = useState('280px')

    const handleClose = (e) => {
        onClose()
    }

    return (
        <>
        { showSidePanel && 
            <StyledPanel width={width} highlightColor={Object.keys(highlightColorMap).includes(highlightColor) ? highlightColorMap[highlightColor] : highlightColor}>
                <HeaderSection>
                    <Typography text={sidePanelTitle} variant='Heading 1' />
                    <StyledCloseButton onClick={handleClose}>
                        <CloseIcon />
                    </StyledCloseButton>
                </HeaderSection>

                { overviewSectionChildren && 
                    <>
                        <DelimiterLine style={{ margin: '2px 0 0 0'}}/>
                        <OverviewSection>
                            {overviewSectionChildren}
                        </OverviewSection>
                        <DelimiterLine style={{ margin: '0 0 2px 0'}}/>
                    </>
                }

                { detailItems && 
                    detailItems.map( (item, index) => {
                        return (
                            <DetailPanel isSelected={item.isSelected} key={index} highlightColor={Object.keys(highlightColorMap).includes(item.highlightColor) ? highlightColorMap[item.highlightColor] : item.highlightColor}>
                                <div className="space-between segment-panel-header">
                                    <Typography text={item.header} variant='Heading 4' style={{color: item.isSelected && item.isTextDark ? color.prussianBlue : '#FFF'}}/>

                                    { item.ranking &&
                                        <div className="justify-right">
                                            <Typography text={item.ranking.title} variant='Heading 1' style={{textAlign: 'right', color: item.isSelected && item.isTextDark ? color.prussianBlue : '#FFF'}}/>
                                            <Typography text={item.ranking.text} variant='Body 1' style={{textAlign: 'right', color: item.isSelected && item.isTextDark ? color.prussianBlue : '#FFF'}}/>
                                        </div>
                                    }
                                </div>
                                <StyledCollapse
                                    highlightColor={Object.keys(highlightColorMap).includes(item.highlightColor) ? highlightColorMap[item.highlightColor] : item.highlightColor}
                                    expandIconPosition='end'
                                    isSelected={item.isSelected}
                                    darkText={item.isTextDark}
                                >
                                    <Panel header={item.title} key={item.title} >
                                        {item.panelContent}
                                    </Panel>
                                </StyledCollapse>
                            </DetailPanel>
                        )
                    })
                }
            </StyledPanel>
        }
        </>
    )
}

