import { DrawControl, useMapEvent } from '@innovation-toolkit/mapbox';
import { useSelector } from 'react-redux';
import { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { 
    setDrawnGeometries,
    selectRowUpdateInfo 
} from '@redux/cityWorkAggregation/slice';
import { openNotification } from '@innovation-toolkit/ui';

const combineLineGeometries = (features) => {
    let featureCollection = {
        type: 'FeatureCollection',
        features: features
    };
    if (features.length > 1) {
        let combinedFeatures = [{
            type: "Feature",
            geometry:  {
                "type": "MultiLineString",
                "coordinates": features.map(element => element.geometry.coordinates)
              },
              properties: {},
              id: features[0].id
        }
       ]

       featureCollection.features = combinedFeatures
    }

    return featureCollection

}

export const DrawContainer = () => {
    // const draw = useSelector(selectDrawRef);
    const drawRef = useRef();
    const dispatch = useDispatch()
    const rowUpdateInfo = useSelector(selectRowUpdateInfo)

    useEffect(() => {
        if (!drawRef.current) return 

        console.log(drawRef.current.getAll().features)
       
    }, [drawRef]);


    useEffect(() => {
        if (!drawRef.current) return;
        if (rowUpdateInfo.isUpdating) {            
            openNotification({
                placement: 'top-right',
                notificationMessage: 'Click on line draw tool on map to add lines to the map. Add as many lines as needed then click save in the edit table.'
            })
        }

    }, [rowUpdateInfo.isUpdating, drawRef]);
    // console.log("drawRef", drawRef?.current?.getAll()?.features)
    const updateGeometries = () => {  
        if (!drawRef) return 
        
        const combinedGeometry = combineLineGeometries(drawRef.current.getAll().features);
        dispatch(setDrawnGeometries(combinedGeometry));
    };

    useMapEvent('draw.update', () => {
        updateGeometries()
    })
    useMapEvent('draw.create', () => {
        updateGeometries()
    })
    useMapEvent('draw.delete', () => {
        updateGeometries()
    })

    if (!rowUpdateInfo.isUpdating) return <></>

    return (
        <DrawControl
            position="top-right"
            displayControlsDefault={false}
            controls={{
                trash: true,
                line_string: true,
            }}
            onCreate={(e) => {}}
            onUpdate={() => {}}
            onDelete={() => {}}

            setRef={(ref) => {
                if (!ref || !ref.current) return

                drawRef.current = ref.current
            }}
        />
    );
};
