import { useDispatch, useSelector } from 'react-redux';
import { AbatementOptimizationAnalysis } from './analysis';
// redux
import { setMenuSelectedKey } from '@redux/menu/actions';
import { selectSelectedKey } from '@redux/menu/selectors';
import { abatementOptimizationActions } from 'component/sidebar/menuItems';

import { Typography, LayerToggleSection, Menu, MainMenu, MenuTabContents, LayerTabWrapper, TitleDivider, color, DACIcon } from '@innovation-toolkit/ui';
import MenuTabButtons from 'component/sidebar/tabs/MenuTabButtons';
import { useState } from 'react';
import { useEffect } from 'react';
import { VegetationLayerToggle } from './layers/Vegetation/toggle';
import { HazmatToggle } from './sharedLayerToggles';
import { NHSLayerToggle } from './layers/NationalHighwaySystem/toggle';
import { EagleAwarenessLayerToggle } from './layers/EagleAwareness/toggle';
import { NFABLayerToggle } from './layers/NationalForestAdministrativeBoundaries/toggle';
import { BLMLayerToggle } from './layers/BLM/toggle';
import { CNDDBLayerToggle } from './layers/CNDDB/toggle';
import { TribalLandsLayerToggle, HospitalsLayerToggle, RacialEquityLayerToggle, addToMapLoadingList, removeFromMapLoadingList, DisadvantagedCommunityLayerToggle } from '@innovation-toolkit/mapbox'
import { selectSearchItems } from '@redux/searchItems/selectors';
import axios from 'axios';
import { APIGateways } from '@config/config';
import { setMandILeakData } from '@redux/abatementOptimization/slice';
import { BridgeLayerToggle } from './layers/Bridge/toggle';
import { RailwayLayerToggle } from './layers/Railway/toggle';
import moment from "moment";
import { MandILeaksToggle } from './layers/MandILeaks/toggle';
import { setLeakCodes } from '@redux/searchItems/actions';

const fetchMandIData = (callBack, filterOptions) => {

    const url = `${APIGateways.leakabatement}/leak_prioritization`;

    const filter = {
        return_type: 'geojson'
    }

    // start_time and end_time
    if (filterOptions?.timeFrame?.startDate && filterOptions?.timeFrame?.endDate) {
        filter.end_date = moment(filterOptions.timeFrame.endDate).format('YYYY-MM-DD')
        filter.start_date = moment(filterOptions.timeFrame.startDate).format('YYYY-MM-DD')
    }

    if (filterOptions?.municipalities && filterOptions?.municipalities.length > 0) {
        filter.municipality = filterOptions.municipalities
    }

    if (filterOptions?.leakStatuses && filterOptions?.leakStatuses.length > 0) {
        filter.LeakStatus = filterOptions.leakStatuses
    }

    if (filterOptions?.leakCodes && filterOptions?.leakCodes.length > 0) {
        filter.LeakCode = filterOptions.leakCodes
    }

    axios.post(url, filter)
        .then((response) => {
            return fetch(response.data)
            .then(fetchResponse => fetchResponse.json())
            .then(data => {
                callBack(data, false)
            })  
        })
        .catch((err) => {
            callBack(null, true)
        })
}

export const AbatementOptimizationMenu = () => {
    const dispatch = useDispatch()
    const activeMenuItem = useSelector(selectSelectedKey);

    const [currentItem, setCurrentItem] = useState(null);
    const [selectKey, setSelectKey] = useState(undefined);

    useEffect(() => {
        dispatch(setLeakCodes(["Code 2", "Code 3 - Steel", "Code 3 - Plastic"]))
    }, [])

    const searchItems = useSelector(selectSearchItems);

    useEffect(() => {
        dispatch(addToMapLoadingList('MandILeaks'))
        fetchMandIData((data, isError) => {

            if (!isError) {
                dispatch(setMandILeakData(data))
            } 
            dispatch(removeFromMapLoadingList('MandILeaks'))
        }, searchItems)
    }, [searchItems])

    const closeTabs = () => {
        dispatch(setMenuSelectedKey(undefined))
        setSelectKey(undefined)
        setCurrentItem(null)
    }

    if (currentItem) return (
        <MainMenu background-color={color.prussianBlue} style={{width: '256px'}}>
            {currentItem.component(closeTabs)}
        </MainMenu>
    )
    return <Menu
        tabs={[
            {
                title: "Filters",
                content: (
                    <MenuTabContents
                        key="abatement-optimization"
                        menuItems={abatementOptimizationActions}
                        selectedMenu={activeMenuItem}
                        onSetSelectedMenu={(value) => dispatch(setMenuSelectedKey(value))}
                        currentItem={currentItem}
                        setCurrentItem={setCurrentItem}
                        selectKey={selectKey}
                        setSelectKey={setSelectKey}
                    >
                        <MenuTabButtons />
                    </MenuTabContents>
                )
            },
            {
                title: "Layers",
                content:
                    <LayerTabWrapper style={{paddingLeft: '16px'}}>
                        <Typography text='Internal Data' variant='Heading 2' style={{marginTop: '16px'}}/>
                        <TitleDivider style={{width: '224px', marginBottom: '16px'}}/>
                        <LayerToggleSection
                            title='SoCalGas Leaks'
                            toggles={[
                                <MandILeaksToggle/>
                            ]}
                        />
                        <LayerToggleSection
                            title='GIS Prioritization Factors'
                            toggles={[
                                <BLMLayerToggle/>,
                                <BridgeLayerToggle/>,
                                <CNDDBLayerToggle/>,
                                <EagleAwarenessLayerToggle/>,
                                <HazmatToggle/>,
                                <NFABLayerToggle/>,
                                <NHSLayerToggle/>,
                                <RailwayLayerToggle/>,
                                <VegetationLayerToggle/>
                            ]}
                        />
                        <Typography style={{marginTop: '32px'}} text='External Data' variant='Heading 2' />
                        <TitleDivider style={{width: '224px', marginBottom: '16px'}}/>
                        <LayerToggleSection
                            title="Areas of Interest"
                            toggles={[
                                <DisadvantagedCommunityLayerToggle title="DAC 4.0 State" titleVariant="Heading 4" icon={DACIcon} dacCriteriaOptions={['ces_4_0_state_dac_ca']}/>,
                                <TribalLandsLayerToggle showChildren />
                            ]}
                        />
                        <LayerToggleSection
                            title="Institutions"
                            toggles={[
                                <HospitalsLayerToggle showChildren/>
                            ]}
                        />
                        <LayerToggleSection
                            title="Population"
                            toggles={[
                                <RacialEquityLayerToggle showChildren/>
                            ]}
                        />
                    </LayerTabWrapper>
            },
            {
                title: "Analysis",
                content: <AbatementOptimizationAnalysis />
            }
        ]}
    />
}