import React, { useEffect } from 'react';
import { Content } from 'antd/lib/layout/layout';
import { Layout } from 'antd/lib';
import { AngelesLinkMapLayers } from './layers/mapLayers';
import { AngelesLinkMenu } from './menu';
import { useDispatch } from 'react-redux';
import { setActiveApp } from '../../../../../shared/redux/apps';
import MapboxReact from 'component/mapbox/mapbox';

export const AngelesLink = () => {
    const dispatch = useDispatch();
    
    useEffect(() => {
        dispatch(setActiveApp('angeles-link'));
    }, [dispatch]);

    return (
        <>
            <AngelesLinkMenu />
            <Layout style={{ maxHeight: '100vh' }}>
                <Content style={{
                    margin: 0,
                    position: "relative",
                    color: "white"
                }}>
                    <MapboxReact layers={<AngelesLinkMapLayers />} />
                </Content>
            </Layout>
        </>
    )
}

