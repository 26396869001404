import React, { useEffect } from 'react';
import { Content } from 'antd/lib/layout/layout';
import { Layout } from 'antd/lib';
import { MethaneMapLayers } from './mapLayers';
import { MethaneMenu } from './menu';
import { useDispatch } from 'react-redux';
import { setActiveApp } from '../../../../../shared/redux/apps';
import CommunityImpactTable from './communityImpactTable';
import MapboxReact from 'component/mapbox/mapbox';

export const Methane = () => {
    const dispatch = useDispatch();
    
    useEffect(() => {
        dispatch(setActiveApp('methane-app'));
    }, [dispatch]);

    return (
        <>
            <MethaneMenu />
            <Layout style={{ maxHeight: '100vh' }}>
                <Content style={{
                    margin: 0,
                    position: "relative",
                    color: "white"
                }}>
                    <CommunityImpactTable data={{}} />
                    <MapboxReact layers={<MethaneMapLayers />} />
                </Content>
            </Layout>
        </>
    )
}

