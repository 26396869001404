import { configureStore } from '@reduxjs/toolkit';

import { apps } from "shared/redux/apps";
import menu from "./menu/reducers";
import searchItems from "./searchItems/reducers";
import mapStateInternal from "./mapStateInternal/reducers";
import sidebar from "./sidebar/reducers";
import mapBox from "./mapbox/reducer";
import lastCall from "./lastCall/reducer";
import config from '../config/reducer';
import methane from './methaneLeaks/reducer'
import emissions from './emissions/reducer'
import { CalEnviro } from 'shared/redux/CalEnviro';
import { AFN } from 'shared/redux/AFN';
import { Hospitals } from 'shared/redux/Hospitals';
import { TribalLands } from 'shared/redux/TribalLands';
import { MapStateReducer, AppConfigReducer } from '@innovation-toolkit/mapbox';
import SelfService from './selfService/reducer';
import StreetImprovements from './streetImprovements/reducer';
import AbatementOptimizationReducer from './abatementOptimization/slice';
import { RBACReducer } from '@innovation-toolkit/rbac';
import CityWorkAggregationReducer from './cityWorkAggregation/slice';
import { AngelesLinksReducer } from './angelesLinks';
import FleetDecarbonizationReducer from './fleetDecarbonization/slice'

const store = configureStore({reducer: {
    config,
    apps,
    appConfig: AppConfigReducer,
    CalEnviro,
    AFN,
    Hospitals,
    TribalLands,
    menu,
    searchItems,
    mapStateInternal,
    sidebar,
    mapBox,
    lastCall,
    methane,
    emissions,
    mapState: MapStateReducer,
    SelfService,
    StreetImprovements,
    abatementOptimization: AbatementOptimizationReducer,
    rbac: RBACReducer,
    cityWorkAggregation: CityWorkAggregationReducer,
    angelesLinks: AngelesLinksReducer,
    fleetDecarbonization: FleetDecarbonizationReducer
},
middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    immutableCheck: false,
    serializableCheck: false,
})
});

export default store;
