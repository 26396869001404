"use client";

import React from 'react';
import { createRoot } from 'react-dom/client';
import { ThemeProvider } from "styled-components";
import { ConfigProvider } from 'antd';
import engb from 'antd/locale/en_GB';
import './index.css';

import {GlobalStyle, theme} from '@innovation-toolkit/ui'

import { Provider } from "react-redux";
import store from "./redux/store";
import { ErrorBoundary } from "react-error-boundary";
import { StrictMode } from 'react';
import { App } from 'component/app';

function fallbackRender({ error, resetErrorBoundary }) {
    // Call resetErrorBoundary() to reset the error boundary and retry the render.
  
    return (
      <div role="alert">
        <p>Something went wrong:</p>
        <pre style={{ color: "red" }}>{error.message}</pre>
      </div>
    );
}
  
createRoot(document.getElementById('root')).render(
    <ErrorBoundary fallbackRender={fallbackRender} onReset={(details) => {console.log('Reset: ', details)}}>
    <StrictMode>
      <Provider store={store}>
          <ThemeProvider theme={theme}>
              <ConfigProvider locale={engb}>
                  <GlobalStyle />
                  <App />
              </ConfigProvider>
          </ThemeProvider>
      </Provider>
    </StrictMode>
    </ErrorBoundary>
);

