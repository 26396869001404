import { AnalysisSection, AnalysisSectionContainer, Typography } from "@innovation-toolkit/ui"
import FolderIcon from "../../../assets/icons/open-folder.svg?react"
import OverlapIcon from "../../../assets/icons/overlap.svg?react"
import { ChartIcon } from "shared/style/iconography/ChartIcon";
import { Checkbox as AntCheckbox } from "antd"
import styled from "styled-components"
import strings from "utils/strings"
import { useDispatch, useSelector } from "react-redux"
import { setWorkTable, setValueMetricsTable, setShowOverlapTable } from "redux/streetImprovements/action"
import { selectWorkTable, selectValueMetricsTable, selectShowOverlapTable } from "redux/streetImprovements/selector"

const StyledCheckbox = styled(AntCheckbox)`
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    align-items: center;
    font-weight: 300;
    color: ${({ theme }) => theme.color.white};

    p {
        padding-left: 4px;
    }

    .ant-checkbox-inner {
        width: 20px;
        height: 20px;
    }
`

const TableSelectCheckbox = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin: 4px 0;
    padding: 0 8px;
`

export const ChargingAnalysis = () => {
    const dispatch = useDispatch()
    const { city, overlaps, projects, moratoriums } =
        useSelector(selectWorkTable)
    const { toolUsage, planning } = useSelector(selectValueMetricsTable)
    const showOverlapTable = useSelector(selectShowOverlapTable)

    return (
        <AnalysisSectionContainer style={{marginTop: '8px'}}>
            <AnalysisSection
                title={strings.components.cityWorkTable.title}
                Icon={FolderIcon}
                action={(active) => {
                    dispatch(setValueMetricsTable())
                    dispatch(setWorkTable({ city: active, overlaps: false, projects: true, moratoriums: true }))
                    dispatch(setShowOverlapTable(false))
                }}
                defaultState={city}
                description={strings.components.cityWorkTable.description}
            >
                <TableSelectCheckbox>
                    City Projects{" "}
                    <StyledCheckbox
                        checked={projects}
                        onChange={(e) =>
                            dispatch(setWorkTable({ projects: e.target.checked }))
                        }
                    />
                </TableSelectCheckbox>
                <TableSelectCheckbox>
                    Moratoriums{" "}
                    <StyledCheckbox
                        checked={moratoriums}
                        onChange={(e) =>
                            dispatch(setWorkTable({ moratoriums: e.target.checked }))
                        }
                    />
                </TableSelectCheckbox>
            </AnalysisSection>
            <AnalysisSection
                title={strings.components.overlapsTable.title}
                Icon={OverlapIcon}
                action={(active) => {
                    dispatch(setValueMetricsTable())
                    dispatch(setWorkTable({ city: false, overlaps: false, projects: true, moratoriums: true }))
                    dispatch(setShowOverlapTable(active))

                }}
                defaultState={showOverlapTable}
                description={strings.components.overlapsTable.description}
            >
                <TableSelectCheckbox>
                    City Projects{" "}
                    <StyledCheckbox
                        checked={projects}
                        onChange={(e) =>
                            dispatch(setWorkTable({ projects: e.target.checked }))
                        }
                    />
                </TableSelectCheckbox>
                <TableSelectCheckbox>
                    Moratoriums{" "}
                    <StyledCheckbox
                        checked={moratoriums}
                        onChange={(e) =>
                            dispatch(setWorkTable({ moratoriums: e.target.checked }))
                        }
                    />
                </TableSelectCheckbox>
            </AnalysisSection>
            <AnalysisSection
                title={strings.components.valueMetricsTable.title}
                Icon={ChartIcon}
                action={(active) => {
                    dispatch(setWorkTable())
                    dispatch(setShowOverlapTable(false))

                    active ? dispatch(setValueMetricsTable({toolUsage: true, planning: false})) : dispatch(setValueMetricsTable())
                }}
                defaultState={toolUsage || planning}
                description={strings.components.valueMetricsTable.description}
                children={<Typography variant='Subtitle' text={strings.components.valueMetricsTable.footNote} style={{color: '#8FA1B1'}} />}
            >
            </AnalysisSection>
        </AnalysisSectionContainer>
    )
}
