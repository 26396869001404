import { useDispatch, useSelector } from "react-redux"
import { MoratoriumsLayerMetadata } from "."
import { addActiveLayer, removeActiveLayer, selectIsLayerActive } from "@innovation-toolkit/mapbox"
import { DelimiterLine, InfoIcon, LayerToggle, LayerToggleIcon, LayerToggleIconChild, ToolIcon, Typography } from '@innovation-toolkit/ui';
import MoratoriumIcon from 'assets/icons/moratorium-icon.svg?react';
import { selectShowMoratoriumMapData } from "redux/streetImprovements/selector";
import { setShowMoratoriumMapData } from "redux/streetImprovements/action";

export const MoratoriumsToggle = () => {
    const MORATORIUMS_LAYER = MoratoriumsLayerMetadata.layerId
    const dispatch = useDispatch()
    const shouldShowMoratoriums = useSelector(selectShowMoratoriumMapData)

    const handleClick = (shouldShow) => {
        dispatch(setShowMoratoriumMapData(shouldShow))
    }

    return (
        <LayerToggle
            title='Moratoriums'
            bodyStyle={{backgroundColor: '#0E263B'}}
            headerStyle={{marginTop: '10px'}}
            tooltipText="This layer is “Zoom Dependent”. Zoom in if you are not seeing results."
            // componentIcon={MoratoriumIcon}
            onChange={handleClick}
            currentState={shouldShowMoratoriums}
            children={
                <div
                style={{
                    position:
                        'relative' /* Position relative for the parent div */,
                    padding: '5px 0px 11px 10px',
                }}
                >
                <div
                    style={{
                        position: 'absolute',
                        left: -8 /* Position on the left */,
                        top: 9 /* Align with the top */,
                        height: '60%' /* Match the height of the parent */,
                        width: '1.5px' /* Width of the border */,
                        backgroundColor:
                            '#FFFFFF33' /* Color of the border */,
                    }}
                />
                <section style={{display: 'flex', flexDirection: 'column'}}>
                    <LayerToggleIconChild
                        Icon={
                            <LayerToggleIcon
                                type="dashed-line" 
                                fillColor={MoratoriumsLayerMetadata.color} 
                                strokeColor="black" 
                                outlineColor="white"
                            />
                        } 
                        title="Moratorium" 
                    />
                </section>
                </div>
            }
        />
    )
}