import CalEnviroLayer from '../../../mapbox/layers/CalEnviroLayer'
import SelfServiceLayer, { SELF_SERVICE_POINT_LAYER } from './SelfServiceLayer';
import { 
    TribalLandsLayer,
    IHSSLayer,
    HHSEmpowerLayer,
    HospitalsLayer,
    HydrogenFuelStationsLayer,
    RacialEquityLayer,
    CalEnviroLayerMetadata,
    RacialEquityLayerMetadata,
    IncomeLayer,
    IncomeLayerMetadata,
    Justice40Layer,
    EventDelegator,
    DisadvantagedCommunityLayer
} from '@innovation-toolkit/mapbox';
import { DOTLayer } from './DOTLayer';


export const SelfServiceMapLayers = () => {
    return (
        <>
            <EventDelegator layerIds={[SELF_SERVICE_POINT_LAYER, IncomeLayerMetadata.layerId, RacialEquityLayerMetadata.layerId, CalEnviroLayerMetadata.layerId]}/>
            <SelfServiceLayer/>
            {/* <CalEnviroLayer /> */}
            <DisadvantagedCommunityLayer showLayer={false}/>
            <TribalLandsLayer filterable={false} showLayer={false}/>
            <SelfServiceLayer/>
            <IHSSLayer showLayer={false}/>
            <HHSEmpowerLayer showLayer={false}/>
            <HospitalsLayer showLayer={false}/>
            <HydrogenFuelStationsLayer showLayer={false}/>
            <RacialEquityLayer showLayer={false}/>
            <IncomeLayer />
            <DOTLayer/>
            <Justice40Layer showLayer={false}/>
        </>
    )
}