import { useEffect, useState } from "react";
import { useSelector } from "react-redux"
import { FLEET_COMPONENT } from "@redux/actionTypes";
import { selectAllVehicles } from "@redux/mapStateInternal/selectors";
import { selectSelectedKey } from "@redux/menu/selectors";
import { selectSearchFleetVehicles } from "@redux/searchItems/selectors"
import strings from "utils/strings"

const FleetVehiclesFilterStatus = () => {
    const selectVehicles = useSelector(selectSearchFleetVehicles);
    const allVehicles = useSelector(selectAllVehicles);
    const selectedKey = useSelector(selectSelectedKey);

    const [vehicles, setVehicles] = useState(null);

    let status = strings.menu.fleet.defaultValue;

    useEffect(() => {
        setVehicles(selectVehicles?.length)
    }, [selectVehicles, setVehicles])

    if (selectedKey === FLEET_COMPONENT) {
        status = strings.menu.fleet.selecting;
    } else if (vehicles > 0) {
        if (!allVehicles) {
            status = strings.formatString(strings.menu.fleet.value, { count: selectVehicles?.length })
        } else {
            status = strings.menu.fleet.allSelected;
        }
    }

    return status;
}

export default FleetVehiclesFilterStatus;