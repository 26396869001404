import { useDispatch, useSelector } from "react-redux"
import { RailwayLayerMetadata } from "."
import { addActiveLayer, removeActiveLayer, selectIsLayerActive } from "@innovation-toolkit/mapbox"
import { LayerToggle, LayerToggleIcon, LayerToggleIconChild } from '@innovation-toolkit/ui';
import { Railway } from "assets/icons/railway";

export const RailwayLayerToggle = () => {
    const RAILWAY_LAYER = RailwayLayerMetadata.layerId
    const dispatch = useDispatch()
    const shouldShowRailwayLayer = useSelector((state) => selectIsLayerActive(state, RAILWAY_LAYER))
    const handleClick = (shouldShow) => {
        if (!shouldShow) {
            dispatch(removeActiveLayer(RAILWAY_LAYER))
        } else {
            dispatch(addActiveLayer(RAILWAY_LAYER))
        }
    }

    return (
        <LayerToggle
            title='Railway'
            componentIcon={Railway}
            onChange={handleClick}
            currentState={shouldShowRailwayLayer}
            children={<LayerToggleIconChild 
                Icon={<LayerToggleIcon 
                    type="line" 
                    fillColor={RailwayLayerMetadata.color} 
                    outlineColor="#ffffff" 
                    strokeColor="#ffffff"
                    />
                }    
                title={<p>Railway</p>}/>}
        />
    )
}