import {createSelector} from "reselect";

const selectState = state => state.mapStateInternal;

const selectAllDistricts = createSelector(
    selectState,
    state => state.allDistricts
);

const selectAllVehicles = createSelector(
    selectState,
    state => state.allVehicles
);

const selectDacDistricts = createSelector(
    selectState,
    state => state.dacDistricts
);

const selectQualityAir = createSelector(
    selectState,
    state => state.qualityAir
);

const selectFleetImpact= createSelector(
    selectState,
    state => state.fleetImpact
);

const selectEmissionType = createSelector(
    selectState,
    state => state.emissionType
)

const selectAllowedSearch = createSelector(
    selectState,
    state => state.allowedSearch
)

const selectSelectedDistricts = createSelector(
    selectState,
    state => state.selectedDistrict
)

const selectMapScenario = createSelector(
    selectState,
    state => state.selectedMapScenario
)

const selectMapScenarioZoom = createSelector(
    selectState,
    state => state.mapScenario.zoom
)

const selectMapTables = createSelector(
    selectState,
    state => state.tables
)

const selectMapCensusTracts = createSelector(
    selectState,
    state => state.mapCensusTracts
)

const selectReplacementTableData = createSelector(
    selectState,
    state => state.replacementTable
)

const selectMapPosition = createSelector(
    selectState,
    state => state.position
)

const selectSideBarVisibility = createSelector(
    selectState,
    state => state.sideBarVisibility
)

const selectSideBarContent = createSelector(
    selectState,
    state => state.sideBarContent
)

export  {
    selectAllDistricts,
    selectAllVehicles,
    selectDacDistricts,
    selectQualityAir,
    selectFleetImpact,
    selectEmissionType,
    selectAllowedSearch,
    selectSelectedDistricts,
    selectMapScenario,
    selectMapScenarioZoom,
    selectMapTables,
    selectMapCensusTracts,
    selectReplacementTableData,
    selectMapPosition,
    selectSideBarVisibility,
    selectSideBarContent
}