import { Layer, Source } from "react-map-gl"
import { APIGateways } from "@config/config"
import { useState } from "react";
import { isActiveLayer, selectIsLayerActive, useActivateMapLayer, useMapEvent, useMapHoverEvent } from "@innovation-toolkit/mapbox";
import { useSelector } from "react-redux";
import { CPPopup } from "./popup";
import { UNCLUTTERED_MIN_ZOOM } from "../../constants";

export const CP_LINE_LAYER = 'CP_LINE_LAYER';
export const CP_LINE_SOURCE = 'CP_LINE_SOURCE';

export const CPLayerMetadata = {
    layerId: CP_LINE_LAYER,
    name: 'Cathodic Protection',
    color: '#001EFF'
}

export const CPLayer = ({showLayer=false}) => {

    const [showCPLayer] = useActivateMapLayer(CPLayerMetadata, showLayer)

    
    const [showPopup, setShowPopup] = useState(false)
    const [popupFeature, setPopupFeature] = useState(null);
    const [lngLat, setLatLng] = useState(null);
    const [target, setTarget] = useState(null);
    const [data, setData] = useState(null);
    const [hoveredLine, setHoveredLine] = useState('');
    // const timerRef = useRef();

    useMapEvent('click', CP_LINE_LAYER, (e) => {
        if (!isActiveLayer(e, CP_LINE_LAYER)) return;

        setShowPopup(true);
        setPopupFeature(e.features[0]);
        setLatLng(e.lngLat);
        setTarget(e.target);
    })

    useMapHoverEvent(CP_LINE_LAYER, (e) => {
        setHoveredLine(e.features[0].properties.GLOBALID)
    }, 300)

    // useMapHover(layerID, timeout, callback)

    // useMapEvent('mouseover', CP_LINE_LAYER, (e) => {
    //     console.log('hello')
    //     timerRef.current = setTimeout(() => setHoveredLine(e.features[0].properties.GLOBALID), 150);
    //     // setHoveredLine(e.features[0].properties.GLOBALID)
    // })

    // useMapEvent('mouseleave', CP_LINE_LAYER, (e) => {
    //     clearTimeout(timerRef.current)
    // })

    const sourceConfig = {
        id: CP_LINE_SOURCE,
        type: 'vector',
        tiles: [`${APIGateways.scgcore}/tiles/cp_area/{z}/{x}/{y}`]
    }

    const lineConfig = {
        id: CP_LINE_LAYER,
        type: "line",
        source: CP_LINE_SOURCE,
        'source-layer': 'CPStationLine',
        minzoom: UNCLUTTERED_MIN_ZOOM,
        layout: {
            'visibility': showCPLayer ? 'visible' : 'none'
        },
        paint: {
            'line-width': 1.5,
            'line-color': 
            [
                'case',
                ['==', ['get', 'GLOBALID'], ['literal', hoveredLine]], '#001EFF',//0015B0
                ["rgba", 107, 107, 107, 0.5]
            ]
        }
    }

    return (
        <Source {...sourceConfig}>
            <Layer {...lineConfig} />
            <CPPopup 
                isOpen={showPopup}
                onClose={() => setShowPopup(false)}
                lngLat={lngLat}
                feature={popupFeature}
                target={target}
            />
        </Source>
    )
}

const LineComponent = ({pattern, color, label}) => {
    return (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                gap: '10px',
                // marginBottom: '14px'
            }}
        >
            <div
                style={{
                    height: '2px',
                    width: '22px',
                    background: color,
                    backgroundRepeat: 'repeat',
                    backgroundSize: 'contain',
                    border: '1px solid #FFFFFF50',
                    marginLeft: '3px'
                }}
            ></div>            
            <p
                style={{
                    padding: '0',
                    margin: '5px'
                }}
            >
                {label}
            </p>
        </div>
        
    )
}


export const CPLegendSection = () => {
    const showLegend = useSelector((state) => selectIsLayerActive(state, CPLayerMetadata.layerId))

    if (!showLegend) {
        return <></>
    }

    return (
        <LineComponent color='#001EFF' label='Cathodic Protection'/>
    )
}

export { CPLayerToggle } from './toggle';