import { useDispatch, useSelector } from "react-redux"
import { DOTLayerMetadata } from "."
import { addActiveLayer, removeActiveLayer, selectIsLayerActive } from "@innovation-toolkit/mapbox"
import { LayerToggle } from '@innovation-toolkit/ui';
import { FacilityIcon } from "assets/icons/FacilityIcon";

export const DOTToggle = () => {
    const layerId = DOTLayerMetadata.layerId
    const dispatch = useDispatch()
    const shouldShowDOT = useSelector((state) => selectIsLayerActive(state, layerId))

    const handleClick = (shouldShow) => {
        if (!shouldShow) {
            dispatch(removeActiveLayer(layerId))
        } else {
            dispatch(addActiveLayer(layerId))
        }
    }

    return (
        <LayerToggle
            title='DOT DAC'
            componentIcon={FacilityIcon}
            onChange={handleClick}
            currentState={shouldShowDOT}
        />
    )
}