import React, { useEffect } from 'react';
import { Content } from 'antd/lib/layout/layout';
import { Layout } from 'antd/lib';
import { StreetImprovementMapLayers } from './layers/mapLayers';
import { StreetImprovementMenu } from './menu';
import { useDispatch, useSelector } from 'react-redux';
import { setActiveApp } from '../../../../../shared/redux/apps';
import WorkTable from 'component/WorkTable';
import { ValueMetricsTable } from './ValueMetricsTable';
import { OverlapTable } from './OverlapTable';
import { setWorkTable } from 'redux/streetImprovements/action';
import { selectWorkTable } from 'redux/streetImprovements/selector';
import { setMapPosition } from '@innovation-toolkit/mapbox';
import MapboxReact from 'component/mapbox/mapbox';

export const StreetImprovement = () => {
    const dispatch = useDispatch();
    const selectedWorkTable = useSelector(selectWorkTable)
    
    useEffect(() => {
        dispatch(setActiveApp('street-improvement'));
    }, [dispatch]);

    return (
        <>
            <StreetImprovementMenu />
            <Layout style={{ maxHeight: '100vh' }}>
                <Content style={{
                    margin: 0,
                    position: "relative",
                    color: "white"
                }}>
                    <WorkTable setWorkTable={setWorkTable} selectedWorkTable={selectedWorkTable} setMapPosition={setMapPosition} />
                    <ValueMetricsTable />
                    <OverlapTable />
                    <MapboxReact layers={<StreetImprovementMapLayers />} />
                </Content>
            </Layout>
        </>
    )
}

