import { useDispatch, useSelector } from 'react-redux';
import {
    addActiveLayer,
    removeActiveLayer,
    selectIsLayerActive,
} from '@innovation-toolkit/mapbox';
import {
    DelimiterLine,
    InfoIcon,
    LayerToggle,
    LayerToggleIcon,
    LayerToggleIconChild,
    Typography,
} from '@innovation-toolkit/ui';
import { MIcon } from 'assets/icons/MIcon';
import { MandILayerToggleIcon } from 'assets/icons/mandi_layer_toggle';
import { selectMandiLeaksFilterMap, setMandiLeaksFilterMap } from 'redux/abatementOptimization/slice';

const ToggleMap = [
    {title: "High Priority", propName: 'high', color: "#B41405"},
    {title: "High-Medium Priority", propName: 'high_mid', color: "#E05400"},
    {title: "Medium Priority", propName: 'mid', color: "#F5C846"},
    {title: "Low Priority", propName: 'low', color: "#32BF8A"},
    {title: "Not Prioritized", propName: 'no ranking factor', color: "#5A5A5A"},
]

const MandISymbol = ({color = "#002762"}) => {
    return <LayerToggleIcon type="custom" Icon={MIcon} fillColor={color} outlineColor="white" strokeColor="white" IconOffset={[4, 4]}/>

}

export const MandILeaksToggle = () => {
    const dispatch = useDispatch();
    const shouldShowMandILeaks = useSelector((state) =>
        selectIsLayerActive(state, 'MANDI_LEAKS_LAYER')
    );
    const mandiLeaksFilterMap = useSelector(selectMandiLeaksFilterMap);

    const handleClick = (shouldShow) => {
        if (!shouldShow) {
            dispatch(removeActiveLayer('MANDI_LEAKS_LAYER'));
        } else {
            dispatch(addActiveLayer('MANDI_LEAKS_LAYER'));
        }
    };

    return (
        <LayerToggle
            title="M&I Leaks"
            componentIcon={MandILayerToggleIcon}
            onChange={handleClick}
            currentState={shouldShowMandILeaks}
            children={
                <>
                    <section
                        style={{display: 'flex', gap: '15px', marginRight: '20px'}}
                    >
                        <InfoIcon width={30}/>
                        <Typography variant='Body 1' text='This layer is "Zoom Dependent". Zoom in to view the leak priority.'/>
                    </section>

                    <DelimiterLine style={{marginLeft: '-29px'}}/> 

                    <LayerToggleIconChild
                        Icon={<MandISymbol/>}
                        title={`M&I Leaks`}
                    />

                    <DelimiterLine style={{marginLeft: '-29px'}}/> 

                    <section style={{display: 'flex', flexDirection: 'column'}}>
                        {
                            ToggleMap.map((prioToggle, index) => (
                                <LayerToggleIconChild 
                                    key={index}
                                    Icon={
                                        <LayerToggleIcon 
                                            type="custom" 
                                            Icon={() => <MandISymbol color={prioToggle.color} />}  
                                            fillColor={prioToggle.color}
                                            strokeColor="white" 
                                            outlineColor="white"
                                        />
                                    } 
                                    title={prioToggle.title}
                                    subLayerCheckbox={{defaultChecked: mandiLeaksFilterMap[prioToggle.propName], onChange: () => dispatch(setMandiLeaksFilterMap({key: prioToggle.propName, value: !mandiLeaksFilterMap[prioToggle.propName]}))}}
                                />
                            ))
                        }
                    </section>
                </>
            }
        />
    );
};
