import { DrawContainer } from "../DrawContainer"
import { UploadedDataLayer } from "./UploadedDataLayer"

export const CityWorkAggregationMapLayers = () => {
    return (
        <>          
            <UploadedDataLayer/>
            <DrawContainer/>
        </>
    )
}