import { MapPopup } from '@innovation-toolkit/mapbox';
import { MapPopupContents } from '@innovation-toolkit/ui';
import { useState, useEffect } from 'react';

export const SurveyPopup = ({type, isOpen, onClose, lngLat, feature}) => {
    const [popupState, setPopupState] = useState({
        lng: 0,
        lat: 0,
    })
    const [data, setData] = useState(null);

    useEffect(() => {
        if (lngLat === null || feature === null) return
        const popupFeature = feature.properties
        setData(popupFeature);

        setPopupState({
            lng: lngLat.lng,
            lat: lngLat.lat,
        })

    }, [isOpen, lngLat, feature])

    return isOpen ?
                <MapPopup longitude={popupState.lng} latitude={popupState.lat} onClose={onClose} className='MandIPopup'>
                <MapPopupContents
                    title={`SoCalGas Work - ${type} Survey`}
                    data={data}
                />
                </MapPopup>
                : null
}