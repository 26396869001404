import { useSelector } from "react-redux"
import { CENSUS_TRACTS_COMPONENT } from "redux/actionTypes"
import { selectSelectedKey } from "redux/menu/selectors"
import { selectSearchCommunities } from "redux/searchItems/selectors"

const CensusTractFilterStatus = () => {
    const selectedKey = useSelector(selectSelectedKey)
    let status = 'Select Census Tract(s)'

    const selectedCommunities = useSelector(selectSearchCommunities)

    if (selectedKey === CENSUS_TRACTS_COMPONENT) {
        status = 'Select Census Tract(s)'
    } else if (selectedCommunities && selectedCommunities.length === 1 && selectedCommunities?.length > 0) {
        status = selectedCommunities[0] + ' Selected'
    } else if (selectedCommunities && selectedCommunities.length > 0) {
        status = selectedCommunities.length + ' Census Tracts Selected'
    }

    return status
}

export default CensusTractFilterStatus
