import { useDispatch, useSelector } from 'react-redux';
import { ChargingAnalysis } from './analysis';
import { DACIcon, DelimiterLine, LayerTabWrapper, LayerToggleSection, MenuTabContents, TitleDivider, color } from '@innovation-toolkit/ui'

// redux
import { setMenuSelectedKey } from '@redux/menu/actions';
import { selectSelectedKey } from '@redux/menu/selectors';
import { streetImprovementActions } from 'component/sidebar/menuItems';
import { HospitalsLayerToggle, CalEnviroLayerToggle, TribalLandsLayerToggle, useCallWebsocketEndpoint, selectWebsocketUrl, DisadvantagedCommunityLayerToggle } from '@innovation-toolkit/mapbox';

import { MainMenu, Menu, Typography } from '@innovation-toolkit/ui'
import { LAStreetImprovementToggle } from './layers/LAStreetImprovement/toggle';
import { MoratoriumsToggle } from './layers/Moratoriums/toggle';
import { CPLayerToggle } from './layers/CPLayer';
import { LSALayerToggle } from './layers/LSALayer/toggle';
import { CPDProjectsToggle, WIPCloudLayerToggle } from './layers/CPDProjects/toggle';
import MenuTabButtons from 'component/sidebar/tabs/MenuTabButtons';
import { useState } from 'react';
import { useEffect } from 'react';
import axios from 'axios';
import { APIGateways } from '@config/config';
import { setCityWorkData, setOverlapIds, setProjectTypes } from '@redux/streetImprovements/action';
import { MandIWorkToggle } from './layers/MandIWork/toggle';
import { selectSearchItems } from '@redux/searchItems/selectors';
import moment from 'moment';
import { ValvesLayerToggle } from './layers/ValvesLayer/toggle';
import { CP10LayerToggle } from './layers/CP10Layer/toggle';
import { GATEWAY_WEBSOCKET_URL } from 'config/config';

export const StreetImprovementMenu = () => {
    const dispatch = useDispatch()
    const activeMenuItem = useSelector(selectSelectedKey);

    const [currentItem, setCurrentItem] = useState(null);
    const [selectKey, setSelectKey] = useState(undefined);
    const searchItems = useSelector(selectSearchItems);


    // Fetch the CPD and M&I overlap Ids
    useEffect(() => {
        axios.post(`${APIGateways.streetimprovements}/overlap/overlap_table`, {
            "cpd":{},
            "mandi":{},
            "overlap_table":{"response_type": "unique_ids"}
        })
            .then(response => fetch(response.data))
            .then(fetchResponse => fetchResponse.json())
            .then(data => dispatch(setOverlapIds(data)))
            .catch(error => {})

        axios.post(`${APIGateways.streetimprovements}/project_types`, {})
            .then(response => dispatch(setProjectTypes(response.data)))
            .catch(error => {})
    }, [])

    const websocketUrl = useSelector(selectWebsocketUrl);

    const [cityWorkDataRequest, setCityWorkDataRequest] = useState({
        action: 'scg-async',
        resource: '/city/get_city_work_data', 
       })

    const [result, isLoading, error] = useCallWebsocketEndpoint(websocketUrl || 'wss://ym6moan84a.execute-api.us-west-2.amazonaws.com/dev', cityWorkDataRequest, true)

    useEffect(() => {
        // if (!searchItems?.timeFrame.startDate || !searchItems?.timeFrame.endDate) return;
        
        setCityWorkDataRequest({
            action: 'scg-async',
            resource: '/city/get_city_work_data', 
            end_timeframe: searchItems?.timeFrame.endDate ? moment(searchItems?.timeFrame.endDate).format('YYYY-MM-DD') : null,
            start_timeframe: searchItems?.timeFrame.startDate ?  moment(searchItems?.timeFrame.startDate).format('YYYY-MM-DD') : null,
        })
    }, [searchItems])


    useEffect(() => {
        if (!result) return;
        dispatch(setCityWorkData(result))
    }, [result])

    const closeTabs = () => {
        dispatch(setMenuSelectedKey(undefined))
        setSelectKey(undefined)
        setCurrentItem(null)
    }

    if (currentItem) return (
        <MainMenu background-color={color.prussianBlue} style={{width: '256px'}}>
            {currentItem.component(closeTabs)}
        </MainMenu>
    )
    return (<Menu
            tabs={[
                {
                    title: "Filters",
                    content: (
                        <MenuTabContents
                            menuItems={streetImprovementActions}
                            selectedMenu={activeMenuItem}
                            onSetSelectedMenu={(value) => dispatch(setMenuSelectedKey(value))}
                            currentItem={currentItem}
                            setCurrentItem={setCurrentItem}
                            selectKey={selectKey}
                            setSelectKey={setSelectKey}
                        >
                            <MenuTabButtons />
                        </MenuTabContents>
                    )
                },
                {
                    title: "Layers",
                    content:
                        <LayerTabWrapper style={{paddingLeft: '16px'}}>
                            <Typography text='Internal Data' variant='Heading 2'/>
                            {/* <TitleDivider style={{width: '224px', marginBottom: '16px'}}/> */}
                            <DelimiterLine style={{width: '224px', marginBottom: '16px'}}/> 
                            <LayerToggleSection
                                title={<div style={{color:'#FFFFFF80', marginBottom: '5px'}}>SoCalGas Work</div>}
                                toggles={[
                                    <CPDProjectsToggle/>,
                                    <MandIWorkToggle/>,
                                    // <SurveyLayerToggle/>,
                                    <WIPCloudLayerToggle/>,
                                    <CPLayerToggle/>,
                                    <LSALayerToggle/>,
                                    <ValvesLayerToggle/>,
                                    <CP10LayerToggle/>,
                                ]}
                            />
                            {/* <CPDProjectsToggle/> */}

                            <Typography style={{marginTop: '32px'}} text='External Data' variant='Heading 2' />
                            <TitleDivider style={{width: '224px', marginBottom: '16px'}}/>
                            <LayerToggleSection
                                title={<div style={{color:'#FFFFFF80', marginBottom: '15px'}}>City Work</div>}
                                toggles={[
                                    // <RoadPavingLayerToggle/>,
                                    <LAStreetImprovementToggle/>,
                                    <MoratoriumsToggle />
                                ]}
                            />
                            {/*<LayerToggleSection
                                title={<div style={{color:'#FFFFFF80', marginBottom: '15px'}}>Areas of Interest</div>}
                                toggles={[
                                    <DisadvantagedCommunityLayerToggle newDesign title="DAC 4.0 State" titleVariant='Heading 4' dacCriteriaOptions={['ces_4_0_state_dac_ca']} />,
                                    <TribalLandsLayerToggle newDesign icon={null} showChildren/>,
                                ]}
                            />
                            <LayerToggleSection
                                title={<div style={{color:'#FFFFFF80', marginBottom: '15px'}}>Institutions</div>}
                                toggles={[
                                    <HospitalsLayerToggle newDesign icon={null} showChildren/>
                                ]}
                            />*/}
                        </LayerTabWrapper>
                },
                {
                    title: "Analysis",
                    content: <ChargingAnalysis />
                }
            ]}
        />
    )

}