import { useDispatch, useSelector } from "react-redux"
import { CPDProjectsLayerMetadata, WIPCloudLayerMetadata } from "."
import { addActiveLayer, removeActiveLayer, selectIsLayerActive } from "@innovation-toolkit/mapbox"
import { DelimiterLine, InfoIcon, LayerToggle, LayerToggleIcon, LayerToggleIconChild, Typography } from '@innovation-toolkit/ui';
import ProjectIcon from 'assets/icons/cpd_p.svg?react';
import { PIcon } from "assets/icons/PIcon";
import WIP_CLOUD_ICON from 'assets/icons/wip_cloud.svg?react';
import { selectFilteringLayer } from "redux/streetImprovements/selector";
import { useEffect, useState } from "react";
import axios from 'axios'
import { APIGateways } from "config/config";

const fetchCPDRefreshDate = (callBack, filterOptions) => {

    const url = `${APIGateways.streetimprovements}/layer_update_date`;

    const filter = {
        layer: 'cpd_projects'
    }

    axios.post(url, filter)
        .then((response) => {
            callBack(response.data, false)
        })
        .catch((err) => {
            callBack(null, true)
        })// NotificationType for work type
}

const fetchWIPRefreshDate = (callBack, filterOptions) => {

    const url = `${APIGateways.streetimprovements}/layer_update_date`;

    const filter = {
        layer: 'cpd_projects'
    }

    axios.post(url, filter)
        .then((response) => {
            callBack(response.data, false)
        })
        .catch((err) => {
            callBack(null, true)
        })// NotificationType for work type
}


export const CPDProjectsToggle = () => {
    const CPD_PROJECTS_LAYER = CPDProjectsLayerMetadata.layerId
    const OVERLAP_LAYER = 'CPD_CITY_WORK_OVERLAP';
    const OVERLAP_RADIUS_LAYER = 'CPD_CITY_WORK_OVERLAP_RADIUS'

    const dispatch = useDispatch()
    const shouldShowCPDProjects = useSelector((state) => selectIsLayerActive(state, CPD_PROJECTS_LAYER))
    const workTypeFilterActiveLayer = useSelector(selectFilteringLayer)
    const layerShowState = [
        useSelector((state) => selectIsLayerActive(state, OVERLAP_LAYER)),
        useSelector((state) => selectIsLayerActive(state, OVERLAP_RADIUS_LAYER)),
    ];
    const [lastRefreshDate, setLastRefreshDate] = useState(null)

    const handleClick = (shouldShow) => {
        if (!shouldShow) {
            dispatch(removeActiveLayer(CPD_PROJECTS_LAYER))
        } else {
            dispatch(addActiveLayer(CPD_PROJECTS_LAYER))
        }
    }

    useEffect(() => {

        fetchCPDRefreshDate((data, isError) => {
            if (!isError) {
                setLastRefreshDate(data)
            } 
        })
        
    }, [])

    const handleIndividualChange = (layerID, index) => {
        if (layerShowState[index]) {
            dispatch(removeActiveLayer(layerID))
        } else {
            dispatch(addActiveLayer(layerID))
        }
    }

    const TooltipMessage = <div>
        This layer is “Zoom Dependent”. Zoom in to view individual projects and overlaps.<br/>
        <span><b style={{fontWeight: 'bold'}}>Last Refresh Date:</b> {lastRefreshDate?.last_updated}</span>
    </div>

    return (
        <LayerToggle
            title='CPD Projects'
            headerStyle={{marginTop: '20px'}}
            bodyStyle={{backgroundColor: '#0E263B'}}
            onChange={handleClick}
            tooltipText={lastRefreshDate ? TooltipMessage : undefined}
            disabled={workTypeFilterActiveLayer === 'MI'}
            currentState={shouldShowCPDProjects}
            children={
                <div
                style={{
                    position:
                        'relative' /* Position relative for the parent div */,
                    padding: '5px 0px 11px 10px',
                }}
            >
                <div
                    style={{
                        position: 'absolute',
                        left: -8 /* Position on the left */,
                        top: 13 /* Align with the top */,
                        height: '80%' /* Match the height of the parent */,
                        width: '1.5px' /* Width of the border */,
                        backgroundColor:
                            '#FFFFFF33' /* Color of the border */,
                    }}
                />

                <section style={{display: 'flex', flexDirection: 'column'}}>
                    <LayerToggleIconChild 
                        Icon={
                            <LayerToggleIcon 
                                type="custom" 
                                Icon={PIcon} 
                                IconOffset={[0, 0]} 
                                fillColor={CPDProjectsLayerMetadata.color} 
                                strokeColor="white" 
                                outlineColor="white"
                            />
                        } 
                        title={<span style={{fontSize: '14px'}}>{CPDProjectsLayerMetadata.name}</span>}
                        // title={CPDProjectsLayerMetadata.name} 
                    />
                    <div style={{margin:'5px'}}></div>
                    <LayerToggleIconChild 
                        Icon={
                            <LayerToggleIcon 
                                type="custom" 
                                Icon={PIcon} 
                                IconOffset={[0, 0]} 
                                fillColor="#D45D00"
                                strokeColor="white" 
                                outlineColor="white"
                            />
                        } 
                        title={<span style={{fontSize: '12px'}}>CPD Overlaps with City Work</span>}
                        subLayerCheckbox={{defaultChecked: layerShowState[0], onChange: () => handleIndividualChange(OVERLAP_LAYER, 0)}}
                    />
                    <div style={{margin:'5px'}}></div>
                    <LayerToggleIconChild 
                        Icon={
                            <LayerToggleIcon 
                                type="circle"
                                fillColor="#BABABA"
                                strokeColor="black" 
                                outlineColor="white"
                            />
                        } 
                        // title="Overlap Radius"
                        title={<span style={{fontSize: '14px'}}>Overlap Radius</span>}
                        subLayerCheckbox={{defaultChecked: layerShowState[1], onChange: () => handleIndividualChange(OVERLAP_RADIUS_LAYER, 1)}}
                    />
                </section>
                </div>
            }
        />
    )
}

export const WIPCloudLayerToggle = () => {
    const WIP_CLOUD_LAYER = WIPCloudLayerMetadata.layerId
    const WIP_CLOUD_OVERLAP = 'WIP_CLOUD_OVERLAP'
    const dispatch = useDispatch()
    const shouldShowWipCloudLayer = useSelector((state) => selectIsLayerActive(state, WIP_CLOUD_LAYER))
    const workTypeFilterActiveLayer = useSelector(selectFilteringLayer)
    const [lastRefreshDate, setLastRefreshDate] = useState(null)

    useEffect(() => {

        fetchWIPRefreshDate((data, isError) => {
            if (!isError) {
                setLastRefreshDate(data)
            } 
        })
        
    }, [])


    const layerShowState = [
        useSelector((state) => selectIsLayerActive(state, WIP_CLOUD_OVERLAP)),
        // useSelector((state) => selectIsLayerActive(state, OVERLAP_RADIUS_LAYER)),
    ];

    const handleClick = (shouldShow) => {
        if (!shouldShow) {
            dispatch(removeActiveLayer(WIP_CLOUD_LAYER))
        } else {
            dispatch(addActiveLayer(WIP_CLOUD_LAYER))
        }
    }

    const handleIndividualChange = (layerID, index) => {
        if (layerShowState[index]) {
            dispatch(removeActiveLayer(layerID))
        } else {
            dispatch(addActiveLayer(layerID))
        }
    }

    useEffect(() => {
        if (workTypeFilterActiveLayer === 'MI') {
            dispatch(removeActiveLayer(WIP_CLOUD_LAYER))
        } else {
            dispatch(addActiveLayer(WIP_CLOUD_LAYER))
        }

    }, [workTypeFilterActiveLayer])

    const TooltipMessage = <div>
    The WIP Cloud layer shows WIP Clouds and Notification Polygons<br/>
    <span><b style={{fontWeight: 'bold'}}>Last Refresh Date:</b> {lastRefreshDate?.last_updated}</span>
    </div>

    return (
        <LayerToggle
            bodyStyle={{backgroundColor: '#0E263B'}}
            headerStyle={{marginTop: '10px'}}
            title='WIP Clouds'
            tooltipText={workTypeFilterActiveLayer === 'MI' ? 'To view CPD projects, navigate to the Work Type filter and press “Clear Selection” or change your preferences to “Show All Work” or "Show CPD Projects".' : TooltipMessage}
            disabled={workTypeFilterActiveLayer === 'MI'}
            // componentIcon={WIP_CLOUD_ICON}
            onChange={handleClick}
            currentState={shouldShowWipCloudLayer}
            children={
            <div
                style={{
                    position:
                        'relative' /* Position relative for the parent div */,
                    padding: '5px 0px 11px 10px',
            }}
            >
            <div
                style={{
                    position: 'absolute',
                    left: -8 /* Position on the left */,
                    top: 13 /* Align with the top */,
                    height: '80%' /* Match the height of the parent */,
                    width: '1.5px' /* Width of the border */,
                    backgroundColor:
                        '#FFFFFF33' /* Color of the border */,
                }}
            />
                <section style={{display: 'flex', flexDirection: 'column'}}>
                    <LayerToggleIconChild 
                        Icon={
                            <LayerToggleIcon 
                                type="polygon-dashed" 
                                fillColor={WIPCloudLayerMetadata.color} 
                                strokeColor="white" 
                                outlineColor="white"
                            />
                        } 
                        // title={WIPCloudLayerMetadata.name} 
                        title={<span style={{fontSize: '14px'}}>{WIPCloudLayerMetadata.name} </span>}
                    />
                    <div style={{margin: '10px'}}/> 
                    <LayerToggleIconChild 
                        Icon={
                            <LayerToggleIcon 
                                type="polygon-dashed" 
                                fillColor="#D45D00"
                                strokeColor="white" 
                                outlineColor="white"
                            />
                        } 
                        // title="WIP Overlaps with City Work"
                        title={<span style={{fontSize: '12px'}}>WIP Overlaps with City Work</span>}
                        subLayerCheckbox={{defaultChecked: layerShowState[0], onChange: () => handleIndividualChange(WIP_CLOUD_OVERLAP, 0)}}

                    />
                </section>
            </div>
            }
        />
    )
}