import { useDispatch, useSelector } from 'react-redux';
import { SetMapCensusTracts } from '@redux/mapStateInternal/action';
import { selectMapCensusTracts } from '@redux/mapStateInternal/selectors';
import { CalEnviroLayer } from '@innovation-toolkit/mapbox';


const StatefulCalEnviroLayer = ({toggleToHighlight = false, tractsSelectable=true, ...rest}) => {
    const dispatch = useDispatch();
    const selectedCensusTracts = useSelector(selectMapCensusTracts);

    const handleTractClick = (e) => {
        if (!tractsSelectable) return;
        
        const { city, tract } = e.features[0].properties;

        dispatch(SetMapCensusTracts({ label: city, tract: tract }))
    }

    const selectedTracts = selectedCensusTracts ? [... new Set(selectedCensusTracts.map(element => element.tract).flat())] : []

    return (
        <CalEnviroLayer
            handleTractClick={handleTractClick}
            selectedCensusTracts={selectedTracts}
            toggleToHighlight={toggleToHighlight}
            {...rest}
        />
    )
}

export default StatefulCalEnviroLayer