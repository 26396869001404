import {
    SET_SHOW_METHANE_CIRCLE_LAYER,
    SET_SHOW_METHANE_HEATMAP_LAYER
} from "./actionTypes"

export const initialState = {
    showMethaneCircle: true,
    showMethaneHeatmap: false
}

const Methane = (state = initialState, {type, payload}) => {
    switch (type) {
        case SET_SHOW_METHANE_CIRCLE_LAYER:
            return { ...state, showMethaneCircle: payload }
        case SET_SHOW_METHANE_HEATMAP_LAYER:
            return { ...state, showMethaneHeatmap: payload}
        default:
            return state
    }
}

export default Methane;