import { Layer, Source } from "react-map-gl"
import { APIGateways } from "@config/config"
import { useSelector } from "react-redux";
import { useActivateMapLayer, useLoadMapPattern, selectBeforeIDs, selectMapStyle } from "@innovation-toolkit/mapbox";

export const CNDDB_ALL_LAYER = 'CNDDB_ALL_LAYER';
export const CNDDB_ALL_SOURCE = 'CNDDB_ALL_SOURCE';

export const CNDDBAllLayerMetadata = {
    layerId: CNDDB_ALL_LAYER,
    name: 'CNDDB All',
    color: '#CB1E28'
}

export const CNDDBAllLayer = ({showLayer=false}) => {

    const [showCNDDBAllLayer] = useActivateMapLayer(CNDDBAllLayerMetadata, showLayer)
    const beforeIDs = useSelector(selectBeforeIDs)
    const mapStyle = useSelector(selectMapStyle)

    try {
        useLoadMapPattern("cnddb_shading", "crosshatch", CNDDBAllLayerMetadata.color, 20, mapStyle)
    } catch (e){
        console.error("Failed to load map pattern for NFAB Layer: ", e);
    }
    

    const sourceConfig = {
        id: CNDDB_ALL_SOURCE,
        type: 'vector',
        tiles: [`${APIGateways.scgcore}/tiles/CNDDB_all/{z}/{x}/{y}`]
    }

    const fillConfig = {
        id: CNDDB_ALL_LAYER,
        type: "fill",
        source: CNDDB_ALL_SOURCE,
        'source-layer': 'CNDDB_all',
        layout: {
            'visibility': showCNDDBAllLayer ? 'visible' : 'none'
        },
        paint: {
            "fill-opacity": 0.5,
            'fill-pattern': 'cnddb_shading'

        },
        beforeId: beforeIDs.fill // Pattern10
    }

    return (
        <Source {...sourceConfig}>
            <Layer {...fillConfig} />
        </Source>
    )
}

