import {
    SET_SHOW_TRIBAL_LANDS,
    CLEAR_TRIBAL_LANDS_STATE
} from "./actionTypes"

export const initialState = {
    showTribalLands: false
}

const TribalLands = (state = initialState, {type, payload}) => {
    switch (type) {
        case CLEAR_TRIBAL_LANDS_STATE:
            return { ...initialState}
        case SET_SHOW_TRIBAL_LANDS:
            return { ...state, showTribalLands: payload }
        default:
            return state
    }
}

export default TribalLands;