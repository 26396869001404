import { MapPopupContents } from '@innovation-toolkit/ui';
import { useEffect, useState } from 'react';
import { Popup } from 'react-map-gl';

const SelfServicePointPopup = ({ isOpen, onClose, lngLat, feature, target }) => {
    const [popupState, setPopupState] = useState({
        lng: 0,
        lat: 0,
        children: null
    })
    const [children, setChildren] = useState(null)


    useEffect(() => {
        if (lngLat === null || feature === null || target === null) return

        const properties = feature.properties;

        let description = <div>
            {Object.keys(properties).map(key => <p><strong>{key}</strong>: {properties[key]}</p>)}
        </div>

        setPopupState({
            lng: lngLat.lng,
            lat: lngLat.lat,
            children: description
        })

    }, [feature, lngLat, target, children])

    const close = () => {
        onClose();
        setPopupState({
            lng: 0,
            lat: 0,
            children: null
        });
        setChildren(null)
    }

    return (
        <>
            {isOpen &&
                <Popup longitude={popupState.lng} latitude={popupState.lat} onClose={close}>
                    <MapPopupContents 
                        title='Self Service Point'
                        data={feature?.properties ?? []}
                    />
                </Popup>
            }
        </>
    )
}

export default SelfServicePointPopup;