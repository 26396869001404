import { DYNAMIC_CONFIG } from '../redux/actionTypes';
import { APIGateways } from './config';

const initialState = {
  APIGateways: APIGateways,
};

const main = (state = initialState, { type, payload }) => {
  switch (type) {
    case DYNAMIC_CONFIG:
      return { ...state, dynamicConfig: payload };
    default:
      return state;
  }
};

export default main;
