export const SET_WORK_TABLE_TYPE = "SET_WORK_TABLE_TYPE"
export const SET_OVERLAP_IDS = "SET_OVERLAP_IDS"
export const SET_VALUE_METRICS_TABLE_TYPE = "SET_VALUE_METRICS_TABLE_TYPE"
export const SET_CPD_OPTIONS = "SET_CPD_OPTIONS"
export const SET_MANDI_OPTIONS = "SET_MANDI_OPTIONS"
export const SET_SELECTED_FILTERING_LAYER = "SET_SELECTED_FILTERING_LAYER"
export const SET_CITY_WORK_DATA = "SET_CITY_WORK_DATA"
export const SET_DRAWN_GEOMETRIES = "SET_DRAWN_GEOMETRIES"
export const SET_ROW_UPDATE_INFO = "SET_ROW_UPDATE_INFO"
export const SET_SHOW_OVERLAP_TABLE = "SET_SHOW_OVERLAP_TABLE"
export const SET_PROJECT_TYPES = "SET_PROJECT_TYPES"
export const SET_SHOW_MORATORIUM_MAP_DATA = "SET_SHOW_MORATORIUM_MAP_DATA"
export const SET_SEARCH_PLANNER_ID = "SET_SEARCH_PLANNER_ID"
export const SET_SEARCH_NOTIFICATION_NO = "SET_SEARCH_NOTIFICATION_NO"
export const SET_SEARCH_ORDER_NO = "SET_SEARCH_ORDER_NO"
export const SET_IS_EDITING_ROWS = "SET_IS_EDITING_ROWS"
export const SET_HIGHLIGHT_MAP = "SET_HIGHLIGHT_MAP"
export const SET_MANDI_CHECKBOX_STATES = "SET_MANDI_CHECKBOX_STATES"
export const SET_OPEN_ORDER_ID_TO_FILTER = "SET_OPEN_ORDER_ID_TO_FILTER"
