import { useEffect, useState } from 'react';
import './PopupContents.css';
import { ChevronLeftIcon, ChevronRightIcon, DelimiterLine, Typography } from '@innovation-toolkit/ui';
import styled from 'styled-components';

// Styled Components
const StyledContainer = styled.div`
  display: flex;
  align-items: center;
`;

const ArrowContainer = styled.div`
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  height: 50px;
`;

const StyledArrow = styled.button`
  background: none;
  border: none;
  font-size: 2rem;
  cursor: pointer;
  padding: 10px;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
`;

const ContentContainer = styled.div`
  flex: 1 1 auto;
  overflow-y: scroll;
  max-height: 245px;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  padding: 10px;
  text-align: left;
`;

const DataRow = ({ element }) => {
    
  return (
    <>
      {Object.keys(element).map((key, index) => {

        const InsertBreakAfterSecondWord = (text) => {
            const words = text.split(' ');
            if (words.length > 2) {
              const firstPart = words.slice(0, 2).join(' ');
              const secondPart = words.slice(2).join(' ');
              return (
                <>
                  {firstPart}
                  <br />
                  {secondPart}
                </>
              );
            }
            return <div>{text}</div>;
          };
          
        return (
            <div className="MapPopupContentsAttribute" key={index}>
              <Typography text={InsertBreakAfterSecondWord(key)} variant='Heading 4' style={{paddingTop: '3px', paddingBottom: '3px', minWidth: '216px'}}/>
              <Typography text={element[key]} variant='Body 1' style={{paddingTop: '3px', paddingBottom: '3px'}}/>
            </div>
          )
      })}
    </>
  );
};

const DataContents = ({ data, setArrIndex }) => {
    const isArray = Array.isArray(data);
    const [currentIndex, setCurrentIndex] = useState(0);

  
    useEffect(() => {
        setArrIndex(currentIndex)
    }, [currentIndex]);
  
    const handlePrevious = () => {
      setCurrentIndex(prevIndex => (prevIndex === 0 ? data.length - 1 : prevIndex - 1));
    };
  
    const handleNext = () => {
      setCurrentIndex(prevIndex => (prevIndex === data.length - 1 ? 0 : prevIndex + 1));
    };
  
    if (isArray) {
      return (
        <StyledContainer>
          <ArrowContainer>
            <StyledArrow onClick={handlePrevious} disabled={data.length <= 1}><ChevronLeftIcon style={{color:'white', width: '15px'}}/></StyledArrow>
          </ArrowContainer>
          <ContentContainer>
            <DataRow element={data[currentIndex]} />
            <p className='index'>{currentIndex + 1} of {data.length}</p>
          </ContentContainer>
          <ArrowContainer>
            <StyledArrow onClick={handleNext} disabled={data.length <= 1}><ChevronRightIcon style={{color:'white', width: '15px'}} /></StyledArrow>
          </ArrowContainer>
        </StyledContainer>
      );
    } else {
      return <DataRow element={data} />;
    }
  };


export const PopupContents = ({
  className,
  children,
  title,
  typeOfWork = 'Projects',
  customTypeOfWork,
  footNote,
  data,
  accentColor,
  index,
  setArrIndex,
  pointCount,
  openOrderTitle
}) => {
    return (
    <div className={`MapPopupContents ${className || ''}`} accentColor={accentColor} style={{width: '432px'}}>
    {title && (
        <>
            <div className='MapPopupContentsHeader'>
            <Typography text={data?.length > 1 ? `${index + 1}/${pointCount} Work Orders` : title} variant='Heading 1' />

            { customTypeOfWork 
                ? <Typography text={customTypeOfWork} variant='Body 1'/>
                : data?.length && <Typography text={`${data.length} ${typeOfWork}`} variant='Body 1'/>
            }

            </div>
            <DelimiterLine/>
        </>
    )}

    {data?.length > 1 ?
    <div style={{margin: 'auto', width:'80%'}}>
        <div>
        <Typography text={`${index + 1} SoCalGas Work - M&I ${openOrderTitle}`} variant='Heading 1' />
        </div>
        <DelimiterLine />
    </div>
    : null 
    }

    <div className={data?.length > 1 ? "" : "MapPopupContentChildren"}>
        {children && children}
        {data && <DataContents 
        data={data} 
        index={index}
        setArrIndex={setArrIndex}
        />}
    </div>
    {footNote && (
        <>
        <DelimiterLine />
        <div className="MapPopupFootNote">
            <Typography text={footNote} variant='Body 1' />
        </div>
        </>
    )}
    </div>
  );
};