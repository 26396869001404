import {
    SET_FILE_COLUMNS,
    SET_FILE_METADATA,
    SET_FILE_UPLOAD_GEO_SOURCE,
    SET_GEOMETRY_STATE,
    SET_GEOMETRY_STATE_ATTRIBUTE,
    SET_OPTIONAL_ATTRIBUTE,
    SET_STYLE_STATE,
    SET_STYLE_STATE_ATTRIBUTE,
    SET_SIMPLE_ANALYTICS_STATE_ATTRIBUTE,
    SET_SHOW_SIMPLE_ANALYTICS_TABLE,
    FETCH_SIMPLE_ANALYTICS_DATA,
    SET_SIMPLE_ANALYTICS_TABLE_DATA,
    SET_FILE_UPLOAD_RESPONSE,
    RESET_SELF_SERVICE_STYLE,
    RESET_SELF_SERVICE,
    RESET_FILE_CLASSIFICATION,
    SET_FILE_DESCRIPTION,
    SET_FILE_LOADED,
    SET_FILE_CLASSIFICATION
} from "./actionTypes"

export const resetSelfService = () => {
    return {
        type: RESET_SELF_SERVICE,
    }
}

export const resetSelfServiceStyle = () => {
    return {
        type: RESET_SELF_SERVICE_STYLE,
    }
}

export const setSelfServiceGeometry = (payload) => {
    return {
        type: SET_GEOMETRY_STATE,
        payload
    }
}

export const setSelfServiceGeometryAttribute = (payload) => {
    return {
        type: SET_GEOMETRY_STATE_ATTRIBUTE,
        payload
    }
}

export const setFileClassification = (payload) => {
    return {
        type: SET_FILE_CLASSIFICATION,
        payload
    }
}

export const resetFileClassification = () => {
    return {
        type: RESET_FILE_CLASSIFICATION,
    }
}

export const setSelfServiceOptionalAttribute = (payload) => {
    return {
        type: SET_OPTIONAL_ATTRIBUTE,
        payload
    }
}

export const setSelfServiceStyle = (payload) => {
    return {
        type: SET_STYLE_STATE,
        payload
    }
}

export const setSelfServiceStyleAttribute = (payload) => {
    return {
        type: SET_STYLE_STATE_ATTRIBUTE,
        payload
    }
}

export const setFileMetadata = (payload) => {
    return {
        type: SET_FILE_METADATA,
        payload
    }
}

export const setFileDescription = (payload) => {
    return {
        type: SET_FILE_DESCRIPTION,
        payload
    }
}

export const setSelfServiceSimpleAnalyticsAttribute = (payload) => {
    return {
        type: SET_SIMPLE_ANALYTICS_STATE_ATTRIBUTE,
        payload
    }
}


export const setFileColumns = (payload) => {
    return {
        type: SET_FILE_COLUMNS,
        payload
    }
}

export const setShowSimpleAnalyticsTable = (payload) => {
    return {
        type: SET_SHOW_SIMPLE_ANALYTICS_TABLE,
        payload
    }
}

export const setFileUploadGeoSource = (payload) => {
    return {
        type: SET_FILE_UPLOAD_GEO_SOURCE,
        payload
    }
}

export const setSelfServiceUploadResponse = (payload) => {
    return {
        type: SET_FILE_UPLOAD_RESPONSE,
        payload
    }
}

export const setFileLoaded = (payload) => {
    return {
        type: SET_FILE_LOADED,
        payload
    }
}

export const setSimpleAnalyticsTableData = (payload) => {
    let colHeaders = []
    let rowHeaders = []
    let columns = []
    let result = payload

    if (payload && Object.keys(payload).length > 0) {
        colHeaders = Object.keys(payload).map(header => {
            columns.push(payload[header])
            return header;
        })

        rowHeaders = Object.keys(columns[0])
        let rows = new Array(rowHeaders.length)

        columns.forEach((column, colIndex) => {

            Object.keys(column).forEach((cell, cellIndex) => {
                if (colIndex === 0) {
                    rows[cellIndex] = { rowHeader: cell, data: [column[cell]] }
                } else {
                    rows[cellIndex] = { ...rows[cellIndex], data: [...rows[cellIndex].data, column[cell]] }
                }
            })
        })
        result = { colHeaders: colHeaders, row: rows }
    }

    return {
        type: SET_SIMPLE_ANALYTICS_TABLE_DATA,
        payload: result
    }
}
