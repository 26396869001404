import { DelimiterLine } from '@innovation-toolkit/ui';
import React from 'react';
import styled from "styled-components";

const StyledTable = styled.table`
table {
    border-collapse: collapse;
    width: 100%;
  }
  th, td{
    padding: 15px 40px;
    text-align: center;
  }
  td {
    padding: 8px;
    text-align: left;
    border: 3px solid #FFFFFF1A;
  }
  td:nth-child(even) {
    background-color: #FFFFFF1A;
  }

  th {
  }

`
const TableContent = styled.div`

  height: 100%;
  width: 906px;
  .ant-table-column-sorter{
    color:white;
}
`

const TableWrapper = styled.div`
  min-width: 80%;
`

const TitleWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 60px;
    position: relative;
    top: 13.49px;
`

const DynamicTable = ({ title, dataSource, columns }) => {
  return (
    <TableContent>
    <TableWrapper>
      <TitleWrapper>
        <h1>{title}</h1>
      </TitleWrapper>
    <DelimiterLine />
      <StyledTable>
          <thead>
            <tr>
            <th rowspan="1"></th>
              {columns.map(column => (
                <th style={{width: '25%', height: '30px'}} key={column.key}>{column.title}</th>
              ))}
            </tr>
          </thead>
          <tbody>
          {Object.keys(dataSource).map((rowKey, rowIndex) => (
            <tr key={rowIndex}>
                <th >{rowKey}</th>
                {Object.values(dataSource[rowKey]).map((value, colIndex) => (
                <td key={colIndex}>
                  <div style={{display: 'flex', justifyContent: 'center'}}>
                  {value}
                  </div>
                </td>
                ))}
            </tr>
            ))}
          </tbody>
        </StyledTable>
    </TableWrapper>
</TableContent>

  );
};

export default DynamicTable;
