export const SET_GEOMETRY_STATE = 'SET_GEOMETRY_STATE';
export const SET_GEOMETRY_STATE_ATTRIBUTE = 'SET_GEOMETRY_STATE_ATTRIBUTE';
export const SET_OPTIONAL_ATTRIBUTE = 'SET_OPTIONAL_ATTRIBUTE';
export const SET_STYLE_STATE = 'SET_STYLE_STATE';
export const SET_STYLE_STATE_ATTRIBUTE = 'SET_STYLE_STATE_ATTRIBUTE';
export const SET_FILE_METADATA = 'SET_FILE_METADATA';
export const SET_FILE_COLUMNS = 'SET_FILE_COLUMNS';
export const SET_FILE_UPLOAD_GEO_SOURCE = 'SET_FILE_UPLOAD_GEO_SOURCE'
export const SET_SIMPLE_ANALYTICS_STATE_ATTRIBUTE = 'SET_SIMPLE_ANALYTICS_STATE_ATTRIBUTE';
export const SET_SHOW_SIMPLE_ANALYTICS_TABLE = 'SET_SHOW_SIMPLE_ANALYTICS_TABLE';
export const SET_SIMPLE_ANALYTICS_TABLE_DATA = 'SET_SIMPLE_ANALYTICS_TABLE_DATA';
export const SET_FILE_UPLOAD_RESPONSE = 'SET_FILE_UPLOAD_RESPONSE';
export const RESET_SELF_SERVICE_STYLE = 'RESET_SELF_SERVICE_STYLE';
export const RESET_FILE_CLASSIFICATION = 'RESET_FILE_CLASSIFICATION';
export const RESET_SELF_SERVICE = 'RESET_SELF_SERVICE';
export const SET_FILE_DESCRIPTION = 'SET_FILE_DESCRIPTION';
export const SET_FILE_LOADED = 'SET_FILE_LOADED';
export const SET_FILE_CLASSIFICATION = 'SET_FILE_CLASSIFICATION';

export const FETCH_SIMPLE_ANALYTICS_DATA = 'self-service/simple-analytics-table';
