import { CheckboxList, FilterActionButtons, HeaderContent } from '@innovation-toolkit/ui';
import _ from 'lodash';
import { useState } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { selectLeakStatuses } from '@redux/searchItems/selectors';
import { setLeakStatuses, resetLeakStatuses } from '@redux/searchItems/actions';

const CheckboxListWrapper = styled.div`
    padding-top: 20px;
    padding-left: 5px;
`
const InactiveLabel = styled.span`
    color: grey;
`    
export const LeakStatusFilter = ({closeTabs, footNote}) => {

    const dispatch = useDispatch();

    const stateSelectedStatuses = useSelector(selectLeakStatuses)

    const [selectedStatuses, setSelectedStatuses] = useState(stateSelectedStatuses.length === 0 ? ['Yes'] : stateSelectedStatuses);

    // Will be updated once we have an endpoint from the DS team
    const options = [
        {label: 'Active', value: 'Yes'}, 
        {label: <InactiveLabel>Inactive</InactiveLabel>, value: 'No', disabled: true},
    ]

    const handleChange = (newSelectedStatuses) => {
        setSelectedStatuses(newSelectedStatuses)
    }

    const handleConfirm = () => {
        dispatch(setLeakStatuses(selectedStatuses));
        closeTabs();
    }

    const handleClear = () => {
        dispatch(resetLeakStatuses());
        closeTabs();
    }

    return (
    <div>
            <HeaderContent
                title="Leak Status"
                closeTabs={closeTabs}
            >
            <CheckboxListWrapper>
                <CheckboxList
                    options={options}
                    onChange={handleChange}
                    value={selectedStatuses}
                />
            </CheckboxListWrapper>
        </HeaderContent>
        <FilterActionButtons
                isConfirmDisabled={_.isEqual(stateSelectedStatuses, selectedStatuses)}
                onConfirm={handleConfirm}
                footNote={footNote}
                isClearDisabled={true}
                onClear={handleClear}
            />
    </div>)
}

export const LeakStatusFilterStatus = ({allLeakStatusesCount = 2}) => {
    const selectedLeakStatuses = useSelector(selectLeakStatuses);

    if (selectedLeakStatuses.length === 0) {
        return "Active Leaks Selected";
    } 
    else if (selectedLeakStatuses.length === 1) {
        return selectedLeakStatuses[0] === 'Yes' 
                    ? 'Active Leaks Selected'
                    : 'Inactive Leaks Selected';
    }
    else if (selectedLeakStatuses.length === allLeakStatusesCount) {
        return "All Leak Statuses Selected"
    }
    else {
        return selectedLeakStatuses.length + ' Leak Statuses selected';
    }
}
