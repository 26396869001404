import {
    END_SEARCH_DATE,
    START_SEARCH_DATE,
    REMOVE_SEARCH_CENSUS_TRACTS,
    SET_SEARCH_FLEET,
    SET_SEARCH_ALL_CENSUS_TRACTS,
    SET_SEARCH_COMMUNITIES,
    SET_SEARCH_CENSUS_TRACTS,
    SET_CITIES,
    RESET_SEARCH_DATE,
    RESET_SEARCH_VEHICLES,
    RESET_SEARCH_COMMUNITIES,
    RESET_SEARCH_PARAMETERS, RESET_SEARCH_VEHICLES_PARAMETERS, RESET_SEARCH_CENSUS_PARAMETERS,
    RESET_CITY_FILTER,
    SET_PROJECT_STATUS,
    SET_DISTRICTS,
    RESET_DISTRICT_FILTER,
    RESET_MUNICIPALITIES_FILTER,
    SET_MUNICIPALITIES,
    SET_LEAK_CODES,
    SET_LEAK_STATUSES,
    RESET_LEAK_CODE_FILTER,
    RESET_LEAK_STATUS_FILTER
} from '../actionTypes';

const initialState = {
    timeFrame: {
        startDate: null,
        endDate: null
    },
    censusTracts: null,
    communities: null,
    vehicles: null,
    projectStatus: [],
    cities: [],
    districts: [],
    municipalities: [],
    leakStatuses: [],
    leakCodes: []
};

const searchItems = (state = initialState, {type, payload}) => {
    switch (type) {
        case START_SEARCH_DATE:
            return {...state, timeFrame: {...state.timeFrame, ...{startDate: payload}}};
        case END_SEARCH_DATE:
            return {...state, timeFrame: {...state.timeFrame, ...{endDate: payload}}};
        case RESET_SEARCH_DATE:
            return {...state, timeFrame: initialState.timeFrame}
        case SET_SEARCH_CENSUS_TRACTS:
            return {...state, censusTracts: payload}
        case SET_SEARCH_COMMUNITIES:
            return {...state, communities: payload}
        case SET_SEARCH_ALL_CENSUS_TRACTS:
            return {...state}
        case SET_CITIES: 
            return {...state, cities: payload}
        case REMOVE_SEARCH_CENSUS_TRACTS:
            return {...state}
        case RESET_SEARCH_PARAMETERS:
            return {
                ...state,
                timeFrame: initialState.timeFrame,
                vehicles: initialState.vehicles,
                communities: initialState.communities,
                projectStatus: initialState.projectStatus,
                cities: initialState.cities,
                districts: initialState.districts,
                municipalities: initialState.municipalities,
                leakCodes: initialState.leakCodes,
                leakStatuses: initialState.leakStatuses
            }
        case SET_SEARCH_FLEET:
            return {...state, vehicles: payload}
        case RESET_SEARCH_VEHICLES:
            return {...state, vehicles: initialState.vehicles}
        case RESET_SEARCH_COMMUNITIES:
            return {...state, communities: initialState.communities}
        case RESET_SEARCH_VEHICLES_PARAMETERS:
            return {...state, vehicles: initialState.vehicles}
        case RESET_SEARCH_CENSUS_PARAMETERS:
            return {...state, censusTracts: initialState.censusTracts}
        case RESET_CITY_FILTER:
            return {...state, cities: initialState.cities}
        case RESET_DISTRICT_FILTER:
            return {...state, districts: initialState.districts}
        case RESET_MUNICIPALITIES_FILTER:
            return {...state, municipalities: initialState.municipalities}
        case RESET_LEAK_STATUS_FILTER:
            return {...state, leakStatuses: initialState.leakStatuses}
        case RESET_LEAK_CODE_FILTER:
            return {...state, leakCodes: initialState.leakCodes}
        case SET_PROJECT_STATUS: 
            return {...state, projectStatus: payload}
        case SET_DISTRICTS:
            return {...state, districts: payload}
        case SET_MUNICIPALITIES:
            return {...state, municipalities: payload}
        case SET_LEAK_STATUSES:
            return {...state, leakStatuses: payload}
        case SET_LEAK_CODES:
            return {...state, leakCodes: payload}
        default:
            return state;
    }
};

export default searchItems;
