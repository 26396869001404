import {
    SET_SHOW_EMISSIONS_LAYER,
} from "./actionTypes"

export const initialState = {
    showEmissionsLayer: false
}

const Emissions = (state = initialState, { type, payload }) => {
    switch (type) {
        case SET_SHOW_EMISSIONS_LAYER:
            return { ...state, showEmissionsLayer: payload }
        default:
            return state
    }
}

export default Emissions;