import Button from './Button';
import styled from 'styled-components'
import { useState } from 'react';

const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly
`

const Row = styled.div`
    width: 100%;
    display: flex; 
    flex-direction: row;
    justify-content: space-evenly;
`

const Icon = () => {
    return (
      <svg id="icon_export" data-name="icon / export" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <rect id="container" width="24" height="24" fill="none"/>
        <g id="icon" transform="translate(-2981 -666.998)">
          <path id="Subtraction_14" data-name="Subtraction 14" d="M18248-1275h-14a4,4,0,0,1-4-4v-14a4,4,0,0,1,4-4h14a4,4,0,0,1,4,4v1h-2v-1a2,2,0,0,0-2-2h-14a2,2,0,0,0-2,2v14a2,2,0,0,0,2,2h14a2,2,0,0,0,2-2v-1h2v1A4,4,0,0,1,18248-1275Z" transform="translate(-15249 1965)" fill="#fff"/>
          <path id="arrow" d="M18325,9385l4,3-4,3Zm-12,4v-2h12v2Z" transform="translate(-15324 -8709)" fill="#fff"/>
        </g>
      </svg>
    )
}

/* Button Demo
    Grid of all possible buttons with foundational button component
*/

export const ButtonDemo = () => {
    const [isDisabled, setIsDisabled] = useState(false);

    return (
        <Wrapper>
            <Row>
                <Button title={`Click to ${isDisabled ? 'Enable' : 'Disable'}`} type='secondary' size='L' onClick={()=> setIsDisabled(!isDisabled)}/>
            </Row>
            <Row>
                <Button title='button' disabled={isDisabled} size='L'/>
                <Button title='button' disabled={isDisabled} size='L' svgIcon={<Icon/>}/>
                <Button title='button' disabled={isDisabled} size='L' type='secondary' />
                <Button title='button' disabled={isDisabled} size='L' type='secondary' svgIcon={<Icon/>} />
            </Row>
            <Row>
                <Button title='button' disabled={isDisabled} size='M'/>
                <Button title='button' disabled={isDisabled} size='M' svgIcon={<Icon/>}/>
                <Button title='button' disabled={isDisabled} size='M' type='secondary' />
                <Button title='button' disabled={isDisabled} size='M' type='secondary' svgIcon={<Icon/>} />
            </Row>
            <Row>
                <Button title='button' disabled={isDisabled} size='S'/>
                <Button title='button' disabled={isDisabled} size='S' type='secondary' />
            </Row>
            <Row>
                <Button title='button' disabled={isDisabled} size='thin'/>
                <Button title='button' disabled={isDisabled} size='thin' type='secondary' />
                <Button title='button' disabled={isDisabled} type='tertiary' />
            </Row>
        </Wrapper>
    )
}