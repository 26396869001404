import { Button, DelimiterLine, Typography, openNotification } from '@innovation-toolkit/ui';
import { DataList, EditFooter, toDateString } from '../../EditableRow';
import { useDispatch, useSelector } from 'react-redux';
import { useState, useEffect, useRef } from 'react';
import { selectCityWorkData, selectDrawnGeometries, selectRowUpdateInfo, selectProjectTypes } from '@redux/streetImprovements/selector';
import { setCityWorkData, setDrawnGeometries, setRowUpdateInfo, setIsEditingRows } from '@redux/streetImprovements/action';
import ConfirmModal from './ConfirmModal';
import { EditHistory } from '../../EditHistory';
import axios from 'axios';
import { APIGateways } from '@config/config';
import moment from 'moment';
import { setSideBarContent, setSideBarVisibility } from 'redux/mapStateInternal/action';

const includedAttributes = [
    'Id',
    'Moratorium End Date', 
    'Moratorium End Date Is Estimated', 
    'Moratorium Start Date', 
    'Moratorium Start Date Is Estimated', 
    'District',
    'Project Category',
    'Project Start Date',
    'Project End Date', 
    'Project Start Date Is Estimated',
    'Project End Date Is Estimated',  
    'Ingestion Source',
    'Dataset Name',
    'Address', 
    'Municipality',
    'Edit History',
    'Representative Address'
];


// const attributeMap = {
//     'Id',
//     'Address', 
//     'Moratorium End Date', 
//     'Moratorium End Date Is Estimated', 
//     'Moratorium Start Date', 
//     'Moratorium Start Date Is Estimated', 
//     'Municipality',
//     'District',
//     'Project Category',
//     'Project End Date', 
//     'Project End Date Is Estimated', 
//     'Project Start Date', 
//     'Project Start Date Is Estimated',
//     'Lat',
//     'Lon',
//     'Dataset Name',
//     'Edit History'
// }

const DataMap = [
  {
    key: 'id',
    formatted_title: 'Id',
    type: 'constant'
  },
  {
    key: 'moratorium_start_date',
    formatted_title: 'Moratorium Start Date',
    type: 'date'
  },
  {
    key: 'moratorium_start_date_is_estimated',
    formatted_title: 'Moratorium Start Date Is Estimated',
    type: 'boolean'
  },
  {
    key: 'moratorium_end_date',
    formatted_title: 'Moratorium End Date',
    type: 'date'
  },
  {
    key: 'moratorium_end_date_is_estimated',
    formatted_title: 'Moratorium End Date Is Estimated',
    type: 'boolean'
  },
  {
    key: 'project_category',
    formatted_title: 'Project Category',
    type: 'select',
    options: [
      'Street Resurfacing or Repaving', 
      'Street Widening', 
      'Slurry Seal', 
      'Bridge Construction', 
      'Storm and Sewer Drain Construction', 
      'Sidewalk or Curb Construction', 
      'Development (Commercial, Industrial, and Residential)', 
      'Moratorium', 
      'Other'
    ]
  },
  {
    key: 'project_start_date',
    formatted_title: 'Project Start Date',
    type: 'date'
  },
  {
    key: 'project_start_date_is_estimated',
    formatted_title: 'Project Start Date Is Estimated',
    type: 'boolean'
  },
  {
    key: 'project_end_date',
    formatted_title: 'Project End Date',
    type: 'date'
  },
  {
    key: 'project_end_date_is_estimated',
    formatted_title: 'Project End Date Is Estimated',
    type: 'boolean'
  },
  {
    key: 'address',
    formatted_title: 'Address',
    type: 'text'
  },
  {
    key: 'representative_address',
    formatted_title: 'Supplemental Address',
    type: 'text'
  },
  {
    key: 'municipality',
    formatted_title: 'Municipality',
    type: 'text'
  },
  {
    key: 'district',
    formatted_title: 'District',
    type: 'text'
  },
  {
    key: 'lat',
    formatted_title: 'Lat',
    type: 'text'
  },
  {
    key: 'lon',
    formatted_title: 'Lon',
    type: 'text'
  },
  {
    key: 'dataset_name',
    formatted_title: 'Dataset Name',
    type: 'constant'
  },
]

const convertSnakeToCamelCase = (str) => {
    const formatted = str.split('_').map(word => word[0].toUpperCase() + word.slice(1)).join(' ');
    return formatted
}

const formatInformation = (element) => {
    const formattedInformation = {}

    for (const property in element) {
        const formattedProperty = convertSnakeToCamelCase(property)

        if (!includedAttributes.includes(formattedProperty)) continue;

        formattedInformation[formattedProperty] = element[property].toString();

        if (property.includes('date') && !property.includes('estimate')) {
            formattedInformation[formattedProperty] = toDateString(element[property]);
        }

    }

    return formattedInformation
}

const formatEditHistory = (history) => {

  if (!history || typeof history === 'string') return []
  const records = []

  history.forEach( record =>  {
    let changes = ''
    // Format Date and Time
    const formatDate = record.timestamp.split("-")
    const am_pm = Number(formatDate[3]) > 12 ? "PM" : "AM"
    const hours = Number(formatDate[3]) > 12 ? Number(formatDate[3])-12 : Number(formatDate[3])
    const date = `${formatDate[2]}/${formatDate[1]}/${formatDate[0]} ${hours}:${formatDate[4]} ${am_pm}`

    // Format changes
    record.changes_made && record.changes_made.forEach( change => {
      const key = Object.keys(change)[0]

      const currentChange = change[`${key}`]
      changes = changes.length > 0 
        ? `${changes}, Changed [${key}] from [${currentChange.before}[ to [${currentChange.now}]`
        : `Changed [${key}] from [${currentChange.before}] to [${currentChange.now}]`
    })

    records.push({
      date: date,
      user: record.user,
      changes: changes
    })
  })

  return records
}

export const LAStreetImprovementSideBar = ({isOpen, lngLat, feature, setPopupFeature}) => {
    const dispatch = useDispatch();

    const mapData = useSelector(selectCityWorkData)
    const projectTypes = useSelector(selectProjectTypes)

    const [data, setData] = useState(null);
    const [editDataMapping, setEditDataMapping] = useState(DataMap)
    const [popupTitle, setPopupTitle] = useState("Street Improvement Project");
    const [showEditHistory, setShowEditHistory] = useState(false);
    const [isEditable, setIsEditable] = useState(false);
    const [popupIndex, setPopupIndex] = useState(0);
    const [modifiedData, setModifiedData] = useState({});
    const [editMode, setEditMode] = useState(false);
    const [editableData, setEditableData] = useState(null);
    const [isModified, setIsModified] = useState(false);
    const [editHistoryContent, setEditHistoryContent] = useState([])
    const [updatingGeometry, setUpdatingGeometry] = useState(false)

    // Modal State
    const [actionType, setActionType] = useState('');
    const [modalTitle, setModalTitle] = useState('');
    const [modalBody, setModalBody] = useState('');
    const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);

    const rowUpdateInfo = useSelector(selectRowUpdateInfo);
    const rowUpdateInfoRef = useRef()
    const drawnGeometries = useSelector(selectDrawnGeometries);
    const drawnGeometriesRef = useRef()

    const statusTitles = {
      project: "Street Improvement Project",
      moratorium: "Moratorium",
    };

    useEffect(() => {   
        drawnGeometriesRef.current = drawnGeometries
    }, [drawnGeometries])
    
    useEffect(() => {
        rowUpdateInfoRef.current = rowUpdateInfo
    }, [rowUpdateInfo])

    useEffect(() => {
        if (!feature) return
        const popupFeature = feature.properties

        let featureIndex = mapData.features.findIndex(searchFeature => searchFeature.properties.id === popupFeature.id);
        let editable = popupFeature.dataset_name === 'webform';

        let formatted = formatInformation(popupFeature);
        
        if (projectTypes && projectTypes.length > 0) {
          const tmpData = []
          editDataMapping.forEach( (data) => {
            if (data.key === 'project_category') {
              tmpData.push({...data, options: projectTypes})
            } else {
              tmpData.push({...data})
            }
          })
          setEditDataMapping(tmpData)
        }
        setPopupTitle(statusTitles[popupFeature.current_status]);
        setIsEditable(editable);
        setPopupIndex(featureIndex);
        setData(formatted)
        setEditableData(formatted)

        setEditHistoryContent(formatEditHistory(formatted['Edit History']))

    }, [isOpen, lngLat, feature, mapData]);

    const handleEditGeometries = () => {
      openNotification({
        notificationMessage: "To replace a project's geometry, click on the “draw” button on the right and draw a new line. Once completed, click on the “Save” button below. The geometry will immediately update. ",
        placement: 'top-right',
        header: 'Edit Geometry'
      })
      setEditMode(true);
      setUpdatingGeometry(true)
      dispatch(setIsEditingRows(true))
      dispatch(setRowUpdateInfo({
          index: popupIndex,
          isUpdating: true,
          currentFeature: data
      }))
    }

    const handleCancelEditGeometries = () => {
      setUpdatingGeometry(false)
      dispatch(setDrawnGeometries({
        type: 'FeatureCollection',
        features: []
      }));
      dispatch(setRowUpdateInfo({
        index: -1,
        isUpdating: false,
        currentFeature: null
      }))
    }
  
    const handleSaveGeometries = () => {
      const newMapData = JSON.parse(JSON.stringify(mapData))
  
      // Update geometry of selected value
      if (drawnGeometriesRef.current.length === 0) {
          openNotification({
              notificationMessage: 'No new geometry entered. Old geometry will be kept',
              placement: 'top-right'
          })
      } else {
          newMapData.features[popupIndex].geometry = drawnGeometriesRef.current.features[0].geometry
          setIsModified(true);
          setModifiedData(formData => {
            let modified = {...formData}
            modified['geometry'] = drawnGeometriesRef.current.features[0].geometry
            return modified
          })

          openNotification({
            notificationMessage: 'New project geometry is now drawn on the map indicated by the blue and black caution tape. To update project, please save your updates.',
            placement: 'top-right'
        })
      }

      setUpdatingGeometry(false)
      dispatch(setRowUpdateInfo({
        index: -1,
        isUpdating: false,
        currentFeature: null
      }))
    }

    const handleSaveProject = () => {
      const newMapData = JSON.parse(JSON.stringify(mapData));
      const payload = newMapData.features[popupIndex]
      // Update geometry of project and update popup location
      if (modifiedData.geometry) {
        let newGeometry = modifiedData.geometry;
        newMapData.features[popupIndex].geometry = newGeometry;

        payload.geometry = newGeometry
        dispatch(setDrawnGeometries({
          type: 'FeatureCollection',
          features: []
        }));
      }
      //Update properties of feature and set as new popup feature
      if(modifiedData.properties) {
        newMapData.features[popupIndex].properties = {...newMapData.features[popupIndex].properties, ...modifiedData.properties};
        // setPopupFeature(newMapData.features[popupIndex]);

        for (const key in modifiedData.properties) {
          payload.properties[key] = modifiedData.properties[key]
        }

        payload.properties['current_status'] = modifiedData.properties['project_category'] !== "Moratorium" ? "project" : "moratorium";
      }

      axios.post(`${APIGateways.citywork}/update_city_work_project`, payload)
           .then((response) => {
              dispatch(setCityWorkData(newMapData));
              setEditMode(isEditMode => !isEditMode);
              dispatch(setIsEditingRows(false))
              setData(editableData);
              setIsModified(false);
              setActionType('');
              const popupData = { siPopup: { isOpen: true, feature: payload, setPopupFeature: null, lngLat: lngLat, target: null}}

              dispatch(setSideBarContent(popupData))
              dispatch(setSideBarVisibility(true))
              openNotification({type: 'success', header: 'Project Updated', notificationMessage: 'All changes saved. If the location was updated, the project should now be at the new location.'});
           })
           .catch((error) => {
              openNotification({type: 'error', header: 'Error updating', notificationMessage: 'An error was encountered while trying to update the project, please try again.'});
           })
      //Handle cleanup after saving and send new data to endpoint
      }

    const handleCancelProjectEdit = () => {
        dispatch(setDrawnGeometries({
          type: 'FeatureCollection',
          features: []
        }));
        setEditMode(isEditMode => !isEditMode);
        dispatch(setIsEditingRows(false))
        setEditableData(data);
        setIsModified(false);
        setActionType('');
    }

    const handleDeleteProject = () => {
      setActionType('');
      const newMapData = JSON.parse(JSON.stringify(mapData));
      const payload = newMapData.features[popupIndex]
      
      // Remove the feature with the specific citywork_id
      newMapData.features = newMapData.features.filter(
          feature => feature.properties.id !== payload.properties.id
      );

      axios.post(`${APIGateways.citywork}/webform/delete_citywork_project`, {"citywork_id": payload.properties.id})
      .then((response) => {
        dispatch(setCityWorkData(newMapData));
        setEditMode(isEditMode => !isEditMode);
        dispatch(setIsEditingRows(false))
        setData(editableData);
        setIsModified(false);
        setActionType('');
        const popupData = { siPopup: { isOpen: false, feature: payload, setPopupFeature: null, lngLat: lngLat, target: null}}

        dispatch(setSideBarContent(popupData))
        dispatch(setSideBarVisibility(false))
        openNotification({type: 'success', header: 'Project Deleted', notificationMessage: 'The project has been deleted. You are now unable to view it on the map.'});
      })
     .catch((error) => {
        openNotification({type: 'error', header: 'Error Deleted', notificationMessage: 'An error was encountered while trying to delete the project, please try again.'});
     })
    }

    const handleOpenModal = (action) => {
      setIsConfirmModalOpen(true);
      setActionType(action);

      switch (action) {
        case 'delete':
          setModalTitle('Delete Project');
          setModalBody('This will delete the project permanently. Are you sure you want to delete this project?');
        break;
        case 'save':
          setModalTitle('Save Edits');
          setModalBody('The project will be updated and this edit will be documented in the “Edit History”. Are you sure you want to save the edits?');
        break;
        case 'cancel':
        default:
          setModalTitle('Discard Unsaved Edits');
          setModalBody("If you proceed, you will loose all of the edits you've made. Are you sure you want to discard these edits?");
      }
    }

    const handleModalConfirm = () => {
      switch (actionType) {
        case 'delete':
          handleDeleteProject();
        break;
        case 'save':
          handleSaveProject();
        break;
        case 'cancel':
        default:
          handleCancelProjectEdit();
      }
      setIsConfirmModalOpen(false);
    }

    return(
      <>
        {
          isOpen ?
          <div style={{ width: '280px', height: 'calc(100% - 127px)', padding: '16px 0px 16px 24px'}}> 
          <Typography text={popupTitle} variant="Heading 2"/>
          <DelimiterLine style={{ marginRight: '24px'}}/>
            { !showEditHistory
              ?<div style={{height: '100%',display: 'flex', justifyContent: 'space-between', flexDirection: 'column', overflow: 'hidden'}}>
                <div style={{ overflowX: 'hidden', overflowY: 'auto'}}>
                  <div style={{paddingRight: '16px'}}>
                    <DataList
                      rawData={feature && feature.properties ? feature.properties : null}
                      data={editableData} 
                      setModifiedData={setModifiedData}
                      setData={setEditableData}
                      editMode={editMode} 
                      isEditable={isEditable}
                      dataMap={editDataMapping}
                      setIsModified={setIsModified}
                      handleEditGeometries={handleEditGeometries}
                      handleShowEditHistory={() => setShowEditHistory(true)}
                      editHistoryData={editHistoryContent}
                      customGeometryEditButtons
                    />
                  </div>
                </div>
                { rowUpdateInfo && !rowUpdateInfo.isUpdating 
                  ?<div style={{ marginRight: '24px'}}>
                      <DelimiterLine />
                      <EditFooter
                        handleSave={() => handleOpenModal('save')}
                        handleCancel={() => handleOpenModal('cancel')}
                        handleDelete={() => handleOpenModal('delete')}
                        editMode={editMode} 
                        setEditMode={(e) => {
                          dispatch(setIsEditingRows(true))
                          setEditMode(e)
                        }} 
                        setModifiedData={setModifiedData}
                        isEditable={isEditable}
                        isModified={isModified}
                        footerStyle={{gap: 'unset', paddingRight: '0px', justifyContent: 'space-between'}}
                      />
                  </div>
                  : updatingGeometry && <GeometryActionButtons handleSaveGeometries={handleSaveGeometries} handleCancelGeometries={handleCancelEditGeometries}/>
                }
              </div> 
              :<EditHistory
                onBackButtonClicked={() => {setShowEditHistory(false);}}
                editHistory={editHistoryContent}
              />
            }
            </div>
          : null
        }
        <ConfirmModal 
            modalTitleText={modalTitle}
            modalBodyText={modalBody}
            isOpen={isConfirmModalOpen}
            onCancel={() => {setIsConfirmModalOpen(false); setActionType('')}}
            onOk={handleModalConfirm}
        />
      </>
    ) 
}

const GeometryActionButtons = ({
  handleCancelGeometries,
  handleSaveGeometries
}) => {
  return (
    <div>
      <Typography text="Drawing" variant="Heading 2"/>
      <DelimiterLine style={{ marginRight: '24px'}}/>
      <div style={{
        display: 'flex',
        justifyContent: 'space-between',
        marginRight: '24px'
      }}>
        <Button 
          variant='secondary' 
          size='thin'
          title='Reset' 
          onClick={handleCancelGeometries}
        />
        <Button 
          variant='primary' 
          size='thin'
          title='Save Geometry' 
          onClick={handleSaveGeometries}
        />
      </div>
    </div>
  )
}

/**
 * 
const expectedEditHistoryFormat = [
  {
    "timestamp": "2023-08-10-14-50-08", 
    "user": "Me", 
    "changes_made": 
      [
        {
          "project_category": 
            {
              "now": "Moratorium", 
              "before": "Moratorium"
            }
        },
        {
          "project_start_date_is_estimated": 
            {
              "now": true, 
              "before": true
            }
        },
      ]
  }
]
 */