import {   
    RacialEquityLayerMetadata,
    IncomeLayer,
    IncomeLayerMetadata,
    RacialEquityLayer,
    CalEnviroLayerMetadata,
    EventDelegator
} from '@innovation-toolkit/mapbox';
import { CharitableDonationLayer } from './CharitableDonations';
import CalEnviroLayer from '../../../mapbox/layers/CalEnviroLayer'

export const PopulationMapLayers = () => {
    return (
        <>
            <EventDelegator layerIds={[IncomeLayerMetadata.layerId, RacialEquityLayerMetadata.layerId, CalEnviroLayerMetadata.layerId]}/>
            <RacialEquityLayer showLayer={true}/>
            <IncomeLayer showLayer={false}/>
            <CharitableDonationLayer showLayer={true}/>
            <CalEnviroLayer />
        </>
    )
}