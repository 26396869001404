import { useSelector } from "react-redux"
import { WORK_TYPE_COMPONENT } from "redux/actionTypes";
import { selectSelectedKey } from "redux/menu/selectors";
import { selectWorkTypes } from "redux/streetImprovements/selector";
import strings from "utils/strings"

const WorkTypeFilterStatus = () => {
    const selectedKey = useSelector(selectSelectedKey);
    let status = strings.menu.workType.defaultValue;

    const selectedWorkTypes = useSelector(selectWorkTypes);

    if(selectedKey === WORK_TYPE_COMPONENT) {
        status = strings.menu.workType.selecting;
    } else if (selectedWorkTypes.length > 0) {
        status = strings.formatString(strings.menu.workType.value, { count: selectedWorkTypes.length})
    }

    return status;
}

export default WorkTypeFilterStatus;