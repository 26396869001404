import { useDispatch, useSelector } from 'react-redux';

// redux
import { setMenuSelectedKey } from '@redux/menu/actions';
import { selectSelectedKey } from '@redux/menu/selectors';

import { Typography, LayerToggleSection, Menu, MainMenu, MenuTabContents, color, LayerTabWrapper, TitleDivider } from '@innovation-toolkit/ui';
import MenuTabButtons from 'component/sidebar/tabs/MenuTabButtons';
import { useState } from 'react';
import { TribalLandsLayerToggle, CalEnviroLayerToggle, HospitalsLayerToggle } from '@innovation-toolkit/mapbox'
import { scgActions } from "../../sidebar/menuItems";
import SCGEmissionsToggle from '../../mapbox/layers/scg-emissions/toggle';
import MethaneCircleToggle from '../../mapbox/layers/MethaneLeaks/toggle';
import MethaneHeatmapToggle from '../../mapbox/layers/MethaneLeaks/heatmapToggle';


export const MethaneMenu = () => {
    const dispatch = useDispatch()
    const activeMenuItem = useSelector(selectSelectedKey);

    const [currentItem, setCurrentItem] = useState(null);
    const [selectKey, setSelectKey] = useState(undefined);

    const closeTabs = () => {
        dispatch(setMenuSelectedKey(undefined))
        setSelectKey(undefined)
        setCurrentItem(null)
    }

    if (currentItem) return (
        <MainMenu background-color={color.prussianBlue} style={{width: '256px'}}>
            {currentItem.component(closeTabs)}
        </MainMenu>
    )
    return <Menu
        tabs={[
            {
                title: "Filters",
                content: (
                    <div style={{marginTop: '16px'}}>
                        <MenuTabContents
                            key="methane-app"
                            menuItems={scgActions}
                            selectedMenu={activeMenuItem}
                            onSetSelectedMenu={(value) => dispatch(setMenuSelectedKey(value))}
                            currentItem={currentItem}
                            setCurrentItem={setCurrentItem}
                            selectKey={selectKey}
                            setSelectKey={setSelectKey}
                        >
                            <MenuTabButtons />
                        </MenuTabContents>
                    </div>
                )
            },
            {
                title: "Layers",
                content:
                    <LayerTabWrapper>
                        <Typography text='Internal Data' variant='Heading 2' style={{marginTop: '16px'}}/>
                        <TitleDivider />
                            <LayerToggleSection
                                title={'Methane Leaks'}
                                toggles={[
                                    <MethaneCircleToggle />,
                                    <MethaneHeatmapToggle />
                                ]}
                            />
                        <Typography style={{marginTop: '16px'}} text='External Data' variant='Heading 2'/>
                        <TitleDivider />
                            <LayerToggleSection
                                title='Areas of Interest'
                                toggles={[
                                    <CalEnviroLayerToggle />,
                                    <TribalLandsLayerToggle />
                                ]}
                            />
                            <LayerToggleSection
                                title='Institutions'
                                toggles={[
                                    <HospitalsLayerToggle />
                                ]}
                            />
                            <LayerToggleSection
                                title='SoCalGas Data'
                                toggles={[
                                    <SCGEmissionsToggle />
                                ]}
                            />
                    </LayerTabWrapper>
            },
            {
                title: "Analysis",
                content: <LayerTabWrapper>
                        </LayerTabWrapper>
            }
        ]}
    />
}