import CalEnviroLayer from '../../../mapbox/layers/CalEnviroLayer'

import { CAL_ENVIRO_FILL_LAYER, DisadvantagedCommunityLayer, EventDelegator, HospitalsLayer, TribalLandsLayer } from '@innovation-toolkit/mapbox';
import { LAStreetImprovementLayer, LAStreetImprovementLayerMetadata } from './LAStreetImprovement';
import { MoratoriumsLayerMetadata } from './Moratoriums';
import { CPDProjectsLayer, CPDProjectsLayerMetadata, WIPCloudLayerMetadata } from './CPDProjects';
import { CPLayer, CPLayerMetadata } from './CPLayer';
import { LSALayer, LSALayerMetadata } from './LSALayer';
import { MandIWorkLayer, MandIWorkLayerMetadata } from './MandIWork';
import { RoutineSurveyLayerMetadata, RoutineSurveyLayer } from './SurveyLayer/RoutineSurveyLayer';
import { SpecialSurveyLayerMetadata, SpecialSurveyLayer } from './SurveyLayer/SpecialSurveyLayer';
import { DrawContainer } from '../DrawContainer';
import { ValvesLayer } from './ValvesLayer';
import { CP10Layer } from './CP10Layer';

export const StreetImprovementMapLayers = () => {
    return (
        <>
            <EventDelegator layerIds={[ 
                CPDProjectsLayerMetadata.layerId, 
                SpecialSurveyLayerMetadata.layerId,
                RoutineSurveyLayerMetadata.layerId,
                MandIWorkLayerMetadata.layerId, 
                SpecialSurveyLayerMetadata.layerId, 
                LAStreetImprovementLayerMetadata.layerId, 
                MoratoriumsLayerMetadata.layerId, 
                WIPCloudLayerMetadata.layerId,
                CAL_ENVIRO_FILL_LAYER, 
                CPLayerMetadata.layerId, 
                LSALayerMetadata.layerId
            ]}/>
            <EventDelegator layerIds={[CPLayerMetadata.layerId, LSALayerMetadata.layerId]} eventTypes={['mouseover']}/>
            {/* <TribalLandsLayer showLayer={false} /> */}
            <LAStreetImprovementLayer showLayer={true}/>
            {/* <MoratoriumsLayer showLayer={true}/> */}
            <CPDProjectsLayer showLayer={true}/>
            {/* <HospitalsLayer showLayer={false}/> */}
            {/* <DisadvantagedCommunityLayer showLayer={false} /> */}
            <CPLayer />
            <LSALayer />
            <ValvesLayer />
            <CP10Layer />
            <MandIWorkLayer/>
            {/* Routine and Special Survey layers commented out until 
                the options are available to filter down to their values    
            */}
            <RoutineSurveyLayer/>
            <SpecialSurveyLayer/>
            <DrawContainer />
            {/* <WIPCloudLayer showLayer={true}/> */}
        </>
    )
}