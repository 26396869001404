import {
    DISTRICT_COMPONENT,
    SET_MENU_CENSUS_TRACTS_DACS,
    SET_MENU_SWAPPING_VEHICLES,
    SET_SEARCH,
    SET_SELECT_STEPS,
    SET_MENU_TOP_IMPACTED_COMMUNITIES,
    SET_AUTH_USER,
    SET_SWAPPING_CSV_FILE,
    SET_MENU_REPLACEMENT_VEHICLES,
    RESET_MENU_VEHICLE_LIST,
    SET_MENU_VEHICLE_LIST,
    SET_MENU_CONTENT,
    SET_SWAPPING_REQUEST,
    SET_SWAPPING_LAST_REQUEST,
    SET_REPLACEMENT_REQUEST, SET_REPLACEMENT_LAST_REQUEST, RESET_SELECTED_KEY, SELECTED_KEY
} from '../actionTypes';

const setDistrictComponent = date => {
    return {
        type: DISTRICT_COMPONENT,
        payload: true
    };
};

const setMenuSelectedKey = payload => {
    return {
        type: SELECTED_KEY,
        payload
    }
}

const resetMenuSelectedKey = () => {
    return {
        type: RESET_SELECTED_KEY,
    }
}

const setSearch = searchJson => {
    return {
        type: SET_SEARCH,
        payload: searchJson
    }
}

const setMenuDacsTracts = payload => {
    return {
        type: SET_MENU_CENSUS_TRACTS_DACS,
        payload,
    }
}

const setMenuImpactedCommunities = payload => {
    return {
        type: SET_MENU_TOP_IMPACTED_COMMUNITIES,
        payload,
    }
}

const setMenuReplacementVehicles = payload => {
    return {
        type: SET_MENU_REPLACEMENT_VEHICLES,
        payload
    }
}

const setMenuSwappingCsvFile = payload => {
    return {
        type: SET_SWAPPING_CSV_FILE,
        payload
    };
}

const setMenuVehicleList = payload => {
    return {
        type: SET_MENU_VEHICLE_LIST,
        payload,
    }
}

const resetMenuVehicleList = () => {
    return {
        type: RESET_MENU_VEHICLE_LIST
    }
}

const setMenuContent = payload => {
    return {
        type: SET_MENU_CONTENT,
        payload
    }
}


const setMenuSwapRequest = payload => {
    return {
        type: SET_SWAPPING_REQUEST,
        payload,
    };
}
const setMenuReplaceRequest = payload => {
    return {
        type: SET_REPLACEMENT_REQUEST,
        payload,
    };
}

const setMenuReplaceLastRequest = payload => {
    return {
        type: SET_REPLACEMENT_LAST_REQUEST,
        payload,
    };
}

const setMenuSwapLastRequest = payload => {
    return {
        type: SET_SWAPPING_LAST_REQUEST,
        payload,
    };
}

const setMenuSwappingVehicles = payload => {
    return {
        type: SET_MENU_SWAPPING_VEHICLES,
        payload
    }
}

const getSwappingVehicles = (payload, swapMsgWss) => async (dispatch) => {
    const newResponse = await fetch(payload);
    const res = await newResponse.json();
    dispatch(setMenuSwappingVehicles(res));
    dispatch(setMenuSwapLastRequest(swapMsgWss))
}

export {
    setMenuSelectedKey,
    resetMenuSelectedKey,
    setDistrictComponent,
    setSearch,
    setMenuDacsTracts,
    setMenuImpactedCommunities,
    setMenuSwappingVehicles,
    setMenuSwappingCsvFile,
    setMenuReplacementVehicles,
    setMenuSwapRequest,
    setMenuVehicleList,
    resetMenuVehicleList,
    setMenuContent,
    setMenuReplaceRequest,
    setMenuSwapLastRequest,
    setMenuReplaceLastRequest,
    getSwappingVehicles
};
