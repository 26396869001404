import { Layer, Source } from "react-map-gl"
import { APIGateways } from "@config/config";
import { useEffect, useState } from "react";
import { useActivateMapLayer, selectBeforeIDs } from "@innovation-toolkit/mapbox";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";

export const ANGELES_LINK_NO_GO_LAYER = 'ANGELES_LINK_NO_GO_LAYER';
export const ANGELES_LINK_NO_GO_SOURCE = 'ANGELES_LINK_NO_GO_SOURCE';

export const AngelesLinkNoGoLayerMetadata = {
    layerId: ANGELES_LINK_NO_GO_LAYER,
    name: 'Angeles Link No-Go',
    color: '#ff0000'
}

const fetchData = (callBack, filterOptions) => {

    const url = `${APIGateways.scgcore}/data/angeles-link-no-go`;

    const filter = {}

    axios.get(url, filter)
        .then((response) => {
            // Returns as a presigned url, fetch the data here
            return fetch(response.data)
                .then((fetchResponse) => fetchResponse.json())
                .then((data) => {
                    callBack(data, false)
                })
        })
        .catch((err) => {
            callBack(null, true)
        })
}

export const AngelesLinkNoGoLayer = ({showLayer=false}) => {

    const dispatch = useDispatch()
    const [showAngelesLinkNoGo] = useActivateMapLayer(AngelesLinkNoGoLayerMetadata, showLayer)
    const beforeIDs = useSelector(selectBeforeIDs)
    const [data, setData] = useState(null);

    useEffect(() => {
        // dispatch(addToMapLoadingList('Asbestos'))
        fetchData((data, isError) => {
            if (!isError) {
                setData(data)
            } 
            // dispatch(removeFromMapLoadingList('Asbestos'))
        })
    }, []) // Add searchDates to filter on search

    const sourceConfig = {
        id: ANGELES_LINK_NO_GO_SOURCE,
        type: "geojson",
        data: data
    }

    const circleConfig = {
        id: ANGELES_LINK_NO_GO_LAYER,
        type: "fill",
        source: ANGELES_LINK_NO_GO_SOURCE,
        layout: {
            'visibility': showAngelesLinkNoGo ? 'visible' : 'none'
        },
        paint: {
            'fill-color': AngelesLinkNoGoLayerMetadata.color,
            'fill-opacity': 0.7
        },
        beforeId: beforeIDs.fill
    }

    return (
        <Source {...sourceConfig}>
            <Layer {...circleConfig} />
        </Source>
    )
}