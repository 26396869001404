import { useSelector } from "react-redux"
import { TIME_FRAME_COMPONENT } from "redux/actionTypes";
import { selectSearchDates } from "redux/searchItems/selectors"
import { selectSelectedKey } from "redux/menu/selectors";
import strings from "utils/strings"
import moment from "moment";

const formatToHumanReadableDate = (date) => moment(date).format("MMM D, YYYY")

const TimeFrameFilterStatus = () => {
    const selectedKey = useSelector(selectSelectedKey);
    const { start, end } = useSelector(selectSearchDates);
    let status = strings.menu.timeFrame.defaultValue;

    if(selectedKey === TIME_FRAME_COMPONENT) {
        status = strings.menu.timeFrame.selecting;
    } else if (start && end) {
        status = strings.formatString(strings.menu.timeFrame.value, { start: formatToHumanReadableDate(start), end: formatToHumanReadableDate(end) });
    } else {
        status = strings.menu.timeFrame.defaultValue;
    }

    return status;
}

export default TimeFrameFilterStatus;