import React, { useCallback, useEffect, useState } from "react"
import styled from "styled-components"

import { HeaderContent } from "shared/components/content";
import { DelimiterLine } from "shared/components/delimiter";
import { CheckboxFilter, StyledCheckbox } from "shared/components/checkbox";
import { FilterActionButtons } from 'shared/components/buttons';
import { compareArrays, findInArray, uniqueArray, removeItemsFromArray } from 'shared/utils/compareArrays';
import { FilterWrapper } from 'shared/components/filterWrapper';

import strings from "shared/utils/strings"

const CheckboxWrapper = styled.div`
    display: flex;
    max-height: ${({ env }) => env === "sdge" ? 'calc(100% - 120px)' : 'calc(100% - 70px)'};
    min-height: 140px;
    overflow: hidden;
`

const Wrapper = styled.div`
    margin: 1rem 0 1rem 1.5rem;

    label {
        justify-content: start;
    }
`

const handleTractsArray = (array) => {
    let newArray = []

    array?.map((x) => {
        return (newArray = [...newArray, ...x.tract])
    })

    return newArray
}

const CensusTractFilter = ({
    searchCommunitiesState,
    dacCommunities,
    selectDacTracts,
    sdgeRegions = [],
    selectSdgeTracts,
    selectedMapTracts,
    communitiesOptions,
    activeEnv,
    closeTabs = () => { },
    onSearchCensusTractsSelected = () => { }, // setSearchCensusTracts
    onMapSelectedCommunitiesSelected = () => { }, // SetMapSelectedCommunities
    onDistrictComponentSelected = () => { }, // setDistrictComponent
    onResetSearchCommunities = () => { }, // resetSearchCommunities
    onSearchCommunitiesSelected = () => { }, // setSearchCommunities
    onMapCensusTractsArraySelected = () => { }, // setMapCensusTractsArray
    onMenuDacsTractsSelected = () => { }, // setMenuDacsTracts
    onMenuSDGETractsSelected = () => { }, // setMenuSdgeTracts
    onClose = () => { }, // resetMenuSelectedKey
}) => {
    const [communitiesNumber, setCommunitiesNumber] = useState(null)
    const [searchCommunity, setSearchCommunity] = useState(
        searchCommunitiesState
    )
    const [dacTracts, setDacTracts] = useState(selectDacTracts)
    const [sdgeTracts, setSdgeTracts] = useState(selectSdgeTracts)
    const [allCommunity, setAllCommunity] = useState(null)
    const [confirmDisabled, setConfirmDisabled] = useState(true)
    const [selectedCommunities, setSelectedCommunities] = useState(
        searchCommunitiesState
    )

    const [checkedTracts, setCheckedTracts] = useState(searchCommunitiesState)
    const [checkedCommunities, setCheckedCommunities] = useState(searchCommunitiesState)

    useEffect(() => {
        if (!searchCommunitiesState) {
            setCommunitiesNumber(null)
            setSearchCommunity([])
            setSelectedCommunities([])
            setCheckedTracts(null)
            setCheckedCommunities([])
        }
    }, [searchCommunitiesState, setSearchCommunity])

    useEffect(() => {
        setCommunitiesNumber(selectedMapTracts?.length)
    }, [selectedMapTracts, setCommunitiesNumber])

    const handleConfirm = useCallback(() => {
        closeTabs();
        onSearchCommunitiesSelected(checkedCommunities?.map(x => x.label) || null);
        onSearchCensusTractsSelected(checkedCommunities);
        onMenuDacsTractsSelected(dacTracts);
        onMenuSDGETractsSelected(sdgeTracts);
        onClose();
        onDistrictComponentSelected(allCommunity);

        onMapCensusTractsArraySelected(checkedCommunities)
    }, [selectedMapTracts, allCommunity, selectedCommunities, checkedCommunities])

    const handleClearSelection = useCallback(() => {
        setSearchCommunity(null)
        setCheckedTracts(null)
        
        onMapCensusTractsArraySelected(null);
        setSelectedCommunities(null)

        onDistrictComponentSelected(false);
        onMapSelectedCommunitiesSelected(null);
        onMapCensusTractsArraySelected(null);
        onMenuDacsTractsSelected(false);
        onMenuSDGETractsSelected(false);
        onResetSearchCommunities();
    }, [setSearchCommunity, setDacTracts, setSdgeTracts, setSelectedCommunities])

    const handleAllDistricts = useCallback(state => {
        setSelectedCommunities(state ? communitiesOptions : null);
        setCheckedTracts(state ? communitiesOptions : null)

    }, [communitiesOptions, setDacTracts, setSdgeTracts, setSelectedCommunities, checkedTracts])

    const handleDacDistricts = useCallback(state => {
        const dacCommunitiesArray = []
        dacCommunities?.map(x => { if (x.label !== '' && x.label !== undefined && x.label !== null) dacCommunitiesArray.push(x.label)})
        setConfirmDisabled(state.target.checked === dacTracts);

        let selectedTracts = null
        if (state.target.checked) {
            if(checkedTracts) {
                selectedTracts = [...checkedTracts, ...dacCommunitiesArray]
            } else {
                selectedTracts = [...dacCommunitiesArray]
            }
        } else {
            if (checkedTracts) {
                selectedTracts = removeItemsFromArray(checkedTracts, dacCommunitiesArray)

                if (sdgeTracts) {
                    selectedTracts = [...selectedTracts, ...sdgeRegions?.map(x => x.label)]
                }
            }
        }

        if (!selectedTracts || selectedTracts == [] || selectedTracts.length < 1) {
            setCheckedTracts(null)
        } else {
            setCheckedTracts(uniqueArray(selectedTracts))
        }

        setDacTracts(state.target.checked)
    }, [dacCommunities, setDacTracts, checkedTracts])

    const handleSdgeRegions = useCallback(state => {
        const sdgeRegionsArray = []
        sdgeRegions?.map(x => { if (x.label !== '' && x.label !== undefined && x.label !== null) sdgeRegionsArray.push(x.label)});
        setConfirmDisabled(state.target.checked === sdgeTracts);

        let selectedTracts = null
        if (state.target.checked) {
            if(checkedTracts) {
                selectedTracts = [...checkedTracts, ...sdgeRegionsArray]
            } else {
                selectedTracts = [...sdgeRegionsArray]
            }
        } else {
            if (checkedTracts) {
                selectedTracts = removeItemsFromArray(checkedTracts, sdgeRegionsArray)

                if (dacTracts) {
                    selectedTracts = [...selectedTracts, ...dacCommunities?.map(x => x.label)]
                }
            }
        }

        if (!selectedTracts || selectedTracts == [] || selectedTracts.length < 1) {
            setCheckedTracts(null)
        } else {
            setCheckedTracts(uniqueArray(selectedTracts))
        }

        setSdgeTracts(state.target.checked);
    }, [sdgeRegions, setSdgeTracts, checkedTracts])

    const handleItemCheckbox = useCallback((select) => {

        let selectedTracts = null
        if (select) {
            selectedTracts = [...select]
        }
        selectedTracts ? setCheckedTracts(uniqueArray(selectedTracts)) : setCheckedTracts(null)
        
    }, [ checkedTracts ]) 

    const handleCheckAll = useCallback((value) => setAllCommunity(value), [setAllCommunity]);

    useEffect(() => {
        let isDACSelected = dacTracts
        let isSDGESelected = sdgeTracts
        let checkedWithoutDAC = []//checkedTracts
        let communities = []
        setCheckedCommunities(null)

        checkedTracts ? isDACSelected = findInArray(dacCommunities?.map(x => x.label), checkedTracts) : isDACSelected = false
        checkedTracts ? isSDGESelected = findInArray(sdgeRegions?.map(x => x.label), checkedTracts) : isSDGESelected = false

        isDACSelected ? setDacTracts(true) : setDacTracts(false)
        isSDGESelected ? setSdgeTracts(true) : setSdgeTracts(false)

        // When the Select All DAC is checked
        if (isDACSelected) {
            setCheckedCommunities(null)
            const dacCommunitiesArray = []
            dacCommunities?.map(x => { if (x.label !== '' && x.label !== undefined && x.label !== null) dacCommunitiesArray.push(x.label)})

            checkedWithoutDAC = removeItemsFromArray(checkedTracts, dacCommunitiesArray)
            const checkedTractsWithoutDAC = checkedWithoutDAC ? checkedWithoutDAC.map(item => communitiesOptions.filter(option => option.label === item)[0]) : null
            if(isSDGESelected) {
                const sdgeRegionsArray = []
                sdgeRegions?.map(x => { if (x.label !== '' && x.label !== undefined && x.label !== null) sdgeRegionsArray.push(x.label)});

                const DACWithoutSDGE = removeItemsFromArray(dacCommunitiesArray, sdgeRegionsArray)
                const checkedWithoutSDGE = removeItemsFromArray(checkedWithoutDAC, sdgeRegionsArray)

                const checkedTractsWithoutSDGE = checkedWithoutSDGE ? checkedWithoutSDGE.map(item => communitiesOptions.filter(option => option.label === item)[0]) : null
                const dac = DACWithoutSDGE.map(item => dacCommunities.filter(option => option.label === item)[0])

                checkedTractsWithoutSDGE ? communities = [...checkedTractsWithoutSDGE, ...dac, ...sdgeRegions ] : communities = [...dac, ...sdgeRegions]
            } else {
                checkedTractsWithoutDAC ? communities = [...checkedTractsWithoutDAC, ...dacCommunities] : communities = [...dacCommunities]
            }
        } else {
            const checked = checkedTracts ? checkedTracts.map(item => communitiesOptions.filter(option => option.label === item)[0]) : null
            checked ? communities = [...checked] : communities = []
        }
        
        communities.length > 0 ? setCheckedCommunities(communities.filter(item => item !== undefined && item !== null)) : setCheckedCommunities(null)
        const updatedTractLabels = !!checkedTracts ? checkedTracts : [];
        const prevSelectedTractLabels = !!searchCommunity ? searchCommunity : [];
        setConfirmDisabled(compareArrays(updatedTractLabels, prevSelectedTractLabels));
        setCommunitiesNumber(checkedTracts ? checkedTracts.length : 0)

    }, [checkedTracts])

    return <HeaderContent title={strings.censusTracts.header} closeTabs={closeTabs}>
        <FilterWrapper>
        <Wrapper>
            <StyledCheckbox
                // indeterminate={indeterminate}
                onChange={handleDacDistricts}
                checked={dacTracts}
                disabled={false}
            >
                <div class='body-primary-text' style={{color: 'white'}}>{strings.censusTracts.dacSwitch}</div>
            </StyledCheckbox>
        </Wrapper>
        {/*<SwitchItem onChange={handleDacDistricts} title={strings.censusTracts.dacSwitch} checkedValue={dacTracts} />*/}
        <DelimiterLine right={16} top={8} />
        { activeEnv === 'sdge' &&
            <>
                <Wrapper>
                    <StyledCheckbox
                        onChange={handleSdgeRegions}
                        checked={sdgeTracts}
                        disabled={false}
                        >
                        <div class='body-primary-text'>{strings.censusTracts.sdgeSwitch}</div>
                    </StyledCheckbox>
                </Wrapper>
                <DelimiterLine right={16} top={8} />
            </>
        }
        <CheckboxWrapper>
            <CheckboxFilter
                onChange={handleAllDistricts}
                options={communitiesOptions} // originally communitiesOptions
                handleItem={(select) => handleItemCheckbox(select)}
                defaultValue={searchCommunity}
                checked={checkedTracts}
                handleCheckAll={handleCheckAll}
                selectAllLabel="Select All"
                disableSelectAll={activeEnv === 'sdge' ? true : false}
            />
        </CheckboxWrapper>
        </FilterWrapper>

            <FilterActionButtons
                onConfirm={handleConfirm}
                isConfirmDisabled={confirmDisabled}
                onClear={handleClearSelection}
                isClearDisabled={!communitiesNumber}
            />
        </HeaderContent>
}

export default CensusTractFilter
