import { CLEAR_AFN_STATE, SET_AFN_SOURCE, SET_SHOW_AFN } from "./actionTypes"

export const initialState = {
    source: null,
    showAFN: false
}

const AFN = (state = initialState, {type, payload}) => {
    switch (type) {
        case CLEAR_AFN_STATE:
            return { ...initialState }
        case SET_AFN_SOURCE:
            return { ...state, source: payload }
        case SET_SHOW_AFN:
            return { ...state, showAFN: payload }
        default:
            return state
    }
}

export default AFN;