import { createSelector } from "reselect";

const selectStreetImprovementsState = state => state.StreetImprovements;

export const selectWorkTable = createSelector(
    selectStreetImprovementsState,
    state => state.workTable
)

export const selectOverlapMandIIds = createSelector(
    selectStreetImprovementsState,
    state => state.overlapIds.mandi
)

export const selectOverlapCPDIds = createSelector(
    selectStreetImprovementsState,
    state => state.overlapIds.cpd
)

export const selectValueMetricsTable = createSelector(
    selectStreetImprovementsState,
    state => state.valueMetrics
)

export const selectCPDOptions = createSelector(
    selectStreetImprovementsState,
    state => state.workFilter.cpdOptions
)

export const selectMAndIOptions = createSelector(
    selectStreetImprovementsState,
    state => state.workFilter.mAndIOptions
)

export const selectFilteringLayer = createSelector(
    selectStreetImprovementsState,
    state => state.workFilter.layerSelected
)

export const selectIsMandIDisabled = createSelector(
    selectStreetImprovementsState,
    state => state.workFilter.layerSelected === 'CPD'
)

export const selectIsCPDDisabled = createSelector(
    selectStreetImprovementsState,
    state => state.workFilter.layerSelected === 'MI'
)

export const selectCityWorkData = createSelector(
    selectStreetImprovementsState,
    state => state.cityWorkData
)

export const selectDrawnGeometries = createSelector(
    selectStreetImprovementsState,
    state => state.drawnGeometries
)

export const selectRowUpdateInfo = createSelector(
    selectStreetImprovementsState,
    state => state.rowUpdateInfo
)

export const selectShowOverlapTable = createSelector(
    selectStreetImprovementsState,
    state => state.showOverlapTable
)

export const selectProjectTypes = createSelector(
    selectStreetImprovementsState,
    state => state.projectTypes
)

export const selectShowMoratoriumMapData = createSelector(
    selectStreetImprovementsState,
    state => state.showMoratoriumMapData
)

export const selectSearchPlannerID = createSelector(
    selectStreetImprovementsState,
    state => state.workFilter.plannerID
)

export const selectSearchNotificationNo = createSelector(
    selectStreetImprovementsState,
    state => state.workFilter.notificationNumber
)

export const selectSearchOrderNo = createSelector(
    selectStreetImprovementsState,
    state => state.workFilter.orderNumber
)

export const selectIsEditingRows = createSelector(
    selectStreetImprovementsState,
    state => state.isEditingRows
)

export const selectHighlightedMap = createSelector(
    selectStreetImprovementsState,
    state => state.highlightedMap
)

export const selectMandiCheckboxState = createSelector(
    selectStreetImprovementsState,
    state => state.mandiCheckboxStates
)

export const selectOpenOrderIdsToFilter = createSelector(
    selectStreetImprovementsState,
    state => state.openOrderIdsToFilter
)

export const selectWorkTypes = createSelector(
    selectStreetImprovementsState,
    (select) => select.workTypes
)