import ChevronRight from "../../assets/icons/chevronRight.svg?react";
import Community from "../../assets/icons/community.svg?react";
import Calendar from "../../assets/icons/calendar.svg?react";
import Vehicle from "../../assets/icons/vehicle.svg?react";

import { useDispatch, useSelector } from 'react-redux';
import { METHANE_COMPONENT, CENSUS_TRACTS_COMPONENT, WORK_TYPE_COMPONENT, LEAK_STATUS_COMPONENT, LEAK_CODE_COMPONENT } from "redux/actionTypes";
import { resetSearchCommunities, resetSearchDate, setSearchCensusTracts, setSearchCommunities, setSearchEndDate, setSearchStartDate } from "redux/searchItems/actions";
import { setDistrictComponent, SetMapCensusTractsArray, SetMapSelectedCommunities } from "redux/mapStateInternal/action";
import { resetMenuSelectedKey, setMenuDacsTracts } from "redux/menu/actions";
import { selectSearchCommunities, selectSearchDates } from "redux/searchItems/selectors";
import { selectDacCensusTracts, selectGroupOptionsCensusTracts } from "redux/mapbox/selectors";
import { selectMenuDacTracts } from "redux/menu/selectors";
import { selectMapCensusTracts } from "redux/mapStateInternal/selectors";
import { selectActiveEnv } from "shared/redux/apps";
// import { ProjectStatusFilter } from '../apps/StreetImprovement/filters/ProjectStatusFilter'

// import ProjectStatusFilterStatus from './tabs/ProjectStatusFilterStatus';
import TimeFrameFilterStatus from "./tabs/TimeFrameFilterStatus";

import moment from "moment";
// import { CityFilter } from "component/apps/StreetImprovement/filters/CityFilter";
// import CityFilterStatus from "./tabs/CityFilterStatus";
import { WorkTypeFilter } from "component/apps/StreetImprovement/filters/workTypeFilter";
import WorkTypeFilterStatus from "component/sidebar/tabs/filterStatus/WorkTypeFilterStatus";
import CensusTractFilterStatus from "./tabs/filterStatus/CensusTractFilterStatus";
import { LeakStatusFilter, LeakStatusFilterStatus } from "component/apps/AbatementOptimization/filters/LeakStatusFilter";
import { LeakCodeFilter, LeakCodeFilterStatus } from "component/apps/AbatementOptimization/filters/LeakCodeFilter";
import { LeakStatusFilterIcon } from "assets/icons/LeakStatusFilterIcon";
import { LeakCodeFilterIcon } from "assets/icons/LeakCodeFilterIcon";
import { CensusTractFilter, TimeFrameFilter } from "@innovation-toolkit/ui";
import { FolderSingleIcon } from "../../../../shared/style/iconography/folder-single-icon";
import FleetContent from "./tabs/content/fleetContent";
import FleetVehiclesFilterStatus from "./tabs/filterStatus/FleetVehiclesFilterStatus";
import { FleetVehicleFilter } from "./tabs/content/FleetVehicleFilter";

const datePills = ["3M", "6M", "12M", "18M", "24M", "30M"]

const customYears = ["2019", "2020", "2021", "2022", "2023", "2025", "2025", "2026", "2027", "2028", "2029", "2030"]

export const SCGTimeFrameFilter = ({ closeTabs, datePills, datePillDirection = "backward", footNote, customYears, selectedYear }) => {
    const dispatch = useDispatch();
    const searchDates = useSelector(selectSearchDates);

    return (
        <TimeFrameFilter
            defaultStartDate={searchDates.start}
            defaultEndDate={searchDates.end}
            defaultMonth={moment()}
            closeTabs={() => { dispatch(closeTabs) }}
            datePillOptions={datePills}
            datePillDirection={datePillDirection}
            selectionDirection='future'
            footNote={footNote}
            customYears={customYears}
            // selectedYear={selectedYear}
            onStartDateSelected={(date) => { dispatch(setSearchStartDate(date)) }}
            onEndDateSelected={(date) => { dispatch(setSearchEndDate(date)) }}
            onReset={() => { dispatch(resetSearchDate()) }}
            onClose={() => { dispatch(resetMenuSelectedKey()) }}
        />)
}

const MethaneCensusTractFilter = ({ closeTabs }) => {
    const dispatch = useDispatch();
    const searchCommunitiesState = useSelector(selectSearchCommunities);
    const dacCommunities = useSelector(selectDacCensusTracts);
    const selectDacTracts = useSelector(selectMenuDacTracts);
    const selectedMapTracts = useSelector(selectMapCensusTracts);
    const communitiesOptions = useSelector(selectGroupOptionsCensusTracts);
    const activeEnv = useSelector(selectActiveEnv);

    return (
        <CensusTractFilter
            searchCommunitiesState={searchCommunitiesState}
            dacCommunities={dacCommunities}
            selectDacTracts={selectDacTracts}
            selectedMapTracts={selectedMapTracts}
            communitiesOptions={communitiesOptions}
            activeEnv={activeEnv}
            closeTabs={() => { dispatch(closeTabs) }}
            onSearchCensusTractsSelected={(value) => dispatch(setSearchCensusTracts(value))}
            onMapSelectedCommunitiesSelected={(value) => dispatch(SetMapSelectedCommunities(value))}
            onDistrictComponentSelected={(value) => dispatch(setDistrictComponent(value))}
            onResetSearchCommunities={(value) => dispatch(resetSearchCommunities(value))}
            onSearchCommunitiesSelected={(value) => dispatch(setSearchCommunities(value))}
            onMapCensusTractsArraySelected={(value) => dispatch(SetMapCensusTractsArray(value))}
            onMenuDacsTractsSelected={(value) => dispatch(setMenuDacsTracts(value))}
            onClose={() => dispatch(resetMenuSelectedKey)}
        />)
}

// comment for now since tract filter is not yet a requirement
// const SharedCensusTractFilter = ({ closeTabs }) => {
//     const dispatch = useDispatch();
//     const searchCommunitiesState = useSelector(selectSearchCommunities);
//     const dacCommunities = useSelector(selectDacCensusTracts);
//     const selectDacTracts = useSelector(selectMenuDacTracts);
//     // const sdgeCommunities = useSelector(selectSdgeCensusTracts)
//     // const selectSdgeTracts = useSelector(selectMenuSdgeTracts);
//     const selectedMapTracts = useSelector(selectMapCensusTracts);
//     const communitiesOptions = useSelector(selectGroupOptionsCensusTracts);
//     const activeEnv = useSelector(selectActiveEnv);

//     return (
//         <CensusTractFilter
//             searchCommunitiesState={searchCommunitiesState}
//             dacCommunities={dacCommunities}
//             selectDacTracts={selectDacTracts}
//             // sdgeRegions={sdgeCommunities}
//             // selectSdgeTracts={selectSdgeTracts}
//             selectedMapTracts={selectedMapTracts}
//             communitiesOptions={communitiesOptions}
//             activeEnv={activeEnv}
//             closeTabs={() => { dispatch(closeTabs) }}
//             onSearchCensusTractsSelected={(value) => dispatch(setSearchCensusTracts(value))}
//             onMapSelectedCommunitiesSelected={(value) => dispatch(SetMapSelectedCommunities(value))}
//             onDistrictComponentSelected={(value) => dispatch(setDistrictComponent(value))}
//             onResetSearchCommunities={(value) => dispatch(resetSearchCommunities(value))}
//             onSearchCommunitiesSelected={(value) => dispatch(setSearchCommunities(value))}
//             onMapCensusTractsArraySelected={(value) => dispatch(SetMapCensusTractsArray(value))}
//             onMenuDacsTractsSelected={(value) => dispatch(setMenuDacsTracts(value))}
//             // onMenuSDGETractsSelected={(value) => dispatch(setMenuSdgeTracts(value))}
//             onClose={() => dispatch(resetMenuSelectedKey)}
//         />)
// }


export const scgActions = [
    {
        componentIcon: Calendar,
        actionIcon: ChevronRight,
        content: 'Time Frame',
        action: METHANE_COMPONENT,
        component: (closeTabs) => <SCGTimeFrameFilter 
            closeTabs={closeTabs} 
            datePills={datePills} 
            selectedYear={String(new Date().getFullYear())}
            />,
        filterStatus: () => <TimeFrameFilterStatus/>
    },
    {
        componentIcon: Community,
        actionIcon: ChevronRight,
        content: 'Census Tracts',
        action: CENSUS_TRACTS_COMPONENT,
        component: (closeTabs) => <MethaneCensusTractFilter closeTabs={closeTabs} />,
        filterStatus: () => <CensusTractFilterStatus />
    },
]

export const streetImprovementActions = [ 
    // {
    //     title: 'All Data',
    //     tooltipText: 'Source: CPD, M&I, City Projects, Moratoriums'
    // },
    // {
    //     title: 'SoCalGas Work',
    //     tooltipText: 'Source: CPD, M&I'
    // },
    {
        componentIcon: Calendar,
        actionIcon: ChevronRight,
        content: 'Time Frame',
        action: METHANE_COMPONENT,
        component: (closeTabs) => <SCGTimeFrameFilter 
            closeTabs={closeTabs} 
            datePills={datePills} 
            datePillDirection="forward"
            footNote='Note: This filter applies to CPD, M&I, WIP Clouds, city projects, and moratoriums on the map and in the City Work and Planned Excavation tables.'
            customYears={customYears}
            selectedYear={String(new Date().getFullYear())}
            />,
        filterStatus: () => <TimeFrameFilterStatus/>
    },

    // {
    //     componentIcon: BuildingWithHouseIcon,
    //     actionIcon: ChevronRight,
    //     content: 'Districts',
    //     action: DISTRICT_FILTER,
    //     component: (closeTabs) => <DistrictFilter closeTabs={closeTabs}  footNote='Note: This filter applies to CPD Projects and M&I Work.' />,
    //     filterStatus: () => <DistrictFilterStatus />,
    // },
    {
        componentIcon: FolderSingleIcon,
        actionIcon: ChevronRight,
        content: 'Work Type',
        action: WORK_TYPE_COMPONENT,
        component: (closeTabs) => <WorkTypeFilter closeTabs={closeTabs}  footNote='Note: This filter applies to CPD projects and M&I work on the map and in the Planned Excavation table.' />,
        filterStatus: () => <WorkTypeFilterStatus />,
    },
    // {
    //     componentIcon: FolderIcon,
    //     actionIcon: ChevronRight,
    //     content: 'Project Status',
    //     action: PROJECT_STATUS_FILTER,
    //     component: (closeTabs) => <ProjectStatusFilter closeTabs={closeTabs} footNote='Note: This filter only applies to CPD Projects.'/>,
    //     filterStatus: () => <ProjectStatusFilterStatus/>
    // },
    // {
    //     title: 'City Work',
    //     tooltipText: 'Source: City Projects, Moratoriums',
    // },
    // {
    //     componentIcon: BuildingIcon,
    //     actionIcon: ChevronRight,
    //     content: 'Cities',
    //     action: CITY_FILTER_STATUS,
    //     component: (closeTabs) => <CityFilter closeTabs={closeTabs} />,
    //     filterStatus: () => <CityFilterStatus/>
    // },


]

export const abatementOptimizationActions = [
    {
        componentIcon: Calendar,
        actionIcon: ChevronRight,
        content: 'Time Frame',
        action: METHANE_COMPONENT,
        component: (closeTabs) => <SCGTimeFrameFilter 
            closeTabs={closeTabs} 
            datePills={datePills} 
            datePillDirection="forward"
            footNote='Note: This filter displays leaks in the selected Compliance Due Date (Required Permanent Repair Date) range.'
            customYears={customYears}
            selectedYear={String(new Date().getFullYear())}
            />,
        filterStatus: () => <TimeFrameFilterStatus/>
    },
    // {
    //     componentIcon: MunicipalityFilterIcon,
    //     actionIcon: ChevronRight,
    //     content: 'Municipalities',
    //     action: 'Hello',
    //     component: (closeTabs) => <MunicipalitiesFilter closeTabs={closeTabs}/>,
    //     filterStatus: () => <MunicipalitiesFilterStatus/>
    // },
    {
        componentIcon: LeakStatusFilterIcon,
        actionIcon: ChevronRight,
        content: 'Leak Status',
        action: LEAK_STATUS_COMPONENT,
        component: (closeTabs) => <LeakStatusFilter closeTabs={closeTabs}/>,
        filterStatus: () => <LeakStatusFilterStatus/>
    }
    ,
    {
        componentIcon: LeakCodeFilterIcon,
        actionIcon: ChevronRight,
        content: 'Leak Code',
        action: LEAK_CODE_COMPONENT,
        component: (closeTabs) => <LeakCodeFilter closeTabs={closeTabs}/>,
        filterStatus: () => <LeakCodeFilterStatus/>
    }
]

export const fleetDecarbonizationActions = [
    {
        componentIcon: Calendar,
        actionIcon: ChevronRight,
        content: 'Time Frame',
        action: METHANE_COMPONENT,
        component: (closeTabs) => <SCGTimeFrameFilter 
            closeTabs={closeTabs} 
            datePills={["1D", "1W", "1M", "3M", "6M", "1Y"]}
            customYears={customYears}
            selectedYear={String(new Date().getFullYear())}
            />,
        filterStatus: () => <TimeFrameFilterStatus/>
    },
    {
        componentIcon: Vehicle,
        actionIcon: ChevronRight,
        content: "Fleet Vehicles",
        action: 'FLEET_COMPONENT',
        component: (closeTabs) => <FleetVehicleFilter closeTabs={closeTabs} />,
        filterStatus: () => <FleetVehiclesFilterStatus />,
      },
    //   {
    //     componentIcon: Community,
    //     actionIcon: ChevronRight,
    //     content: "Census Tracts",
    //     action: 'CENSUS_TRACTS_COMPONENT',
    //     component: (closeTabs) => <SharedCensusTractFilter closeTabs={closeTabs} />,
    //     filterStatus: () => <CensusTractFilterStatus />,
    //   },
]

