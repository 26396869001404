import React from 'react';
import { Button, Modal } from '@innovation-toolkit/ui';

const ConfirmModal = ({ isOpen, onCancel, onOk, modalBodyText, modalTitleText }) => {
  return (
    <Modal
      open={isOpen}
      title={modalTitleText}
      cancelText="Cancel"
      okText="Export CSV"
      onCancel={onCancel}
      bodyStyle={{ backgroundColor: '#0E263B' }}
      style={{backgroundColor: '#0E263B'}}
      centered={true}
      footer={[
        <Button key="submit" variant="primary" onClick={onOk} title='Yes, Proceed' style={{marginTop: '8px'}}/>,
        <Button key="back" onClick={onCancel} title='Cancel' variant='secondary' style={{marginTop: '8px'}}/>,
      ]}
    >
      <h3>{modalBodyText}</h3>
    </Modal>
  )
}
export default ConfirmModal;