import { Layer, Source } from "react-map-gl"
import { useState } from "react";
import { isActiveLayer, useActivateMapLayer, useLoadMapImage, selectBeforeIDs, useMapEvent } from "@innovation-toolkit/mapbox";
import caution_yellow from 'assets/pattern/caution_yellow.png';
import caution_red from 'assets/pattern/caution_red.png'
import { useSelector } from "react-redux";
import { selectCityWorkAggregationMapData } from "redux/cityWorkAggregation/slice";
import { UploadedDataPopup } from "./UploadedDataPopup";

export const UPLOADED_DATA_LAYER = 'UPLOADED_DATA_LAYER';
export const UPLOADED_DATA_SOURCE = 'UPLOADED_DATA_SOURCE';

export const UploadedDataLayerMetadata = {
    layerId: UPLOADED_DATA_LAYER,
    name: 'Clean Transportation Project',
    color: '#C79166',
    layerSymbols: [
        { symbol: caution_yellow, symbolName: 'caution-tape' },
        { symbol: caution_red, symbolName: 'caution-tape-red' },
    ]
}

const convertPixelsToFeet = (distanceProperty, latProperty) => {

    return [
        'interpolate',
        ['exponential', 2],
        ['zoom'],
        0, 0,
        20, [
          '/',
          ['/', 
            ['/', ['get', distanceProperty], 3.281], 
            0.075
          ],
          ['cos', ['*', ['get', latProperty], ['/', Math.PI, 180]]],
        ],
      ]
}

export const UploadedDataLayer = ({showLayer=false}) => {

    const beforeIDs = useSelector(selectBeforeIDs)
    const patterns = { red: 'caution-tape-red', yellow: 'caution-tape'}
    
    const [showPopup, setShowPopup] = useState(false)
    const [popupFeature, setPopupFeature] = useState(null);
    const [lngLat, setLatLng] = useState(null);
    const [target, setTarget] = useState(null);
    const cityWorkAggregationMapData = useSelector(selectCityWorkAggregationMapData);

    useLoadMapImage(patterns.yellow, caution_yellow)
    useLoadMapImage(patterns.red, caution_red)

    const handleClick = (e) => {
        if (!isActiveLayer(e, UPLOADED_DATA_LAYER)) return;

        setShowPopup(true);
        setPopupFeature(e.features[0]);
        setLatLng(e.lngLat);
        setTarget(e.target);
    }

    useMapEvent('click', UPLOADED_DATA_LAYER, handleClick)
    useMapEvent('click', UPLOADED_DATA_LAYER + "_symbol", handleClick)

    const sourceConfig = {
        id: UPLOADED_DATA_SOURCE,
        type: "geojson",
        data: cityWorkAggregationMapData
    }

    const lineConfig = {
        id: UPLOADED_DATA_LAYER,
        type: "line",
        source: UPLOADED_DATA_SOURCE,
        paint: {
            'line-width': 4,
            'line-pattern': [
                'case',
                ['==', ['get', 'Work_Type'], 'Moratorium'], patterns.red,
                patterns.yellow
            ]
        },
        // beforeId: beforeIDs.line
    }

    const lineOutlineConfig = {
        id: UPLOADED_DATA_LAYER + "_line_outline",
        type: "line",
        source: UPLOADED_DATA_SOURCE,
        paint: {
            'line-width': 2,
            // 'line-pattern': patterns.yellow
            'line-color': "#bababa",
            'line-offset': convertPixelsToFeet('Project Radius (Feet)', 'lat_physical_address_0')
        },
        // beforeId: 'road-label-simple'
    }

    const negativeLineOutlineConfig = {
        id: UPLOADED_DATA_LAYER + "_negative_line_outline",
        type: "line",
        source: UPLOADED_DATA_SOURCE,
        paint: {
            'line-width': 2,
            // 'line-pattern': patterns.yellow
            'line-color': "#bababa",
            'line-offset': [
        'interpolate',
        ['exponential', 2],
        ['zoom'],
        0, 0,
        20, ["*", -1, [
          '/',
          ['/', 
            ['/', ['get', 'Project Radius (Feet)'], 3.281], 
            0.075
          ],
          ['cos', ['*', ['get', 'lat_physical_address_0'], ['/', Math.PI, 180]]],
        ]],
      ]
        },
        beforeId: beforeIDs.line
    }

    const symbolConfig = {
        id: UPLOADED_DATA_LAYER + "_symbol",
        type: "symbol",
        filter: ["==", ["geometry-type"], "Point"],
        source: UPLOADED_DATA_SOURCE,
        layout: {
            'icon-image': patterns.yellow,
            'icon-size': 0.02
        }
    }   

    const circleRadiusConfig = {
        id: UPLOADED_DATA_LAYER + "_circle_radius",
        type: "circle",
        filter: ["==", ["geometry-type"], "Point"],
        source: UPLOADED_DATA_SOURCE,
        paint: {
            'circle-color': '#333333',
            'circle-stroke-color': '#bababa',
            'circle-stroke-width': 2,
            'circle-opacity': 0.5,
            'circle-radius': convertPixelsToFeet('Project Radius (Feet)', 'lat_physical_address_0'),
        }
    }

    const fillConfig = {
        id: UPLOADED_DATA_LAYER + "_fill",
        type: "fill",
        source: UPLOADED_DATA_SOURCE,
        paint: {
            'fill-outline-color': 'black'
        }
    }

    return (
        <Source {...sourceConfig}>
            {/* <Layer {...lineOutlineConfig} /> */}
            {/* <Layer {...negativeLineOutlineConfig}/> */}
            <Layer {...lineConfig} />

            {/* <Layer {...fillConfig}/> */}

            <Layer {...symbolConfig}/>
            {/* <Layer {...circleRadiusConfig}/> */}
            <UploadedDataPopup 
                isOpen={showPopup}
                onClose={() => setShowPopup(false)}
                lngLat={lngLat}
                feature={popupFeature}
                target={target}
            />
        </Source>
    )
}