import { setMapPosition } from '@innovation-toolkit/mapbox'
import { DataTable, LargeTable } from '@innovation-toolkit/ui'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectMandILeakData, selectShowLeakTable, setShowLeakTable } from '@redux/abatementOptimization/slice'
import _ from 'lodash'
import moment from 'moment'
import { convertToJSONArray } from './layers/MandILeaks/popup'
import { selectMandiLeaksFilterMap } from 'redux/abatementOptimization/slice'

const columns = [
    {
        title: 'Priority',
        dataIndex: 'Ranking',
        key: 'Ranking',
        // sortable: true,
        mapLocationButton: true,
    },
    {
        title: 'Leak Object ID',
        dataIndex: 'LeakObjectID',
        key: 'leakObjectId',
    },
    {
        title: 'Leak Code',
        dataIndex: 'LeakCode',
        key: 'leakCode',
    },
    {
        title: 'Land',
        dataIndex: 'land',
        key: 'land'
    },
    {
        title: 'Permanent Required End Date',
        dataIndex: 'permanent_required_end_date',
        key: 'permanent_required_end_date',
    }
]

const xlsxDownloadColumns = [
    ...columns,
    {
        title: 'High Priority Factors',
        dataIndex: 'High Priority Factors',
        key: 'High Priority Factors',
    },
    {
        title: 'High-Medium Priority Factors',
        dataIndex: 'High Medium Priority Factors',
        key: 'High Medium Priority Factors',
    },
    {
        title: 'Medium Priority Factors',
        dataIndex: 'Medium Priority Factors',
        key: 'Medium Priority Factors',
    },
    {
        title: 'Low Priority Factors',
        dataIndex: 'Low Priority Factors',
        key: 'Low Priority Factors',
    }
]

const priorityNameMap = {
    "high": "High",
    "high_mid": "High-Medium",
    "mid": "Medium",
    "low": "Low",
    "no ranking factor": "Not Prioritized"
}

const priorityValueMap = {
    "High": 1,
    "High-Medium": 2,
    "Medium": 3,
    "Low": 4,
    "Not Prioritized": 5
}

const formatArray = (arr) => {
    if (!arr || arr.length === 0 || typeof arr === 'string') return ''
    return arr.reduce((prev = '', current) => {
        if (current) {
            return prev + ', ' + current

        } else {
            return prev
        }
    })
}

const formatData = (geojsonData) => {
    const formattedData = [];
    const features = _.cloneDeep(geojsonData.features)

    for (let i = 0; i < features.length; i++) {
        formattedData[i] = features[i].properties;
        formattedData[i].geometry = features[i].geometry;
        formattedData[i].key = i;

        formattedData[i].Ranking = priorityNameMap[formattedData[i].priority_ranking]

        formattedData[i].permanent_required_end_date = moment(formattedData[i].permanent_required_end_date).format("MM/DD/YYYY")
        
        formattedData[i].land = formattedData[i].private === "Yes" ? "Private" : "Public"
        formattedData[i]["High Priority Factors"] = formatArray(JSON.parse(convertToJSONArray(formattedData[i].high_priority_factors)))
        formattedData[i]["High Medium Priority Factors"] = formatArray(JSON.parse(convertToJSONArray(formattedData[i].high_mid_priority_factors)))
        formattedData[i]["Medium Priority Factors"] = formatArray(JSON.parse(convertToJSONArray(formattedData[i].mid_priority_factors)))
        formattedData[i]["Low Priority Factors"] = formatArray(JSON.parse(convertToJSONArray(formattedData[i].low_priority_factors)))
    }

    formattedData.sort((a, b) => {
        return priorityValueMap[a.Ranking] - priorityValueMap[b.Ranking];
    });

    return formattedData;
}

const LeakPrioritizationTable = () => {
    const dispatch = useDispatch()
    const isOpen = useSelector(selectShowLeakTable)

    const [data, setData] = useState(null);
    const mandiLeakData = useSelector(selectMandILeakData);
    const mandiLeaksFilterMap = useSelector(selectMandiLeaksFilterMap);

    useEffect(() => {
        if (!mandiLeakData) return;
        setData(formatData(mandiLeakData).filter((leak) => mandiLeaksFilterMap[leak.priority_ranking]));

    }, [mandiLeakData, mandiLeaksFilterMap]);


    const handleClose = () => {
        dispatch(setShowLeakTable(false))
    }

    const handleMapLocationButtonClick = (row) => {
        dispatch(setMapPosition({longitude: row.geometry.coordinates[0], latitude: row.geometry.coordinates[1], zoom: 16}))
    }

    const tabs = [
        {
            title: 'Leak Prioritization',
            contentSections: [
                {
                    content: <>
                        {data ? <div style={{paddingLeft: '5px', paddingRight: '3px', overflow: 'auto', marginRight: 'auto'}}><LargeTable columns={columns} xlsxDownloadColumns={xlsxDownloadColumns} data={data} onMapLocationButtonClick={handleMapLocationButtonClick}/></div>: <div>Loading</div>}
                    </>
                }
            ]
        }
    ]

    return (
        <DataTable handleClose={handleClose} open={isOpen} tabs={tabs} />
    )
}

export default LeakPrioritizationTable