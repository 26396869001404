import { Legend, RacialEquityLegendSection, IncomeLegendSection } from "@innovation-toolkit/mapbox"

export const PopulationLegendSection = () => {
    return (
        <Legend 
            width='280px' 
            maxheight={340} 
            header={{headerTitle: 'Map Legend', headerCaption: 'This map shows SoCalGas population'}} >
            <>
                <RacialEquityLegendSection />

                <h3>Median Income</h3>
                <div style={{paddingLeft: '16px'}}>
                    <IncomeLegendSection />
                </div>
            </>
        </Legend>
    )
}