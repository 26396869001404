import { createSelector } from "reselect";

const selectMethaneState = state => state.methane;


export const selectShowMethaneCircle = createSelector(
    selectMethaneState,
    state => state.showMethaneCircle
)

export const selectShowMethaneHeatap = createSelector(
    selectMethaneState,
    state => state.showMethaneHeatmap
)