import { SET_WORK_TYPES } from "redux/actionTypes"
import {
    SET_WORK_TABLE_TYPE,
    SET_OVERLAP_IDS,
    SET_VALUE_METRICS_TABLE_TYPE,
    SET_CPD_OPTIONS,
    SET_MANDI_OPTIONS,
    SET_SELECTED_FILTERING_LAYER,
    SET_CITY_WORK_DATA,
    SET_DRAWN_GEOMETRIES,
    SET_ROW_UPDATE_INFO,
    SET_SHOW_OVERLAP_TABLE,
    SET_PROJECT_TYPES,
    SET_SHOW_MORATORIUM_MAP_DATA,
    SET_SEARCH_PLANNER_ID,
    SET_SEARCH_NOTIFICATION_NO,
    SET_SEARCH_ORDER_NO,
    SET_IS_EDITING_ROWS,
    SET_HIGHLIGHT_MAP,
    SET_MANDI_CHECKBOX_STATES,
    SET_OPEN_ORDER_ID_TO_FILTER
} from "./actionTypes"

export const setWorkTable = (payload) => {
    return {
        type: SET_WORK_TABLE_TYPE,
        payload
    }
}

export const setOverlapIds = (payload) => {
    return {
        type: SET_OVERLAP_IDS, 
        payload
    }
}

export const setValueMetricsTable = (payload) => {
    return {
        type: SET_VALUE_METRICS_TABLE_TYPE,
        payload
    }
}

export const setCPDOptions = (payload) => {
    return {
        type: SET_CPD_OPTIONS,
        payload
    }
}

export const setMAndIOptions = (payload) => {
    return {
        type: SET_MANDI_OPTIONS, 
        payload
    }
}

export const setSelectedFilteringLayer = (payload) => {
    return {
        type: SET_SELECTED_FILTERING_LAYER,
        payload
    }
}

export const setCityWorkData = (payload) => {
    return {
        type: SET_CITY_WORK_DATA,
        payload
    }
}

export const setDrawnGeometries = (payload) => {
    return {
        type: SET_DRAWN_GEOMETRIES,
        payload
    }
}

export const setRowUpdateInfo = (payload) => {
    return {
        type: SET_ROW_UPDATE_INFO,
        payload
    }
}

export const setShowOverlapTable = (payload) => {
    return {
        type: SET_SHOW_OVERLAP_TABLE,
        payload
    }
}

export const setProjectTypes = (payload) => {
    return {
        type: SET_PROJECT_TYPES,
        payload
    }
}

export const setShowMoratoriumMapData = (payload) => {
    return {
        type: SET_SHOW_MORATORIUM_MAP_DATA,
        payload
    }
}

export const setSearchPlannerID = (payload) => {
    return {
        type: SET_SEARCH_PLANNER_ID,
        payload
    }
}

export const setSearchNotificationNo = (payload) => {
    return {
        type: SET_SEARCH_NOTIFICATION_NO,
        payload
    }
}

export const setSearchOrderNo = (payload) => {
    return {
        type: SET_SEARCH_ORDER_NO,
        payload
    }
}

export const setIsEditingRows = (payload) => {
    return {
        type: SET_IS_EDITING_ROWS,
        payload
    }
}

export const setHighlightedMap = (payload) => {
    return {
        type: SET_HIGHLIGHT_MAP,
        payload
    }
}

export const setMandiCheckboxStates = (payload) => {
    return {
        type: SET_MANDI_CHECKBOX_STATES,
        payload
    }
}

export const setOpenOrdersIdsToFilter = (payload) => {
    return {
        type: SET_OPEN_ORDER_ID_TO_FILTER,
        payload
    }
}

export const setWorkTypes = payload => {
    return {
        type: SET_WORK_TYPES,
        payload
    }
}