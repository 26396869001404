import {
  CheckboxTree,
  FilterActionButtons,
  HeaderContent,
} from "@innovation-toolkit/ui";

import { useState } from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { selectLeakCodes } from "redux/searchItems/selectors";
import { setLeakCodes, resetLeakCodes } from "redux/searchItems/actions";


// Will be updated once we have an endpoint from the DS team
const options = [
  {
    title: "BG - Non Hazardous",
    key: "BG Non Hazardous",
    children: [
      {
        title: "Code 2",
        key: "Code 2",
      },
      {
        title: "Code 3 - Steel",
        key: "Code 3 - Steel",
      },
      {
        title: "Code 3 - Plastic",
        key: "Code 3 - Plastic",
      },
    ],
  },
  {
    title: "BG - Hazardous",
    key: "BG Hazardous",
  },
  {
    title: "AG - Non Hazardous",
    key: "AG Non Hazardous",
  },
  {
    title: "AG - Hazardous",
    key: "AG Hazardous",
  },
];

export const LeakCodeFilter = ({ closeTabs, footNote }) => {
  const dispatch = useDispatch();

  const stateSelectedCodes = useSelector(selectLeakCodes);
  const [selectedCodes, setSelectedCodes] = useState(stateSelectedCodes);
  const [hasSelectionChanged, setHasSelectionChanged] = useState(false);

  useEffect(() => {
    // setSelectedCodes(["BG Non Hazardous", "Code 2", "Code 3 ST", "Code 3 PL"]);
  }, []);

  const handleTreeCheck = (checkedKeys) => {
    console.log(checkedKeys)
    setSelectedCodes(checkedKeys.filter((key) => key !== "BG Non Hazardous"));
    setHasSelectionChanged(true);
  };

  const handleConfirm = () => {
    dispatch(setLeakCodes(selectedCodes));
    closeTabs();
  };

  const handleClear = () => {
    dispatch(resetLeakCodes());
    setSelectedCodes([]);
    setHasSelectionChanged(false);
    closeTabs();
  };

  return (
    <HeaderContent title="Leak Code" closeTabs={closeTabs}>
      <CheckboxTree
        treeData={options}
        selectable={false}
        checkedKeys={selectedCodes}
        onCheck={handleTreeCheck}
      />
      <FilterActionButtons
        isConfirmDisabled={false}
        onConfirm={handleConfirm}
        footNote={footNote}
        isClearDisabled={selectedCodes.length === 0}
        onClear={handleClear}
      />
    </HeaderContent>
  );
};

export const LeakCodeFilterStatus = ({ allLeakCodesCount = 6 }) => {
  const selectedLeakCodes = useSelector(selectLeakCodes);
  const [hasSelection, setHasSelection] = useState(
    selectedLeakCodes.length > 0
  );

  useEffect(() => {
    setHasSelection(selectedLeakCodes.length > 0);
  }, [selectedLeakCodes]);

  if (!hasSelection) {
    return "Select Leak Code(s)";
  } else if (selectedLeakCodes.length === 1) {
    return selectedLeakCodes[0] + " Selected";
  } else if (selectedLeakCodes.length === allLeakCodesCount) {
    return "All Leak Codes Selected";
  } else {
    return selectedLeakCodes.length + " Leak Codes Selected";
  }
};
