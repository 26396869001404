import { useState } from 'react';
import { Layer, Source } from 'react-map-gl';
import _ from 'lodash';

// redux
import { useSelector } from 'react-redux';
import { selectSelfServiceMapSource, selectStyle } from '@redux/selfService/selector';
import SelfServicePointPopup from './pointPopup';
import { useMapEvent } from '@innovation-toolkit/mapbox';

export const SELF_SERVICE_POINT_LAYER = 'SELF_SERVICE_POINT_LAYER';
export const SELF_SERVICE_HEATMAP_LAYER = 'SELF_SERVICE_HEATMAP_LAYER';

const getDataStats = (featureCollection, property) => {
    
    const stats = {
        sum: 0,
        totalPoints: 0,
        min: Infinity,
        max: -Infinity
    }

    if (!featureCollection) return stats;

    stats.totalPoints = featureCollection.features.length

    let currentValue;
    for (let i = 0; i < featureCollection.features.length; i++) {
        currentValue = Number(featureCollection.features[i].properties[property]);

        stats.sum += currentValue;

        if (currentValue < stats.min) stats.min = currentValue;
        if (currentValue > stats.max) stats.max = currentValue;
    }

    return stats;
}

const getHeatmapColor = (colors) => {
    const numColors = colors.length;
    const colorList = colors.slice().reverse()

    const step = 1 / numColors;
    let currentStep = 0.05;
    let colorExpression = [
        0,
        'rgba(0, 0, 255, 0)',
    ]

    for (let i = 0; i < numColors; i++) {
        colorExpression.push(currentStep);
        colorExpression.push(colorList[i].color);
        currentStep += step
        currentStep = _.round(currentStep, 2)

    }

    return [
        'interpolate',
        ['linear'],
        ['heatmap-density'],
        ...colorExpression
    ]
}

const SelfServiceLayer = () => {
    const [showPopup, setShowPopup] = useState(false)
    const [popupFeature, setPopupFeature] = useState(null);
    const [lngLat, setLngLat] = useState(null);

    const data = useSelector(selectSelfServiceMapSource);

    const style = useSelector(selectStyle);

    const getDataValue = ['to-number', ['get', style.heatmapAttribute]]

    useMapEvent('click', SELF_SERVICE_POINT_LAYER, (e) => {
        setPopupFeature(e.features[0])
        setShowPopup(true)
        setLngLat(e.lngLat)
    })

    const sourceConfig = {
        id: 'self-service-source',
        type: 'geojson',
        data: data,
    };
    
    const selfServiceConfig = {
        id: SELF_SERVICE_POINT_LAYER,
        source: 'self-service-source',
        type: "circle",
        paint: {
            "circle-color": style.color,
            "circle-stroke-width": style.borderSize === 'None' ? 0 : Number(style.borderSize.replace('px', '')),
            "circle-stroke-color": style.borderColor,
        },
    };

    const stats = getDataStats(data, style.heatmapAttribute)

    const intensityModifier = stats.totalPoints / 10
    const selfServiceHeatmapConfig = {
        id: SELF_SERVICE_HEATMAP_LAYER,
        source: 'self-service-source',
        type: 'heatmap',
        paint: {
            'heatmap-intensity': //style.heatmapIntensity / 10 ??
            [
                'interpolate',
                ['linear'],
                ['zoom'],
                0, (0.1 * intensityModifier),// zoom, value
                7, (0.1 * intensityModifier),
                8, (0.05 * intensityModifier),
                9, (0.07 * intensityModifier),
                10, (0.03 * intensityModifier),
                11, (0.03 * intensityModifier),
                12, (0.025 * intensityModifier),
                13, (0.01 * intensityModifier),
                14, (0.015 * intensityModifier),
                15, (0.01 * intensityModifier)
            ],
            'heatmap-weight': [
                'interpolate',
                ['cubic-bezier', .61,.01,.91,.69],
                ['to-number', getDataValue],

                0, 0.03,
                stats.max, 5
            ],
            'heatmap-color': getHeatmapColor(style.heatmapColors),
            'heatmap-opacity': 0.53,
            'heatmap-radius': [
                'interpolate',
                ['linear'],
                ['zoom'],
                0, 3,
                7, 3,
                10, 30,
                12, 80,
                13, 100,
            ],
        },
        // beforeId: HEATMAP_LAYER_BEFORE_ID,
    }

    return (
        data !== null ? <Source {...sourceConfig}>
            {style.mapType === 'Point' && <>
                <Layer {...selfServiceConfig} />
                <SelfServicePointPopup 
                    isOpen={showPopup}
                    onClose={() => setShowPopup(false)}
                    lngLat={lngLat}
                    feature={popupFeature}
                />
            </>}
            {style.mapType === 'Heatmap' && <>
                <Layer {...selfServiceHeatmapConfig}/>
            </>}
        </Source>
        :<div></div>
    );
};

export default SelfServiceLayer;