import {
    ALL_DISTRICTS,
    ALL_VEHICLES,
    SET_ALLOWED_SEARCH,
    SET_MAP_COMMUNITIES,
    SET_EMISSION_TYPE,
    SET_HEATMAP,
    SET_MAP_SCENARIO,
    SET_MAP_TABLES,
    SET_MAP_CENSUS_TRACTS, SET_MAP_REPLACEMENT_TABLE, RESET_MAP_CENSUS_TRACTS,
    RESET_MAP_POSITION,
    SET_MAP_POSITION,
    SET_SIDE_BAR_VISIBILITY,
    SET_SIDE_BAR_CONTENT
} from "../actionTypes";

const setDistrictComponent = state => {
    return {
        type: ALL_DISTRICTS,
        payload: state
    };
};

const setAllVehicles = state => {
    return {
        type: ALL_VEHICLES,
        payload: state
    };
};

const setHeatMapState = state => {
    return {
        type: SET_HEATMAP,
        payload: state
    };
};

const setEmissionType = payload => {
    return {
        type: SET_EMISSION_TYPE,
        payload
    }
}

const setAllowedSearch = payload => {
    return {
        type: SET_ALLOWED_SEARCH,
        payload
    }
}

const SetMapSelectedCommunities = payload => {
    return {
        type: SET_MAP_COMMUNITIES,
        payload
    }
}

const SetMapSelectedScenario = payload => {
    return {
        type: SET_MAP_SCENARIO,
        payload
    }
}

const setShowAnalysisTable = payload => {
    return {
        type: SET_MAP_TABLES,
        payload,
    }
}

const setMapCensusTracts = payload => {
    return {
        type: SET_MAP_CENSUS_TRACTS,
        payload,
    }
}

const setMapReplacementTable = payload => {
    return {
        type: SET_MAP_REPLACEMENT_TABLE,
        payload,
    }
}

const SetMapCensusTractsArray = payload => async (dispatch, getState) => {
    const census = getState().mapStateInternal.mapCensusTracts

    if (payload === null) {
        return dispatch(setMapCensusTracts(null))
    }

    const payloadMapCensusTracts = payload?.map(item => {
        const { label, tract } = item;

        const censusItem = census?.find(x => x.label === label)
        const newTract = censusItem?.tract.filter(x => tract.includes(x));

        return { label, tract: newTract || tract, value: label }
    })
    const updatedMapCensusTracts = payloadMapCensusTracts || [...new Map([...census || [], ...payloadMapCensusTracts].map(v => [JSON.stringify([v.label]), v])).values()]

    return dispatch(setMapCensusTracts(payload ? updatedMapCensusTracts : null))
}

const SetMapCensusTracts = data => async (dispatch, getState) => {
    const { label, tract } = data;

    const census = getState().mapStateInternal.mapCensusTracts

    if (census?.find(x => x.value === label)) {
        const allTract = census.find(x => x.value === label)?.tract

        // include tract
        if (allTract.includes(tract)) {
            const index = allTract.indexOf(tract)

            const newTract = [
                ...allTract.slice(0, index),
                ...allTract.slice(index + 1)
            ];

            let newCensus;

            if (newTract.length === 0) {
                const indexOfValueToRemove = census.findIndex(x => x.value === label);

                newCensus = [
                    ...census.slice(0, indexOfValueToRemove),
                    ...census.slice(index + 1)
                ]

                if (newCensus.length === 0) {
                    newCensus = null;
                }
            }
            else {
                newCensus = census?.map(item => {
                    if (item.label === label) {
                        return { label, tract: newTract, value: label }
                    }
                    return item;
                })
            }

         return dispatch(setMapCensusTracts(newCensus))
        } else {

            const response = census.map(item => {
                if (item.label === label) {
                    const oldTracts = census.find(x => x.label === label).tract
                    return {
                        label,
                        tract: [...oldTracts, tract],
                        value: label
                    }
                } else return item
            })

            dispatch(setMapCensusTracts(response))
        }
    } else {
        census ? dispatch(setMapCensusTracts([...census, { label, tract: [tract], value: label }])) :
            dispatch(setMapCensusTracts([{ label, tract: [tract], value: label }]))
    }
}

const resetMapCensusTracts = () => {
    return {
        type: RESET_MAP_CENSUS_TRACTS
    }
}

const setMapPosition = payload => {
    return {
        type: SET_MAP_POSITION,
        payload
    }
}


const resetMapPosition = () => {
    return {
        type: RESET_MAP_POSITION
    }
}

const setSideBarVisibility = payload => {
    return {
        type: SET_SIDE_BAR_VISIBILITY,
        payload
    }
}

const setSideBarContent = payload => {
    return {
        type: SET_SIDE_BAR_CONTENT,
        payload
    }
}

export {
    setDistrictComponent,
    setAllVehicles,
    setHeatMapState,
    setEmissionType,
    setAllowedSearch,
    SetMapSelectedCommunities,
    SetMapSelectedScenario,
    setShowAnalysisTable,
    SetMapCensusTracts,
    setMapCensusTracts,
    SetMapCensusTractsArray,
    setMapReplacementTable,
    resetMapCensusTracts,
    setMapPosition,
    resetMapPosition,
    setSideBarVisibility,
    setSideBarContent
};