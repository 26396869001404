import {
    FLEET_COMPONENT,
    DISTRICT_COMPONENT,
    QUALITY_AIR_COMPONENT,
    CENSUS_TRACTS_COMPONENT,
    ACTION_BUTTON,
    FILTER_COMPONENT,
    TIME_FRAME_COMPONENT,
    SET_SELECT_STEPS,
    SET_SEARCH,
    SET_MENU_CENSUS_TRACTS_DACS,
    SET_MENU_TOP_IMPACTED_COMMUNITIES,
    SET_MENU_SWAPPING_VEHICLES,
    SET_SWAPPING_REQUEST,
    SET_SWAPPING_CSV_FILE,
    SET_MENU_REPLACEMENT_VEHICLES,
    SET_MENU_VEHICLE_LIST,
    RESET_MENU_VEHICLE_LIST,
    SET_MENU_CONTENT,
    SET_SWAPPING_LAST_REQUEST,
    SET_REPLACEMENT_REQUEST,
    SET_REPLACEMENT_LAST_REQUEST, RESET_SELECTED_KEY, SELECTED_KEY
} from '../actionTypes';

const initialState = {
    selectedKeys: undefined,
    fleetComponent: false,
    districtComponent: false,
    qualityAirComponent: false,
    topImpactedComponent: ['0'],
    actions: {
        timeFrame: true,
        censusTracts: false,
        fleet: false
    },
    content: null,
    selected: {
        [TIME_FRAME_COMPONENT]: false,
        [CENSUS_TRACTS_COMPONENT]: false,
        [FLEET_COMPONENT]: false,
        [ACTION_BUTTON]: false,
        [FILTER_COMPONENT]: false,
    },
    censusTracts: {
        dac: false
    },
    lastSearch: null,
    swappingVehicles: null,
    replacementVehicles: null,
    replacementRequest: true,
    replacementLastRequest: null,
    swappingCSVFile: null,
    swapRequest: true,
    vehicleList: null,
    swapLastRequest: null,
};

const menu = (state = initialState, {type, payload}) => {
    switch (type) {
        case RESET_SELECTED_KEY:
            return { ...state, selectedKeys:undefined }
        case SELECTED_KEY:
            return { ...state, selectedKeys: payload }
        case FLEET_COMPONENT:
            return {...state, fleetComponent: payload};
        case DISTRICT_COMPONENT:
            return {...state, districtComponent: payload};
        case QUALITY_AIR_COMPONENT:
            return {...state, qualityAirComponent: payload};
        case SET_SELECT_STEPS:
            return {...state, selected: {...state.selected, ...payload}}
        case SET_MENU_CENSUS_TRACTS_DACS:
            return {...state, censusTracts: {dac: payload}}
        case SET_SEARCH:
            return {...state, lastSearch: payload}
        case SET_MENU_TOP_IMPACTED_COMMUNITIES:
            return {...state, topImpactedComponent: payload}
        case SET_MENU_SWAPPING_VEHICLES:
            return {...state, swappingVehicles: payload}
        case SET_MENU_REPLACEMENT_VEHICLES:
            return {...state, replacementVehicles: payload}
        case SET_SWAPPING_CSV_FILE:
            return {...state, swappingCSVFile: payload}
        case SET_SWAPPING_REQUEST:
            return {...state, swapRequest: payload}
        case SET_MENU_VEHICLE_LIST:
            return  { ...state, vehicleList: payload}
        case RESET_MENU_VEHICLE_LIST:
            return {...state, vehicleList: initialState.vehicleList}
        case SET_MENU_CONTENT:
            return  { ...state, content: payload}
        case SET_SWAPPING_LAST_REQUEST:
            return {...state, swapLastRequest: payload}
        case SET_REPLACEMENT_REQUEST:
            return {...state, replacementRequest: payload}
        case SET_REPLACEMENT_LAST_REQUEST:
            return {...state, replacementLastRequest: payload}
        default:
            return state;
    }
};

export default menu;
