export const createCommunitiesOption = features => features &&
    features
        .reduce((acc, obj) => {
            // const dac = obj.properties.dac === 'DAC'
            // if (dac) {
                const tract = obj.tract
                const city = obj.city
                if (!acc[city]) {
                    return {...acc, ...{[city]: [tract]}}
                }
                return {...acc, [city]: [...acc[city], tract]}
            // }
            // return {...acc}

        }, {})
