import { Column } from '@ant-design/plots';
import { theme } from '../../theme/theme';
import styled from 'styled-components';
import { Spin } from "antd";

/* BAR CHART
    - Based off of the column component from antd (https://charts.ant.design/en/examples/column/basic#basic)
    
    - Properties:
        -> data:    Array of objects that contain the keys specified in
                    the xField and yField properties.
        -> xField:  Key to use for plotting data on the X axis
        -> yField:  Key to use for plotting data on the Y axis
*/

const SpinnerWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`

const BarChart = ({
    data,
    xField,
    yField,
    onHover = () => { },
    ...rest
}) => {
    // const maxY = 0.95;
    // const systemAverage = 0.75;
    // const annotation = {
    //     type: 'html',
    //     html: `<div class="systemAverage">
    //     <style>
    //     .g2-html-annotation {
    //       width: 100%;
    //       height: calc(100% - 20px);
    //       top: 0px !important;
    //       overflow: hidden;  
    //       }
    //     .systemAverage {
    //       background: rgba(0, 0, 0, 0.5); 
    //       width: 100%; 
    //       height: 100%; 
    //       bottom: -${(1 - (systemAverage / maxY)) * 100}%; 
    //       position: absolute;
    //     }
    //     </style>
    //     </div>`
    // }
    const config = {
        onReady: (plot) => {
            plot.chart.on('element:mouseover', (evt) => {
                onHover(evt);
            });
        },
        // annotations: [annotation],
        padding: 30,
        color: theme.color.blueJeans,
        xField: xField,
        yField: yField,
        meta: {
            [xField]: {
                // Capitalize first letter of X axis values
                formatter: (value, index) => isNaN(value) ? value.charAt(0).toUpperCase() + value.slice(1).replace(/_/g, ' ') : value
            },
            [yField]: {
                // Round Y axis values to 3 decimal places
                formatter: (value, index) => !isNaN(value) ? Math.round(value * 10000) / 10000 : value
            }
        },
        xAxis: {
            label: {
                style: {
                    fill: '#FFFFFF'
                },
                autoHide: true,
                autoRotate: false,
            },
            // Style the horizontal line that separates the bars from the x axis values
            line: {
                style: {
                    lineWidth: 2,
                    stroke: 'rgba(255, 255, 255, 0.5)'
                }
            },
        },
        yAxis: {
            label: {
                style: {
                    fill: '#FFFFFF'
                },
                autoHide: true
            },
            line: {
                // Style the vertical line that separates the bars from the Y axis values
                style: {
                    lineWidth: 2,
                    stroke: 'rgba(255, 255, 255, 0.5)'
                }
            },
            grid: {
                // Style horizontal lines for each label on Y axis
                line: {
                    style: {
                        lineWidth: 0,
                    }
                }
            }
        },
        // Directly style bars in chart
        theme: {
            maxColumnWidth: 30
        },
    }

    return (
        (!!data
            ? <Column data={data} {...config} {...rest} />
            : <SpinnerWrapper><Spin tip="Loading..." spinning /></SpinnerWrapper>
        )
    )
}

export default BarChart;