import {
    CLEAR_HOSPITALS_STATE,
    SET_HOSPITALS_SOURCE,
    SET_SHOW_HOSPITALS
} from "./actionTypes"

export const initialState = {
    source: null,
    showHospitals: false
}

const Hospitals = (state = initialState, {type, payload}) => {
    switch (type) {
        case CLEAR_HOSPITALS_STATE:
            return { ...initialState }
        case SET_HOSPITALS_SOURCE:
            return { ...state, source: payload }
        case SET_SHOW_HOSPITALS:
            return { ...state, showHospitals: payload }
        default:
            return state
    }
}

export default Hospitals;