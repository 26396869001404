// assets
import MethaneIconOutlined from "shared/style/iconography/Thermometer_Icon.svg?react";


// redux 
import { useSelector } from 'react-redux';
import { selectShowMethaneHeatap } from "redux/methaneLeaks/selectors";
import { SET_SHOW_METHANE_HEATMAP_LAYER } from "redux/methaneLeaks/actionTypes";

// constantS
import strings from 'utils/strings';
import { SwitchAction } from "@innovation-toolkit/ui";

const MethaneHeatmapToggle = () => {
    const showMethaneHeatmap = useSelector(selectShowMethaneHeatap);

    return ( 
        <SwitchAction
            componentIcon={MethaneIconOutlined}
            content={strings.methane.toggle.heatmap.title}
            action={SET_SHOW_METHANE_HEATMAP_LAYER}
            submenu={1}
            currentState={showMethaneHeatmap}
        />
    )
}

export default MethaneHeatmapToggle;