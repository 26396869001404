const ROLES = {
  CIPSDGE: 'CIPSDGE-communityimpactplatformusers-RO',
  DT_CIP_DEVELOPERS_STAKEHOLDERS: 'dt-cip-developers-stakeholders',
  DT_CIP_SCG_STREET_IMPROVEMENT_CIT_WORK_NON_PLANNER: 'dt-cip-scg-streetimprovement-citywork-non-planner',
  DT_CIP_SCG_STREET_IMPROVEMENT_CITY_WORK_PLANNER: 'dt-cip-scg-streetimprovement-citywork-planner',
  DT_CIP_SCG_ABATEMENT: 'dt-cip-scg-abatement',
  DT_CIP_SCG_METHANE: 'dt-cip-scg-methane',
  DT_CIP_SCG_CHARITABLE: 'dt-cip-scg-charitable',
  DT_CIP_SCG_SELF_SERVICE: 'dt-cip-scg-selfservice',
  DT_CIP_SCG_FLEET: 'dt-cip-scg-fleet-decarbonization',
  GIS_AD_GROUP: 'GIS-Elec-Policy-Compliance',

  DT_CIP_SCG_STREET_IMPROVEMENT_CITY_WORK_TST: 'dt-cip-scg-streetimprovement-citywork-tst',
  DT_CIP_SCG_ABATEMENT_TST: 'dt-cip-scg-abatement-tst',
  DT_CIP_SCG_FLEET_TST: 'dt-cip-scg-fleetdecarb-tst',
}

const ROLES_TST = {
  DT_CIP_TST_SCG_FLEET: 'dt-cip-scg-fleet-decarbonization-tst'
}

const DEV_PERMISSIONS = {
  CIP_ACCESS: {
    key: "cip",
    roles: [
      ROLES.DT_CIP_DEVELOPERS_STAKEHOLDERS
    ],
  },
  DEV_AND_ALL: {
    key: "dev_and_all",
    roles: [
      ROLES.DT_CIP_DEVELOPERS_STAKEHOLDERS
    ],
  },
  SI_AND_CITY_WORK_NON_PLANNERS: {
    key: "si_and_city_work_non_planners",
    roles: [
      ROLES.DT_CIP_SCG_STREET_IMPROVEMENT_CIT_WORK_NON_PLANNER,
      ROLES.DT_CIP_SCG_STREET_IMPROVEMENT_CITY_WORK_PLANNER,
      ROLES.DT_CIP_DEVELOPERS_STAKEHOLDERS
    ],
  },
  SI_AND_CITY_WORK_PLANNERS: {
    key: "si_and_city_work_planners",
    roles: [
      ROLES.DT_CIP_DEVELOPERS_STAKEHOLDERS
    ],
  },
  ABATEMENT_OPTIMIZATION: {
    key: "abatement_optimization",
    roles: [
      ROLES.DT_CIP_DEVELOPERS_STAKEHOLDERS
    ],
  },
  METHANE: {
    key: "methane",
    roles: [
      ROLES.DT_CIP_SCG_METHANE,
      ROLES.DT_CIP_DEVELOPERS_STAKEHOLDERS
    ],
  },
  CHARITABLE_CONTRIBUTIONS: {
    key: "charitable_contributions",
    roles: [
      ROLES.DT_CIP_SCG_CHARITABLE,
      ROLES.DT_CIP_DEVELOPERS_STAKEHOLDERS
    ],
  },
  SCG_SELF_SERVICE: {
    key: "scg_self_service",
    roles: [
      ROLES.DT_CIP_SCG_SELF_SERVICE,
      ROLES.DT_CIP_DEVELOPERS_STAKEHOLDERS
    ],
  },
  SCG_FLEET_DECARBONIZATION: {
    key: 'scg_fleet_decarbonization',
    roles: [
      ROLES.DT_CIP_DEVELOPERS_STAKEHOLDERS
    ]
  },
  GIS_AD_GROUP: {
    key: 'GIS-Elec-Policy-Compliance',
    roles: [
      ROLES.GIS_AD_GROUP
    ]
  }
}

const TEST_PERMISSIONS = {
  CIP_ACCESS: {
    key: "cip",
    roles: [
      ROLES.DT_CIP_DEVELOPERS_STAKEHOLDERS
    ],
  },
  DEV_AND_ALL: {
    key: "dev_and_all",
    roles: [
      ROLES.DT_CIP_DEVELOPERS_STAKEHOLDERS
    ],
  },
  SI_AND_CITY_WORK_NON_PLANNERS: {
    key: "si_and_city_work_non_planners",
    roles: [
      ROLES.DT_CIP_SCG_STREET_IMPROVEMENT_CIT_WORK_NON_PLANNER,
      ROLES.DT_CIP_SCG_STREET_IMPROVEMENT_CITY_WORK_PLANNER,
      ROLES.DT_CIP_DEVELOPERS_STAKEHOLDERS
    ],
  },
  SI_AND_CITY_WORK_PLANNERS: {
    key: "si_and_city_work_planners",
    roles: [
      ROLES.DT_CIP_SCG_STREET_IMPROVEMENT_CITY_WORK_TST,
      ROLES.DT_CIP_DEVELOPERS_STAKEHOLDERS
    ],
  },
  ABATEMENT_OPTIMIZATION: {
    key: "abatement_optimization",
    roles: [
      ROLES.DT_CIP_SCG_ABATEMENT_TST,
      ROLES.DT_CIP_DEVELOPERS_STAKEHOLDERS
    ],
  },
  METHANE: {
    key: "methane",
    roles: [
      ROLES.DT_CIP_SCG_METHANE,
      ROLES.DT_CIP_DEVELOPERS_STAKEHOLDERS
    ],
  },
  CHARITABLE_CONTRIBUTIONS: {
    key: "charitable_contributions",
    roles: [
      ROLES.DT_CIP_SCG_CHARITABLE,
      ROLES.DT_CIP_DEVELOPERS_STAKEHOLDERS
    ],
  },
  SCG_SELF_SERVICE: {
    key: "scg_self_service",
    roles: [
      ROLES.DT_CIP_SCG_SELF_SERVICE,
      ROLES.DT_CIP_DEVELOPERS_STAKEHOLDERS
    ],
  },
  SCG_FLEET_DECARBONIZATION: {
    key: 'scg_fleet_decarbonization',
    roles: [
      ROLES.DT_CIP_SCG_FLEET_TST,
      ROLES.DT_CIP_DEVELOPERS_STAKEHOLDERS
    ]
  },
  GIS_AD_GROUP: {
    key: 'GIS-Elec-Policy-Compliance',
    roles: [
      ROLES.GIS_AD_GROUP
    ]
  }
}

const PROD_PERMISSIONS = {
  CIP_ACCESS: {
    key: "cip",
    roles: [
      ROLES.DT_CIP_DEVELOPERS_STAKEHOLDERS
    ],
  },
  DEV_AND_ALL: {
    key: "dev_and_all",
    roles: [
      ROLES.DT_CIP_DEVELOPERS_STAKEHOLDERS
    ],
  },
  SI_AND_CITY_WORK_NON_PLANNERS: {
    key: "si_and_city_work_non_planners",
    roles: [
      ROLES.DT_CIP_SCG_STREET_IMPROVEMENT_CIT_WORK_NON_PLANNER,
      ROLES.DT_CIP_SCG_STREET_IMPROVEMENT_CITY_WORK_PLANNER,
      ROLES.DT_CIP_DEVELOPERS_STAKEHOLDERS
    ],
  },
  SI_AND_CITY_WORK_PLANNERS: {
    key: "si_and_city_work_planners",
    roles: [
      ROLES.DT_CIP_SCG_STREET_IMPROVEMENT_CITY_WORK_PLANNER,
      ROLES.DT_CIP_SCG_STREET_IMPROVEMENT_CIT_WORK_NON_PLANNER,
      ROLES.DT_CIP_DEVELOPERS_STAKEHOLDERS
    ],
  },
  ABATEMENT_OPTIMIZATION: {
    key: "abatement_optimization",
    roles: [
      ROLES.DT_CIP_SCG_ABATEMENT,
      ROLES.DT_CIP_DEVELOPERS_STAKEHOLDERS
    ],
  },
  METHANE: {
    key: "methane",
    roles: [
      ROLES.DT_CIP_SCG_METHANE,
      ROLES.DT_CIP_DEVELOPERS_STAKEHOLDERS
    ],
  },
  CHARITABLE_CONTRIBUTIONS: {
    key: "charitable_contributions",
    roles: [
      ROLES.DT_CIP_SCG_CHARITABLE,
      ROLES.DT_CIP_DEVELOPERS_STAKEHOLDERS
    ],
  },
  SCG_SELF_SERVICE: {
    key: "scg_self_service",
    roles: [
      ROLES.DT_CIP_SCG_SELF_SERVICE,
      ROLES.DT_CIP_DEVELOPERS_STAKEHOLDERS
    ],
  },
  SCG_FLEET_DECARBONIZATION: {
    key: 'scg_fleet_decarbonization',
    roles: [
      ROLES.DT_CIP_SCG_FLEET,
      ROLES_TST.DT_CIP_TST_SCG_FLEET,
      ROLES.DT_CIP_DEVELOPERS_STAKEHOLDERS
    ]
  },
  GIS_AD_GROUP: {
    key: 'GIS-Elec-Policy-Compliance',
    roles: [
      ROLES.GIS_AD_GROUP
    ]
  }
}

const PERMISSIONS = {
  dev: {...DEV_PERMISSIONS},
  tst: {...TEST_PERMISSIONS},
  prd: {...PROD_PERMISSIONS}
}

export { ROLES, PERMISSIONS }