import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { Select as AntdSelect, Checkbox } from 'antd';
import { SetFilteredVehicles, restFleetVehiclesFilter, setFleetVehiclesFilter, setVehicleFilterFleet } from "../../../../redux/sidebar/actions";
import { selectFleetFilter, selectVehicles } from "../../../../redux/sidebar/selectors";
import { selectVehiclesList } from "../../../../redux/menu/selectors"
import { resetSearchVehicles, setSearchFleet } from "../../../../redux/searchItems/actions";
import { selectSearchFleetVehicles } from "../../../../redux/searchItems/selectors";
import { resetMenuSelectedKey, resetMenuVehicleList, setMenuVehicleList } from "../../../../redux/menu/actions";
import VehicleIconComponent from '../../../../assets/icons/noVehiclesFound.svg';
import { setAllVehicles } from '../../../../redux/mapStateInternal/action';
import strings from 'utils/strings';
import { FilterWrapper, compareArrays } from '../../../../../../shared';
import { Button, FilterActionButtons, HeaderContent, SearchBar } from '@innovation-toolkit/ui';

const CheckboxGroup = Checkbox.Group;

const NoVehiclesFound = styled.div`
  width: 256px;
  height: 190px;
  background-color: #FFFFFF1A;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
`
const NoVehiclesFoundTitle = styled.div`
  line-height: 1.2;
  width: 224px;
  height: 39px;
  font-size: 16px;
  text-align: center;
  margin: 10px 16px;
`
const VehicleIcon = styled.img`
  display: flex;
  width: 80px;
  height: 80px;
  margin-bottom: 32px;
`
const NoVehiclesFoundSection = styled.div`
  display: ${({ isVisible }) => isVisible ? 'block' : 'none'};
`
const VehiclesFoundSection = styled.div`
  min-height: 280px;
  display: ${({ isVisible }) => isVisible ? 'block' : 'none'};
`
const VehicleCount = styled.div`
  color: #fff;
  font-size: 13px;
  width: 235px;
  margin: 20px 10px 8px 10px;
  text-align: center;
  display: inline-grid;
`
const StyledCheckboxAll = styled(Checkbox)`
  color: white;
  margin: 5px 15px;
  padding-bottom: 15px;
`
const HighestVehicleEmitting = styled.div`
  color: white;
  width: 256px;
  height: 64px;
  background-color: #FFFFFF29;
  display: inline-grid;
  align-items: center;

  .name {
    font-size: 12px;
    font-weight: bold;
    margin-left: 20px;
    position: relative;
    bottom: 10px;
  }
`
const StyledCheckbox = styled(CheckboxGroup)`
  color: white;
  margin: 5px 23px;
  overflow: scroll;
  display: grid;
  min-height: 140px;
  max-height: calc(100vh - 730px);

  &&::-webkit-scrollbar {
    display: none;
  }

  .ant-checkbox-wrapper {
    margin-left: 0 !important;
    margin-top: 8px;
    color: white !important;
    font-size: 13px;
    height: 20px;
  }
`
const StyledSelect = styled(AntdSelect)`
  width: 220px;
  color: white;
  margin: 5px 15px;

  .ant-select-selector {
    background-color: #FFFFFF33 !important;
    border-radius: 5px !important;
  }

  .ant-select-arrow {
    color: white;
  }
`
const FilterButtons = styled.div`
  display: flex;
  margin: 10px 20px;
  justify-content: flex-end;
`
const OrDiv = styled.div`
  color: white;
  font-size: 14px;
  width: 235px;
  height: 5px;
  margin: 14px 0;
  text-align: center;
`
const FoundSection = styled.div`
  width: 255px;
  color: white;
  background-color: #0E263B !important;
  padding-bottom: 10px;
  margin: 24px 0 0 0;
`
const List = styled.div`
  color: white;
  overflow: scroll;
  display: grid;
  height: 100%;

  &&::-webkit-scrollbar {
    display: none;
  }
`
const SelectHeading = styled.div`
  color: white;
  margin: 10px 20px 5px 20px;
  font-size: 12px;
  height: 0;
`

const FleetContent = ({ closeTabs }) => {
  const dispatch = useDispatch()

  let vehicles = useSelector(selectVehicles);
  const searchVehicles = useSelector(selectSearchFleetVehicles);
  const fleetFilter = useSelector(selectFleetFilter)
  console.log(vehicles)

  const [filters, setFilters] = useState({ ...fleetFilter });
  const selectList = useSelector(selectVehiclesList)

  const [checkedList, setCheckedList] = useState(selectList);

  const [searchValue, setSearchValue] = useState('');
  const [checkAll, setCheckAll] = useState(false);
  const [filterData, setFilterData] = useState(vehicles)

  const [usingDepartmentOptions, setUsingDepartmentOptions] = useState([]);
  const [majorGroupOptions, setMajorGroupOptions] = useState([])
  const [fuelTypeOptions, setFuelTypeOptions] = useState([]);
  const [parkingLocationOptions, setParkingLocationOptions] = useState([]);
  const [confirmDisabled, setConfirmDisabled] = useState(true);
  const [vehiclesNumber, setVehiclesNumber] = useState(null);


  useEffect(() => {
    setFilters(fleetFilter)
  }, [fleetFilter, setFilters])

  useEffect(() => {
    if (!selectList) {
      setVehiclesNumber(null);
      setCheckedList([]);
    }
  }, [selectList, setCheckedList])

  useEffect(() => {
    setVehiclesNumber(checkedList?.length)
  }, [checkedList, setVehiclesNumber])

  // useEffect(() => {
  //   setVehiclesNumber(selectList?.length)
  // }, [selectList, setVehiclesNumber])

  const handleConfirm = useCallback(() => {
    dispatch(checkedList.length > 0 ? SetFilteredVehicles(vehicleName) : SetFilteredVehicles(filterData))
    dispatch(setSearchFleet(checkedList.length ? checkedList : null))
    dispatch(setMenuVehicleList(checkedList))
    dispatch(resetMenuSelectedKey())
    closeTabs()
    dispatch(setAllVehicles(checkAll))
  }, [dispatch, filterData, checkedList])

  const vehicleName = checkedList && [...new Set(checkedList.map(vehicle => {
    return vehicle.split('(')[0].trim()
  }))]

  const [filteredOptions, setFilteredOptions] = useState([])
  const filteredVehicles = filterData.map(item => `${item.unit_no} ( ${Math.round(item.total_co2_pounds).toLocaleString()} lbs CO2 )`)


  const allVehiclesByNo =
    vehicles && vehicles.slice()
      .sort((a, b) => parseFloat(b.total_co2_pounds) - parseFloat(a.total_co2_pounds))
      .map(item => `${item.unit_no} ( ${Math.round(item.total_co2_pounds).toLocaleString()} lbs CO2 )`)

  useEffect(() => {
    const parkingLocationMap = filteredOptions.length === 0 ? vehicles.map(item => item.parking_loc_name) : filteredOptions.map(item => item.parking_loc_name);
    setParkingLocationOptions([...new Set(parkingLocationMap)])
  }, [vehicles, filteredOptions, setParkingLocationOptions, setFuelTypeOptions, setUsingDepartmentOptions, setMajorGroupOptions])

  useEffect(() => {
    const fuelTypeOptionsMap = filteredOptions.length === 0 ? vehicles.map(item => item.fuel_type) : filteredOptions.map(item => item.fuel_type);
    setFuelTypeOptions([...new Set(fuelTypeOptionsMap)])
  }, [vehicles, filteredOptions, setParkingLocationOptions, setFuelTypeOptions, setUsingDepartmentOptions, setMajorGroupOptions])

  useEffect(() => {
    const usingDepartmentMap = filteredOptions.length === 0 ? vehicles.map(item => item.using_dept_no_desc) : filteredOptions.map(item => item.using_dept_no_desc);
    setUsingDepartmentOptions([...new Set(usingDepartmentMap)])
  }, [vehicles, filteredOptions, setParkingLocationOptions, setFuelTypeOptions, setUsingDepartmentOptions, setMajorGroupOptions])

  useEffect(() => {
    const majorGroupOptionsMap = filteredOptions.length === 0 ? vehicles.map(item => item.major_group) : filteredOptions.map(item => item.major_group);

    setMajorGroupOptions([...new Set(majorGroupOptionsMap)])
  }, [vehicles, filteredOptions, setParkingLocationOptions, setFuelTypeOptions, setUsingDepartmentOptions, setMajorGroupOptions])

  useEffect(() => {
    const filteredData = vehicles
      .filter(item => filters.using_dept_no_desc ? item.using_dept_no_desc === filters.using_dept_no_desc : item)
      .filter(item => filters.fuel_type ? item.fuel_type === filters.fuel_type : item)
      .filter(item => filters.major_group ? item.major_group === filters.major_group : item)
      .filter(item => filters.parking_loc_name ? item.parking_loc_name === filters.parking_loc_name : item)
      .filter(item => filters.vehicleName ? item.unit_no?.toUpperCase().includes(filters.vehicleName?.toUpperCase()) : item)
    setFilterData(filteredData);
  }, [filters, vehicles, setFilterData])

  useEffect(() => {
    const filteredDropdownOptions = vehicles.filter(item =>
      (item.using_dept_no_desc === filters.using_dept_no_desc) ||
      (item.fuel_type === filters.fuel_type) ||
      (item.major_group === filters.major_group) ||
      (item.parking_loc_name === filters.parking_loc_name) ||
      (item.unit_no?.toUpperCase()?.includes(filters.vehicleName?.toUpperCase())) ||
      (item.using_dept_no_desc === filters.using_dept_no_desc && item.fuel_type === filters.fuel_type) ||
      (item.using_dept_no_desc === filters.using_dept_no_desc && item.major_group === filters.major_group) ||
      (item.using_dept_no_desc === filters.using_dept_no_desc && item.parking_loc_name === filters.parking_loc_name) ||
      (item.parking_loc_name === filters.parking_loc_name && item.major_group === filters.major_group) ||
      (item.fuel_type === filters.energyType && item.major_group === filters.major_group) ||
      (item.fuel_type === filters.energyType && item.parking_loc_name === filters.parking_loc_name)
    )
    setFilteredOptions(filteredDropdownOptions);
  }, [filters, setFilteredOptions])

  useEffect(() => {
    setFilterData(vehicles)
  }, [vehicles, setFilterData])

  const onChange = list => {
    // Compare previously selected tracts so we can disable/enable confirm button correctly
    const updatedList = !!list ? list.map(item => item.unit_no) : [];
    const prevSelectedVehicles = !!searchVehicles ? searchVehicles.map(item => item.unit_no) : [];
    setConfirmDisabled(compareArrays(updatedList, prevSelectedVehicles));

    setCheckedList(list);
    setCheckAll(list.length === allVehiclesByNo.length);
  };
  const onCheckAllChange = e => {
    const updatedList = e.target.checked ? (!filteredVehicles ? allVehiclesByNo : filteredVehicles) : [];
    const prevSelectedVehicles = !!searchVehicles ? searchVehicles.map(item => item.unit_no) : [];
    setConfirmDisabled(compareArrays(updatedList, prevSelectedVehicles));

    setCheckedList(updatedList);
    setCheckAll(e.target.checked);
  };
  const onSearch = value => {
    dispatch(setFleetVehiclesFilter({ ...filters, vehicleName: value }))
  };
  const onClickClear = useCallback(() => {
    dispatch(restFleetVehiclesFilter())
    setCheckedList([]);
    setSearchValue('');
  }, [setFilters, setCheckedList, dispatch])

  const handleClearSelection = useCallback(() => {
    dispatch(restFleetVehiclesFilter())
    setCheckedList(null);
    dispatch(SetFilteredVehicles([]))
    dispatch(setSearchFleet([]))
    dispatch(resetMenuVehicleList());
    dispatch(resetSearchVehicles())
  }, [dispatch, setCheckedList, setFilters])

  return (
    <HeaderContent title={strings.menu.fleet.header} closeTabs={closeTabs}>
      <FilterWrapper>

        <SelectHeading style={{paddingTop: '12px'}}>Using Department</SelectHeading>
        <StyledSelect
          className="dropdown"
          data-testid="styled-dropdown-1"
          onChange={value => dispatch(setFleetVehiclesFilter({ ...filters, using_dept_no_desc: value }))}
          value={filters.using_dept_no_desc}
          getPopupContainer={trigger => trigger.parentNode}
        >
          {usingDepartmentOptions.sort().map((item, index) => (
            <StyledSelect.Option key={index} value={item}>
              {item}
            </StyledSelect.Option>
          ))}
        </StyledSelect>
        <SelectHeading>Major Group</SelectHeading>
        <StyledSelect
          className="dropdown"
          data-testid="styled-dropdown-2"
          onChange={value => dispatch(setFleetVehiclesFilter({ ...filters, major_group: value }))}
          value={filters.major_group}
          getPopupContainer={trigger => trigger.parentNode}
        >
          {majorGroupOptions.sort().map((item, index) => (
            <StyledSelect.Option key={index} value={item}>
              {item}
            </StyledSelect.Option>
          ))}
        </StyledSelect>
        <SelectHeading>Fuel Type</SelectHeading>
        <StyledSelect
          className="dropdown"
          data-testid="styled-dropdown-3"
          onChange={value => dispatch(setFleetVehiclesFilter({ ...filters, fuel_type: value }))}
          value={filters.fuel_type}
          getPopupContainer={trigger => trigger.parentNode}
        >
          {fuelTypeOptions.sort().map((item, index) => (
            <StyledSelect.Option key={index} value={item}>
              {item}
            </StyledSelect.Option>
          ))}
        </StyledSelect>
        <SelectHeading>Parking Location</SelectHeading>
        <StyledSelect
          className="dropdown"
          data-testid="styled-dropdown-4"
          onChange={value => dispatch(setFleetVehiclesFilter({ ...filters, parking_loc_name: value }))}
          value={filters.parking_loc_name}
          getPopupContainer={trigger => trigger.parentNode}
        >
          {parkingLocationOptions.sort().map((item, index) => (
            <StyledSelect.Option key={index} value={item}>
              {item}
            </StyledSelect.Option>
          ))}
        </StyledSelect>
        <OrDiv> - or - </OrDiv>
        {/* <RowFlex> */}
        <SearchBar
          style={{
            width: "220px",
            color: "white",
            margin: "5px 15px",
            borderRadius: "50px"
          }}
          placeholder="Search Vehicle by Name"
          onSearch={onSearch}
          allowClear={true}
          onChange={(e) => setSearchValue(e.target.value)}
          value={searchValue}
        />
        <FilterButtons>
          <Button title="Clear All Filters" variant="tertiary" onClick={onClickClear} dataTestId='clear-button' />
        </FilterButtons>
        {/* </RowFlex> */}
        <FoundSection>
          {/* When there are found vehicles */}
          <VehiclesFoundSection isVisible={filterData.length > 0 ? true : false}>
            <VehicleCount>{filterData.length} Vehicles found </VehicleCount>
            <StyledCheckboxAll onChange={onCheckAllChange} checked={checkAll}>Select All</StyledCheckboxAll>
            <List>
              <HighestVehicleEmitting>
                <SelectHeading>Highest Emitting Vehicle</SelectHeading>
                <div
                  className="name">{!Object.values(fleetFilter).find(x => x !== null) ? [allVehiclesByNo[0]] : [filteredVehicles[0]]}</div>
              </HighestVehicleEmitting>
              {/* TODO Fix eventually - Large number of checkboxes is slowing down everything in this component */}
              <StyledCheckbox
                options={!Object.values(fleetFilter).find(x => x !== null) ? allVehiclesByNo : filteredVehicles}
                value={checkedList}
                onChange={onChange} />
            </List>
          </VehiclesFoundSection>
          {/* When there are NOT found vehicles */}
          <NoVehiclesFoundSection isVisible={filterData.length > 0 ? false : true}>
            <NoVehiclesFound>
              <NoVehiclesFoundTitle>There are no vehicles matching your search</NoVehiclesFoundTitle>
              <VehicleIcon src={VehicleIconComponent} alt="Vehicle Icon"></VehicleIcon>
            </NoVehiclesFound>
          </NoVehiclesFoundSection>

        </FoundSection>

      </FilterWrapper>
      <FilterActionButtons
        onConfirm={handleConfirm}
        isConfirmDisabled={confirmDisabled}
        onClear={handleClearSelection}
        isClearDisabled={!checkedList || checkedList?.length === 0}
      />
    </HeaderContent>
  )
}
export default FleetContent