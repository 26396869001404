import { Layer, Source } from "react-map-gl"
import { APIGateways } from "@config/config"
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { isActiveLayer, useActivateMapLayer, selectBeforeIDs } from "@innovation-toolkit/mapbox";
import axios from "axios";



export const NATIONAL_HIGHWAY_SYSTEM_LINE_LAYER = 'NATIONAL_HIGHWAY_SYSTEM_LINE_LAYER';
export const NATIONAL_HIGHWAY_SYSTEM_LINE_SOURCE = 'NATIONAL_HIGHWAY_SYSTEM_LINE_SOURCE';

export const NHSLayerMetadata = {
    layerId: NATIONAL_HIGHWAY_SYSTEM_LINE_LAYER,
    name: 'National Highway System',
    color: '#0279BD'
}

const fetchNHSData = (callBack, filterOptions) => {

    axios.get(`${APIGateways.scgcore}/data/nhs`)
        .then((response) => {
            return fetch(response.data)
            .then(fetchResponse => fetchResponse.json())
            .then(data => {
                callBack(data, false)
            }) 
        })
        .catch((err) => {
            callBack(null, true)
        })
}

export const NHSLayer = ({showLayer=false}) => {

    const [showNHSLayer] = useActivateMapLayer(NHSLayerMetadata, showLayer)
    const beforeIDs = useSelector(selectBeforeIDs)
    const [data, setData] = useState(null);

    useEffect(() => {
        fetchNHSData((data, isError) => {
            if (!isError) {
                setData(data)
            } 
        })
    }, [])

    const sourceConfig = {
        id: NATIONAL_HIGHWAY_SYSTEM_LINE_SOURCE,
        type: 'geojson',
        data: data
    }

    const lineConfig = {
        id: NATIONAL_HIGHWAY_SYSTEM_LINE_LAYER,
        type: "line",
        source: NATIONAL_HIGHWAY_SYSTEM_LINE_SOURCE,
        layout: {
            'visibility': showNHSLayer ? 'visible' : 'none'
        },
        paint: {
            'line-width': ['step',
            ['zoom'],
                1,
                5, 2,
                10, 3,
                13, 5,
                14, 6,
                15, 7,
                16, 10,
                17, 15
        ],
            'line-color': NHSLayerMetadata.color,
            'line-opacity': .6
        },
        beforeId: beforeIDs.line
    }

    return (
        <Source {...sourceConfig}>
            <Layer {...lineConfig} />
        </Source>
    )
}

