import { useDispatch, useSelector } from "react-redux"
import { AngelesLinkNoGoLayerMetadata } from "."
import { addActiveLayer, removeActiveLayer, selectIsLayerActive } from "@innovation-toolkit/mapbox"
import { DropDown, LayerToggle, LayerToggleIcon, LayerToggleIconChild, LayerToggleSection, SmallDropDown, Typography } from '@innovation-toolkit/ui';
import SensitiveSites from "assets/icons/sensitive-sites.svg?react";

export const NoGoLayerToggle = () => {
    const LAYER_ID = AngelesLinkNoGoLayerMetadata.layerId
    const dispatch = useDispatch()
    const shouldShowLayer = useSelector((state) => selectIsLayerActive(state, LAYER_ID))

    const handleClick = (shouldShow) => {
        if (!shouldShow) {
            dispatch(removeActiveLayer(LAYER_ID))
        } else {
            dispatch(addActiveLayer(LAYER_ID))
        }
    }


    return (
        <LayerToggle
            title='No-Go'
            componentIcon={SensitiveSites}
            onChange={handleClick}
            currentState={shouldShowLayer}
            children={<>
            <LayerToggleIconChild 
                Icon={<LayerToggleIcon 
                    type="polygon" 
                    fillColor='#ff0000' 
                    outlineColor="#ffffff" 
                    strokeColor="black"
                />} 
                title='No-Go Layer'
            />
            </>}
        />
    )
}