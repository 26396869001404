import React from 'react';
import { MapLoadingIndicator } from "@innovation-toolkit/mapbox";
import { 
    fetchUserRolesUsingAWS,
    hasPermissions,
    selectCurrentUserRoles,
    setCurrentUserEmail,
    setCurrentUserRoles
} from "@innovation-toolkit/rbac";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

import { PERMISSIONS } from 'config/auth.config';
import { APP_ENV } from 'config/config';
import { ErrorRoute } from './ErrorRoute';

const routePermissionsMap = {
    'street-improvement': 'SI_AND_CITY_WORK_PLANNERS',
    'city-work-aggregation': 'SI_AND_CITY_WORK_PLANNERS',
    'abatement-optimization': 'ABATEMENT_OPTIMIZATION',
    'self-service': 'SCG_SELF_SERVICE',
    'angeles-link': 'DEV_AND_ALL',
    'methane': 'METHANE',
    'population': 'DEV_AND_ALL',
    'fleet-decarbonization': 'SCG_FLEET_DECARBONIZATION',
}


export const PrivateRoutes = () => {
    const dispatch = useDispatch();
    const userRoles = useSelector(selectCurrentUserRoles);
    const location = useLocation();
    const navigate = useNavigate();
    const rolesTimeout = useRef(null);

    const [canAccessCurrentRoute, setCanAccessCurrentRoute] = useState(false);
    const [hasNoAllowedPermissions, setHasNoAllowedPermissions] = useState(false);
    const [errorPageMessage, setErrorPageMessage] = useState("Route access denied due to permissions.");

    // Fetch current user's custom:access roles using innovation-toolkit/rbac
    useEffect(() => {
        (async () => {
            const { roles, email } = await fetchUserRolesUsingAWS({})
            if (roles) {
                //Save user's roles to rbac-redux
                dispatch(setCurrentUserRoles(roles));
            }
            if (email) {
                dispatch(setCurrentUserEmail(email));
            }
        })();

    }, [dispatch]);


    useEffect(() => {
        Object.keys(routePermissionsMap).forEach(key => {
            if (location.pathname.includes(key) && !hasPermissions(PERMISSIONS[APP_ENV][routePermissionsMap[key]], userRoles)) setCanAccessCurrentRoute(false);
            else if (location.pathname.includes(key) && hasPermissions(PERMISSIONS[APP_ENV][routePermissionsMap[key]], userRoles)) setCanAccessCurrentRoute(true);
        });
    }, [userRoles, location]);

    useEffect(() => {
        //Allow time for roles to load, if none found push to error screen
        if(userRoles.length === 0) {
            rolesTimeout.current = setTimeout(() => noPermissions(), 10000);
        } else {
            if (rolesTimeout.current) clearTimeout(rolesTimeout.current);
            if (location.pathname === '/') {
                //Redirect users at root to an allowed application
                const keyMap = Object.keys(routePermissionsMap);
                for (let i = 0; i < keyMap.length; i++) {
                    if (hasPermissions(PERMISSIONS[APP_ENV][routePermissionsMap[keyMap[i]]], userRoles)) {
                        navigate(keyMap[i]);
                        return;
                    }
                }
                noPermissions();
            }
        }
    }, [location, navigate, userRoles]);

    //User has no allowed permissions default to error screen
    const noPermissions = () => {
        setHasNoAllowedPermissions(true);
        setErrorPageMessage("You are not currently granted access to any specific apps.  Please contact digitaltwin@sempra.com to request access to the specific apps needed")
    }

    //Either allow the user to the accessible route or show route error (unless user is at root, then we attempt to redirect them to an application)
    return (
        canAccessCurrentRoute ? <Outlet /> :  (location.pathname !== '/' && hasNoAllowedPermissions) || hasNoAllowedPermissions ? <ErrorRoute overrideMessage={errorPageMessage} /> : <MapLoadingIndicator isLoading={true} />
    )
}