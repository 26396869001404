import { RacialEquityLayerToggle, IncomeLayerToggle, CalEnviroLayerToggle } from '@innovation-toolkit/mapbox'
import { CharitableDonationLayerToggle } from './layers/CharitableDonations/toggle';
import { Typography, LayerToggleSection, Menu, LayerTabWrapper, TitleDivider } from '@innovation-toolkit/ui';


export const PopulationMenu = () => {

    return <Menu
        tabs={[{
                title: "Layers",
                content:
                    <LayerTabWrapper>
                        <Typography text='Internal Data' variant='Heading 2' style={{marginTop: '16px'}}/>
                        <TitleDivider />
                        <LayerToggleSection
                            title='Charitable'
                            toggles={[
                                <CharitableDonationLayerToggle/>
                            ]}
                        />
                        <Typography style={{marginTop: '32px'}} text='External Data' variant='Heading 2'/>
                        <TitleDivider />
                        <LayerToggleSection
                            title='Population'
                            toggles={[
                                <RacialEquityLayerToggle />,
                                <IncomeLayerToggle/>,
                                <CalEnviroLayerToggle />
                            ]}
                        />
                    </LayerTabWrapper>
            }
        ]}
    />
}