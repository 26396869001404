import { selectBeforeIDs, useActivateMapLayer, useMapEvent } from "@innovation-toolkit/mapbox"
import { Layer, Source } from "react-map-gl"
import { useSelector } from "react-redux"
import { selectEmissionsType } from "redux/fleetDecarbonization/slice"
import { useEffect, useState } from "react"
import { APIGateways } from "config/config"
import { filterHeatmap } from './filters';
import { selectSearchDates, selectSearchFleetVehicles } from "redux/searchItems/selectors"
import { selectMapCensusTracts } from "redux/mapStateInternal/selectors"


const EMISSIONS_HEATMAP_DATA_FIELDS = {
    unit_no: 'unit_no',
    lat: 'lat',
    lon: 'lon',
    dt: 'dt',
    driving_CO2_pounds: 'driving_co2_pounds',
    idling_CO2_pounds: 'idling_co2_pounds',
    driving_and_idling_CO2_pounds: 'driving_and_idling_co2_pounds',
    duration_in_hours: 'duration_in_hours',
    trip_distance_miles: 'trip_distance_miles',
    end_tract: 'end_tract'
}

export const SCG_FLEET_DECARB_LAYER = 'SCG_FLEET_DECARB_LAYER'
export const FleetDecarbonizationLayerMetadata = {
    layerId: SCG_FLEET_DECARB_LAYER,
    name: 'Fleet Decarbonization',
    color: '#FFFFFF'
}

export const FleetDecarbonizationLayer = ({ showLayer = true }) => {
    const emissionsType = useSelector(selectEmissionsType)
    const beforeIDs = useSelector(selectBeforeIDs)
    const [showFleetLayer] = useActivateMapLayer(FleetDecarbonizationLayerMetadata, showLayer);
    const [shownEmissions, setShownEmissions] = useState(['coalesce',['get', EMISSIONS_HEATMAP_DATA_FIELDS.driving_and_idling_CO2_pounds],0])
    const searchDates = useSelector(selectSearchDates);
    const searchFleetVehicles = useSelector(selectSearchFleetVehicles);

    const [newSearchTracts, setNewSearchTracts] = useState(null)

    const intensityModifier = 35
    const heatmapColors = [
        0,
        'rgba(0, 0, 255, 0)',
        0.01,
        'rgba(0, 0, 255, 0.2)',
        0.1,
        'royalblue',
        0.3,
        'cyan',
        0.5,
        'lime',
        0.7,
        'hsl(60, 100%, 50%)',
        0.9,
        'hsl(36, 98%, 55%)',
        1,
        'red',
    ];

    const getHeatmapColorInterpolation = () => {

        let valueBuckets = [0.01, 0.2, 0.3, 0.4, 0.7, 1.2, 1.5]
    
        const colorInterpolation = [
            valueBuckets[0],
            0,
            valueBuckets[1],
            0.1,
            valueBuckets[2],
            0.3,
            valueBuckets[3],
            0.5,
            valueBuckets[4],
            0.7,
            valueBuckets[5],
            0.9,
            valueBuckets[6],
            1,
        ];
    
        return colorInterpolation;
    };

    const updateEmissionsShown = () => {
        switch (emissionsType) {
            case 'combined':
                setShownEmissions([
                    'coalesce',
                    ['get', EMISSIONS_HEATMAP_DATA_FIELDS.driving_and_idling_CO2_pounds],
                    0
                ])
                break;
            case 'moving':
                setShownEmissions([
                    'coalesce',
                    ['get', EMISSIONS_HEATMAP_DATA_FIELDS.driving_CO2_pounds],
                    0
                ])
                break;
            case 'idling':
                setShownEmissions([
                    'coalesce',
                    ['get', EMISSIONS_HEATMAP_DATA_FIELDS.idling_CO2_pounds],
                    0
                ])
                break;
            default:
                setShownEmissions([
                    'coalesce',
                    ['get', ''],
                    0
                ])
                break;
        }
    }

    useEffect(() => {
        updateEmissionsShown()
    }, [emissionsType])

    const sourceConfig = {
        id: 'emissions',
        type: 'vector',
        tiles: [`${APIGateways.legacy}/gis-extract/scg_fleet_heatmap/{z}/{x}/{y}`]
}

    const fillConfig = {
        id: 'emission-heatmap-circle',
        type: 'circle',
        source: 'emissions',
        'source-layer': 'scg_fleet_heatmap',
        layout: {
            visibility: showFleetLayer ? 'visible' : 'none'
        },
        minzoom: 13,
        paint: {
            'circle-color': [
                'interpolate',
                ['linear'],
                [
                    'interpolate',
                    ['linear'],
                    shownEmissions,
                    ...getHeatmapColorInterpolation(),
                ],
                ...heatmapColors,
            ],
            'circle-radius': 3,
            'circle-stroke-width': 1,
            'circle-stroke-color': '#000000',
        },
        filter: filterHeatmap(searchFleetVehicles, searchDates, newSearchTracts),
        beforeId: beforeIDs.fill//'tunnel-simple'
    }

    useMapEvent('click', 'emission-heatmap', (e)=>{
       const clicked = e.features[0]?.properties.tract
       if(newSearchTracts !== null){
        return setNewSearchTracts(null)
       }
       setNewSearchTracts([{tract: [clicked]}])
    }, [newSearchTracts])

    const heatmapLayerConfig = {
        id: 'emission-heatmap',
        type: 'heatmap',
        source: 'emissions',
        'source-layer': 'scg_fleet_heatmap',
        paint: {
            'heatmap-intensity': [
                'interpolate',
                ['linear'],
                ['zoom'],
                0, (0.1 * intensityModifier),
                7, (0.1 * intensityModifier),
                8, (0.05 * intensityModifier),
                9, (0.07 * intensityModifier),
                10, (0.03 * intensityModifier),
                11, (0.03 * intensityModifier),
                12, (0.025 * intensityModifier),
                13, (0.01 * intensityModifier),
                14, (0.015 * intensityModifier),
                15, (0.015 * intensityModifier)
            ],//getHeatmapIntensity(searchFleetVehicles, searchDates),
            'heatmap-weight': [ 
                'interpolate', 
                ['linear'], 
                shownEmissions,//emissionTypeExpression, 
                ...getHeatmapColorInterpolation()
            ],
            'heatmap-color': [
                'interpolate',
                ['linear'],
                ['heatmap-density'],
                ...heatmapColors,
            ],
            'heatmap-opacity': 0.53,
            'heatmap-radius': [
                'interpolate',
                ['linear'],
                ['zoom'],
                0, 3,
                7, 3,
                10, 30,
                12, 80,
                13, 100,
            ],
        },
        layout: {
            visibility: showFleetLayer ? 'visible' : 'none'
        },
        filter: filterHeatmap(searchFleetVehicles, searchDates, newSearchTracts),
        beforeId: beforeIDs.heatmap
    }

    return (
        <Source {...sourceConfig}>
            <Layer {...heatmapLayerConfig}/>
            <Layer {...fillConfig}/>
        </Source>
    )
}

export { FleetDecarbonizationLayerToggle } from "./toggle"