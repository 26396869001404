import { useDispatch, useSelector } from "react-redux"
import { NFABLayerMetadata } from "."
import { addActiveLayer, removeActiveLayer, selectIsLayerActive } from "@innovation-toolkit/mapbox"
import { LayerToggle, LayerToggleIcon, LayerToggleIconChild } from '@innovation-toolkit/ui';
import { Plant } from "assets/icons/plant";
import { ForestSign } from "assets/icons/forestSign";

export const NFABLayerToggle = () => {
    const NATIONAL_FOREST_ADMINISTRATIVE_BOUNDARIES_LAYER = NFABLayerMetadata.layerId
    const dispatch = useDispatch()
    const shouldShowNFABLayer = useSelector((state) => selectIsLayerActive(state, NATIONAL_FOREST_ADMINISTRATIVE_BOUNDARIES_LAYER))

    const handleClick = (shouldShow) => {
        if (!shouldShow) {
            dispatch(removeActiveLayer(NATIONAL_FOREST_ADMINISTRATIVE_BOUNDARIES_LAYER))
        } else {
            dispatch(addActiveLayer(NATIONAL_FOREST_ADMINISTRATIVE_BOUNDARIES_LAYER))
        }
    }

    return (
        <LayerToggle
            title='National Forest'
            componentIcon={ForestSign}
            onChange={handleClick}
            currentState={shouldShowNFABLayer}
            children={<LayerToggleIconChild 
                Icon={<LayerToggleIcon 
                    type="polygon-crosshatch" 
                    fillColor={NFABLayerMetadata.color}
                    outlineColor="#ffffff" 
                    strokeColor="#031627"/>} 
                title={"National Forest"}
            />}
        />
    )
}