import { Layer, Source } from "react-map-gl"
import { APIGateways } from "@config/config"
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useActivateMapLayer, selectBeforeIDs } from "@innovation-toolkit/mapbox";
import axios from "axios";

export const EAGLE_AWARENESS_FILL_LAYER = 'EAGLE_AWARENESS_FILL_LAYER';
export const EAGLE_AWARENESS_FILL_SOURCE = 'EAGLE_AWARENESS_FILL_SOURCE';

export const EagleAwarenessLayerMetadata = {
    layerId: EAGLE_AWARENESS_FILL_LAYER,
    name: 'Eagle Awareness',
    color: '#9CDD21'
}

const fetchEagleAwarenessData = (callBack) => {

    axios.get(`${APIGateways.scgcore}/data/eagle-awareness`)
        .then((response) => {
            return fetch(response.data)
            .then(fetchResponse => fetchResponse.json())
            .then(data => {
                callBack(data, false)
            }) 
        })
        .catch((err) => {
            callBack(null, true)
        })
}

export const EagleAwarenessLayer = ({showLayer=false}) => {

    const [showEagleAwarenessLayer] = useActivateMapLayer(EagleAwarenessLayerMetadata, showLayer)
    const beforeIDs = useSelector(selectBeforeIDs)
    const [data, setData] = useState(null);

    useEffect(() => {
        fetchEagleAwarenessData((data, isError) => {
            if (!isError) {
                setData(data)
            } 
        })
    }, [])

    const sourceConfig = {
        id: EAGLE_AWARENESS_FILL_SOURCE,
        type: 'geojson',
        data: data
    }

    const lineConfig = {
        id: EAGLE_AWARENESS_FILL_LAYER,
        type: "line",
        source: EAGLE_AWARENESS_FILL_SOURCE,
        layout: {
            'visibility': showEagleAwarenessLayer ? 'visible' : 'none'
        },
        paint: {
            "line-color": EagleAwarenessLayerMetadata.color,
            "line-width": 3
            // "fill-opacity": 0.6
        },
        beforeId: beforeIDs.fill
    }

    return (
        <Source {...sourceConfig}>
            <Layer {...lineConfig} />
        </Source>
    )
}