import {createSelector} from "reselect";

const selectState = state => state.sidebar;

const selectVehicles = createSelector(
    selectState,
    state => state.vehicles
);

const selectFleetFilter = createSelector(
    selectState,
    select => select.fleetVehiclesFilter
)

export  {
    selectVehicles,
    selectFleetFilter,
}