import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";

const initialState = {
  selectedBird: 'BLACK TERN'
}

// SLICE
const angelesLinksSlice = createSlice({
  name: "angelesLinks",
  initialState,
  reducers: {
    setSelectedBird(state, action) {
      state.selectedBird = action.payload;
    }
  },
});

export const {
  setSelectedBird,
} = angelesLinksSlice.actions;
export const AngelesLinksReducer = angelesLinksSlice.reducer;

// SELECTORS
const selectState = (state) => state.angelesLinks;

export const selectSelectedBird = createSelector(selectState, (state) => state.selectedBird)
 