import { useDispatch, useSelector } from "react-redux"
import { EagleAwarenessLayerMetadata } from "."
import { addActiveLayer, removeActiveLayer, selectIsLayerActive } from "@innovation-toolkit/mapbox"
import { LayerToggle, LayerToggleIcon, LayerToggleIconChild } from '@innovation-toolkit/ui';
import { Eagle } from "assets/icons/eagle";

export const EagleAwarenessLayerToggle = () => {
    const EAGLE_AWARENESS_FILL_LAYER = EagleAwarenessLayerMetadata.layerId
    const dispatch = useDispatch()
    const shouldShowEagleAwarenessLayer = useSelector((state) => selectIsLayerActive(state, EAGLE_AWARENESS_FILL_LAYER))

    const handleClick = (shouldShow) => {
        if (!shouldShow) {
            dispatch(removeActiveLayer(EAGLE_AWARENESS_FILL_LAYER))
        } else {
            dispatch(addActiveLayer(EAGLE_AWARENESS_FILL_LAYER))
        }
    }

    return (
        <LayerToggle
            title='Eagle Awareness'
            componentIcon={Eagle}
            onChange={handleClick}
            currentState={shouldShowEagleAwarenessLayer}
            children={<LayerToggleIconChild 
                Icon={<LayerToggleIcon 
                    type="circle" 
                    fillColor={EagleAwarenessLayerMetadata.color} 
                    outlineColor="#ffffff" 
                    strokeColor="#031627"
                    opacity=".6"/>} 
                    title="Eagle Awareness"/>}
        />
    )
}