import { useCallback, useEffect, useState } from 'react';
import { message } from "antd";
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { theme } from 'shared/theme/theme';
import { InvestmentAnalysis } from './analysis';
import { MultiColorInput, Typography, LayerToggleSection, Menu, MainMenu, Button, TimeFrameFilter, CensusTractFilter, LayerTabWrapper, TitleDivider, SmallDropDown, ColorInput, Input, Tooltip, DACIcon } from '@innovation-toolkit/ui' 
import { FileUpload } from './FileUpload'
 
// Layers
import { TribalLandsLayerToggle, IHSSLayerToggle, HHSEmpowerLayerToggle, HospitalsLayerToggle, HydrogenFuelStationsLayerToggle, RacialEquityLayerToggle, IncomeLayerToggle, addToMapLoadingList, removeFromMapLoadingList, DisadvantagedCommunityLayerToggle } from '@innovation-toolkit/mapbox'

// redux
import { setMenuDacsTracts } from '@redux/menu/actions';
import { resetMapCensusTracts, setDistrictComponent, SetMapCensusTractsArray, SetMapSelectedCommunities } from '@redux/mapStateInternal/action';
import { resetSearchCommunities, resetSearchDate, resetSearchParameters, setSearchCensusTracts, setSearchCommunities, setSearchEndDate, setSearchStartDate } from "redux/searchItems/actions";
import { selectMenuDacTracts, selectSelectedKey } from '@redux/menu/selectors';
import { selectMapCensusTracts } from '@redux/mapStateInternal/selectors';
import { selectSearchCommunities, selectSearchDates } from '@redux/searchItems/selectors';
import { selectDacCensusTracts, selectGroupOptionsCensusTracts } from '@redux/mapbox/selectors';
import { selectActiveEnv } from 'shared/redux/apps';
import { resetSelfServiceStyle, setFileDescription, setFileMetadata, setSelfServiceGeometryAttribute, setSelfServiceOptionalAttribute, setFileColumns, setSelfServiceStyleAttribute, setFileLoaded, resetSelfService, setFileUploadGeoSource, setSimpleAnalyticsTableData } from '@redux/selfService/action';
import { selectGeometry, selectUploadDescription, selectUploadBucket, selectOptionalAttributes, selectSelfServiceMapSource, selectStyle, selectUploadColumns, selectUploadKey, selectFileLoaded, selectNumericUploadColumns, selectSimpleAnalyticsTableSetUp } from '@redux/selfService/selector';

import './menu.css';
import { APIGateways, APP_ENV, LEGACY_WEBSOCKET_URL } from '@config/config';

import axios from 'axios';
import moment from 'moment';
import { DOTToggle } from './layer/DOTLayer/toggle';
import { useWebSocket } from 'react-use-websocket/dist/lib/use-websocket';
import { CopyOutlined } from '@ant-design/icons';
import { useUserToken } from '@innovation-toolkit/rbac';

const { internalData, externalData } = {
    internalData: {
        title: 'Internal Data',
        customers: 'Customers',
        fuelStations: 'Fuel Stations',
        reliability: 'Reliability',
    },
    externalData: {
        title: 'External Data',
        areasOfInterest: 'Areas of Interest',
        fuelStations: 'Fuel Stations',
        electricityDependent: 'Electricity Dependent',
        institutions: 'Institutions'
    },
}
const Subtitle = styled.p`
    color: ${({ theme }) => theme.color.rainyDay};
    font-family: ${({ theme }) => theme.BASE_LIGHT_FONT_FAMILY};
    font-size: 12px;
    line-height: 14px;
    margin-top: 4px;
`

const AttributeSelector = ({ title, Select, Subtitle }) => {
    return (
        <div className="AttributeSelectorContainer">
            <div className='AttributeSelector'>
                <Typography variant="Heading 3" text={title}/>
                {Select}
            </div>
            {!!Subtitle && Subtitle}
        </div>
    )
}

const FilterButton = styled(Button)`
    margin-top: 4px;
`

const OptionalAttributesSubtitle = ({ columnType, handleOnClick, isSelected, text, hideSubtitleAfterSelected }) => {
    if (!text) text = `Select the ${columnType} column from the uploaded data set`
    return (
        isSelected ?
            (hideSubtitleAfterSelected ? <></> : <FilterButton title="Filter Results" type="tertiary" onClick={handleOnClick} /> ):
            <Subtitle>{text}</Subtitle>
    )
}

const StyledSharedButton = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 224px;
  margin-bottom: 16px;
  padding-right: 16px;
  background-color: transparent;
`;

export const FileShareURLInput = ({
    isDisabled = false,
    onClick = () => {},
  }) => {
    const [fileId, setFileId] = useState(null);
  
    const handleUpdateResultsClick = () => {
      onClick(fileId);
    };
  
    return (
      <StyledSharedButton disabled={isDisabled} style={{ paddingRight: "0px" }}>
        <Input
          label="File ID"
          labelTooltip="Insert File ID to use the data from already uploaded file"
          labelTooltipPosition="right"
          type="text"
          placeholder="placeholderFile.cvs"
          value={fileId}
          onChange={(v) => setFileId(v.target.value)}
        />
        <Button
          onClick={handleUpdateResultsClick}
          type="secondary"
          size="thin"
          title="Load"
          style={{
            marginTop: "24px",
            marginLeft: "8px",
            width: "46px",
            border: "1px solid #FFF",
            height: "32px",
          }}
        />
      </StyledSharedButton>
    );
  };
  
  export const FileShareCopyButton = ({ idToCopy }) => {
    const handleClick = () => {
      idToCopy && navigator.clipboard.writeText(idToCopy);
    };
  
    return (
      <StyledSharedButton>
        <h3 style={{ margin: "7px 0" }}>Share File ID</h3>
        <Tooltip placement="right" text="Copy File ID to Clipboard">
          <Button
            onClick={handleClick}
            type="primary"
            icon={<CopyOutlined />}
          />
        </Tooltip>
      </StyledSharedButton>
    );
  };
  


export const SelfServiceMenu = () => {
    const dispatch = useDispatch()
    const activeMenuItem = useSelector(selectSelectedKey);

    const geometryState = useSelector(selectGeometry);
    const optionalAttributes = useSelector(selectOptionalAttributes);
    const styleState = useSelector(selectStyle);

    // Redux selectors for the Optional Attributes filters
    const searchDates = useSelector(selectSearchDates);
    const searchCommunitiesState = useSelector(selectSearchCommunities);
    const dacCommunities = useSelector(selectDacCensusTracts);
    const selectDacTracts = useSelector(selectMenuDacTracts);
    const sdgeCommunities = [] //useSelector(selectSdgeCensusTracts)
    const selectSdgeTracts = []; //useSelector(selectMenuSdgeTracts);
    const selectedMapTracts = useSelector(selectMapCensusTracts);
    const communitiesOptions = useSelector(selectGroupOptionsCensusTracts);
    const numericColumns = useSelector(selectNumericUploadColumns)
    const activeEnv = useSelector(selectActiveEnv);
    const mapSource = useSelector(selectSelfServiceMapSource)
    const fileKey = useSelector(selectUploadKey)
    const isFileLoaded = useSelector(selectFileLoaded)
    
    const uploadColumns = useSelector(selectUploadColumns);
    const uploadKey = useSelector(selectUploadKey);
    const uploadDescription = useSelector(selectUploadDescription);
    const uploadBucket = useSelector(selectUploadBucket);

    const simpleAnalyticsTableSetup = useSelector(selectSimpleAnalyticsTableSetUp);

    // const websocketUrl = useSelector(selectWebsocketUrl);
    
    const [websocketUrl, setWebsocketUrl] = useState('')

    const [menuFilter, setMenuFilter] = useState(null);
    const [isTimeFrameSelected, setIsTimeFrameSelected] = useState(false);
    const [isCensusTractsSelected, setIsCensusTractsSelected] = useState(false);
    const [presignedUrl, setPresignedUrl] = useState('');
    const [presignedFields, setPresignedFields] = useState({});
    const [latLonSelected, setLatLonSelected] = useState(geometryState.latitude && geometryState.longitude)
    const [calendarStartDate, setCalendarStartDate] = useState(moment())
    const [descriptionInputValue, setDescriptionInputValue] = useState(null);
    const [fileUploadStatus, setFileUploadStatus] = useState(null)
    const [isEnabledShareButton, setIsEnabledShareButton] = useState(false)
    const [websocketMessageHistory, setWebsocketMessageHistory] = useState([]);

    // For these two placeholders we would have some selector that would get these values from redux state
    // depending on the uploaded dataset
    // const placeholderColumnTitles = ['lat_col', 'lon_col', 'date_col', 'census_col', 'leaks_col', 'col_1', 'col_2', 'col_3']
    const placeholderDataLayoutOptions = ['Point']

    const placeholderMapType = ['Point', 'Heatmap']
    const placeholderBorderSize = ['None', '1px', '2px']

    const [userToken] = useUserToken();

    useEffect(() => {
        if (userToken) {
            setWebsocketUrl(`${LEGACY_WEBSOCKET_URL}?authorization=${userToken}`)
        }
    }, [dispatch, userToken])

    const { sendMessage, lastMessage } = useWebSocket(websocketUrl, {
        shouldReconnect: (closeEvent) => true,
        reconnectAttempts: 10,
        //attemptNumber will be 0 the first time it attempts to reconnect, so this equation results in a reconnect pattern of 1 second, 2 seconds, 4 seconds, 8 seconds, and then caps at 10 seconds until the maximum number of attempts is reached
        reconnectInterval: (attemptNumber) => Math.min(Math.pow(2, attemptNumber) * 1000, 10000),
    }, !!websocketUrl);

    // Each time a response from the websocket is received perform the appropriate action
    useEffect(() => {
        if (!lastMessage) return
        setWebsocketMessageHistory([...websocketMessageHistory, lastMessage]);
        const response = JSON.parse(lastMessage.data);

        if (response.endpoint === '/self-service/columns') {
            const body = JSON.parse(response.body);
            dispatch(setFileColumns(body.columns_list));
            dispatch(removeFromMapLoadingList('self-service-columns'));

        } else if (response.endpoint === '/self-service/data') {
            // Data request returns a presigned url, fetch for data
            fetch(response.body)
                .then((response) => response.json())
                .then((data) => {
                    dispatch(setFileUploadGeoSource(data))
                    dispatch(removeFromMapLoadingList('self-service-data'));
                })
        } else if (response.endpoint === '/self-service/simple-analytics-table') {

            const data = JSON.parse(response.body)
            dispatch(setSimpleAnalyticsTableData(data))

        }
    }, [lastMessage]);

    useEffect(() => {
        return () => {
            dispatch(resetSelfService());
        }
    }, [])

    useEffect(() => {
        optionalAttributes?.timeFrame !== null ? setIsTimeFrameSelected(true) : setIsTimeFrameSelected(false)
        optionalAttributes?.censusTracts !== null ? setIsCensusTractsSelected(true) : setIsCensusTractsSelected(false)
    }, [optionalAttributes])

    useEffect(() => {
        if (mapSource?.features) {
            const startDate = moment.min(mapSource.features.map(item => moment(item.properties[optionalAttributes.timeFrame])))
            setCalendarStartDate(startDate.isAfter('1970-01-02') ? startDate : undefined)
        }
    }, [mapSource, optionalAttributes])

    const handleFilterClick = (filterType) => {
        menuFilter === filterType ? setMenuFilter(null) : setMenuFilter(filterType);
    }

    // When file is uploaded fetch the columns
    useEffect(() => {
        if (fileUploadStatus === "done" && !!uploadBucket && !!uploadKey) {
            sendMessage(JSON.stringify({
                action: 'async',
                resource: '/self-service/columns',
                key_path: uploadKey,
                bucket_path: uploadBucket
            }))
            dispatch(addToMapLoadingList('self-service-columns'));
        }
    }, [fileUploadStatus, uploadBucket, uploadKey])

    // When columns are successfully selected fetch data
    useEffect(() => {
        if (!geometryState.latitude || !geometryState.longitude) return;

        const payload = {
            "key_path": uploadKey,
            "bucket_path": uploadBucket,
            "lat_column": geometryState.latitude,
            "long_column": geometryState.longitude,
            "additional_columns": uploadColumns.join()
        }

        if (selectedMapTracts !== null) {
            const formattedCensusTracts = selectedMapTracts.map(item => item.tract).flat().join();
            payload.census_tracts = formattedCensusTracts;
        }
    
        if (searchDates.start !== null && searchDates.end !== null) {
            payload.date_column = optionalAttributes.timeFrame;
            payload.start_date = moment(searchDates.start).format('YYYY-MM-DD');
            payload.end_date = moment(searchDates.end).format('YYYY-MM-DD');
        }

        sendMessage(JSON.stringify({
            action: 'async',
            resource: '/self-service/data',
            ...payload
        }))
        dispatch(addToMapLoadingList('self-service-data'));

        setLatLonSelected(true);

    }, [geometryState, selectedMapTracts, searchDates])

    // When simple analytics table columns are chosen fetch data for table
    useEffect(() => {
        for (let value of Object.values(simpleAnalyticsTableSetup)) {
            if (value === '') return
        }
        const filter = {
            key_path: uploadKey,
            bucket_path: uploadBucket,
            lat_column: geometryState.latitude,
            long_column: geometryState.longitude,
            additional_columns: `${simpleAnalyticsTableSetup.columns},${simpleAnalyticsTableSetup.values}`,
            pivot_row: "DAC75",
            pivot_column: simpleAnalyticsTableSetup.columns,
            pivot_value: simpleAnalyticsTableSetup.values,
            pivot_agg: simpleAnalyticsTableSetup.aggregation.toLowerCase()
        }
        dispatch(setSimpleAnalyticsTableData(null))

        // Send message on websocket with this filter
        sendMessage(JSON.stringify({
            action: 'async',
            resource: '/self-service/simple-analytics-table',
            ...filter
        }))
    }, [simpleAnalyticsTableSetup])

    const fileUploadChange = useCallback((info) => {
        const { status } = info.file;
        if (status === "done") {
            dispatch(resetSelfServiceStyle());
            dispatch(resetSearchParameters())
            dispatch(resetMapCensusTracts())
            setIsEnabledShareButton(true)
        }
    })

    const getFileStatus = useCallback((info) => {
        const { status } = info.file;
        setFileUploadStatus(status)
    })

    const beforeUpload = useCallback(async (file) => {
        setFileUploadStatus("removed")
        return true;
    })

    const handleSubmitDescription = async () => {
        dispatch(setFileDescription({description: descriptionInputValue}));
        try {
            await axios.post(`${APIGateways.legacy}/self-service/description`, {
                description: descriptionInputValue,
                fileId: uploadKey,
                bucket: uploadBucket,
            })
        }
        catch (e) {
            console.error(e)
        }
    }

    const handleGetFileUsingID = useCallback((fileID) => {
        dispatch(setFileMetadata({key: fileID, bucket: 'self-service-raw-data-bucket-' + APP_ENV}))
    })

    const uploadFileToS3 = (file) => new Promise(async (resolve, reject) => {
        // Get presigned url to upload file to S3
        const res = await axios.post(
            `${APIGateways.legacy}/self-service/upload`,
            {},
            { headers: { 'file-name': file.name } }
        );
        const presignedUrl = res.data.presigned.url;
        const fields = res.data.presigned.fields
        setPresignedUrl(res.data.presigned.url);
        setPresignedFields(res.data.presigned.fields)
        dispatch(setFileMetadata({ bucket: res.data.bucket, key: res.data.key }))
    
        // Create a FormData object to hold the file that is 
        // being uploaded
        const formData = new FormData();
        Object.keys(fields)
            .forEach((key) => {
            formData.append(key, fields[key]);
        });    
        formData.append('file', file);
    
        // Upload file to S3
        try {
            await fetch(presignedUrl, { method: 'POST', body: formData })
        } catch {
            reject("Failed to upload file")
        }
    
        // Return information about where the file was uploaded to
        resolve(res.data.S3_Path)
    })


    useEffect(() => {
        if( isFileLoaded !== null) {
            if (isFileLoaded) {
                setFileUploadStatus('done')
                dispatch(resetSelfServiceStyle());
                message.success(`File is loaded successfully.`);
            } else {
                message.error(`File load failed.`);
            }
            dispatch(setFileLoaded(null))
        }
    }, [isFileLoaded])

    if (menuFilter !== null) {
        return (
            <MainMenu background-color={theme.color.prussianBlue}>
                {menuFilter === 'date' &&
                    <TimeFrameFilter
                        defaultStartDate={searchDates.start}
                        defaultEndDate={searchDates.end}
                        defaultMonth={calendarStartDate}
                        datePillOptions={["1D", "1W", "1M", "3M", "6M", "1Y"]}
                        closeTabs={() => setMenuFilter(null)}
                        onStartDateSelected={(date) => dispatch(setSearchStartDate(date))}
                        onEndDateSelected={(date) => dispatch(setSearchEndDate(date))}
                        onReset={() => dispatch(resetSearchDate())}
                        onClose={() => setMenuFilter(null)}
                    />
                }
                {menuFilter === 'census' &&
                    <CensusTractFilter
                        searchCommunitiesState={searchCommunitiesState}
                        dacCommunities={dacCommunities}
                        selectDacTracts={selectDacTracts}
                        sdgeRegions={sdgeCommunities}
                        selectSdgeTracts={selectSdgeTracts}
                        selectedMapTracts={selectedMapTracts}
                        communitiesOptions={communitiesOptions}
                        activeEnv={activeEnv}
                        closeTabs={() => setMenuFilter(null)}
                        onSearchCensusTractsSelected={(value) => dispatch(setSearchCensusTracts(value))}
                        onMapSelectedCommunitiesSelected={(value) => dispatch(SetMapSelectedCommunities(value))}
                        onDistrictComponentSelected={(value) => dispatch(setDistrictComponent(value))}
                        onResetSearchCommunities={(value) => dispatch(resetSearchCommunities(value))}
                        onSearchCommunitiesSelected={(value) => dispatch(setSearchCommunities(value))}
                        onMapCensusTractsArraySelected={(value) => dispatch(SetMapCensusTractsArray(value))}
                        onMenuDacsTractsSelected={(value) => dispatch(setMenuDacsTracts(value))}
                        onClose={() => setMenuFilter(null)}
                    />
                }
            </MainMenu>)
    }

    return <Menu
        tabs={[
            {
                title: "Upload",
                content:
                    <>
                        <LayerTabWrapper className='SelfServiceMenu'>
                            <section className="uploadComponent">
                                <Typography text='File Upload' variant='Heading 2' style={{ marginTop: '16px'}}/>
                                <TitleDivider />
                                {APP_ENV === 'dev' && 
                                    <>
                                        <FileShareURLInput onClick={handleGetFileUsingID}/>
                                        <label style={{fontSize: '0.75rem', fontFamily: 'InterstateBold', fontWeight: '500', lineHeight: '32px'}}>Upload File</label>
                                    </>
                                }
                                
                                <FileUpload
                                    dispatch={dispatch}
                                    text = {fileKey ? 'Click or drag a new single file to this area to upload' : 'Click or drag a single file to this area to upload'}
                                    acceptedFiles=".csv, .xlsx"
                                    onChange={fileUploadChange}
                                    beforeUpload={beforeUpload}
                                    customRequest={(options) => {

                                        uploadFileToS3(options.file)
                                            .then((s3Path) => {
                                                

                                                options.onSuccess(s3Path, options.file)
                                            })
                                    }}
                                    getFileStatus={getFileStatus}
                                />
                                {fileUploadStatus === "done" &&
                                    <>
                                        <Input 
                                            label="Description"
                                            rows={3}
                                            placeholder="Please enter a brief description of the file you are uploading (optional)"
                                            type='textarea'
                                            onChange={(event) => setDescriptionInputValue(event.target.value)}
                                            value={descriptionInputValue}
                                        />
                                        <Button 
                                            size='thin'
                                            title='Add Description'
                                            style={{ margin: '16px 0 24px auto' }}
                                            disabled={!descriptionInputValue || descriptionInputValue === uploadDescription}
                                            onClick={handleSubmitDescription}
                                        />
                                    </>
                                }
                                {/* {APP_ENV === 'dev' && isEnabledShareButton && fileKey && <FileShareCopyButton idToCopy={fileKey}/>} */}
                                
                            </section>
                            { fileUploadStatus === "done" &&
                                <>
                                    <section>
                                        <Typography text='Geometry' variant='Heading 2'/>
                                        <TitleDivider />
                                        <AttributeSelector
                                            title='Data Layout'
                                            Select={
                                                <SmallDropDown
                                                    options={placeholderDataLayoutOptions}
                                                    selectedOption={geometryState.dataLayout ?? 'Select'}
                                                    onSelectChange={(option) => { dispatch(setSelfServiceGeometryAttribute({ dataLayout: option })) }}
                                                    type='small'
                                                />
                                            }
                                        />
                                        { geometryState.dataLayout && 
                                            <>
                                                <AttributeSelector
                                                    title='Latitude'
                                                    Select={
                                                        <SmallDropDown
                                                            options={uploadColumns}
                                                            selectedOption={geometryState.latitude ?? 'Select'}
                                                            onSelectChange={(option) => { dispatch(setSelfServiceGeometryAttribute({ latitude: option })) }}
                                                            type='small'
                                                        />
                                                    }
                                                />
                                                <AttributeSelector
                                                    title='Longitude'
                                                    Select={
                                                        <SmallDropDown
                                                            options={uploadColumns}
                                                            selectedOption={geometryState.longitude ?? 'Select'}
                                                            onSelectChange={(option) => { dispatch(setSelfServiceGeometryAttribute({ longitude: option })) }}
                                                            type='small'
                                                        />
                                                    }
                                                />
                                            </>
                                        }
                                    </section>
                                    { (geometryState.latitude && geometryState.longitude) &&
                                        <>
                                        {/* Filters */}
                                            {/* <section>
                                                <Typography text='Optional Attributes' variant='Heading 2'/>
                                                <TitleDivider />
                                                <AttributeSelector
                                                    title='Time Frame'
                                                    Select={
                                                        <SmallDropDown
                                                            options={uploadColumns}
                                                            selectedOption={optionalAttributes.timeframe ?? 'Select'}
                                                            onSelectChange={(option) => { dispatch(setSelfServiceOptionalAttribute({ timeFrame: option })) }}
                                                            type='small'
                                                        />
                                                    }
                                                    Subtitle={<OptionalAttributesSubtitle columnType='date' handleOnClick={() => handleFilterClick('date')} isSelected={isTimeFrameSelected} />}
                                                />
                                                <AttributeSelector
                                                    title='Census Tracts'
                                                    Select={<></>
                                                        // <SmallDropDown
                                                        //     options={uploadColumns}
                                                        //     selectedOption='Select'
                                                        //     onSelectChange={(option) => { dispatch(setSelfServiceOptionalAttribute({ censusTracts: option })) }}
                                                        //     type='small'
                                                        // />
                                                    }
                                                    Subtitle={<OptionalAttributesSubtitle columnType='census tract' handleOnClick={() => handleFilterClick('census')} isSelected={latLonSelected} text="Census tract is determined by the data point’s latitude and longitude" />}
                                                />
                                            </section> */}
                                            <section>
                                                { geometryState.latitude && geometryState.longitude &&
                                                    <>
                                                        <Typography text='Style' variant='Heading 2'/>
                                                        <TitleDivider />
                                                        <AttributeSelector
                                                            title='Map Type'
                                                            Select={
                                                                <SmallDropDown
                                                                    options={placeholderMapType}
                                                                    selectedOption={styleState.mapType ?? 'Select'}
                                                                    onSelectChange={(option) => { dispatch(setSelfServiceStyleAttribute({ mapType: option })) }}
                                                                    type='small'
                                                                />
                                                            }
                                                        />
                                                        
                                                        {styleState.mapType && styleState.mapType === "Point" &&
                                                            <>
                                                                {/* <AttributeSelector
                                                                    title='Data Field'
                                                                    Select={
                                                                        <SmallDropDown
                                                                            options={uploadColumns}
                                                                            selectedOption={styleState.dataField ?? 'Select'}
                                                                            onSelectChange={(option) => { dispatch(setSelfServiceStyleAttribute({ dataField: option })) }}
                                                                            type='small'
                                                                        />
                                                                    }
                                                                />
                                                                { styleState.dataField && 
                                                                    <> */}
                                                                        <AttributeSelector
                                                                            title='Color'
                                                                            Select={
                                                                                <ColorInput 
                                                                                    selectedColor={styleState.color}
                                                                                    getColor={(color) => { dispatch(setSelfServiceStyleAttribute({ color: color })) }} 
                                                                                />
                                                                            }
                                                                        />
                                                                        <AttributeSelector
                                                                            title='Border Size'
                                                                            Select={
                                                                                <SmallDropDown
                                                                                    options={placeholderBorderSize}
                                                                                    selectedOption={styleState.borderSize}
                                                                                    onSelectChange={(option) => { dispatch(setSelfServiceStyleAttribute({ borderSize: option })) }}
                                                                                    type='small'
                                                                                />
                                                                            }
                                                                        />
                                                                        {styleState.borderSize && styleState.borderSize !== "None" &&
                                                                            <>
                                                                                <AttributeSelector
                                                                                    title='Border Color'
                                                                                    Select={
                                                                                        <ColorInput selectedColor={styleState.borderColor} getColor={(color) => { dispatch(setSelfServiceStyleAttribute({ borderColor: color })) }} />
                                                                                    }
                                                                                />
                                                                            </>
                                                                        }
                                                                    {/* </>
                                                                } */}
                                                            </>
                                                        }
                                                        {styleState.mapType && styleState.mapType === "Heatmap" &&
                                                            <>
                                                                <AttributeSelector
                                                                    title='Attribute'
                                                                    Select={
                                                                        <SmallDropDown
                                                                            options={numericColumns}
                                                                            selectedOption={styleState.heatmapAttribute ?? 'Select'}
                                                                            onSelectChange={(option) => { dispatch(setSelfServiceStyleAttribute({ heatmapAttribute: option })) }}
                                                                            type='small'
                                                                        />
                                                                    }
                                                                    Subtitle={
                                                                        <OptionalAttributesSubtitle 
                                                                            columnType='heatmap attribute' 
                                                                            isSelected={styleState.heatmapAttribute !== null} 
                                                                            hideSubtitleAfterSelected={true}
                                                                            text="Select count or a numeric column from the uploaded data set" 
                                                                        />}
                                                                    />
                                                                {/* <AttributeSelector
                                                                    title='Heatmap Intensity'
                                                                    Select={<></>}
                                                                />
                                                                <Slider
                                                                    min={1}
                                                                    max={10}
                                                                    onChange={(value) => dispatch(setSelfServiceStyleAttribute({heatmapIntensity: value}))}
                                                                /> */}
                                                                <MultiColorInput
                                                                    onChange={(colors) => { dispatch(setSelfServiceStyleAttribute({ heatmapColors: colors})) }}
                                                                />

                                                            </>
                                                        }
                                                    </>
                                                }
                                            </section>
                                        </>
                                    }
                                </>
                            }
                        </LayerTabWrapper>
                    </>
            },
            {
                title: "Layers",
                content:
                    <LayerTabWrapper>
                        {/* <h2>{internalData.title}</h2>
                        <TitleDivider />
                        <LayerToggleSection
                            title={internalData.customers}
                            toggles={[
                                <AFNLayerToggle />
                            ]}
                        />
                        <LayerToggleSection
                            title={internalData.fuelStations}
                            toggles={[
                                // <InternalChargersLayerToggle />,
                            ]}
                        />
                        {/* <LayerToggleSection
                            title={internalData.reliability}
                            toggles={[
                                <CircuitReliabilityMetricsLayerToggle />
                            ]}
                        /> */} 


                        <Typography text='External Data' variant='Heading 2' style={{ marginTop: '16px'}}/>
                        <TitleDivider />
                        <LayerToggleSection
                            title={externalData.areasOfInterest}
                            toggles={[
                               <div style={{marginBottom: '-8px'}}><DisadvantagedCommunityLayerToggle icon={DACIcon} titleVariant='Heading 4' /></div>,
                                <DOTToggle/>,
                                <TribalLandsLayerToggle />
                            ]}
                        />
                      
                        <LayerToggleSection
                            title={externalData.electricityDependent}
                            toggles={[
                                <IHSSLayerToggle />,
                                <HHSEmpowerLayerToggle />
                            ]}
                        />
                          <LayerToggleSection
                            title={externalData.fuelStations}
                            toggles={[
                                // <ExternalEVLayerToggle />,
                                <HydrogenFuelStationsLayerToggle />
                            ]}
                        />
                        <LayerToggleSection
                            title={externalData.institutions}
                            toggles={[
                                <HospitalsLayerToggle />,
                            ]}
                        />
                        <LayerToggleSection
                            title='Population'
                            toggles={[
                                <RacialEquityLayerToggle />,
                                <IncomeLayerToggle/>
                            ]}
                        />
                    </LayerTabWrapper>
            },
            {
                title: "Analysis",
                content: <InvestmentAnalysis />
            }
        ]}
    />
}