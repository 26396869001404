import { message, Upload, Button as AntButton, Modal } from "antd";
import { CopyOutlined } from "@ant-design/icons";
// import { CancelIcon } from "../../style/iconography/CancelIcon";
// import { UploadIcon } from "../../style/iconography/UploadIcon";
import { useCallback, useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { resetSelfService, setFileClassification, resetFileClassification } from "redux/selfService/action";
import { resetSearchParameters } from "redux/searchItems/actions";
import { resetMapCensusTracts } from "redux/mapStateInternal/action";
// import { Tooltip } from "../tooltip";
// import { Input } from "../filterWrapper";
//import { Button } from "..";
import styled from "styled-components";
// import SmallDropDown from "./SmallDropDown";
import { selectClassification, selectFileLoaded, selectUploadKey } from "redux/selfService/selector";
import strings from 'shared/utils/strings'
import { Button, Typography, SmallDropDown, Tooltip, Input, CancelIcon, UploadIcon } from '@innovation-toolkit/ui'

const { Dragger } = Upload;

/* FILE UPLOAD
    - Based on the Upload component from AntD (https://ant.design/components/upload/)
    - User can drag and drop file into the Upload field or by click on the file the 
    - default window is opened to select a file from computer
    
    - Properties:
        -> name:                The name of uploading file
        -> acceptedFiles:       File types that can be accepted (.xlsx, .csv, .txt, ...)
        -> multiple:            Allow selecting multiple files when true
        -> action:              Uploading URL
        -> token:               Authorization token to be added to the headers
        -> disableUploadButton: Disable Upload Button
        -> showUploadList:      Whether to show default upload list, could be an object 
                                to specify showPreviewIcon, showRemoveIcon, showDownloadIcon, 
                                removeIcon and downloadIcon individually
        -> maxCount:            Limit the number of uploaded files
        -> maxFileSize:         Limit the maximum file size in [MB]
        -> text:                Text/Title to show on the upload button (bold)
        -> hint:                Text/Hint to show on the upload button  (normal)
        -> customRequest:       Override for the default xhr behavior allowing 
                                for additional customization and ability to implement 
                                your own XMLHttpRequest
        -> onChange:            A callback function, can be executed when uploading state is changing
        -> getFileStatus:       A callback function returning the file info (status, response,...)
        -> getFiles:            A callback function returning array of uploaded files
        -> size:                File Upload component's size
*/

export const FileUpload = ({
  name = "file",
  skipPopup = false,
  acceptedFiles,
  multiple = false,
  action,
  token,
  method,
  disableUploadButton,
  dispatch,
  handleReset = () => {
    dispatch(resetSelfService());
    dispatch(resetSearchParameters());
    dispatch(resetMapCensusTracts());
  },
  showUploadList = { removeIcon: <CancelIcon style={{color: 'white'}} onClick={handleReset} /> },
  maxCount = 1,
  maxFileSize = 20,
  text = "Click or drag a new single file to this area to upload",
  hint = (
    <>
      File format: CSV, XLSX <br />
      Maximum size: 20MB
    </>
  ),
  customRequest = () => {},
  onChange = () => {},
  getFileStatus = () => {},
  getFiles = () => {},
  beforeUpload = () => {},
  size = {
    width: 224,
    height: 176,
  },
}) => {

  const [draggerMargin, setDraggerMargin] = useState(24);
  const [fileName, setFileName] = useState("");
  const [isOpen, setOpen] = useState(false);
  const fileWasUploaded = !!useSelector(selectUploadKey)

  useEffect(() => {
    setOpen(isOpen)
  }, [isOpen])

  const handleChange = useCallback((info) => {
    const { status } = info.file;

    if (status === "done" || status === "error" || status === "uploading") {
      setDraggerMargin(0);
    } else {
      setDraggerMargin(24);
    }

    //Popup displaying upload state (on the top of website)
    if (status === "done") {
      message.success(`File ${info.file.name} is uploaded successfully.`);
    } else if (status === "error") {
      message.error(`File ${info.file.name} upload failed.`);
    }
    getFileStatus(info);
    onChange(info);
  }, []);

  const handleDrop = useCallback((e) => {
    // Dropped Files
    if (!skipPopup) {
      setOpen(true);
    }
    getFiles(e.dataTransfer.files);
  });

  const handleCustomRequest = useCallback((e) => {
    // Handle custom request
    customRequest(e);
  });

  const resolveRef = useRef(() => {;})
  const rejectRef = useRef(() => {;})

  const promiseFunc = () => {
    return new Promise((resolve, reject) => {
      resolveRef.current = resolve;
      rejectRef.current = reject
    })
  }

  const handleBeforeUpload = async (file) => {
    //open modal
    if (!skipPopup) {
      try{
        setOpen(true);
        let type = await promiseFunc()
      } catch(err){
        return false
      } finally {
        setOpen(false)
        dispatch(resetFileClassification())
      }
    }
    setFileName(file.name);

    if (file.size / 1024 / 1024 > maxFileSize) {
      message.error(`File ${file.name} is too large`);
      return Upload.LIST_IGNORE;
    }
    return beforeUpload ? beforeUpload(file) : true;
  };

  return (
    <>
    <Dragger
      name={name}
      accept={acceptedFiles}
      multiple={multiple}
      action={action}
      method={method}
      disabled={disableUploadButton}
      showUploadList={fileWasUploaded && showUploadList}
      progress={{ strokeWidth: 4, showInfo: true }}
      maxCount={maxCount}
      onChange={(info) => handleChange(info)}
      onDrop={(event) => handleDrop(event)}
      customRequest={!action && handleCustomRequest}
      beforeUpload={(file) => handleBeforeUpload(file)}
      style={{
        width: size.width,
        height: size.height,
        marginBottom: draggerMargin,
      }}
      headers={
        token
          ? {
              authorization: token,
              "file-name": fileName,
            }
          : {
              "file-name": fileName,
            }
      }
    >
      <p className="ant-upload-drag-icon">
        <UploadIcon style={{color: 'white'}} />
      </p>
      <p className="ant-upload-text">{text}</p>
      <p className="ant-upload-hint">{hint}</p>
    </Dragger>
    <DataClassificationModal resolveRef={resolveRef} rejectRef={rejectRef} isOpen={isOpen}/>
    </>
  );
};

export default FileUpload;

export const FileShareURLInput = ({
  isDisabled = false,
  onClick = () => {},
}) => {
  const [fileId, setFileId] = useState(null);

  const handleUpdateResultsClick = () => {
    onClick(fileId);
  };

  return (
    <StyledSharedButton disabled={isDisabled} style={{ paddingRight: "0px" }}>
      <Input
        label="File ID"
        labelTooltip="Insert File ID to use the data from already uploaded file"
        labelTooltipPosition="right"
        type="text"
        placeholder="placeholderFile.cvs"
        value={fileId}
        onChange={(v) => setFileId(v.target.value)}
      />
      <Button
        onClick={handleUpdateResultsClick}
        type="secondary"
        size="thin"
        title="Load"
        style={{
          marginTop: "24px",
          marginLeft: "8px",
          width: "46px",
          border: "1px solid #FFF",
          height: "32px",
        }}
      />
    </StyledSharedButton>
  );
};

const StyledSharedButton = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 224px;
  margin-bottom: 16px;
  padding-right: 16px;
  background-color: transparent;
`;

export const FileShareCopyButton = ({ idToCopy }) => {
  const handleClick = () => {
    idToCopy && navigator.clipboard.writeText(idToCopy);
  };

  return (
    <StyledSharedButton>
      <h3 style={{ margin: "7px 0" }}>Share File ID</h3>
      <Tooltip placement="right" text="Copy File ID to Clipboard">
        <AntButton
          onClick={handleClick}
          type="primary"
          icon={<CopyOutlined />}
        />
      </Tooltip>
    </StyledSharedButton>
  );
};

export const StyledModal = styled(Modal)`
  .ant-modal-content {
    background-color: #031627;
    /* width: 500px;
    height: 437px; */
    svg {
      fill: #ffffff;
    }
  }

  .ant-modal-header {
    background-color: #031627;
    border-bottom: 1px solid #7b7b7b;
    height: 54px;
  }

  .ant-modal-body {
    padding: 21px 24px 16px 24px;
    .body-primary-text {
      margin-bottom: 5px;
    }
    h2 {
      padding-top: 5px;
    }
    .subtitle {
      margin-bottom: 10px;
    }
  }

  .ant-modal-footer {
    border-top: 1px solid #7b7b7b;
    padding: 16px 24px 16px 24px;
    .tertiary {
      position: absolute;
      bottom: 29px;
    }
  }
`;

export const StyledButtons = styled.div`
  display: flex;
  justify-content: flex-end;
`

export const StyledDropDown = styled.div`
  display: flex;
  flex-direction: row;

  .normalSmallDropDown {
    position: absolute;
    right: 180px;
    bottom: 89px;
  }
`

export const DataClassificationModal = ({
  resolveRef, 
  rejectRef, 
  isOpen, 
  onOk= () =>{}, 
  onCancel=()=>{}
}) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(isOpen);
  const classificationState = useSelector(selectClassification);
  const [isDisabled, setButtonDisabled] = useState(true);
  const tooltipErrorMessage = "Your data cannot be uploaded because the data file is restricted"
  useEffect(() => {
    if (classificationState === 'Internal' || classificationState === 'Confidential' || classificationState === 'Public'){
      setButtonDisabled(false);
    } else {
      setButtonDisabled(true);}
  }, [classificationState])

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen])

  const handleOk = () => {
    //when button is clicked call resolveRef.current
    resolveRef.current('confidential')
    setOpen(false);
    onOk();
  };

  const handleCancel = () => {
    //when button is clicked call rejectRef.current
    rejectRef.current()
    setOpen(false);
    onCancel();
  };

  const placeholderDataClassificationOptions = ['Public', 'Internal', 'Confidential', 'Restricted']
  const modal = strings.modal

  return (
    <>
      <StyledModal
          visible={open}
          title={<h1>Data Classification</h1>}
          onOk={handleOk}
          onCancel={handleCancel}
          footer={[
              <Button className='tertiary' variant="tertiary" title="Learn More"/>,
            <StyledButtons>  
              <Button variant="secondary" size="S" title="CANCEL" onClick={handleCancel}/>,
              <Button
              size="S"
              onClick={handleOk}
              title='I AGREE'
              tooltipText={isDisabled && classificationState === 'Restricted'? tooltipErrorMessage: ''}
              disabled={isDisabled}
              style={{marginLeft: '12px'}}
            />,
            </StyledButtons>
          ]}
        >
          <Typography style={{marginBottom: '8px'}} variant="Body 1" text={modal.bodyText1}/>
          <Typography style={{marginBottom: '8px'}} variant="Body 1" text={modal.bodyText2}/>
          <Typography style={{marginBottom: '8px'}} variant="Body 1" text={modal.bodyText3}/>
          <Typography style={{marginBottom: '8px'}} variant="Heading 2" text={modal.bodyBoldText}/>
          <Typography style={{marginBottom: '5px', color:"#8FA1B1"}} variant="Subtitle" text={modal.subtitle}/>
          <StyledDropDown>
            <Typography variant="Heading 3" text="Data Classification"/>
            <SmallDropDown
              selectedOption={classificationState ?? 'Select'}
              onSelectChange={(option) => { dispatch(setFileClassification(option)) }}
              type='normal'
              options={placeholderDataClassificationOptions}
            />
          </StyledDropDown>
        </StyledModal>
    </>
  );
};
