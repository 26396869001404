import { useDispatch, useSelector } from "react-redux"
import { LAStreetImprovementLayerMetadata } from "."
import { addActiveLayer, removeActiveLayer, selectIsLayerActive } from "@innovation-toolkit/mapbox"
import { DelimiterLine, InfoIcon, LayerToggle, LayerToggleIcon, LayerToggleIconChild, ToolIcon, Typography } from '@innovation-toolkit/ui';
import ProjectIcon from 'assets/icons/city_project.svg?react';

export const LAStreetImprovementToggle = () => {
    const COMMITTED_PROJECTS_LAYER = LAStreetImprovementLayerMetadata.layerId
    const dispatch = useDispatch()
    const shouldShowLAStreetImprovement = useSelector((state) => selectIsLayerActive(state, COMMITTED_PROJECTS_LAYER))

    const handleClick = (shouldShow) => {
        if (!shouldShow) {
            dispatch(removeActiveLayer(COMMITTED_PROJECTS_LAYER))
        } else {
            dispatch(addActiveLayer(COMMITTED_PROJECTS_LAYER))
        }
    }

    return (
        <LayerToggle
            title='Projects'
            bodyStyle={{backgroundColor: '#0E263B'}}
            headerStyle={{marginTop: '10px'}}
            tooltipText="This layer is “Zoom Dependent”. Zoom in if you are not seeing results."
            // componentIcon={ProjectIcon}
            onChange={handleClick}
            currentState={shouldShowLAStreetImprovement}
            children={
                <div
                style={{
                    position:
                        'relative' /* Position relative for the parent div */,
                    padding: '5px 0px 11px 10px',
                }}
                >
                <div
                    style={{
                        position: 'absolute',
                        left: -8 /* Position on the left */,
                        top: 9 /* Align with the top */,
                        height: '60%' /* Match the height of the parent */,
                        width: '1.5px' /* Width of the border */,
                        backgroundColor:
                            '#FFFFFF33' /* Color of the border */,
                    }}
                />
                <section style={{display: 'flex', flexDirection: 'column'}}>
                    <LayerToggleIconChild
                        Icon={
                            <LayerToggleIcon
                                type="dashed-line" 
                                fillColor={LAStreetImprovementLayerMetadata.color} 
                                strokeColor="black" 
                                outlineColor="white"
                            />
                        } 
                        title="Street Improvement" 
                    />
                </section>
                </div>
            }
        />
    )
}