import React, { useEffect } from 'react';
import { Content } from 'antd/lib/layout/layout';
import { Layout } from 'antd/lib';
import { FleetDecarbonizationMapLayers } from './layers/mapLayers';
import { FleetDecarbonizationMenu } from './menu';
import { useDispatch } from 'react-redux';
import { setActiveApp } from '../../../../../shared/redux/apps';
import { VehicleReplacementTable } from './VehicleReplacementTable';
import { FleetImpactTable } from './FleetImpactTable';
import MapboxReact from 'component/mapbox/mapbox';
import ValueMetricsTables from './ValueMetricsTables';

export const FleetDecarbonization = () => {
    const dispatch = useDispatch();
    
    useEffect(() => {
        dispatch(setActiveApp('fleet-decarbonization'));
    }, [dispatch]);

    return (
        <>
            <FleetDecarbonizationMenu />
            <Layout style={{ maxHeight: '100vh' }}>
                <Content style={{
                    margin: 0,
                    position: "relative",
                    color: "white"
                }}>
                    <VehicleReplacementTable />
                    <ValueMetricsTables />
                    <FleetImpactTable />
                    <MapboxReact layers={<FleetDecarbonizationMapLayers />} />
                </Content>
            </Layout>
        </>
    )
}

