import { MapPopup } from '@innovation-toolkit/mapbox';
import { ChevronLeftIcon, MapPopupContents, Typography } from '@innovation-toolkit/ui';
import { useState, useEffect } from 'react';
import moment from 'moment';
import styled from 'styled-components';
import _ from 'lodash';

const toDateString = (date) => {
    if (!date) return '';

    return moment(date).format('MM/DD/YYYY');
}

const priorityColorCode = {
    "High": '#B41405',
    "high": '#B41405',
    "High-Medium": '#E05400',
    "high_mid": '#E05400',
    "Medium": '#F5C846',
    "mid": '#F5C846',
    "Low": '#32BF8A',
    "low": '#32BF8A',
    "Not Prioritized": '#5A5A5A',
    "no ranking factors": '#5A5A5A'

}

const whiteHeadingText = ["High", "High-Medium", "Not Prioritized"];

const formatFactors = (factorArray) => {
    factorArray = JSON.parse(factorArray)

    if (factorArray.length === 0) return {}

    let outputFactorMap = {}

    factorArray.forEach((element) => {
        outputFactorMap[element] = "yes"
    })

    return outputFactorMap
}

const formatPrioritizationData = (element) => {

    const formattedInformation = {     
        priority: element.priority_ranking,
        highPriority: formatFactors(element.high_priority_factors),
        highMediumPriority: formatFactors(element.high_mid_priority_factors),
        mediumPriority: formatFactors(element.mid_priority_factors),
        lowPriority: formatFactors(element.low_priority_factors),
    }

    Object.keys(formattedInformation).forEach(key => {
        if (!formattedInformation[key]) {
            formattedInformation[key] = '-'
        }
    })

    return formattedInformation
}

const formatDetails = (element) => {

    const formattedInformation = {     

        'Leak Object ID': element.LeakObjectID,
        // 'Leak Object ID':  element.LeakObjectID,
        // 'Mat Code': element.OrderMAT,
        'District': element.District,
        'City': element.City,
        'Address': element.Address,
        'Land': element.private === "Yes" ? "Private" : "Public",
        'Detect Date': toDateString(element.DetectDate),
        // 'Leak Fix Date': toDateString(element.LeakFixDate),
        'Leak Active': element.LeakActive,
        'Leak Code': element.LeakCode,
        'Days Leak Active': element.DaysLeakActive,
        // 'Order': element.Order,
        // 'Notification': element.Notification,
        // 'Order Completion Date': toDateString(element.OrderCompletionDate),
        // 'Order Completion Time': element.OrderCompletionTime,
        // 'Facility': element.Facility,
        // 'Leak In': element.LeakIn,
        // 'Cause': element.Cause,
        // 'Type Repair': element.TypeRepair,
        // 'Material': element.Material,
        // Leak Prioritization Section
        
    }

    Object.keys(formattedInformation).forEach(key => {
        if (!formattedInformation[key]) {
            formattedInformation[key] = '-'
        }
    })

    return formattedInformation
}

export const convertToJSONArray = (string) => {
    if (!string) return 
     
    return string.replaceAll("'", "\"")
}

export const MandILeakPopup = ({isOpen, onClose, lngLat, feature, target}) => {

    const [popupState, setPopupState] = useState({
        lng: 0,
        lat: 0,
        children: null
    })

    const [detailsSelected, setDetailsSelected] = useState(true)
    const  [data, setData] = useState({})

    

    useEffect(() => {

        if (lngLat === null || feature === null) return
        const popupFeature = feature.properties

        let formattedFeature = { ...popupFeature } 
        
        formattedFeature.high_priority_factors = convertToJSONArray(formattedFeature.high_priority_factors)
        formattedFeature.high_mid_priority_factors = convertToJSONArray(formattedFeature.high_mid_priority_factors)
        formattedFeature.mid_priority_factors = convertToJSONArray(formattedFeature.mid_priority_factors)
        formattedFeature.low_priority_factors = convertToJSONArray(formattedFeature.low_priority_factors)


        let description = ''
        setData(formattedFeature);
        
        setPopupState({
            lng: lngLat.lng,
            lat: lngLat.lat,
            children: description
        })

    }, [isOpen, lngLat, feature])

    return isOpen ?
                <MapPopup longitude={popupState.lng} latitude={popupState.lat} onClose={onClose} className='MandIPopup' closeOnClick={false}>
                    <MapPopupContents
                        title={<LeakDetailsTitle detailsSelected={detailsSelected} setDetailsSelected={setDetailsSelected}/>}
                        footNote={<MandIFootnote detailsSelected={detailsSelected} setDetailsSelected={setDetailsSelected} showPrioritizationFactors={data.priority_ranking && _.toUpper(data.priority_ranking) !== 'NO RANKING FACTORS'}/>}
                        data={detailsSelected ? formatDetails(data) : null}
                        children={detailsSelected ? null : <LeakPrioritizationFactors data={data}/>}
                        accentColor={priorityColorCode[data.priority_ranking]}
                        accentHeader={data.priority_ranking && _.toUpper(data.priority_ranking) === 'NO RANKING FACTORS' ? 'NO RANKING FACTORS':  _.toUpper(data.priority_ranking) + " PRIORITY"}
                        accentHeaderTextColor={(whiteHeadingText.includes(data.priority_ranking) && "white")}
                    />
                </MapPopup>
                : null
}

const StyledFootnote = styled.div`
    font-size: 12px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    text-decoration: underline;
    cursor: pointer;
    margin: auto 24px auto 24px;
`

function MandIFootnote({ detailsSelected, setDetailsSelected, showPrioritizationFactors }) {
    const handleOnClick = () => {
        setDetailsSelected(!detailsSelected)
    }
    if (!showPrioritizationFactors) {
        return (
            <Typography variant="Body 1" text="Source: SAP HANA"/>
        )
    }

    return (
        <StyledFootnote onClick={handleOnClick}>
            {detailsSelected ? "View Leak Prioritization Factors" : "View Leak Details"}
        </StyledFootnote>
    )
}

function BackToLeakDetails({ setDetailsSelected }) {
    const handleOnClick = () => {
        setDetailsSelected(true)
    }

    return <div onClick={handleOnClick} style={{height: '22px'}}><ChevronLeftIcon/></div>
}

function LeakDetailsTitle({ detailsSelected, setDetailsSelected }) {
    return detailsSelected ? (
        "SoCalGas Work - M&I Leak"
    ) : (
        <div style={{ display: "flex", gap: "21px" }}>
            <BackToLeakDetails setDetailsSelected={setDetailsSelected} />
            <Typography variant="Heading 1" text="Leak Prioritization Factors" style={{padding: 0, margin: 0}}/>
        </div>
    )
}

function LeakPrioritizationSection({ ranking, data }) {
    // If at least one value in data is a Yes then display section
    if (Object.keys(data).some((key) => data[key])){
        return (
            <div>
                {/* <Typography variant="Subtitle" text="(Placeholder data: waiting on prioritization factor endpoint)"/> */}
                <Typography variant="Heading 4" text={`${ranking} Priority`} style={{marginBottom: '12px', color: 'white'}}/>
                {Object.keys(data).map((key) => {
                    if (!data[key]) return <></>;
    
                    return (
                        data[key] !== null && 
                        <div style={{display: 'flex', gap: '14px', marginBottom: '15px'}}>
                            <Typography variant="Heading 4" text={data[key] ? "Y" : "N"}/>
                            <Typography variant="Body 1" text={key}/>
                            {
                                data[key] && 
                                    <span style={{ 
                                        backgroundColor: priorityColorCode[ranking],
                                        width: '12px', 
                                        height: '12px', 
                                        borderRadius: '50%', 
                                        margin: '2px 0px 2px auto'}}/> 
                            }
                            
                        </div>
                    )
                })}
            </div>
        )
    } else {
        return <></>
    }
    
}

function LeakPrioritizationFactors({ data }) {
    const formattedData = formatPrioritizationData(data)
    // True if leak contains at least one prioritization factor, false if none
    const containsAtLeastOnePrioritizationFactor = Object.keys(formattedData).map(key => {
        if (key === 'priority') return false;
        let data = formattedData[key]
        return Object.keys(formattedData[key]).some((key) => data[key])
    }).some(element => element)

    if (containsAtLeastOnePrioritizationFactor) {
        return (
            <div>
                <LeakPrioritizationSection ranking="High" data={formattedData.highPriority} />
                <LeakPrioritizationSection ranking="High-Medium" data={formattedData.highMediumPriority} />
                <LeakPrioritizationSection ranking="Medium" data={formattedData.mediumPriority} />
                <LeakPrioritizationSection ranking="Low" data={formattedData.lowPriority} />
            </div>
        )
    } else {
        return <Typography variant='Heading 4' text='No prioritizations found for this leak'/>
    }
    
}