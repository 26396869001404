// assets
import { SwitchAction } from "@innovation-toolkit/ui";
import EmissionsLayerIcon from "../../../../assets/icons/cloud-windy-line.svg?react";


// redux 
import { useSelector } from 'react-redux';
import { SET_SHOW_EMISSIONS_LAYER } from "redux/emissions/actionTypes";
import { selectShowEmissionsLayer } from "redux/emissions/selectors";

// constantS
import strings from 'utils/strings';

const SCGEmissionsToggle = () => {
    const showEmissionsLayer = useSelector(selectShowEmissionsLayer);

    return (
        <SwitchAction
            componentIcon={EmissionsLayerIcon}
            content={strings.emissions.toggle.title}
            action={SET_SHOW_EMISSIONS_LAYER}
            submenu={1}
            currentState={showEmissionsLayer}
        />
    )
}

export default SCGEmissionsToggle;