import { Layer, Source } from "react-map-gl"
import { useEffect, useState } from "react";
import { isActiveLayer, selectIsLayerActive, useActivateMapLayer, useMapHoverEvent, selectBeforeIDs, useMapEvent, useLoadMapImage } from "@innovation-toolkit/mapbox";
import { useSelector } from "react-redux";
// import { LSAPopup } from "./popup";
import { UNCLUTTERED_MIN_ZOOM } from "../../constants";
import valves_symbol from './valves.png';
import axios from 'axios'
import { APIGateways } from "config/config";
import { ValvesPopup } from "./popup";

export const VALVES_LAYER = 'VALVES_LAYER';
export const VALVES_SOURCE = 'VALVES_SOURCE';

export const ValvesLayerMetadata = {
    layerId: VALVES_LAYER,
    name: 'Valves Layer',
    color: '#8F00FF',
    layerSymbols: [
        { symbol: valves_symbol, symbolName: 'valve-icon' },
    ]
}


const fetchValvesData = (callBack, filterOptions) => {

    const url = `${APIGateways.streetimprovements}/reference/valves`;

    const filter = {}

    axios.post(url, filter)
        .then((response) => {
            return fetch(response.data)
            .then(fetchResponse => fetchResponse.json())
            .then(data => {
                callBack(data, false)
            })  
        })
        .catch((err) => {
            callBack(null, true)
        })
}

export const ValvesLayer = ({showLayer=false}) => {

    const [valvesData, setValvesData] = useState(null)
    const beforeIDs = useSelector(selectBeforeIDs)
    const [showValvesLayer] = useActivateMapLayer(ValvesLayerMetadata, showLayer);
    const [showPopup, setShowPopup] = useState(false)
    const [popupFeature, setPopupFeature] = useState(null);
    const [lngLat, setLatLng] = useState(null);
    const [target, setTarget] = useState(null);

    useEffect(() => {
        // Fetch LSA Data
        fetchValvesData((data, isError) => {
            if (!isError) {
                setValvesData(data)
            } 
        })
    },[])

    useLoadMapImage('valve-icon', valves_symbol)

    useMapEvent("click", VALVES_LAYER, (e)=> {

        setShowPopup(true);
        setPopupFeature(e.features[0]);
        setLatLng(e.lngLat);
        setTarget(e.target);
    })

    const sourceConfig = {
        id: VALVES_SOURCE,
        type: "geojson",
        data: valvesData,
        cluster: true,
        clusterMaxZoom: 12,
        clusterRadius: 60,
        // clusterProperties: {
        // LeakObjectIDList: ['concat', ['concat', ['to-number', ['get', 'LeakObjectID']], ', '], ['accumulated']],
        // },
    }

    const symbolConfig = {
        id: VALVES_LAYER,
        type: 'symbol',
        source: VALVES_SOURCE,
        layout: {
        visibility: showValvesLayer ? 'visible' : 'none',
        'icon-image': 'valve-icon',
        'icon-size': 0.45,
        'icon-allow-overlap': true,
        },
        paint: {
        'icon-translate': [0,-27],
        },
        beforeId: beforeIDs.symbol
    };

    return (
        <Source {...sourceConfig}>
            <Layer {...symbolConfig}/>
            <ValvesPopup 
                isOpen={showPopup}
                onClose={() => setShowPopup(false)}
                lngLat={lngLat}
                feature={popupFeature}
                target={target}
            />
        </Source>
    )
}
