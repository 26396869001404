// import { censusTractLayerFilter, dateLayerFilter } from "@innovation-toolkit/mapbox";
import { dateLayerFilter } from "@innovation-toolkit/mapbox";
import { useSelector } from "react-redux";
import { selectMapCensusTracts } from "redux/mapStateInternal/selectors";

const vehicleEmissionsFilter = (searchFleetVehicles) => {

    return [
        'in',
        ['get', 'unit_no'],
        ['literal', searchFleetVehicles],
    ];
}

const censusTractLayerFilter = (selectedCensusTracts, filterAttribute = 'tract') => {
    const tract = ['get', filterAttribute];
    const selectedTracts = new Set([]);

    for (const selection of selectedCensusTracts) {
        for (const tract of selection.tract) {
            selectedTracts.add(tract);
        }
    }

    return [
        'in',
        tract,
        ['literal', Array.from(selectedTracts)]
    ];
}

export const filterHeatmap = (
    searchFleetVehicles,
    searchDates,
    selectedCensusTracts
) => {

    const selectedTractArray = useSelector(selectMapCensusTracts)

    let vehicleFilter = ['literal', true];
    let dateFilter = ['literal', true];
    let tractFilter = ['literal', true];

    if (searchFleetVehicles) {

        vehicleFilter = vehicleEmissionsFilter(searchFleetVehicles)

    }

    if (searchDates && searchDates.start && searchDates.end) {

        dateFilter = dateLayerFilter(searchDates, 'dt')

    }

    if (selectedCensusTracts) {

        tractFilter = censusTractLayerFilter(selectedCensusTracts || selectedTractArray, 'tract')
    }


    return [
        'all',
        vehicleFilter,
        dateFilter,
        tractFilter
    ]
};