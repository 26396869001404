import { useDispatch, useSelector } from "react-redux"
import { addActiveLayer, removeActiveLayer, selectIsLayerActive } from "@innovation-toolkit/mapbox"
import { LayerToggle, LayerToggleIconChild, LayerToggleIcon } from '@innovation-toolkit/ui';
import { BLMIcon } from "assets/icons/BLMIcon";
import { LandSurfaceEstateLayerMetadata } from "./Land_Surface_Estate";


export const BLMLayerToggle = () => {
    const dispatch = useDispatch()

    const layerIds = [LandSurfaceEstateLayerMetadata.layerId ]; // TODO Add BLM layer ids here
    const layerShowState = useSelector((state) => selectIsLayerActive(state, /*AdminFieldBoundaryLayerMetadata.layerId,*/ LandSurfaceEstateLayerMetadata.layerId))
    
    const handleClick = (shouldShow) => {
        if (!shouldShow) {
            layerIds.forEach((layerId) =>  dispatch(removeActiveLayer(layerId)))
        } else {
            layerIds.forEach((layerId) =>  dispatch(addActiveLayer(layerId)))
        }
    }

    return (
        <LayerToggle
            title='BLM'
            componentIcon={BLMIcon}
            onChange={handleClick}
            currentState={layerShowState}
            children={
                <>
                    <LayerToggleIconChild Icon={<LayerToggleIcon type="polygon" fillColor={LandSurfaceEstateLayerMetadata.color} strokeColor="white" outlineColor="white"/>} title="Land Surface Estate"/>
                </>
            }
        />
    )
}