import { useDispatch, useSelector } from "react-redux"
import { NHSLayerMetadata } from "."
import { addActiveLayer, removeActiveLayer, selectIsLayerActive } from "@innovation-toolkit/mapbox"
import { LayerToggle, LayerToggleIcon, LayerToggleIconChild } from '@innovation-toolkit/ui';
import { Road } from "assets/icons/road";

export const NHSLayerToggle = () => {
    const NATIONAL_HIGHWAY_SYSTEM_LINE_LAYER = NHSLayerMetadata.layerId
    const dispatch = useDispatch()
    const shouldShowNHSLayer = useSelector((state) => selectIsLayerActive(state, NATIONAL_HIGHWAY_SYSTEM_LINE_LAYER))
    const handleClick = (shouldShow) => {
        if (!shouldShow) {
            dispatch(removeActiveLayer(NATIONAL_HIGHWAY_SYSTEM_LINE_LAYER))
        } else {
            dispatch(addActiveLayer(NATIONAL_HIGHWAY_SYSTEM_LINE_LAYER))
        }
    }

    return (
        <LayerToggle
            title='National Highway'
            componentIcon={Road}
            onChange={handleClick}
            currentState={shouldShowNHSLayer}
            children={<LayerToggleIconChild 
                Icon={<LayerToggleIcon 
                    type="line" 
                    fillColor={NHSLayerMetadata.color} 
                    outlineColor="#ffffff" 
                    strokeColor="#ffffff"
                    />
                }    
                title={<p>National Highway</p>}/>}
        />
    )
}