import { SET_ACTIVE_APP, SET_ACTIVE_ENV, SET_ACTIVE_MENU_TAB } from "./actionTypes"

export const setActiveApp = payload => {
    return {
        type: SET_ACTIVE_APP,
        payload
    }
}

export const setActiveMenuTab = payload => {
    return {
        type: SET_ACTIVE_MENU_TAB,
        payload
    }
}

export const setActiveEnv = payload => {
    return {
        type: SET_ACTIVE_ENV,
        payload
    }
}