import {
    MAPBOX_BOUNDARIES,
    MAPBOX_EMISSIONS_SOURCE,
    MAPBOX_REGION,
    MAPBOX_SOURCE,
    MAPBOX_ZOOM,
    MAPBOX_DISTRICTS,
    MAPBOX_METHANE,
    MAPBOX_IMPACTED_COMMUNITIES
} from '../actionTypes';

const initialState = {
    source: null,
    fleetImpact: {
        idlingPounds: null,
        movingPounds: null,
    },
    region: null,
    districts: null,
    methane: null,
    zoom: 8,
    boundaries: {
        minLat: -115.894,
        maxLat: -117.938,
        minLong: 33.391,
        maxLong: 35.603,
    },
    impactCommunities: null,
};

const mapBox = (state = initialState, { type, payload }) => {
    switch (type) {
        case MAPBOX_SOURCE:
            return { ...state, source: payload };
        case MAPBOX_REGION:
            return { ...state, region: payload };
        case MAPBOX_DISTRICTS:
            return { ...state, districts: payload };
        case MAPBOX_METHANE:
            return { ...state, methane: payload };
        case MAPBOX_ZOOM:
            return { ...state, zoom: payload };
        case MAPBOX_BOUNDARIES:
            return { ...state, boundaries: payload };
        case MAPBOX_EMISSIONS_SOURCE:
            return { ...state, fleetImpact: payload };
        case MAPBOX_IMPACTED_COMMUNITIES:
            return { ...state, impactCommunities: payload };
        default:
            return state;
    }
};

export default mapBox;
