import { MapPopup } from '@innovation-toolkit/mapbox';
import { MapPopupContents } from '@innovation-toolkit/ui';
import { useState, useEffect } from 'react';
import moment from 'moment';
// import './popup.css'

const toDateString = (date) => {
    if (!date) return '';

    // Parse the date string in 'YYYYMMDD' format
    return moment(date, 'YYYYMMDD').format('MM/DD/YYYY');
}

const formatDetails = (element) => {

    const formattedInformation = {     
        'District': element.district,
        'Location': element.location,
        'City': element.city,
        'Valve Number': element.valve,
        'Equipment Number': element.equipment,
        'Start Up Date': toDateString(element.startupdate),
        'Valve Material': element.valvematerial,
        'Valve Type': element.valvetype,
        'Position': element.position,
        'Maintenance Plan Description': element.maintenanceplandescription,
    }

    Object.keys(formattedInformation).forEach(key => {
        if (!formattedInformation[key]) {
            formattedInformation[key] = '-'
        }
    })

    return formattedInformation
}

export const ValvesPopup = ({isOpen, onClose, lngLat, feature, target}) => {
    const [popupState, setPopupState] = useState({
        lng: 0,
        lat: 0,
        children: null
    })

    const [children, setChildren] = useState(null)
    const  [data, setData] = useState({})

    useEffect(() => {

        if (lngLat === null || feature === null) return
        const popupFeature = feature.properties
        const formattedFeature = formatDetails(popupFeature)
        setData(formattedFeature)
        
        setPopupState({
            lng: lngLat.lng,
            lat: lngLat.lat,
        })

    }, [isOpen, lngLat, feature, children])



    return isOpen ?
                <MapPopup longitude={popupState.lng} latitude={popupState.lat} onClose={onClose} className='StreetImprovementPopup'>
                    <MapPopupContents
                        title='Valves'
                        footNote='Source: GIS'
                        data={data}
                    />
                </MapPopup>
                : null
}