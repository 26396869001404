import { createSelector, createSlice } from "@reduxjs/toolkit"

export const initialState = {
    metadata: {
        columns: null,
        bucket: null,
        key: null,
        s3Path: null,
        loaded: null,
    },
    mapData: null,
    errors: [],
    cityWorkAggregationTable: false,
    drawnGeometries: [],
    rowUpdateInfo: {
        index: -1,
        isUpdating: false
    },
    progress: {
        current: 0,
        total: 0
    }
}

const cityWorkAggregation = createSlice({
    name: 'cityWorkAggregation',
    initialState,
    reducers: {
        // MARK: Currently not used => {
        resetCityWorkAggregation(state, action) {
            state.metadata = initialState.metadata
        },
        setFileMetadata(state, action) {
            state.metadata = { 
                ...state.metadata, 
                bucket: action.payload.bucket, 
                key: action.payload.key, 
                s3Path: action.payload.s3Path 
            }
        },
        setFileColumns(state, action) {
            state.metadata.columns = [...action.payload]
        },
        setFileLoaded(state, action) {
            state.metadata.loaded = action.payload
        },
        setCityAggProgress(state, action) {
            state.progress = action.payload
        },
        setGeocodingErrors(state, action) {
            state.errors = action.payload
        },
        // }
        setCityWorkAggregationMapData(state, action) {
            state.mapData = action.payload
        },
        setShowCityWorkAggregationTable(state, action) {
            state.cityWorkAggregationTable = action.payload
        },
        setDrawnGeometries(state, action) {
            state.drawnGeometries = action.payload
        },
        setRowUpdateInfo(state, action) {
            state.rowUpdateInfo = action.payload
        },
    }
});

export const {
    // MARK: Currently not used => {
    resetCityWorkAggregation,
    setFileMetadata,
    setFileColumns,
    setFileLoaded,
    setCityAggProgress,
    setGeocodingErrors,
    // }
    setCityWorkAggregationMapData,
    setShowCityWorkAggregationTable,
    setDrawnGeometries,
    setRowUpdateInfo,
} = cityWorkAggregation.actions;

const selectCityWorkAggregation = (state) => state.cityWorkAggregation;

export const selectUploadColumns = createSelector(
    selectCityWorkAggregation,
    select => {
        return select.metadata.columns?.map(column => column.columns_name) || []
    }
)

export const selectUploadBucket = createSelector(
    selectCityWorkAggregation,
    select => {
        return select.metadata.bucket
    }
)

export const selectUploadKey = createSelector(
    selectCityWorkAggregation,
    select => {
        return select.metadata.key
    }
)

export const selectFileLoaded = createSelector(
    selectCityWorkAggregation,
    select => {
        return select.metadata.loaded
    }
)

export const selectS3Path = createSelector(
    selectCityWorkAggregation,
    select => {
        return select.metadata.s3Path
    }
)

export const selectCityWorkAggregationMapData = createSelector(
    selectCityWorkAggregation,
    select => {
        return select.mapData
    }
)

export const selectGeocodingErrors = createSelector(
    selectCityWorkAggregation,
    select => {
        return select.errors
    }
)

export const selectShowCityWorkAggregationTable = createSelector(
    selectCityWorkAggregation,
    select => {
        return select.cityWorkAggregationTable
    }
)

export const selectDrawnGeometries = createSelector(
    selectCityWorkAggregation,
    select => {
        return select.drawnGeometries
    }
)

export const selectRowUpdateInfo = createSelector(
    selectCityWorkAggregation,
    select => {
        return select.rowUpdateInfo
    }
)

export const selectCityAggProgress = createSelector(
    selectCityWorkAggregation,
    select => {
        return select.progress
    }
)

export default cityWorkAggregation.reducer