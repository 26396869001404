import { MapPanel } from "@innovation-toolkit/mapbox";
import { NumberedTable, Typography } from "@innovation-toolkit/ui";
import axios from "axios";
import { APIGateways } from "@config/config";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setShowSimpleAnalyticsTable } from "redux/selfService/action";
import { selectShowSimpleAnalytics } from "redux/selfService/selector";

export const CustomTitleComponent = () => {
  return (
    <div style={{ display: "flex", alignItems: "left" }} role="wrapper">
      <Typography
        variant="Heading 1"
        text="Leak Code Breakdown"
        style={{ width: "100%" }}
      />
    </div>
  );
};

const tooltipInfo =
  "This table breaks down the leak codes in DACs vs Non-DACs. The results reflect all available data and are not affected by any filters.";

export const AbatementOptimizationSimpleAnalytics = () => {
  const dispatch = useDispatch();
  const showSimpleAnalytics = useSelector(selectShowSimpleAnalytics);
  const [dacData, setDacData] = useState([]);

  const handleTableOpen = useCallback(
    (show) => {
      dispatch(setShowSimpleAnalyticsTable(show));
    },
    [dispatch]
  );


  useEffect(() => {
    // Fetch Data
    const dacUrl = `${APIGateways.leakabatement}/active_leaks_by_dac`;
    const dacNameMap = {
      Yes: "DAC",
      No: "Non-DAC",
      Neither: "Neither",
    };
  
    axios.post(dacUrl).then((response) => {
      const data = response.data;
      data.forEach((element) => (element.DAC = dacNameMap[element.DAC]));
      // Sort data by DAC column, putting "DAC" first
      const sortedData = data.sort((a, b) => {
        if (a.DAC === "DAC") {
          return -1;
        } else if (b.DAC === "DAC") {
          return 1;
        } else {
          return 0;
        }
      });
      setDacData(sortedData);
    });
  }, []);

  const dacColumns = [
    {
      title: "DAC",
      dataIndex: "DAC",
    },
    {
      title: "Total",
      dataIndex: "Active Leaks - Total",
    },
    {
      title: "Code 2",
      dataIndex: "Code 2",
    },
    {
      title: "Code 3 PL",
      dataIndex: "Code 3 PL",
    },
    {
      title: "Code 3 ST",
      dataIndex: "Code 3 ST",
    },
    // {
    //     title: 'BG Haz',
    //     dataIndex: 'BG Hazardous'
    // },
    {
      title: "AG Non-Haz",
      dataIndex: "AG Non-Hazardous",
    },
    {
      title: "AG Haz",
      dataIndex: "AG Hazardous",
    },
  ];

  const mapPanelTitleStyle = {
    textAlign: "left",
  };

  return (
    <MapPanel
      style={mapPanelTitleStyle}
      title={<CustomTitleComponent />}
      showDividerLine={false}
      defaultState={showSimpleAnalytics}
      openOrClose={showSimpleAnalytics}
      handleOpenOrClose={(show) => handleTableOpen(show)}
      tooltipText={tooltipInfo}
    >
      <NumberedTable
        showNumbers={true}
        dataSource={dacData}
        columns={dacColumns}
        style={{ textAlign: 'left' }}
            />
        </MapPanel>
    )
} 
