import { createSelector } from "@reduxjs/toolkit";

const selectApps = state => state.apps;

export const selectActiveApp = createSelector(
    selectApps,
    state => state.activeApp
);

export const selectActiveMenuTab = createSelector(
    selectApps,
    state => state.activeMenuTab
);

export const selectActiveEnv = createSelector(
    selectApps,
    state => state.activeEnv
)