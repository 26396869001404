import React from 'react';
import styled from 'styled-components'

const Line = styled.div`
  margin: ${({ top, right }) => `${top}px ${right}px`};
  border: 0.5px solid ${({ color }) => `${color}`};
  opacity: 20%;
`

const DelimiterLine = ({top = 12, right = 0, color = 'white', style}) => <Line top={top} right={right} color={color} style={style}/>;

export default DelimiterLine;
