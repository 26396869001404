import CalEnviroLayer from '../../../mapbox/layers/CalEnviroLayer'
import { TribalLandsLayer, EventDelegator, HydrogenFuelStationsLayer, MapPopup } from '@innovation-toolkit/mapbox';
import { AngelesLinkSegmentBLayer } from './Angeles_Link_Segment_B';
import { AngelesLinkSegmentCLayer } from './Angeles_Link_Segment_C';
import { BirdSpeciesOfSpecialConcernLayer } from './Bird_Species_of_Special_Concern';
import { SensitiveSitesLayer } from './Sensitive_Sites';
import { LandSurfaceEstateLayer } from '../../AbatementOptimization/layers/BLM/Land_Surface_Estate';
import { RailwayLayer } from '../../AbatementOptimization/layers/Railway';
import { BridgeLayer } from '../../AbatementOptimization/layers/Bridge';
import { CNDDBAllLayer } from '../../AbatementOptimization/layers/CNDDB';
import { EagleAwarenessLayer } from '../../AbatementOptimization/layers/EagleAwareness';
import { AsbestosLayer } from '../../AbatementOptimization/layers/AsbestosLayer';
import { EnvirostarLayer } from '../../AbatementOptimization/layers/EnvirostarLayer';
import { AsphaltTestingLayer } from '../../AbatementOptimization/layers/AsphaltTestingLayer';
import { NFABLayer } from '../../AbatementOptimization/layers/NationalForestAdministrativeBoundaries';
import { NHSLayer } from '../../AbatementOptimization/layers/NationalHighwaySystem';
import { VegetationLayer } from '../../AbatementOptimization/layers/Vegetation';
import { LUFTsLayer } from '../../AbatementOptimization/layers/LUFTsLayer';
import { RecentEarthquakeLayer } from './RecentEarthquakeLayer';
import { AngelesLinkNoGoLayer } from './NoGoLayer';
import { EnergyJusticeDOELayer } from './EnergyJusticeDOE';
import { useEffect, useState } from 'react';
import { useMap } from 'react-map-gl';
import { MapPopupContents } from '@innovation-toolkit/ui';

export const AngelesLinkMapLayers = () => {
    return (
        <>
            <EventDelegator layerIds={[]}/>
            <AngelesLinkSegmentBLayer showLayer/>
            <AngelesLinkSegmentCLayer showLayer/>
            <BirdSpeciesOfSpecialConcernLayer showLayer/>
            <SensitiveSitesLayer showLayer/>
            
            <LandSurfaceEstateLayer/>
            <BridgeLayer/>
            <CNDDBAllLayer/>
            <EagleAwarenessLayer/>
            <AsbestosLayer />
            <EnvirostarLayer />
            <LUFTsLayer/>
            <AsphaltTestingLayer/>
            <NFABLayer/>
            <NHSLayer/>
            <RailwayLayer/>
            <VegetationLayer/>

            <CalEnviroLayer tractsSelectable={false} toggleToHighlight={false}/>
            <TribalLandsLayer showLayer={false}/>

            <RecentEarthquakeLayer showLayer={true}/>
            <AngelesLinkNoGoLayer showLayer={true}/>
            <EnergyJusticeDOELayer showLayer={true} />

            {/* <HydrogenFuelStationsLayer showLayer={false}/> */}
        </>
    )
}