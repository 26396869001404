import { Layer, Source } from "react-map-gl"
import { APIGateways } from "@config/config"
import { useEffect, useState } from "react";
import { selectBeforeIDs, isActiveLayer, LegendItem, selectIsLayerActive, useActivateMapLayer, useMapEvent } from "@innovation-toolkit/mapbox";
import { useSelector } from "react-redux";
import { DOTPopup } from "./popup";
import axios from "axios";
import { InfoIcon } from "assets/icons/InfoIcon";

export const DOT_LINE_LAYER = 'DOT_LINE_LAYER';
export const DOT_FILL_LAYER = 'DOT_FILL_LAYER';

export const DOT_LINE_SOURCE = 'DOT_LINE_SOURCE';

export const DOTLayerMetadata = {
    layerId: DOT_LINE_LAYER,
    name: 'DOT DAC',
    color: '#ffbb00'
}

const fetchDOTData = (callBack, filterOptions) => {

    const url = `${APIGateways.scgcore}/data/dot`;

    const filter = {}

    axios.get(url, filter)
        .then((response) => {
            // Returns as a presigned url, fetch the data here
            return fetch(response.data)
                .then((fetchResponse) => fetchResponse.json())
                .then((data) => {
                    callBack(data, false)
                })
        })
        .catch((err) => {
            callBack(null, true)
        })
}

export const DOTLayer = ({showLayer=false}) => {

    const [showDOTLayer] = useActivateMapLayer(DOTLayerMetadata, showLayer)
    const beforeIDs = useSelector(selectBeforeIDs)
    
    const [showPopup, setShowPopup] = useState(false)
    const [popupFeature, setPopupFeature] = useState(null);
    const [lngLat, setLatLng] = useState(null);
    const [target, setTarget] = useState(null);

    const [data, setData] = useState(null);

    useEffect(() => {
        fetchDOTData((data, isError) => {
            if (!isError) {
                setData(data)
            } 
        })
    }, [])

    useMapEvent('click', DOT_FILL_LAYER, (e) => {
        if (!isActiveLayer(e, DOT_FILL_LAYER)) return;

        setShowPopup(true);
        setPopupFeature(e.features[0]);
        setLatLng(e.lngLat);
        setTarget(e.target);
    })

    const sourceConfig = {
        id: DOT_LINE_SOURCE,
        type: "geojson",
        data: data
    }


    const fillConfig = {
        id: DOT_FILL_LAYER,
        type: 'fill',
        source: DOT_LINE_SOURCE,
        layout: {
            visibility: showDOTLayer ? 'visible' : 'none'
        },
        paint: {
            'fill-opacity': 0.5,
            'fill-color': [
                'case',
                ['==', ['get', 'OverallDis'], 1], '#ffbb00',
                '#d1d1d1'
            ]
        },
        minzoom: 11,
        beforeId: beforeIDs.line
    }

    const lineConfig = {
        id: DOT_LINE_LAYER,
        type: 'line',
        source: DOT_LINE_SOURCE,
        minzoom: 11,
        beforeId: beforeIDs.line,
        layout: {
            visibility: showDOTLayer ? 'visible' : 'none'
        },
        paint: {
            'line-color': '#8A8A8A',
        }
    }

    return (
        <Source {...sourceConfig}>
            <Layer {...lineConfig} />
            <Layer {...fillConfig}/>
            <DOTPopup 
                isOpen={showPopup}
                onClose={() => setShowPopup(false)}
                lngLat={lngLat}
                feature={popupFeature}
                target={target}
            />
        </Source>
    )
}

export const DOTLegendSection = () => {
    const showLegend = useSelector((state) => selectIsLayerActive(state, DOTLayerMetadata.layerId))

    if (!showLegend) {
        return <></>
    }

    return (
        <>
            <LegendItem color={DOTLayerMetadata.color} title={DOTLayerMetadata.name} small={true}/>
            <div style={{display: 'flex', gap: '10px', alignItems: 'center', marginBottom: '8px', color: '#838383'}}>
                <InfoIcon/> 
                <div className='subtitle'>This layer is “Zoom Dependent”. Zoom in if you are not seeing any results.</div>
            </div>
        </>
    )
}

