import { MapPopup } from '@innovation-toolkit/mapbox';
import { MapPopupContents } from '@innovation-toolkit/ui';
import { useState, useEffect } from 'react';

export const DOTPopup = ({isOpen, onClose, lngLat, feature, target}) => {
    const [popupState, setPopupState] = useState({
        lng: 0,
        lat: 0,
        children: null
    })

    const  [data, setData] = useState({})


    useEffect(() => {

        if (lngLat === null || feature === null) return
        const popupFeature = feature.properties

        let description = ''
        setData(popupFeature);
     
        
        setPopupState({
            lng: lngLat.lng,
            lat: lngLat.lat,
            children: description
        })

    }, [isOpen, lngLat, feature])



    return isOpen ?
                <MapPopup longitude={popupState.lng} latitude={popupState.lat} onClose={onClose} className='StreetImprovementPopup'>
                    <MapPopupContents
                        title='Transportation Disadvantaged Census Tracts (Historically Disadvantaged Communities)'
                        footNote='Source: U.S. Department of Transportation'
                        data={data}
                    />
                </MapPopup>
                : null
}