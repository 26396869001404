import { useDispatch, useSelector } from "react-redux"
import { VegetationLayerMetadata } from "."
import { addActiveLayer, removeActiveLayer, selectIsLayerActive } from "@innovation-toolkit/mapbox"
import { LayerToggle, LayerToggleIcon, LayerToggleIconChild } from '@innovation-toolkit/ui';
import { Plant } from "assets/icons/plant";

export const VegetationLayerToggle = () => {
    const VEGETATION_LAYER = VegetationLayerMetadata.layerId
    const dispatch = useDispatch()
    const shouldShowVegetationLayer = useSelector((state) => selectIsLayerActive(state, VEGETATION_LAYER))

    const handleClick = (shouldShow) => {
        if (!shouldShow) {
            dispatch(removeActiveLayer(VEGETATION_LAYER))
        } else {
            dispatch(addActiveLayer(VEGETATION_LAYER))
        }
    }

    return (
        <LayerToggle
            title='Vegetation'
            componentIcon={Plant}
            onChange={handleClick}
            currentState={shouldShowVegetationLayer}
            children={<LayerToggleIconChild 
                Icon={<LayerToggleIcon 
                    type="dashed-polygon" 
                    fillColor={VegetationLayerMetadata.color} 
                    outlineColor="#ffffff" 
                    strokeColor="#031627"
                />} 
                title='Vegetation'
            />}
        />
    )
}