import { DataTable, InfinityTable, NoDataInfo, Typography } from '@innovation-toolkit/ui';
import { useDispatch, useSelector } from 'react-redux';
import styled from "styled-components";
import { selectFleetDecarbonizationTable, setFleetDecarbonizationTable } from 'redux/fleetDecarbonization/slice';
import { useEffect, useState } from 'react';
import { CloseSmallIcon } from '../../../../../shared/style/iconography/close_small';
import { Row, Col, Spin } from 'antd';
import { DelimiterLine } from '../../../../../shared';
import './FleetImpactTable.css';
import strings from "utils/strings"
import axios from 'axios'
import { APIGateways } from 'config/config';

const FleetWrapper = styled.div`
    display: ${({ fleetImpact }) => fleetImpact ? "block" : 'none'};
    z-index: 60;
`
const Title = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 0;

    background:rgba(255, 255, 255, 0.1);
    border-radius: 4px;
    height: 48px;
    opacity: 1;
    width: ${ ({ width }) => width };
`
const StyledCloseButton = styled.div`
    cursor: pointer;
    position: absolute;
    top: 4px;
    right: 11px;
`

export const mockEmissionsData = [
    {
        movingData: 25213,
        idlingData: 13241,
    }
]

const CloseButton = ({ icon, onClick }) => {
    return <StyledCloseButton data-testid="styled-close-button" onClick={onClick}>{icon}</StyledCloseButton>
};

const fetchImpactTableData = (callBack, filterOptions) => {

    const url = `${APIGateways.legacy}/scg_fleet/fleet_impact_table`;

    const filter = {}

    axios.post(url, filter)
        .then((response) => {
            return callBack(response.data)
        })
        .catch((err) => {
            callBack(null, true)
        })
}


export const FleetImpactTable = () => {
    const dispatch = useDispatch()
    const {fleetTable} = useSelector(selectFleetDecarbonizationTable)
    const [emissionsData, setEmissionsData] = useState([])
    const [emissionPercentages, setEmissionsPercentages] = useState({
        movingPercent: 0,
        idlingPercent: 0
    })
    const [loading, setLoading] = useState(true)


    const handleClose = () => {
        dispatch(setFleetDecarbonizationTable({fleet: false}))
    }

    useEffect(()=>{
        fetchImpactTableData((data, isError) => {
            if (!isError) {
                setEmissionsData(data)
            } 
        })
    }, [])

    useEffect(()=> {
        if(emissionsData.length === 0){
            return
        }
        setLoading(false)

    }, [emissionsData])


    useEffect(()=>{
        if(emissionsData.length === 0) {
            return
        }

        const { driving_co2_pounds, idling_co2_pounds } = emissionsData
        const sum = driving_co2_pounds + idling_co2_pounds

        setEmissionsPercentages({
            movingPercent : Math.round(driving_co2_pounds / sum * 100),
            idlingPercent : Math.round(idling_co2_pounds / sum * 100)
        })

    }, [emissionsData])

    const TableTitles = ({ children, width, padding}) => {

    return (
        <Title width={width} padding={padding}>
        {children}
        </Title>
    )
    }

    // will update this once filter is available
    const TitleComponent = <h4>
        {strings.components.fleetImpactTable.title}
        {/* {selectedVehicles && strings.formatString(strings.components.fleetImpact.vehiclesTitle, { count: Object.keys(selectedVehicles)?.length })}
        {selectedCommunities && strings.formatString(strings.components.fleetImpact.communitiesTitle, { count: selectedCommunities?.length })}
        {(selectedDates.startDate && selectedDates.endDate) && strings.formatString(strings.components.fleetImpact.dateTitle, {
            start: selectedDates?.startDate,
            end: selectedDates?.endDate
        })} */}
    </h4>

    return (
        <FleetWrapper fleetImpact={fleetTable}>

            <div className="impact-wrapper">
                <div className="title-wrapper">
                    <TableTitles styled={{margin: '0px'}}>
                        {TitleComponent}
                    </TableTitles>
                    <CloseButton
                        onClick={handleClose}
                        icon={<CloseSmallIcon />}>
                    </CloseButton>
                </div>
                <Row className='categories' justify="center" style={{height: '50px', alignItems: 'center'}}>
                    <Col span={7}><h4>Emissions</h4></Col>
                    <Col span={7}><h4>CO2 Moving (Pounds)</h4></Col>
                    <Col span={7}><h4>CO2 Idling (Pounds)</h4></Col>
                </Row>
            <DelimiterLine style={{width: '80%', position: 'absolute', margin: '0px 10px', left: '70px'}}/>
                <Row className='values' justify="center">
                    <Col span={7} className="body-primary-text">Total Output</Col>
                    <Col span={7} className="body-primary-text">
                        <Spin spinning={loading}>
                        <div data-testid="movingPoundsSpinner">
                            {emissionsData.driving_co2_pounds ?
                                Math.round(emissionsData.driving_co2_pounds)?.toLocaleString('en-US') :
                                0
                            } ({emissionPercentages.movingPercent}%)
                        </div>
                        </Spin>
                    </Col>
                    <Col span={7} className="body-primary-text">
                        <Spin spinning={loading}>
                            <div data-testid="idlingPoundsSpinner">
                            {emissionsData.idling_co2_pounds ?
                                Math.round(emissionsData.idling_co2_pounds)?.toLocaleString('en-US') :
                                0
                            } ({emissionPercentages.idlingPercent}%)
                            </div>
                        </Spin>
                    </Col>
                </Row>
                <DelimiterLine style={{margin: '0px 10px', position: 'absolute', width: '80%', left: '70px'}}/>
            </div>

        </FleetWrapper>
    )
}