import {SET_LAST_CALL, SET_LAST_CALL_DATE} from "../actionTypes";

const initialState = {
    timeFrame: {
        startDate: null,
        endDate: null
    },
    censusTracts: [],
    communities: [],
    fleet: {
        vehicles: null,
        electrics: null
    },
    swapTable: {
        request: true,
        lastRequest: null
    },
    replaceTable: {
        request: true,
        lastRequest: null
    }
};

const lastCall = (state = initialState, {type, payload}) => {
    switch (type) {
        case SET_LAST_CALL:{
            return {...payload}
        }
        case SET_LAST_CALL_DATE: {
            return {...state, timeFrame: payload}
        }
        default:
            return state

    }
}

export default lastCall