import { Layer, Source } from "react-map-gl"
import { APIGateways } from "@config/config";
import { useEffect, useState } from "react";
import { useActivateMapLayer, selectBeforeIDs, getPropertyOnCensusTract } from "@innovation-toolkit/mapbox";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";

export const ENERGY_JUSTICE_DOE_LAYER = 'ENERGY_JUSTICE_DOE_LAYER';
export const ENERGY_JUSTICE_DOE_SOURCE = 'ENERGY_JUSTICE_DOE_SOURCE';

export const EnergyJusticeDOELayerMetadata = {
    layerId: ENERGY_JUSTICE_DOE_LAYER,
    name: 'Angeles Link No-Go',
    color: '#0e591c'
}

const fetchData = (callBack, filterOptions) => {

    const url = `${APIGateways.dtcore}/data/energy-justice-doe`;

    const filter = {}

    axios.get(url, filter)
        .then((response) => {
            // Returns as a presigned url, fetch the data here
            return fetch(response.data)
                .then((fetchResponse) => fetchResponse.json())
                .then((data) => {
                    callBack(data, false)
                })
        })
        .catch((err) => {
            callBack(null, true)
        })
}

export const EnergyJusticeDOELayer = ({showLayer=false}) => {

    const dispatch = useDispatch()
    const [showEnergyJusticeDOE] = useActivateMapLayer(EnergyJusticeDOELayerMetadata, showLayer)
    const beforeIDs = useSelector(selectBeforeIDs)
    const [data, setData] = useState(null);
    const [opacityProperty, setOpacityProperty] = useState(0.5)
    const [mapFilterProperty, setMapFilterProperty] = useState(['has', 'tract'])

    useEffect(() => {
        // dispatch(addToMapLoadingList('Asbestos'))
        fetchData((data, isError) => {
            if (!isError) {
                setData(data)
            } 
            // dispatch(removeFromMapLoadingList('Asbestos'))
        })
    }, []) // Add searchDates to filter on search

    // Update fill-opacity when the data changed
    useEffect(() => {
        const opacityCalc = () => {
            let max = -Infinity
            if (data) {
                for (let i = 0; i < data.length; i++) {
                    max = Math.max(max, data[i].CAIDI);
                }
    
                return [
                    'case',
                    ["==", getPropertyOnCensusTract('DAC or Tribal Designation', data), "Yes"], 0.7,
                    0
                ]
            } else return 0;
        }

        const mapFilter = () => {
            return data ? [
                'in',
                ['get', 'tract'],
                [
                    'literal',
                    data.map(element => element.tract)
                ]]
                : ['literal', true]
        }

        setOpacityProperty(opacityCalc())
        setMapFilterProperty(mapFilter())
    }, [data])

    const sourceConfig = {
        id: ENERGY_JUSTICE_DOE_SOURCE,
        type: 'vector',
        url: 'mapbox://vdzamba.502h6unv'
    };

    const fillConfig = {
        id: ENERGY_JUSTICE_DOE_LAYER,
        type: "fill",
        source: ENERGY_JUSTICE_DOE_SOURCE,
        'source-layer': 'censustractgeometries',
        filter: mapFilterProperty,
        layout: {
            'visibility': showEnergyJusticeDOE ? 'visible' : 'none'
        },
        paint: {
            'fill-opacity': opacityProperty,
            'fill-color': '#0e591c',
        },
        beforeId: beforeIDs.fill
    }

    return (
        <Source {...sourceConfig}>
            <Layer {...fillConfig} />
        </Source>
    )
}