import { DataTable, InfinityTable, Typography, InfoIcon, NoDataInfo } from '@innovation-toolkit/ui';
import React, { useEffect, useState } from 'react';
import { APIGateways } from "@config/config";
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { setValueMetricsTable } from '@redux/streetImprovements/action';
import { selectValueMetricsTable } from '@redux/streetImprovements/selector';
import styled from "styled-components";
import moment from 'moment';
import { Tooltip } from 'antd';

const TableWrapper = styled.div`
    width: 920px;
`;

const TitleWrapper = styled.div`
    display: flex;
    align-items: flex-end;
    justify-content: center;
    flex-direction: row;
    gap: 6px;
`;

const fetchToolUsageTableData = (callBack) => {

    const url = `${APIGateways.streetimprovements}/get_st_imp_metrics`;

    const toolUsageBody = {
        table: 'si_tool_usage',
        return_type: 'json'
    }

    axios.post(url, toolUsageBody)
    .then((response) => {
        callBack(response.data, false)
    })
    .catch((error) => {
        console.error(error)
        callBack(null, true)
    })
}

const fetchSIPlanningTableData = (callBack) => {

    const url = `${APIGateways.streetimprovements}/get_st_imp_metrics`;

    const siPlanningBody = {
        table: 'si_planning',
        return_type: 'json'
    }

    axios.post(url, siPlanningBody)
    .then((response) => {
        callBack(response.data, false)
    })
    .catch((error) => {
        console.error(error)
        callBack(null, true)
    })
}

export const ValueMetricsTable = () => {
    const dispatch = useDispatch()
    const { toolUsage, planning } = useSelector(selectValueMetricsTable)
    const [ toolUsageData, setToolUsageData] = useState(null)
    const [ siPlanningData, setSiPlanningData ] = useState(null)

    useEffect(() => {
        fetchToolUsageTableData((data, isError) => {
            isError
                ? setToolUsageData([])
                : setToolUsageData(data)
        })

        fetchSIPlanningTableData((data, isError) => {
            isError
                ? setSiPlanningData([])
                : setSiPlanningData(data)
        })
    }, [])

    const handleClose = () => {
        dispatch(setValueMetricsTable())
    }

    const toolUsageColumns = [
        {
            title: <Typography variant='Heading 4' text='Month' />,
            dataIndex: 'month',
            defaultSortOrder: 'descend',
            sorter: (a, b) => {
                a = a.month.split("-")
                b = b.month.split("-")
                return new Date(a[1], a[0], 1) - new Date(b[1], b[0], 1)
            },
            render: (text) => moment(new Date(text.split("-")[1], parseInt(text.split("-")[0])) - 1).format('MMM YYYY')
        },
        {
            title: <Typography variant='Heading 4' text='Planner Using Tool' />,
            dataIndex: 'planner_using_tool',
        },
        {
            title: <Tooltip
                        placement='top'
                        title='Number of municipalities with
                                project or moratorium data in the
                                tool'
                        showArrow={false}
                        overlayStyle={{width: '197px'}}>
                            <TitleWrapper>
                                <Typography style={{width: '102px'}} variant='Heading 4' text='Municipalities with Data'/>
                                <InfoIcon/>
                            </TitleWrapper>
                    </Tooltip>,
            dataIndex: 'munic_w_data',
        },
        {
            title: <Tooltip
                        placement='top'
                        title='Number of municipalities with
                                project or moratorium data in the
                                tool'
                        showArrow={false}
                        overlayStyle={{width: '197px'}}>
                            <TitleWrapper>
                                <Typography style={{width: '102px'}} variant='Heading 4' text='Automated Municipalities' />
                                <InfoIcon/>
                            </TitleWrapper>
                    </Tooltip>,
            dataIndex: 'automated_municipalities',
        },
        {
            title: <Tooltip
                        placement='top'
                        title='Number of municipalities with
                                project or moratorium data in the
                                tool'
                        showArrow={false}
                        overlayStyle={{width: '197px'}}>
                            <TitleWrapper>
                                <Typography style={{width: '80px'}} variant='Heading 4' text='Automated Sources' />
                                <InfoIcon/>
                            </TitleWrapper>
                    </Tooltip>,
            dataIndex: 'automated_sources',
        },
        {
            title: <Tooltip
                        placement='top'
                        title='The percentage is equal to the total number of excavation projects (CPD and M&I) within the municipalities / total CPD and M&I work that displays in the SI tool'
                        showArrow={false}
                        overlayStyle={{width: '197px'}}>
                            <TitleWrapper>
                                <Typography variant='Heading 4' text='Total Excavations' />
                                <InfoIcon/>
                            </TitleWrapper>
                    </Tooltip>,
            dataIndex: 'excavation_metric',
            render: (value) => {
                return `${Number(value).toLocaleString('us', {minimumFractionDigits: 2, maximumFractionDigits: 2})}%`
            }
        },
    ];

    const siPlanningColumns = [
        {
            title: <Typography variant='Heading 4' text='Month' />,
            dataIndex: 'month',
            sorter: (a, b) => {
                a = a.month.split("-")
                b = b.month.split("-")
                return new Date(a[1], a[0], 1) - new Date(b[1], b[0], 1)
            },
            render: (text) => moment(new Date(text.split("-")[1], parseInt(text.split("-")[0])) - 1).format('MMM YYYY')
        },
        {
            title: <Typography variant='Heading 4' text='Overlaps Identified'/>,
            dataIndex: 'overlaps_identified',
        },
        {
            title: <Tooltip
                        placement='top'
                        title='Number of hours planners have
                        saved using this tool '
                        showArrow={false}
                        overlayStyle={{width: '197px'}}>
                            <TitleWrapper>
                                <Typography variant='Heading 4' text='Planner Hours Saved' />
                                <InfoIcon/>
                            </TitleWrapper>
                    </Tooltip>,
            dataIndex: 'planner_hrs_saved',
        }
    ];

    const tabs = [
        {
            title: "Tool Usage",
            contentSections: [
                {
                    content: <TableWrapper style={{width: '800px'}}>
                                { toolUsageData && toolUsageData.length === 0
                                    ? <NoDataInfo title='No data found'/>
                                    : <InfinityTable columns={toolUsageColumns} dataSource={toolUsageData} scrollAreaHeight={224}/>
                                }
                            </TableWrapper>
                }
            ]
        },

        // Hide Planner tab - User Story 549540 (May 19th 2023)
        // `${import.meta.env.APP_ENV}` === 'dev' &&
        // {
        //     title: "Planning",
        //     contentSections: [
        //         {
        //             content: <TableWrapper>
        //                         { siPlanningData && siPlanningData.length === 0
        //                             ? <NoDataInfo title='No data found'/>
        //                             : <InfinityTable columns={siPlanningColumns} dataSource={siPlanningData} scrollAreaHeight={224}/>
        //                         }
        //                     </TableWrapper>
        //         }
        //     ]
        // }
    ]
    return (
        <DataTable
            handleClose={handleClose}
            open={toolUsage || planning}
            tabs={tabs}
        />
    )
}