
export const APP_ENV = import.meta.env.REACT_APP_SCG_APP_ENV;
export const AWS_REGION = import.meta.env.REACT_APP_SCG_AWS_REGION;
export const AWS_COGNITO_IDENTITY_POOL_ID = import.meta.env.REACT_APP_SCG_AWS_COGNITO_IDENTITY_POOL_ID;
export const AWS_COGNITO_USER_POOL_ID = import.meta.env.REACT_APP_SCG_AWS_COGNITO_USER_POOL_ID;
export const AWS_COGNITO_CLIENT_ID = import.meta.env.REACT_APP_SCG_AWS_COGNITO_CLIENT_ID;
export const AWS_COGNITO_CLIENT_DOMAIN_NAME = import.meta.env.REACT_APP_SCG_AWS_COGNITO_CLIENT_DOMAIN_NAME;
export const AWS_COGNITO_IDP_NAME = import.meta.env.REACT_APP_SCG_AWS_COGNITO_IDP_NAME;
export const AWS_COGNITO_IDP_SIGNIN_URL = import.meta.env.REACT_APP_SCG_AWS_COGNITO_IDP_SIGNIN_URL;
export const AWS_COGNITO_IDP_SIGNOUT_URL = import.meta.env.REACT_APP_SCG_AWS_COGNITO_IDP_SIGNOUT_URL;
export const AWS_COGNITO_IDP_OAUTH_CLAIMS = import.meta.env.REACT_APP_SCG_AWS_COGNITO_IDP_OAUTH_CLAIMS.split(' ');
export const AWS_COGNITO_IDP_GRANT_FLOW = import.meta.env.REACT_APP_SCG_AWS_COGNITO_IDP_GRANT_FLOW;
export const APIGateway = `${import.meta.env.REACT_APP_SCG_GATEWAY_API_URL !== undefined
    ? import.meta.env.REACT_APP_SCG_GATEWAY_API_URL
    : 'https://cip-api-dev.sempra.com'
    }`;
export const GATEWAY_WEBSOCKET_URL = `${import.meta.env.REACT_APP_SCG_GATEWAY_WEBSOCKET_URL !== undefined
    ? import.meta.env.REACT_APP_SCG_GATEWAY_WEBSOCKET_URL
    : 'wss://9ag61mqhu2.execute-api.us-west-2.amazonaws.com'
    }/`;
export const LEGACY_WEBSOCKET_URL = import.meta.env.REACT_APP_LEGACY_WEBSOCKET_URL
export const APIGateways = {
    legacy: import.meta.env.REACT_APP_SCG_GATEWAY_API_URL,
    sdgecore: import.meta.env.REACT_APP_SDGECORE_GATEWAY_API_URL,
    charging: import.meta.env.REACT_APP_CHARGING_GATEWAY_API_URL,
    dtcore: import.meta.env.REACT_APP_DTCORE_GATEWAY_API_URL,
    scgcore: import.meta.env.REACT_APP_SCGCORE_GATEWAY_API_URL,
    charitable: import.meta.env.REACT_APP_CHARITABLE_GATEWAY_API_URL,
    citywork: import.meta.env.REACT_APP_CITYWORK_GATEWAY_API_URL,
    leakabatement: import.meta.env.REACT_APP_LEAKABATEMENT_GATEWAY_API_URL,
    streetimprovements: import.meta.env.REACT_APP_STREETIMPROVEMENTS_GATEWAY_API_URL
}
export const AWS_RUM_APPLICATION_ID = import.meta.env.REACT_APP_RUM_APPLICATION_ID;
export const AWS_RUM_COGNITO_IDENTITY_POOL_ID = import.meta.env.REACT_APP_RUM_COGNITO_IDENTITY_POOL_ID;