import React, { useCallback, useEffect, useState } from "react"
import moment from "moment"
import styled from "styled-components"
import strings from "shared/utils/strings"
import { Calendar } from "@innovation-toolkit/ui"
import { HeaderContent } from "shared/components/content"
// import { FilterActionButtons } from "shared/components/buttons"
import { FilterActionButtons, theme } from "@innovation-toolkit/ui"
import { QuickDateFilters } from "shared/components/filters"
import { FilterWrapper } from 'shared/components/filterWrapper';

const START = "start"
const END = "end"

const InputWrapper = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;

    input {
        max-width: 35%;
        width: auto;
        background: inherit;
        border: 0;
        color: white;
    }

    input::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: grey;
        opacity: 1; /* Firefox */
    }

    h3 {
        padding: 8px;
    }
`

const TimeFrameFilter = ({
    defaultStartDate,
    defaultEndDate,
    defaultMonth,
    datePills,
    selectionDirection,
    footNote = '',
    customYears,
    selectedYear,
    closeTabs = () => {},
    onStartDateSelected = () => {},
    onEndDateSelected = () => {},
    onReset = () => {},
    onClose = () => {},
}) => {
    const [startDate, setStart] = useState(defaultStartDate || null)
    const [endDate, setEnd] = useState(defaultEndDate || null)
    const [selectedRange, setSelectedRange] = useState([null, null])
    const [selectDateType, setSelectDateType] = useState(START)
    const [confirmDisabled, setConfirmDisabled] = useState(true)

    useEffect(() => {
        setStart(defaultStartDate || "")
        setEnd(defaultEndDate || "")
        setSelectedRange([defaultStartDate, defaultEndDate])
        !defaultStartDate && !defaultEndDate && setSelectDateType(START)
    }, [
        setSelectedRange,
        defaultStartDate,
        defaultEndDate,
        setStart,
        setEnd,
        setSelectDateType,
    ])

    const [endCalendarDate, setEndCalendarDate] = useState(null)

    //temporary
    const formatDate = (date) => {
        const d = new Date(date),
            month = "" + (d.getMonth() + 1),
            day = "" + d.getDate(),
            year = d.getFullYear()

        return [year, month, day].join("-")
    }
    const newStartCalendarDate = startDate !== null && formatDate(startDate)
    //temporary wait for data
    const [startCalendarDate, setStartCalendarDate] = useState(
        startDate === null ? defaultMonth : moment(newStartCalendarDate)
    )

    useEffect(() => {
        if (
            startDate &&
            endDate &&
            (startDate !== defaultStartDate || endDate !== defaultEndDate)
        ) {
            setConfirmDisabled(false)
        } else {
            setConfirmDisabled(true)
        }
        setSelectedRange([startDate, endDate])
    }, [startDate, endDate, setSelectedRange])

    const handleDateRange = useCallback(
        (value) => {
            switch (selectDateType) {
                case START:
                    setSelectDateType(END)
                    setStart(moment(value).format("MMM DD, YYYY"))
                    break
                case END:
                    moment(value.format("MMM DD, YYYY")).isAfter(
                        startDate,
                        "day"
                    ) && setEnd(moment(value).format("MMM DD, YYYY"))
                    break
                default:
                    break
            }
        },
        [setStart, setEnd, startDate, selectDateType]
    )

    const handleDateType = useCallback(
        (value) => {
            setSelectDateType(value.target.id)
        },
        [setSelectDateType]
    )

    const handleConfirm = useCallback(() => {
        if (startDate && endDate) {
            onStartDateSelected(startDate)
            onEndDateSelected(endDate)
            closeTabs()
            onClose()
        }
    }, [startDate, endDate])

    const handleClearSelection = useCallback(() => {
        onReset()
        setEndCalendarDate(null)
        setSelectDateType(START)
        setStart(null)
        setEnd(null)
    }, [setStart, setEnd, setEndCalendarDate, setSelectDateType])

    useEffect(() => {
        if (
            moment(selectedRange[0]).month() ===
                moment(selectedRange[1]).month() &&
            moment(selectedRange[0]).year() === moment(selectedRange[1]).year()
        ) {
            setStartCalendarDate(moment(selectedRange[0]))
            setEndCalendarDate(false)
        } else if (selectedRange[1] !== null && selectedRange[1] !== "") {
            setStartCalendarDate(moment(selectedRange[0]))
            setEndCalendarDate(moment(selectedRange[1]))
        }
    }, [selectedRange, setEndCalendarDate])

    const handleStartEndCalendarDate = useCallback(
        (date, id) => {
            switch (selectDateType) {
                case START:
                    return id === START && setStartCalendarDate(date)
                case END:
                    if (id === END) {
                        return setEndCalendarDate(date)
                    } else if (!endDate) {
                        return setEndCalendarDate(date)
                    } else if (
                        moment(startDate).month() === moment(endDate).month() &&
                        !endCalendarDate
                    ) {
                        return setEndCalendarDate(date)
                    }
                    break
                default:
                    return
            }
        },
        [
            setStartCalendarDate,
            setEndCalendarDate,
            selectDateType,
            moment,
            endCalendarDate,
        ]
    )

    const handleQuickFilters = ([start, end]) => {
        const formattedStart = moment(start).format("MMM DD, YYYY")
        const formattedEnd = moment(end).format("MMM DD, YYYY")

        // Setting start/end changes the calendar title. Ex: "Feb 01, 2021 - Mar 17, 2021"
        setStart(formattedStart)
        setEnd(formattedEnd)

        // Setting selectedRange updates dates inside of calendar
        setSelectedRange([formattedStart, formattedEnd])
    }

    return (
        <HeaderContent title={strings.timeFrame.title} closeTabs={closeTabs}>
            <FilterWrapper>
                <InputWrapper>
                    <input
                        type="text"
                        placeholder={strings.timeFrame.startPlaceholder}
                        defaultValue={startDate}
                        onClick={handleDateType}
                        id={START}
                    />
                    <h3 contentColor={theme.rainyDay}>-</h3>
                    <input
                        type="text"
                        placeholder={strings.timeFrame.endPlaceholder}
                        defaultValue={endDate}
                        onClick={handleDateType}
                        id={END}
                    />
                </InputWrapper>

                <QuickDateFilters
                    setRange={handleQuickFilters}
                    selectedRange={selectedRange}
                    datePills={datePills}
                    selectionDirection={selectionDirection}
                ></QuickDateFilters>

                <Calendar
                    id={START}
                    select={handleDateRange}
                    value={startDate}
                    startDate={startCalendarDate}
                    changeDate={handleStartEndCalendarDate}
                    selectedRange={selectedRange}
                    customYears={customYears}
                    selectedYear={selectedYear}
                />
                {endCalendarDate && (
                    <Calendar
                        id={END}
                        select={handleDateRange}
                        startDate={endCalendarDate}
                        changeDate={handleStartEndCalendarDate}
                        selectedRange={selectedRange}
                        customYears={customYears}
                        selectedYear={selectedYear}
                    />
                )}
            </FilterWrapper>
            <FilterActionButtons
                footNote={footNote}
                onConfirm={handleConfirm}
                isConfirmDisabled={confirmDisabled}
                onClear={handleClearSelection}
                isClearDisabled={!(selectedRange[0] || selectedRange[1])}
            />
        </HeaderContent>
    )
}

export default TimeFrameFilter
