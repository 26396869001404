import { Layer, Source } from "react-map-gl"
import { APIGateways } from "@config/config";
import { useEffect, useState } from "react";
import { useActivateMapLayer, selectBeforeIDs, useMapEvent } from "@innovation-toolkit/mapbox";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";

export const RECENT_EARTHQUAKE_LAYER = 'RECENT_EARTHQUAKE_LAYER';
export const RECENT_EARTHQUAKE_SOURCE = 'RECENT_EARTHQUAKE_SOURCE';

export const RECENT_EARTHQUAKE_LayerMetadata = {
    layerId: RECENT_EARTHQUAKE_LAYER,
    name: 'Recent Earthquake Layer',
    color: 'ffffff'
}

const MILLISECONDS_IN_HOUR = 1000 * 60 * 60
const MILLISECONDS_IN_DAY = MILLISECONDS_IN_HOUR * 24
const MILLISECONDS_IN_WEEK = MILLISECONDS_IN_DAY * 7

const fetchData = (callBack, filterOptions) => {

    const url = `https://www.scsn.org/wp-content/data/pbEqMarkers.json`;

    return fetch(url)
        .then((fetchResponse) => fetchResponse.json())
        .then((data) => {
            callBack(data, false)
        })
        .catch((err) => {
            callBack(null, true)
        })
}

const fetchFaultData = (callBack, filterOptions) => {

    const url = `https://www.scsn.org/wp-content/data/ca_faults.json`;

    return fetch(url)
        .then((fetchResponse) => fetchResponse.json())
        .then((data) => {
            callBack(data, false)
        })
        .catch((err) => {
            callBack(null, true)
        })
}


/**
 * This layer is taken from this site https://www.scsn.org/
 * 
 */

export const RecentEarthquakeLayer = ({showLayer=false}) => {

    const dispatch = useDispatch()
    const [showRecentEarthquake] = useActivateMapLayer(RECENT_EARTHQUAKE_LayerMetadata, showLayer)
    const beforeIDs = useSelector(selectBeforeIDs)
    const [data, setData] = useState(null);
    const [faultData, setFaultData] = useState(null);

    useEffect(() => {
        // dispatch(addToMapLoadingList('Asbestos'))
        fetchData((data, isError) => {
            if (!isError) {
                setData(data)
            } 
            // dispatch(removeFromMapLoadingList('Asbestos'))
        })

        fetchFaultData((faultData, isError) => {
            if (!isError) {
                setFaultData(faultData)
            }
        })
    }, []) // Add searchDates to filter on search

    const sourceConfig = {
        id: RECENT_EARTHQUAKE_SOURCE,
        type: "geojson",
        data: data
    }

    const lineConfig = {
        id: RECENT_EARTHQUAKE_LAYER,
        type: "circle",
        source: RECENT_EARTHQUAKE_SOURCE,
        layout: {
            'visibility': showRecentEarthquake ? 'visible': 'none'
        },
        paint: {
            'circle-radius': 
            [
                'interpolate',
                ['linear'],

                    ['to-number', ['get', 'mag']],
                    1, 4,
                    7, 30 

            ],
            'circle-color': [
                'case',
                ['<', ['-', new Date().getTime(), ['get', 'time']], MILLISECONDS_IN_HOUR], '#F16B64',
                ['<', ['-', new Date().getTime(), ['get', 'time']], MILLISECONDS_IN_DAY], '#5455EF',
                ['<', ['-', new Date().getTime(), ['get', 'time']], MILLISECONDS_IN_WEEK], '#EDED76',
                '#D3D3D3'
            ],
            'circle-opacity': 0.9,
            'circle-stroke-color': 'black',
            'circle-stroke-width': 1
        },
        beforeId: beforeIDs.circle
    }

    const faultSourceConfig = {
        id: RECENT_EARTHQUAKE_SOURCE + "_fault",
        type: 'geojson',
        data: faultData
    }

    const faultLineConfig = {
        id: RECENT_EARTHQUAKE_LAYER + "_fault",
        type: "line",
        source: RECENT_EARTHQUAKE_SOURCE + "_fault",
        layout: {
            'visibility': showRecentEarthquake ? 'visible': 'none'
        },
        paint:{
            'line-color': '#676767'
        },
        beforeId: beforeIDs.line
    }

    return (
        <>
        <Source {...sourceConfig}>
            <Layer {...lineConfig} />
        </Source>
        <Source {...faultSourceConfig}>
            <Layer {...faultLineConfig} />
        </Source>
        </>
    )
}