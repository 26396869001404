import { Layer, Source, useMap } from "react-map-gl"
import { APIGateways } from "@config/config"
import { useEffect, useState } from "react";
import { useActivateMapLayer, useLoadMapImage, selectBeforeIDs, isActiveLayer, zoomInOnCluster, useMapEvent } from "@innovation-toolkit/mapbox";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import routine_survey_icon from './routine_survey_icon.png';
import mandi_m_icon from '../MandIWork/images/MANDI_M.png';
import { SurveyPopup } from "./popup";

export const ROUTINE_SURVEY = 'ROUTINE_SURVEY';

export const RoutineSurveyLayerMetadata = {
    layerId: ROUTINE_SURVEY,
    source: 'ROUTINE_SURVEY_SOURCE',
    name: 'Routine Survey',
    color: '#002762',
    layerSymbols: [
        { symbol: routine_survey_icon, symbolName: 'routine-survey-icon' },
    ]
}

const fetchRoutineSurveys = (callBack, filterOptions) => {

    const url = `${APIGateways.streetimprovements}/get_mandi_data`;

    axios.post(url, {
        routine_surveys: true,
        special_surveys: false,
        excavations: false
    })
        .then((response) => {
            return fetch(response.data)
            .then(fetchResponse => fetchResponse.json())
            .then(data => {
                callBack(data, false)
            })  
        })
        .catch((err) => {
            callBack(null, true)
        })
}

export const RoutineSurveyLayer = ({showLayer=true}) => {

    const dispatch = useDispatch()
    const { current: map } = useMap();

    const [showRoutineSurvey] = useActivateMapLayer(RoutineSurveyLayerMetadata, showLayer);
    const beforeIDs = useSelector(selectBeforeIDs)
 
    const [routineSurveyData, setRoutineSurveyData] = useState(null);

    useEffect(() => {
        fetchRoutineSurveys((data, isError) => {
            if (!isError) {
                setRoutineSurveyData(data)
            } 
        })
    }, []) // Add searchItems to dependencies to enable searching

    useLoadMapImage('routine-survey-icon', routine_survey_icon)
    useLoadMapImage('mandi-m-icon', mandi_m_icon)

    const [showPopup, setShowPopup] = useState(false)
    const [popupFeature, setPopupFeature] = useState(null);
    const [lngLat, setLatLng] = useState(null);
    const [target, setTarget] = useState(null);
    

    useMapEvent('click', RoutineSurveyLayerMetadata.layerId, (e) => {
        if (!isActiveLayer(e, RoutineSurveyLayerMetadata.layerId)) return;
        const feature = e.features[0];        

        if (feature.properties.cluster) {
            zoomInOnCluster(map, RoutineSurveyLayerMetadata.source, e.features)
    
            return
        }

        setShowPopup(true);
        setPopupFeature(feature);
        setLatLng(e.lngLat);
        setTarget(e.target);
    })

    const sourceConfig = {
        id: RoutineSurveyLayerMetadata.source,
        type: "geojson",
        data: routineSurveyData,
        cluster: true,
        clusterMaxZoom: 12,
        clusterRadius: 60,
    }

    // Pins
    const symbolConfig = {
        id: RoutineSurveyLayerMetadata.layerId,
        type: 'symbol',
        source: RoutineSurveyLayerMetadata.source,
        layout: {
            visibility: showRoutineSurvey ? 'visible' : 'none',
            'icon-image': 'mandi-m-icon',
            // 'icon-image': 'routine-survey-icon',
            'icon-size': 1,
            'icon-allow-overlap': true,
        },
        paint: {
            'icon-translate': [0,-27],
        },
        beforeId: beforeIDs.symbol
    };

    return (
        <>
        <Source {...sourceConfig}>
            {/* <Layer {...symbolConfig} /> */}
            <SurveyPopup 
                type="Routine"
                isOpen={showPopup}
                onClose={() => setShowPopup(false)}
                lngLat={lngLat}
                feature={popupFeature}
                target={target}
            />
           
        </Source>
        </>

    )
}
