import React, { useState, useEffect } from 'react'
import styled, { css } from 'styled-components'

const QuickFiltersWrapper = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 0 18px;
    margin-top: 10px;
`;

const QuickFilterButton = styled.button`
    display: flex;
    color: white;
    font-size: 12px;
    border: 2px solid white;
    border-radius: 2px;
    background: transparent;
    justify-content: center;
    align-items: center;
    width: calc((100% / 6) - 6.667px);
    height: 22px;
    cursor: pointer;

    ${props => props.active && css`
        background: #40A9FF;
        border-color: #40A9FF;
    `}

    ${props => props.disabled && css`
        color: #8FA1B1;
        border-color: #8FA1B1;
    `}
`;

const formatDate = (date) => {
    if (!date) {
        return null;
    }
    const d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    return [year, month, day].join('-');
}

const defaultDatePills = [
    { name: '1D', numberOfDays: 1, isDisabled: true },
    { name: '1W', numberOfDays: 7 },
    { name: '1M', numberOfDays: 30 },
    { name: '3M', numberOfDays: 91 },
    { name: '6M', numberOfDays: 183 },
    { name: '1Y', numberOfDays: 365 },
]

const QuickDateFilters = ({selectedRange, setRange, datePills = defaultDatePills, selectionDirection = 'past'}) => {
    const [activeFilter, setActiveFilter] = useState('');
    const [previousQuickFilterRange, setPreviousQuickFilterRange] = useState([null, null]);

    useEffect(() => {
        const [selectedStart, selectedEnd] = selectedRange;
        const [previousStart, previousEnd] = previousQuickFilterRange;
        
        // Set dates to same format so we can compare
        const selectedFormattedRange = [formatDate(selectedStart), formatDate(selectedEnd)];
        const previousFormattedRange = [formatDate(previousStart), formatDate(previousEnd)];
        
        // If newly selected date range doesn't match the previously selected quick filter, remove active
        if (
            selectedFormattedRange[0] !== null &&
            selectedFormattedRange[1] !== null &&
            selectedFormattedRange[0] === previousFormattedRange[0] &&
            selectedFormattedRange[1] === previousFormattedRange[1]
        ) {
            // console.log('keep active');
        } else {
            setActiveFilter('');
        }
    }, [selectedRange])

    const handleQuickFilter = (name, numberOfDays) => {
        let start = new Date();
        let end = new Date();

        selectionDirection === "past"
            ? start.setDate(start.getDate() - numberOfDays) // Set start date n number of days in the past
            : end.setDate(end.getDate() + numberOfDays) // Set end date n number of days in the future
        setActiveFilter(name);

        setPreviousQuickFilterRange([start, end]);
        return [start, end];
    }

    return (
        <QuickFiltersWrapper>
            {datePills.map(({ name, numberOfDays, isDisabled = false }) => {
                return (
                    <QuickFilterButton
                        disabled={isDisabled}
                        active={activeFilter === name}
                        type="button"
                        onClick={() =>
                            setRange(
                                handleQuickFilter(name, numberOfDays)
                            )
                        }
                    >
                        {name}
                    </QuickFilterButton>
                )
            })}
        </QuickFiltersWrapper>
    )
}

export default QuickDateFilters;