import { useDispatch, useSelector } from "react-redux"
import { CNDDBAllLayerMetadata } from "."
import { addActiveLayer, removeActiveLayer, selectIsLayerActive } from "@innovation-toolkit/mapbox"
import { LayerToggle, LayerToggleIcon, LayerToggleIconChild } from '@innovation-toolkit/ui';
import { PlantSearch } from "assets/icons/plantSearch";

export const CNDDBLayerToggle = () => {
    const CNDDB_ALL_LAYER = CNDDBAllLayerMetadata.layerId
    const dispatch = useDispatch()
    const shouldShowCNDDBLayer = useSelector((state) => selectIsLayerActive(state, CNDDB_ALL_LAYER))

    const handleClick = (shouldShow) => {
        if (!shouldShow) {
            dispatch(removeActiveLayer(CNDDB_ALL_LAYER))
        } else {
            dispatch(addActiveLayer(CNDDB_ALL_LAYER))
        }
    }

    return (
        <LayerToggle
            title='CNDDB'
            componentIcon={PlantSearch}
            onChange={handleClick}
            currentState={shouldShowCNDDBLayer}
            children={<LayerToggleIconChild 
                Icon={<LayerToggleIcon 
                    type="polygon-crosshatch" 
                    fillColor={CNDDBAllLayerMetadata.color} 
                    outlineColor="#ffffff" 
                    strokeColor="white"/>} 
                    title="CNDDB"/>}
        />
    )
}