import { useEffect, useState } from "react";
import './community-impact-table.css'
import { APIGateways } from "@config/config";
import axios from "axios";
import { MapPanel } from "@innovation-toolkit/mapbox";

export function getJson() {
    return axios.get(`${APIGateways.leakabatement}/methane/community-impact-table`)
      .then(response => {
          return response.data;
      })
      .catch(error => {
        console.error(error);
      });
  }

const CommunityImpactTable = ({defaultState=false}) => {
    const [data, setData] = useState(null);
    const [total, setTotal] = useState(0);

    useEffect(() => {
        getJson().then(res => {
            // In "res", the following is DAC/NonDAC
            // [1]["True"] = DAC
            // [1]["False"] = NonDAC
            setData(res);
            
            // Manually get sum of leaks for each severity (for grand total)
            setTotal(res.map(item => item["Total"]).reduce((accumulator, currentValue, index) => {
                if (index === 0) {
                    return accumulator;
                }
                return accumulator + currentValue;
            }, 0));
        })
    }, [])

    return (
        <MapPanel title="Distribution of Methane Leaks" defaultState={true}>

        {!!data && <table className="cip-table">
            <tbody>
                <tr className="align-center">
                    <th></th>
                    <th></th>
                    <th colSpan="6" scope="colgroup">BY SEVERITY LEAK CODE</th>
                </tr>
                <tr className="align-center">
                    <th></th>
                    <th>TOTAL</th>
                    <th className="border-left border-top">1</th>
                    <th className="border-top">2</th>
                    <th className="border-top">3</th>
                    <th className="border-top">4</th>
                    <th className="border-top">5</th>
                    <th className="border-right border-top">6</th>
                </tr>
                <tr className="align-center">
                    <th className="align-right">TOTAL LEAKS</th>
                    <td className="alt-bg">{total}</td>
                    {/* <td className="alt-bg">{data[7]["Total"]}</td> */}
                    <td>{data[1]["Total"].toLocaleString()}</td>
                    <td className="alt-bg">{data[2]["Total"].toLocaleString()}</td>
                    <td>{data[3]["Total"].toLocaleString()}</td>
                    <td className="alt-bg">{data[4]["Total"].toLocaleString()}</td>
                    <td>{data[5]["Total"].toLocaleString()}</td>
                    <td className="alt-bg">{data[6]["Total"].toLocaleString()}</td>
                </tr>
                <tr className="align-center">
                    <th className="align-right">DACs</th>
                    <td className="alt-bg">{Math.round(data[7]["True"])}%</td>
                    <td>{Math.round(data[1]["True"])}%</td>
                    <td className="alt-bg">{Math.round(data[2]["True"])}%</td>
                    <td>{Math.round(data[3]["True"])}%</td>
                    <td className="alt-bg">{Math.round(data[4]["True"])}%</td>
                    <td>{Math.round(data[5]["True"])}%</td>
                    <td className="alt-bg">{Math.round(data[6]["True"])}%</td>
                </tr>
                <tr className="align-center">
                    <th className="align-right">NON-DACs</th>
                    <td className="alt-bg">{Math.round(data[7]["False"])}%</td>
                    <td>{Math.round(data[1]["False"])}%</td>
                    <td className="alt-bg">{Math.round(data[2]["False"])}%</td>
                    <td>{Math.round(data[3]["False"])}%</td>
                    <td className="alt-bg">{Math.round(data[4]["False"])}%</td>
                    <td>{Math.round(data[5]["False"])}%</td>
                    <td className="alt-bg">{Math.round(data[6]["False"])}%</td>
                </tr>
                <tr></tr>
            </tbody>
        </table>
        
    }
    
    </MapPanel>
    )
}

export default CommunityImpactTable;