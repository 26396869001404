import { AnalysisSection, AnalysisSectionContainer, Button, Typography } from "@innovation-toolkit/ui"
import ReplacementIcon from '../../../assets/icons/replacement_icon_small.svg?react'
import FleetImpactIcon from '../../../assets/icons/fleet_impact.svg?react'
import ValueMetricsIcon from '../../../assets/icons/value_metrics.svg?react'
import ExportIcon from '../../../assets/icons/export.svg?react'
import strings from "utils/strings"
import { useDispatch, useSelector } from "react-redux"
import { selectFleetDecarbonizationTable, selectValueMetricsTable, selectVehicleReplacementTable, setFleetDecarbonizationTable, setValueMetricsTable, setVehicleReplacementTable } from "redux/fleetDecarbonization/slice"

export const FleetDecarbAnalysis = () => {
    const dispatch = useDispatch()
    const { vehicleReplacementTable } = useSelector(selectVehicleReplacementTable)
    const { fleetTable } = useSelector(selectFleetDecarbonizationTable)
    const valueMetricsTable = useSelector(selectValueMetricsTable)

    return (
        <AnalysisSectionContainer style={{marginTop: '8px'}}>
            <AnalysisSection
                title={'Fleet Impact'}
                Icon={FleetImpactIcon}
                action={(active) => {
                    dispatch(setVehicleReplacementTable({vehicleReplacementTable: false}))
                    dispatch(setFleetDecarbonizationTable({fleetTable: active}))
                    dispatch(setValueMetricsTable(false))
                }}
                defaultState={fleetTable}
                description={strings.components.fleetImpactTable.description}
                children={<Typography variant='Subtitle' text={strings.components.fleetImpactTable.footNote} style={{color: '#8FA1B1'}} />}
            >
            </AnalysisSection>
            <AnalysisSection
                title={'Vehicle Replace'}
                Icon={ReplacementIcon}
                action={(active) => {
                    dispatch(setFleetDecarbonizationTable({fleetTable: false}))
                    dispatch(setValueMetricsTable(false))
                    dispatch(setVehicleReplacementTable({vehicleReplacementTable: active}))
                }}
                defaultState={vehicleReplacementTable}
                description={strings.components.vehicleReplacementTable.description}
                children={<Typography variant='Subtitle' text={strings.components.vehicleReplacementTable.footNote} style={{color: '#8FA1B1'}} />}
            >
                
                { // Would add this once the full table for vehicle replacement is ready
                /* {
                    <Button
                        type='secondary'
                        onClick={() => handleExportCSV(replaceCSVUrl)}
                        title='CSV EXPORT'
                        svgIcon={<ExportIcon />}
                        size='L'
                    />
                } */}
            </AnalysisSection>
            <AnalysisSection
                title={'Value Metrics'}
                Icon={ValueMetricsIcon}
                action={(active) => {
                    dispatch(setValueMetricsTable(active))
                    dispatch(setFleetDecarbonizationTable({fleetTable: false}))
                    dispatch(setVehicleReplacementTable({vehicleReplacementTable: false}))
                }}
                defaultState={valueMetricsTable}
                description={strings.components.valueMetricsTable.description}
                children={<Typography variant='Subtitle' text={strings.components.valueMetricsTable.footNote} style={{color: '#8FA1B1'}} />}
            >
            </AnalysisSection>
        </AnalysisSectionContainer>
    )
}
