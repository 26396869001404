import { Layer, Source } from "react-map-gl"
import { APIGateways } from "@config/config";
import { useEffect, useState } from "react";
import { useActivateMapLayer, selectBeforeIDs, useMapEvent } from "@innovation-toolkit/mapbox";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { selectSelectedBird } from "redux/angelesLinks";

export const BIRD_SPECIES_OF_SPECIAL_CONCERN_LAYER = 'BIRD_SPECIES_OF_SPECIAL_CONCERN_LAYER';
export const BIRD_SPECIES_OF_SPECIAL_CONCERN_SOURCE = 'BIRD_SPECIES_OF_SPECIAL_CONCERN_SOURCE';

export const Bird_Species_of_Special_Concern_LayerMetadata = {
    layerId: BIRD_SPECIES_OF_SPECIAL_CONCERN_LAYER,
    name: 'Bird Species of Special Concern',
    color: 'yellow'
}

const fetchData = (callBack, filterOptions) => {

    const url = `${APIGateways.scgcore}/data/Bird_Species_of_Special_Concern`;

    const filter = {}

    axios.get(url, filter)
        .then((response) => {
            // Returns as a presigned url, fetch the data here
            return fetch(response.data)
                .then((fetchResponse) => fetchResponse.json())
                .then((data) => {
                    callBack(data, false)
                })
        })
        .catch((err) => {
            callBack(null, true)
        })
}

export const BirdSpeciesOfSpecialConcernLayer = ({showLayer=false}) => {

    const dispatch = useDispatch()
    const [showBird_Species_of_Special_Concern] = useActivateMapLayer(Bird_Species_of_Special_Concern_LayerMetadata, showLayer)
    const beforeIDs = useSelector(selectBeforeIDs)
    const [data, setData] = useState(null);
    const [hexData, setHexData] = useState({
        hexId: null,
        data: []
    })
    const selectedBird = useSelector(selectSelectedBird)

    const [ids, setIds] = useState([])

    useEffect(() => {
        // dispatch(addToMapLoadingList('Asbestos'))
        fetchData((data, isError) => {
            if (!isError) {
                setData(data)
            } 
            // dispatch(removeFromMapLoadingList('Asbestos'))
        })
    }, []) // Add searchDates to filter on search

    useMapEvent('click', BIRD_SPECIES_OF_SPECIAL_CONCERN_LAYER, (e) => {
        const feature = e.features[0]; 

        const hexId = feature.properties.HEX25_ID;

        // fetch(`https://services2.arcgis.com/Uq9r85Potqm3MfRV/ArcGIS/rest/services/biosds463_fpu/FeatureServer/1/query?where=HEX25_ID%3D${hexId}&objectIds=&time=&resultType=none&outFields=*&returnIdsOnly=false&returnUniqueIdsOnly=false&returnCountOnly=false&returnDistinctValues=false&cacheHint=false&orderByFields=&groupByFieldsForStatistics=&outStatistics=&having=&resultOffset=&resultRecordCount=&sqlFormat=none&f=pjson&token=`)
        // .then(r => r.json())
        // .then(response => {
        //     setHexData({
        //         hexId,
        //         data: response.features
        //     })
        // })
    })

    useEffect(() => {
        let escapedBirdKeyword = selectedBird
        escapedBirdKeyword = encodeURIComponent(escapedBirdKeyword.replaceAll("'", "''"));

        fetch(`https://services2.arcgis.com/Uq9r85Potqm3MfRV/ArcGIS/rest/services/biosds463_fpu/FeatureServer/1/query?where=COMNAME%3D%27${escapedBirdKeyword}%27&objectIds=&time=&resultType=none&outFields=*&returnIdsOnly=false&returnUniqueIdsOnly=false&returnCountOnly=false&returnDistinctValues=false&cacheHint=false&orderByFields=&groupByFieldsForStatistics=&outStatistics=&having=&resultOffset=&resultRecordCount=&sqlFormat=none&f=pjson&token=`)
        .then(r => r.json())
        .then(response => {
            setIds(response.features.map((element) => element.attributes.HEX25_ID))
        })
    }, [selectedBird])

    const sourceConfig = {
        id: BIRD_SPECIES_OF_SPECIAL_CONCERN_SOURCE,
        type: "geojson",
        data: data
    }

    const fillConfig = {
        id: BIRD_SPECIES_OF_SPECIAL_CONCERN_LAYER,
        type: "fill",
        source: BIRD_SPECIES_OF_SPECIAL_CONCERN_SOURCE,
        filter: ['in', ['get', 'HEX25_ID'], ['literal', ids]],
        layout: {
            'visibility': showBird_Species_of_Special_Concern ? 'visible' : 'none'
        },
        paint: {
            'fill-color': 'yellow',
            'fill-opacity': 0.3
        },
        beforeId: beforeIDs.fill
    }

    const lineConfig = {
        id: BIRD_SPECIES_OF_SPECIAL_CONCERN_LAYER + "_line",
        type: 'line',
        source: BIRD_SPECIES_OF_SPECIAL_CONCERN_SOURCE,
        layout: {
            'visibility': showBird_Species_of_Special_Concern ? 'visible' : 'none'
        },
        paint: {
            'line-opacity': 0.1
        },
        beforeId: beforeIDs.line
    }

    return (
        <Source {...sourceConfig}>
            <Layer {...fillConfig} />
            <Layer {...lineConfig} />
        </Source>
    )
}