import {
    CLEAR_CONCENTRATED_DATA_SOURCE,
    SET_CONCENTRATED_DATA_SOURCE
} from "./actionTypes"

export const clearConcentratedDataState = () => {
    return {
        type: CLEAR_CONCENTRATED_DATA_SOURCE
    }
}

export const setConcentratedDataSource = payload => {
    return {
        type: SET_CONCENTRATED_DATA_SOURCE,
        payload
    }
}
