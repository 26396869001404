import { Layer, Source } from "react-map-gl"
import { APIGateways } from "@config/config"
import { useState } from "react";
import { isActiveLayer, selectIsLayerActive, useActivateMapLayer, useMapHoverEvent, selectBeforeIDs, useMapEvent } from "@innovation-toolkit/mapbox";
import { useSelector } from "react-redux";
// import { LAStreetImprovementPopup } from "./popup";
import { LSAPopup } from "./popup";
import { UNCLUTTERED_MIN_ZOOM } from "../../constants";

export const LSA_LAYER = 'LSA_LAYER';
export const LSA_LINE_LAYER = 'LSA_LINE_LAYER'
export const LSA_SOURCE = 'LSA_SOURCE';

export const LSALayerMetadata = {
    layerId: LSA_LAYER,
    name: 'Leak Survey Area',
    color: '#8F00FF'
}

export const LSALayer = ({showLayer=false}) => {

    const [showLSALayer] = useActivateMapLayer(LSALayerMetadata, showLayer)
    const beforeIDs = useSelector(selectBeforeIDs)
    
    const [showPopup, setShowPopup] = useState(false)
    const [popupFeature, setPopupFeature] = useState(null);
    const [lngLat, setLatLng] = useState(null);
    const [target, setTarget] = useState(null);
    const [hoveredPolygon, setHoveredPolygon] = useState('');
    
    useMapEvent('click', LSA_LAYER, (e) => {
        if (!isActiveLayer(e, LSA_LAYER)) return;

        setShowPopup(true);
        console.log(e.features[0])
        setPopupFeature(e.features[0]);
        setLatLng(e.lngLat);
        setTarget(e.target);
    })

    useMapHoverEvent(LSA_LAYER, (e) => {
        console.log('hover')
        setHoveredPolygon(e.features[0].properties.GLOBALID)
    }, 300)

    const sourceConfig = {
        id: LSA_SOURCE,
        type: 'vector',
        tiles: [`${APIGateways.scgcore}/tiles/leak_survey_area/{z}/{x}/{y}`]
    }

    const fillConfig = {
        id: LSA_LAYER,
        type: "fill",
        source: LSA_SOURCE,
        'source-layer': 'LeakSurveyArea',
        minzoom: UNCLUTTERED_MIN_ZOOM,
        layout: {
            'visibility': showLSALayer ? 'visible' : 'none'
        },
        paint: {
            'fill-color': 'transparent',
            'fill-opacity': 1,
            "fill-outline-color": 'transparent'
            // [
            //     'case',
            //     ['==', ['get', 'GLOBALID'], ['literal', hoveredPolygon]], '#AA00FF',//0015B0
            //     ["rgba", 107, 107, 107, 0.5]
            // ]
        },
        beforeId: beforeIDs.fill
    }
    const lineConfig = {
        id: LSA_LINE_LAYER,
        type: "line",
        source: LSA_SOURCE,
        'source-layer': 'LeakSurveyArea',
        minzoom: UNCLUTTERED_MIN_ZOOM,
        layout: {
            'visibility': showLSALayer ? 'visible' : 'none'
        },
        paint: {
            'line-width': [
                'case',
                ['==', ['get', 'GLOBALID'], ['literal', hoveredPolygon]], 2,//0015B0
                1
            ],
            "line-color": [
                'case',
                ['==', ['get', 'GLOBALID'], ['literal', hoveredPolygon]], '#8F00FF',//0015B0
                ["rgba", 82, 82, 82, 0.5]
            ]
        },
        beforeId: beforeIDs.fill
    }

    return (
        <Source {...sourceConfig}>
            <Layer {...fillConfig} />
            <Layer {...lineConfig} />
            <LSAPopup 
                isOpen={showPopup}
                onClose={() => setShowPopup(false)}
                lngLat={lngLat}
                feature={popupFeature}
                target={target}
            />
        </Source>
    )
}

const LineComponent = ({pattern, color, label}) => {
    return (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                gap: '10px',
                // marginBottom: '12px'
            }}
        >
            <div
                style={{
                    height: '2px',
                    width: '22px',
                    background: color,
                    backgroundRepeat: 'repeat',
                    backgroundSize: 'contain',
                    border: '1px solid #FFFFFF50',
                    marginLeft: '3px'
                }}
            ></div>            
            <p
                style={{
                    padding: '0',
                    margin: '5px'
                }}
            >
                {label}
            </p>
        </div>
        
    )
}


export const LSALegendSection = () => {
    const showLegend = useSelector((state) => selectIsLayerActive(state, LSALayerMetadata.layerId))

    if (!showLegend) {
        return <></>
    }

    return (
        <LineComponent color='#8F00FF' label='Leak Survey Area'/>
    )
}