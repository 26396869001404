import { AnalysisSection, AnalysisSectionContainer } from "@innovation-toolkit/ui"
import { ChartIcon } from "shared/style/iconography/ChartIcon";
import { useDispatch, useSelector } from "react-redux"
import { setShowCityWorkAggregationTable, selectShowCityWorkAggregationTable } from "redux/cityWorkAggregation/slice";

export const CityWorkAggregationEdit = () => {
    const dispatch = useDispatch()
    const showTable = useSelector(selectShowCityWorkAggregationTable)

    return (
        <AnalysisSectionContainer>
            <AnalysisSection
                title={"City Work"}
                Icon={ChartIcon}
                action={(show) => {
                    dispatch(setShowCityWorkAggregationTable(show))
                }}
                defaultState={showTable}
                description={"This table shows all of the projects that were uploaded. If the project is outlined red, we were unable to determine the geometry. We recommend checking the error, updating the file, and uploading again. If the geometry of a project is off and you would like to replace the line, select “Edit” in the Geometry column and follow the instructions that appear."}
            >
            </AnalysisSection>
        </AnalysisSectionContainer>
    )
}
