import { setMapPosition } from '@innovation-toolkit/mapbox'
import { DataTable, LargeTable, Typography } from '@innovation-toolkit/ui'
import { Spin } from 'antd'
import _ from 'lodash'

import axios from 'axios'
import { APIGateways } from 'config/config'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectSearchItems } from 'redux/searchItems/selectors'
import { setHighlightedMap, setShowOverlapTable } from 'redux/streetImprovements/action'
import { selectIsCPDDisabled, selectIsMandIDisabled, selectOpenOrderIdsToFilter, selectShowOverlapTable, selectWorkTable, selectWorkTypes } from 'redux/streetImprovements/selector'
import styled from 'styled-components'

const StyledLoadingContainer = styled.div`
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex-direction: column;
`

export const OverlapTable = () => {

    const dispatch = useDispatch()

    const searchItems = useSelector(selectSearchItems)
    const workTypes = useSelector(selectWorkTypes)
    const [overlapsData, setOverlapsData] = useState([])
    const [ loadingData, setLoadingData ] = useState(true)
    const [filteredData, setFilteredData] = useState([])
    const [filteredOverlapsData, setFilteredOverlapsData] = useState([])
    const showOverlapTable = useSelector(selectShowOverlapTable)
    const { projects, moratoriums } = useSelector(selectWorkTable)
    const openOrderIdsToFilter = useSelector(selectOpenOrderIdsToFilter)

    const isMandIDisabled = useSelector(selectIsMandIDisabled)
    const isCPDDisabled = useSelector(selectIsCPDDisabled)

    // Get overlap table data
    useEffect(() => {
        // setLoadingData(true)
        const filter = { cpd: {}, mandi: {}, overlap_table: {} }
        // let selectedType = "ALL"

        if (searchItems?.timeFrame?.startDate && searchItems?.timeFrame?.endDate) {
            filter.overlap_table.end_timeframe = moment(searchItems?.timeFrame.endDate).format('YYYY-MM-DD')
            filter.overlap_table.start_timeframe = moment(searchItems?.timeFrame.startDate).format('YYYY-MM-DD')
        }

        axios.post(`${APIGateways.streetimprovements}/overlap/overlap_table`,  filter)
            .then(response => {
                fetch(response.data)
                    .then(fetchResponse => fetchResponse.json())
                    .then(data => {
                        setOverlapsData(data.map((element, index) => { return {...element, key: String(index + 1)}}))
                    })

            }).catch((err) => console.error(err))
    }, [searchItems])

    // Filter overlaps table to display city work or moratoriums
    useEffect(() => {

        let newFilteredData = _.cloneDeep(overlapsData)

        // Remove all overlaps that do not match current project and moratorium selection
        for (let i = 0; i < newFilteredData.length; i++) {
            newFilteredData[i].overlaps = newFilteredData[i].overlaps.filter((row) => {
                let shouldKeepRow = true;
                // If Moratoriums checkbox is not selected filter out
                // any row with type Moratorium
                if (!moratoriums && (row.type === "Moratorium" || row.type === "moratorium" || row.type === "0")) {
                    shouldKeepRow = false;
                }
                if (!projects && (row.type === "City Project" || row.type === "project")) {
                    shouldKeepRow = false;
                }

                return shouldKeepRow;
            })
        }

        // Remove all SoCalGas projects in list that do not have overlaps after previous filter
        newFilteredData = newFilteredData.filter((outerRow) => {
            return outerRow.overlaps.length !== 0;
        })

        // If M&I or CPD are disabled remove them from the table
        newFilteredData = newFilteredData.filter((outerRow) => {
            if (outerRow.type === 'CPD' && isCPDDisabled) {
                return false
            } else if (outerRow.type === 'M&I' && isMandIDisabled) {
                return false
            }
            return true
        })

        // Filter by selected Work Types (open order ids and notification numbers)
        if (workTypes && workTypes.length > 0) {
            const workTypeIds = []
            let currentWorkType

            for (let i = 0; i < workTypes.length; i++) {
                currentWorkType = workTypes[i]

                if (workTypes[i].includes("[M&I]")) {
                    currentWorkType = currentWorkType.substring(0, currentWorkType.indexOf("[M&I]"))
                } else if (workTypes[i].includes("[CPD]")) {
                    currentWorkType = currentWorkType.substring(0, currentWorkType.indexOf("[CPD]"))
                }

                workTypeIds.push(Number(currentWorkType))
            }

            newFilteredData = newFilteredData.filter((outerRow) => {
                return workTypeIds.includes(outerRow.sap_id)
            })

        }


        setFilteredOverlapsData(newFilteredData)
        setLoadingData(false)

    }, [overlapsData, projects, moratoriums, isCPDDisabled, isMandIDisabled, workTypes])


    useEffect(() => {
        // Filter the overlapsData to remove items with sap_id in openOrderIdsToFilter
        if (openOrderIdsToFilter.length > 0) {
            const newFilteredOverlapsData = filteredOverlapsData.filter(item => !openOrderIdsToFilter.includes(item.sap_id));
            setFilteredData(newFilteredOverlapsData);
        } else {
            setFilteredData(filteredOverlapsData);
        }
    }, [filteredOverlapsData, openOrderIdsToFilter]);


    const columns = [
        {
            title: 'Work Type',
            dataIndex: 'type',
            key: 'type',
            mapLocationButton: true,
            sortable: true,
            xlsxDownloadTitle:'Work Type',
            width: 200

        },
        {
            title: 'SAP ID',
            dataIndex: 'sap_id',
            key: 'sap_id',
            xlsxDownloadTitle:'SAP ID',
            width: 200
        },
        {
            title: 'SAP Work Type',
            dataIndex: 'sap_work_type',
            key: 'sap_work_type',
            xlsxDownloadTitle:'SAP Work Type',
            width: 200,
            formatText: (value) => {
                return (value !== "NaN" && value !== "") ? value : "-"
            },
        },
        {
            title: 'Address',
            dataIndex: 'address',
            key: 'address',
            xlsxDownloadTitle:'Address',
            width: 200
        },
        {
            title: "Supplemental Address",
            tooltip: 'Some city work does not have a specific address provided by the municipality, and instead is located using a description (e.g., moratorium located on Main St. between 1st & 2nd Ave.) The supplemental address is provided using the approximate address of the project or moratorium.',
            dataIndex: 'representative_address',
            key: 'representative_address',
            xlsxDownloadTitle:'Supplemental Address',
            width: 200
        },
        {
            title: 'Start Date',
            dataIndex: 'start_date',
            key: 'start_date',
            render:  (value, record) => {
                return moment(value).format("M/D/YYYY")
            },
            xlsxDownloadTitle:'Start Date',
            width: 200
        },
        {
            title: 'End Date',
            dataIndex: 'end_date',
            key: 'end_date',
            render:  (value, record) => {
                return moment(value).format("M/D/YYYY")
            },
            xlsxDownloadTitle:'End Date',
            width: 200
        },
        {
            title: 'Region',
            dataIndex: 'region',
            xlsxDownloadTitle:'Region',
            width: 200

        },
        {
            title: 'District',
            dataIndex: 'district',
            xlsxDownloadTitle:'District',
            width: 200

        },
        {
            title: 'City/Municipality',
            dataIndex: 'municipality',
            key: 'municipality',
            sortable: true,
            xlsxDownloadTitle:'City/Municipality',
            width: 200,
        },
        {
            title: 'Contact Person',
            dataIndex: 'contact_person_id',
            key: 'contact_person_id',
            formatText: (value) => {
                return (value !== "NaN" && value !== "") ? value : "-"
            },
            xlsxDownloadTitle:'Contact Person',
            width: 200
        },
        {
            title: 'City Work ID',
            dataIndex: 'city_work_id',
            key: 'city_work_id',
            render:  (value, record) => {
                return (record.type === "CPD" || record.type === "M&I") ? '-' : value
            },
            xlsxDownloadTitle:'City Work ID',
            width: 200
        },
    ]

    const handleMapLocationButtonClick = (record) => {
        dispatch(setHighlightedMap(record))
        dispatch(setMapPosition({ latitude: Number(record.lat), longitude: Number(record.lon), zoom: 15 }))
    }

    const tabs = [
        {
            title: 'Planned Excavation',
            contentSections: [
                {
                    content: <>
                        { loadingData === false
                            ? <LargeTable expandedScroll={false} columns={columns} data={filteredData} onMapLocationButtonClick={handleMapLocationButtonClick}/>
                            : <StyledLoadingContainer><Spin /><Typography text='Loading' variant='Heading 4'/></StyledLoadingContainer>
                        }
                    </>
                }
            ]
        }
    ]


    return <DataTable handleClose={() => dispatch(setShowOverlapTable(false))} open={showOverlapTable} tabs={tabs}  />
}