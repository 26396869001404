import React from "react";
import styled from "styled-components";
import Switch from "./Switch";

const StyledSwitch = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 34px 15px 34px;
`

const SwitchItem = ({onChange, title, checkedValue}) => {

    return <StyledSwitch>
        <div class='body-primary-text'>{title}</div>
        <Switch onChange={onChange} checkedValue={checkedValue}/>
    </StyledSwitch>
}

export default SwitchItem;