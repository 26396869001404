import { BarChart } from '@innovation-toolkit/ui';
import { useEffect, useState } from 'react';
import { Popup } from 'react-map-gl';
import styled from 'styled-components'

const PopupWrapper = styled.div`
  height: 268px;
  width: 368px !important;
`

const SCGEmissionsPopup = ({isOpen, onClose, lngLat, feature}) => {
    const [popupState, setPopupState] = useState({
        lng: 0,
        lat: 0,
        children: null
    })

    useEffect(() => {
        if (lngLat === null || feature === null) return

        let description = <>
            <h2>{feature?.feature.ZIP}</h2>
            <h3>Estimated Emissions (Mscf/yr)</h3>
            <PopupWrapper>
                <BarChart data={feature.emissionsByYear} yField="Annual_Emissions_Mscf" xField="Year"></BarChart>
            </PopupWrapper>
        </>
        

        setPopupState({
            lng: lngLat.lng,
            lat: lngLat.lat,
            children: description
        })

    }, [isOpen, lngLat, feature])



    return (
        <>
            {isOpen && 
                <Popup longitude={popupState.lng} latitude={popupState.lat} onClose={onClose}>
                    {popupState.children}
                </Popup>
            }
        </>
    )
}

export default SCGEmissionsPopup;