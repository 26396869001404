import { useDispatch, useSelector } from "react-redux"
import { Bird_Species_of_Special_Concern_LayerMetadata } from "."
import { addActiveLayer, removeActiveLayer, selectIsLayerActive } from "@innovation-toolkit/mapbox"
import { DropDown, LayerToggle, LayerToggleIcon, LayerToggleIconChild, SmallDropDown } from '@innovation-toolkit/ui';
import Bird from "assets/icons/bird.svg?react";
import { useEffect, useState } from "react";
import { selectSelectedBird, setSelectedBird } from "redux/angelesLinks";

export const BirdSpeciesOfSpecialConcernToggle = () => {
    const LAYER_ID = Bird_Species_of_Special_Concern_LayerMetadata.layerId
    const dispatch = useDispatch()
    const shouldShowLayer = useSelector((state) => selectIsLayerActive(state, LAYER_ID))
    const [birdNames, setBirdNames] = useState([])
    const selectedBird = useSelector(selectSelectedBird)

    const handleClick = (shouldShow) => {
        if (!shouldShow) {
            dispatch(removeActiveLayer(LAYER_ID))
        } else {
            dispatch(addActiveLayer(LAYER_ID))
        }
    }

    useEffect(() => {
        // get all common names
        fetch('https://services2.arcgis.com/Uq9r85Potqm3MfRV/ArcGIS/rest/services/biosds463_fpu/FeatureServer/1/query?where=1%3D1&objectIds=&time=&resultType=none&outFields=COMNAME,SCINAME&returnIdsOnly=false&returnUniqueIdsOnly=false&returnCountOnly=false&returnDistinctValues=true&cacheHint=false&orderByFields=&groupByFieldsForStatistics=&outStatistics=&having=&resultOffset=&resultRecordCount=&sqlFormat=none&f=pjson&token=')
        .then(r => r.json())
        .then(response => {
            setBirdNames(response.features.map(element => element.attributes.COMNAME))
        })
    }, [])


    return (
        <LayerToggle
            title='Bird Species'
            componentIcon={Bird}
            onChange={handleClick}
            currentState={shouldShowLayer}
            children={<><LayerToggleIconChild 
                Icon={<LayerToggleIcon 
                    type="polygon" 
                    fillColor={Bird_Species_of_Special_Concern_LayerMetadata.color} 
                    outlineColor="#ffffff" 
                    strokeColor="#031627"
                />} 
                title='Bird Species of Special Concern'
            />
            <DropDown
                options={birdNames}
                defaultValue={selectedBird}
                onChange={(value) => dispatch(setSelectedBird(value))}
                style={{marginTop: '10px'}}
            />
            </>}
        />
    )
}