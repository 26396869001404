import {createSelector} from 'reselect';

const selectSearchItems = state => state.searchItems;
const selectMapState = state => state.mapStateInternal;
const selectStreetImprovementsState = state => state.StreetImprovements;


const selectTimeFrame = createSelector(
    selectSearchItems,
    select => select.timeFrame
)

const selectSearchDates = createSelector(
    selectTimeFrame,
    select => {
        return {
            start: select.startDate,
            end: select.endDate
        }
    }
)

const selectSearchCommunities = createSelector(
    selectSearchItems,
    select => select.communities,
)

const selectCensusTracts = createSelector(selectSearchItems, select => select.censusTracts)

const selectSearchFleetVehicles = createSelector(
    selectSearchItems,
    select => select.vehicles,
)

const selectMapUpdateButton = createSelector(
    selectSearchItems,
    select => select.timeFrame.startDate || select.timeFrame.endDate || select.communities || select.vehicles
)

const selectIsClearSearchDisabled = createSelector(
    selectSearchItems,
    selectMapState,
    selectStreetImprovementsState,
    (searchItems, mapStateInternal, streetImprovements) => {
        return searchItems.timeFrame.startDate === null &&
                searchItems.timeFrame.endDate === null &&
                searchItems.censusTracts === null &&
                searchItems.communities === null &&
                searchItems.vehicles === null &&
                searchItems.districts?.length === 0 &&
                mapStateInternal.mapCensusTracts === null &&
                searchItems.cities.length === 0 &&
                searchItems.projectStatus.length === 0 &&
                searchItems.districts.length === 0 &&
                searchItems.municipalities.length === 0 &&
                searchItems.leakCodes.length === 0 &&
                searchItems.leakStatuses.length === 0 && 
                streetImprovements.workTypes.length === 0
        }
    
)

const selectProjectStatuses = createSelector(
    selectSearchItems,
    (select) => select.projectStatus
)

const selectCities = createSelector(
    selectSearchItems,
    (select) => select.cities
)

const selectDistricts = createSelector(
    selectSearchItems,
    (select) => select.districts
)

const selectMunicipalities = createSelector(
    selectSearchItems,
    (select) => select.municipalities
)

const selectLeakStatuses = createSelector(
    selectSearchItems,
    (select) => select.leakStatuses
)

const selectLeakCodes = createSelector(
    selectSearchItems,
    (select) => select.leakCodes
)

export {
    selectSearchItems,
    selectSearchDates,
    selectSearchCommunities,
    selectSearchFleetVehicles,
    selectMapUpdateButton,
    selectCensusTracts,
    selectIsClearSearchDisabled,
    selectProjectStatuses,
    selectCities,
    selectDistricts,
    selectMunicipalities,
    selectLeakStatuses,
    selectLeakCodes
}