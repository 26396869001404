import React from "react";
import styled from "styled-components";

const LogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: start;
  width: 80px;
  height: 104px;
`;

//30px 32px 37px 32px;

const Logo = styled.img`
  display: flex;
  justify-content: center;
  padding-top: 30px;
`

const LogoComponent = ({ svgComponent, altString, children }) => {

  return <LogoWrapper >
    {svgComponent ? <Logo src={svgComponent} alt={altString} /> : children}
  </LogoWrapper>
}

export default LogoComponent;