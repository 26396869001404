import { useDispatch, useSelector } from "react-redux"
import { RECENT_EARTHQUAKE_LayerMetadata } from "."
import { addActiveLayer, removeActiveLayer, selectIsLayerActive } from "@innovation-toolkit/mapbox"
import { DropDown, LayerToggle, LayerToggleIcon, LayerToggleIconChild, LayerToggleSection, SmallDropDown, Typography } from '@innovation-toolkit/ui';
import SensitiveSites from "assets/icons/sensitive-sites.svg?react";

export const RecentEarthquakeLayerToggle = () => {
    const LAYER_ID = RECENT_EARTHQUAKE_LayerMetadata.layerId
    const dispatch = useDispatch()
    const shouldShowLayer = useSelector((state) => selectIsLayerActive(state, LAYER_ID))

    const handleClick = (shouldShow) => {
        if (!shouldShow) {
            dispatch(removeActiveLayer(LAYER_ID))
        } else {
            dispatch(addActiveLayer(LAYER_ID))
        }
    }


    return (
        <LayerToggle
            title='SCSN'
            componentIcon={SensitiveSites}
            onChange={handleClick}
            currentState={shouldShowLayer}
            tooltipText={<span><bold>Source: </bold> <a href="https://www.scsn.org/" rel="noreferrer" target="_blank">SCSN</a></span>}
            children={<>
            <Typography variant="Heading 4" text="Recent Earthquake Activity"/>
            <LayerToggleIconChild 
                Icon={<LayerToggleIcon 
                    type="point" 
                    fillColor='#F16B64' 
                    outlineColor="#ffffff" 
                    strokeColor="black"
                />} 
                title='Last hour'
            />
            <LayerToggleIconChild 
                Icon={<LayerToggleIcon 
                    type="point" 
                    fillColor='#5455EF' 
                    outlineColor="#ffffff" 
                    strokeColor="black"
                />} 
                title='Last day'
            />
            <LayerToggleIconChild 
                Icon={<LayerToggleIcon 
                    type="point" 
                    fillColor='#EDED76' 
                    outlineColor="white" 
                    strokeColor="black"
                />} 
                title='Last week'
            />
            <LayerToggleIconChild 
                Icon={<LayerToggleIcon 
                    type="point" 
                    fillColor='#D3D3D3' 
                    outlineColor="#ffffff" 
                    strokeColor="black"
                />} 
                title='Older'
            />
            <Typography variant="Heading 4" text="Fault Lines"/>
            <LayerToggleIconChild 
                Icon={<LayerToggleIcon 
                    type="line" 
                    fillColor='white' 
                    outlineColor="black" 
                    strokeColor="#676767"
                />} 
                title='California Faults'
            />
            </>}
        />
    )
}