import styled from "styled-components"

const FilterWrapper = styled.div`
    position: relative;
    height: calc(100vh - 162px - 82px); // max height - FilterActionButtons - Header
    margin-top: 0px;
    margin-bottom: 0px;
    overflow-y: auto;
    -ms-overflow-style: none;
    overflow-x: hidden;
    ::-webkit-scrollbar-track {
        background-color: transparent;
    }

    ::-webkit-scrollbar-thumb {
        background: rgba(255, 255, 255, 0.2);
        border-radius: 100px;
    }

    ::-webkit-scrollbar {
        height: 48px;
        width: 6px;
        padding-right: 6px;
    }
`

export default FilterWrapper
