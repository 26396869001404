import {
    FilterActionButtons,
    SearchBar,
    Typography,
    DelimiterLine,
    HeaderContent
} from '@innovation-toolkit/ui';
import strings from 'utils/strings';
import { useEffect, useState } from 'react';
import { FilterWrapper } from 'shared/components/filterWrapper';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { selectWorkTypes } from '@redux/streetImprovements/selector';
import _ from 'lodash'
import { setWorkTypes } from '@redux/streetImprovements/action';
import { selectCPDOptions, selectMAndIOptions, selectFilteringLayer, selectSearchNotificationNo, selectSearchOrderNo } from '@redux/streetImprovements/selector';
import { setSelectedFilteringLayer, setSearchPlannerID, setSearchNotificationNo, setSearchOrderNo } from '@redux/streetImprovements/action';
import { Checkbox, Radio } from 'antd';
import styled from 'styled-components';
import { VariableSizeList } from 'react-window';
import { CheckboxOption } from 'component/sidebar/tabs/content/FleetVehicleFilter';
import { selectIsCPDDisabled, selectIsMandIDisabled } from 'redux/streetImprovements/selector';

const RadioGroup = styled(Radio.Group)`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 10px;

    margin: 24px;
`

const StyledCheckbox = styled(Checkbox)`

    .ant-checkbox-wrapper {
        align-items: center !important;
    }

    .ant-checkbox .ant-checkbox-inner {
        width: 20px;
        height: 20px;
        background-color: transparent;
        border: 2px solid #FFF;
        border-radius: 4px;
        transition: all 0.3s;

        :hover {
            background-color: transparent;
        }
    }
`

const RadioButton = styled(Radio)`
    .ant-radio{
        margin-right: 4px;
    }

    .ant-radio-inner {
        width: 20px;
        height: 20px;

        background-color: transparent;
        border: 2px solid #FFF;
    }

    .ant-radio-checked .ant-radio-inner {
        background-color: #178FFE;
    }

    .ant-radio-inner::after {
        width: 18px;
        height: 18px;

        margin-top: -9px;
        margin-left: -9px;

        background-color: #FFF;
    }
`

const SearchBarWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin: 24px 16px;
`

const CheckAll = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
    margin-bottom: 1px;
    background-color: rgba(255, 255, 255, 0.1);
    padding: 10px 16px 15px 28px;
    max-height: 80px;
    height: 80px;
`

const sortResults = (options, selectedOptions) => {
    const newFilteredValues = options.sort((option_1, option_2) => {
        const v1_checked = selectedOptions.includes(option_1)
        const v2_checked = selectedOptions.includes(option_2)

        if (v1_checked && !v2_checked) {
            return -1
        } else if (v2_checked && !v1_checked) {
            return 1
        } else {
            return 0
        }
    })

    return newFilteredValues
}

const layerFilterOptions = {
    ALL: "All",
    CPD: "CPD",
    MI: "MI"
}

export const WorkTypeFilter = ({ closeTabs, footNote }) => {
    const dispatch = useDispatch();

    const selectedWorkLayerToFilter = useSelector(selectFilteringLayer);
    const CPDOptions = useSelector(selectCPDOptions);
    const MAndIOptions = useSelector(selectMAndIOptions);
    const searchNotificationNo = useSelector(selectSearchNotificationNo)
    const searchOrderNo = useSelector(selectSearchOrderNo)

    const [ filteredLayer, setFilteredLayer ] = useState(selectedWorkLayerToFilter)
    const [checkAll, setCheckAll] = useState(false);

    const stateWorkTypes = useSelector(selectWorkTypes);
    const [indeterminate, setIndeterminate] = useState(stateWorkTypes.length === 0 ? false : true);
    const [allFilterOptions, setAllFilterOptions] = useState([])
    const [filteredListOfOptions, setFilteredListOfOptions] = useState([])
    const [selectedOptions, setSelectedOptions] = useState([])
    const [searchStrings, setSearchStrings] = useState({
        contactPersonId: '',
        notificationNumber: '',
        orderNumber: ''
    })
    const isMandIDisabled = useSelector(selectIsMandIDisabled)
    const isCPDDisabled = useSelector(selectIsCPDDisabled)
    const [runSearchToggle, setRunSearchToggle] = useState(false)

    useEffect(() => {
        if (!stateWorkTypes || stateWorkTypes.length === 0) return
        // setCountResultsFound(stateWorkTypes.length)
        // setFilterOptions(stateWorkTypes)
        // initialSearch()

        setSelectedOptions(stateWorkTypes)
    }, [stateWorkTypes])

    // const initialSearch = () => {
    //     if (searchPlannerID.length > 0) { handleSearchChange(searchPlannerID, searchBarID.person) }
    //     if (searchNotificationNo.length > 0) { handleSearchChange(searchNotificationNo, searchBarID.notificationNumber) }
    //     if (searchOrderNo.length > 0) { handleSearchChange(searchOrderNo, searchBarID.orderNumber) }
    // }

    // When data sources change update all options
    useEffect(() => {

        let newFilteredListOfOptions = []

        if (CPDOptions) {
            newFilteredListOfOptions = [...CPDOptions.map(element => element.notificationNumber)]
        }

        if (MAndIOptions) {
            newFilteredListOfOptions = [...newFilteredListOfOptions, ...MAndIOptions]
        }

        setAllFilterOptions(newFilteredListOfOptions)

        setRunSearchToggle(!runSearchToggle)

    }, [CPDOptions, MAndIOptions])

    useEffect(() => {
        setFilteredListOfOptions(allFilterOptions)
    }, [allFilterOptions])

    // Handles the search bar filtering
    useEffect(() => {
        let newFilteredListOfCPDOptions = [...allFilterOptions]
        let cpdSearchActive = false;
        let mandiSearchActive = false

        if (searchStrings.contactPersonId) {
            cpdSearchActive = true

            // Get list of personIds that match the string
            const listOfMatchingNotificationNumbers = new Set(CPDOptions.filter(element => element.personID.toLowerCase().includes(searchStrings.contactPersonId.toLowerCase())).map(element => element.notificationNumber))
            const newFilteredListOfOptionsSet = new Set(newFilteredListOfCPDOptions)
            
            newFilteredListOfCPDOptions = [...newFilteredListOfOptionsSet.intersection(listOfMatchingNotificationNumbers)]

            // Get list of ids associated with any personId that matches the
        }

        if (searchStrings.notificationNumber) {
            cpdSearchActive = true

            newFilteredListOfCPDOptions = newFilteredListOfCPDOptions.filter(element => {
                return element.includes(searchStrings.notificationNumber) && element.includes('[CPD]')
            })
        }

        let newFilteredListOfMandIOptions = [...allFilterOptions]

        if (searchStrings.orderNumber) {
            mandiSearchActive = true

            newFilteredListOfMandIOptions = newFilteredListOfMandIOptions.filter(element => {
                return element.includes(searchStrings.orderNumber) && element.includes('[M&I]')
            })
        }

        let newFilteredList = []

        let optionsBeforeFiltering = allFilterOptions

        if (isCPDDisabled) {
            optionsBeforeFiltering = optionsBeforeFiltering.filter(element => !element.includes('[CPD]'))
        } else if (isMandIDisabled) {
            optionsBeforeFiltering = optionsBeforeFiltering.filter(element => !element.includes('[M&I]'))
        }


        if (!cpdSearchActive && !mandiSearchActive) {
            newFilteredList = optionsBeforeFiltering
        } 

        if (cpdSearchActive) {
            newFilteredList = [...newFilteredListOfCPDOptions]
        }

        if (mandiSearchActive) {
            newFilteredList = [...newFilteredList, ...newFilteredListOfMandIOptions]
        }

        const newSortedResults = sortResults(newFilteredList, selectedOptions)


        setFilteredListOfOptions(newSortedResults)

    }, [searchStrings, CPDOptions, MAndIOptions, isCPDDisabled, isMandIDisabled, runSearchToggle])

    // When an item is selected sort the options to show that item at the top
    useEffect(() => {
        const newSortedResults = sortResults(filteredListOfOptions, selectedOptions)

        setFilteredListOfOptions(newSortedResults)
    }, [selectedOptions])

    const handleLayerSelect = (selected) => {
        setFilteredLayer(selected)
        dispatch(setSelectedFilteringLayer(selected))
    }

    const handleConfirm = () => {

        let MandIisInSelection = false
        let CPDisInSelection = false

        for (let i = 0; i < selectedOptions.length; i++) {
            if ( selectedOptions[i].includes("[M&I]") ) {
                MandIisInSelection = true
            } else if ( selectedOptions[i].includes("[CPD]")) {
                CPDisInSelection = true
            }

            // If both are found no need to check the rest of the selection
            if (MandIisInSelection && CPDisInSelection) {
                break;
            }
        }

        if (CPDisInSelection && MandIisInSelection) { 
            handleLayerSelect(layerFilterOptions.ALL)
            // !shouldShowMANDIWork && dispatch(addActiveLayer(MandIWorkLayerMetadata.layerId))
            // !shouldShowCPDProjects && dispatch(addActiveLayer(CPDProjectsLayerMetadata.layerId))
        }
        else if (CPDisInSelection && !MandIisInSelection) { 
            handleLayerSelect(layerFilterOptions.CPD)
            // shouldShowMANDIWork && dispatch(removeActiveLayer(MandIWorkLayerMetadata.layerId))
            // !shouldShowCPDProjects && dispatch(addActiveLayer(CPDProjectsLayerMetadata.layerId))
        }
        else if (!CPDisInSelection && MandIisInSelection) { 
            handleLayerSelect(layerFilterOptions.MI)
            // !shouldShowMANDIWork && dispatch(addActiveLayer(MandIWorkLayerMetadata.layerId))
            // shouldShowCPDProjects && dispatch(removeActiveLayer(CPDProjectsLayerMetadata.layerId))
        }

        // dispatch(setSearchPlannerID(personSearchString))
		dispatch(setWorkTypes(selectedOptions))
		closeTabs()
	}

	const handleClear = () => {
        setSelectedOptions([])

        setSearchStrings({
            contactPersonId: '',
            notificationNumber: '',
            orderNumber: ''
        })

        setCheckAll(false)
        setIndeterminate(false)

        dispatch(setSearchPlannerID(''))
        dispatch(setSearchNotificationNo(''))
        dispatch(setSearchOrderNo(''))

		dispatch(setWorkTypes([]))
        dispatch(setSelectedFilteringLayer(layerFilterOptions.ALL))
	}

    const handleCheckAllChange = (e) => {
        if (e.target.checked && (filteredListOfOptions.length === allFilterOptions.length)) {
            return
        }

        setSelectedOptions(e.target.checked ? filteredListOfOptions : []);
        setIndeterminate(false);
        setCheckAll(e.target.checked);


    };

    const CheckboxRow = ({index, style}) => {

        const handleCheckboxChange = (checked, id) => {

            const newSelectedOptions = [...selectedOptions]

            if (checked) {
                newSelectedOptions.push(id)
            } else {
                const indexOfId = selectedOptions.indexOf(id)

                newSelectedOptions.splice(indexOfId, 1)
            }

            setIndeterminate(!!newSelectedOptions.length && newSelectedOptions.length < filteredListOfOptions.length);


            setSelectedOptions(newSelectedOptions)
        }

        return <div style={style}>
            <CheckboxOption 
                label={`${filteredListOfOptions[index]}`}
                checked={selectedOptions.includes(filteredListOfOptions[index])}
                onChange={(e) => handleCheckboxChange(e.target.checked, filteredListOfOptions[index])}
            />
        </div>
    }


    return (
        <HeaderContent
            title={strings.menu.workType.header}
            closeTabs={closeTabs}
        >
            <div 
                style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: 'calc(100vh - 125px)'}}
                onMouseEnter={() => setRunSearchToggle(!runSearchToggle)}
            >
            <div>
            <RadioGroup onChange={(e) => handleLayerSelect(e.target.value)} value={filteredLayer}>
                <RadioButton value={layerFilterOptions.ALL}> <Typography variant='Body 1' text='Show All Work' /></RadioButton>
                <RadioButton value={layerFilterOptions.CPD}> <Typography variant='Body 1' text='Show CPD Projects' /></RadioButton>
                <RadioButton value={layerFilterOptions.MI}> <Typography variant='Body 1' text='Show M&I Work' /></RadioButton>
            </RadioGroup>
            <DelimiterLine style={{ margin: '0px 16px'}}/>

            <SearchBarWrapper>
                { (filteredLayer === layerFilterOptions.CPD || filteredLayer === layerFilterOptions.ALL) && 
                    <SearchBar
                        onChange={(e) => setSearchStrings({...searchStrings, contactPersonId: e.target.value})}
                        value={searchStrings.contactPersonId}
                        placeholder='Search Contact Person ID'
                    />
                }
                { (filteredLayer === layerFilterOptions.CPD || filteredLayer === layerFilterOptions.ALL) && 
                    <SearchBar
                        onChange={(e) => setSearchStrings({...searchStrings, notificationNumber: e.target.value})}
                        value={searchStrings.notificationNumber}
                        placeholder='Search Notification Number'
                    />
                }
                { (filteredLayer === layerFilterOptions.MI || filteredLayer === layerFilterOptions.ALL) &&
                    <SearchBar
                        onChange={(e) => setSearchStrings({...searchStrings, orderNumber: e.target.value})}
                        value={searchStrings.orderNumber}
                        placeholder='Search Order Number'
                    />
                }
            </SearchBarWrapper>

            {/* { countResultsFound < 1  
                ? <FilterWrapper>
                    <div style={{ height: '183px', justifyContent: 'flex-start'}}>
                        <NoDataInfo title={"Specify the type of work or project(s) you wish to view."} />
                    </div>
                  </FilterWrapper>
                :<> */}
                    <CheckAll>
                        <Typography variant='Heading 3' text={`${filteredListOfOptions.length.toLocaleString('en-US')} Work IDs found`} style={{margin: 'auto'}} />
                        <Typography variant='Heading 3' text={`${selectedOptions.length.toLocaleString('en-US')} Work IDs selected`} style={{margin: 'auto'}} />
                        {/* { countResultsFound < displayedResultsLimit &&  */}
                            <StyledCheckbox indeterminate={indeterminate} onChange={handleCheckAllChange} checked={checkAll}>
                                <Typography variant='Heading 4' text='Select All' />
                            </StyledCheckbox>
                        {/* }  */}
                    </CheckAll>

                    <FilterWrapper style={{backgroundColor: '#FFFFFF1A', paddingTop: '8px', height: '300px'}}>
                        {/* <CheckboxList 
                            options={filterOptions}
                            onChange={handleSelectionChange}
                            value={selectedWorkTypes}
                            /> */}
                        <VariableSizeList
                            height={300}
                            width={500}
                            itemCount={filteredListOfOptions.length}
                            itemSize={() => 30}
                        >
                            {CheckboxRow}
                        </VariableSizeList>
                    </FilterWrapper>
                {/* </> */}
            {/* } */}
            </div>
            <FilterActionButtons
                isConfirmDisabled={_.isEqual(selectedOptions, stateWorkTypes)}
                onConfirm={handleConfirm}
                footNote={footNote}
                isClearDisabled={_.isEqual(selectedOptions, [])}
                onClear={handleClear}
            />

            </div>
        </HeaderContent>
    );
};
