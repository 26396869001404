import { DrawControl, selectDrawRef, useMapEvent } from '@innovation-toolkit/mapbox';
import { useSelector } from 'react-redux';
import { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { setDrawnGeometries } from '@redux/streetImprovements/action';
import { selectRowUpdateInfo } from '@redux/streetImprovements/selector';
import { openNotification } from '@innovation-toolkit/ui';

const combineLineGeometries = (features) => {
    let featureCollection = {
        type: 'FeatureCollection',
        features: features
    };
    if (features.length > 1) {
        let combinedFeatures = [{
            type: "Feature",
            geometry:  {
                "type": "MultiLineString",
                "coordinates": features.map(element => element.geometry.coordinates)
              },
              properties: {},
              id: features[0].id
        }
       ]
       featureCollection.features = combinedFeatures
    }
    return featureCollection
}

export const DrawContainer = () => {
    const draw = useSelector(selectDrawRef);
    const drawRef = useRef();
    const dispatch = useDispatch()
    const rowUpdateInfo = useSelector(selectRowUpdateInfo)

    useEffect(() => {
        if (!draw) return;
        drawRef.current = draw.current;
    }, [drawRef, draw]);

    useEffect(() => {
        if (!drawRef.current) return;
        if (rowUpdateInfo.isUpdating) {            
            openNotification({
                placement: 'top-right',
                notificationMessage: 'Click on line draw tool on map to add lines to the map. Add as many lines as needed then click save in the edit table.'
            })
        }

    }, [rowUpdateInfo.isUpdating, drawRef]);

    const updateGeometries = () => {  
        const combinedGeometry = combineLineGeometries(drawRef.current.getAll().features);
        dispatch(setDrawnGeometries(combinedGeometry));
    };

    useMapEvent('draw.create', () => {
        updateGeometries()
    })

    useMapEvent('draw.update', () => {
        updateGeometries()
    })

    useMapEvent('draw.delete', () => {
        updateGeometries()
    })

    const deleteGeometries = () => {
        dispatch(setDrawnGeometries({
            type: 'FeatureCollection',
            features: []
          }));
    }

    if (!rowUpdateInfo.isUpdating) return <></>

    return (
        <DrawControl
            position="top-right"
            displayControlsDefault={false}
            controls={{
                trash: true,
                line_string: true,
            }}
            onCreate={() => {}}
            onUpdate={() => {}}
            onDelete={() => {}}
            setRef={(ref) => {
                if (!ref || !ref.current) return

                drawRef.current = ref.current
            }}
        />
    );
};
