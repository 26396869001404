import { useDispatch, useSelector } from "react-redux"
import { Angeles_Link_Segment_B_LayerMetadata } from "."
import { addActiveLayer, removeActiveLayer, selectIsLayerActive } from "@innovation-toolkit/mapbox"
import { LayerToggle, LayerToggleIcon, LayerToggleIconChild } from '@innovation-toolkit/ui';
import RoadSplit from "assets/icons/road-split.svg?react";
import { Angeles_Link_Segment_C_LayerMetadata } from "../Angeles_Link_Segment_C";

export const AngelesLinkSegmentToggle = () => {
    const dispatch = useDispatch()

    const layerIds = [ Angeles_Link_Segment_B_LayerMetadata.layerId, Angeles_Link_Segment_C_LayerMetadata.layerId];
    const layerShowState = [
        useSelector((state) => selectIsLayerActive(state, Angeles_Link_Segment_B_LayerMetadata.layerId)),
        useSelector((state) => selectIsLayerActive(state, Angeles_Link_Segment_C_LayerMetadata.layerId)),
    ];
    
    const handleClick = (shouldShow) => {
        if (!shouldShow) {
            layerIds.forEach((layerId) =>  dispatch(removeActiveLayer(layerId)))
        } else {
            layerIds.forEach((layerId) =>  dispatch(addActiveLayer(layerId)))
        }
    }

    const handleIndividualChange = (layerID, index) => {
        if (layerShowState[index]) {
            dispatch(removeActiveLayer(layerID))
        } else {
            dispatch(addActiveLayer(layerID))
        }
    }

    return (
        <LayerToggle
            title='Proposed Routes'
            componentIcon={RoadSplit}
            onChange={handleClick}
            currentState={layerShowState.includes(true)}
            children={
                <section style={{display: 'flex', flexDirection: 'column'}}>
                    <LayerToggleIconChild 
                        Icon={<LayerToggleIcon type="line" fillColor={Angeles_Link_Segment_B_LayerMetadata.color} strokeColor="white" outlineColor="white"/>} 
                        title={Angeles_Link_Segment_B_LayerMetadata.name} 
                        subLayerCheckbox={{defaultChecked: layerShowState[0], onChange: () => handleIndividualChange(Angeles_Link_Segment_B_LayerMetadata.layerId, 0)}}/>
                    <LayerToggleIconChild 
                        Icon={<LayerToggleIcon type="line" fillColor={Angeles_Link_Segment_C_LayerMetadata.color} strokeColor="white" outlineColor="white"/>} 
                        title={Angeles_Link_Segment_C_LayerMetadata.name}
                        subLayerCheckbox={{defaultChecked: layerShowState[1], onChange: () => handleIndividualChange(Angeles_Link_Segment_C_LayerMetadata.layerId, 1)}}/>
                </section>
            }
        />
    )
}