import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { APIGateways } from '@config/config';
import { selectMapPosition } from '@redux/mapStateInternal/selectors';
import { Map, setMapPosition, setMapboxStyle, selectMapStyle, beforeId, setBeforeIDs } from '@innovation-toolkit/mapbox'
import axios from 'axios';

import "@innovation-toolkit/mapbox/index.css";
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css'

import { useUserToken } from '@innovation-toolkit/rbac';

const initialMapPosition = {
    longitude: -117.6906,
    latitude: 34.0981,
    zoom: 8,
    bearing: 0,
    pitch: 0
}

const MapboxReact = ({layers, UseCaseLegend = <></>, ...rest}) => {

    const mapPosition = useSelector(selectMapPosition);
    const mapStyle = useSelector(selectMapStyle)

    const dispatch = useDispatch();

    const [mapboxToken, setMapboxToken] = useState(null);
    useEffect(() => {
        axios.post(`${APIGateways.scgcore}/api_key_auth`, {
            secret_name: "MAPBOX_TOKEN_WRITE"
        })
        .then((response) => {
            setMapboxToken(response.data.token);
        })
        .catch(() => {})
    }, [dispatch])

    const [userToken] = useUserToken();
    const tokenRef = useRef();
    tokenRef.current = userToken;

    useEffect(() => {
        dispatch(setMapPosition(initialMapPosition))
    }, [])

    if (!mapboxToken) {
        return <></>
    }

    return (
        <Map
            id="mapRef"
            mapPosition={{
                longitude: mapPosition.longitude,
                latitude: mapPosition.latitude,
                zoom: mapPosition.zoom,
                bearing: mapPosition.bearing,
                pitch: mapPosition.pitch
            }}
            mapStyle={mapStyle}
            handleMoveEnd={() => {}}
            accessToken={mapboxToken}
            style={{
                height: '100vh',
                width: '100%',
                position: 'absolute',
                top: 0,
                left: 0
            }}
            showGeocoderMarker={true}
            // Bounding box to surround the SoCalGas territory
            geocoderOptions={{bbox: [ -122.6232, 32.3986, -114.0507, 37.3359 ]}}
            baseMapSwitcherArgs={{
                mapStyles: ['Basic', 'Monochrome', 'Satellite']
            }}
            transformRequest={(url) => {
                if (Object.values(APIGateways).some((api) => url.startsWith(api))) {
                    return {
                        url: url,
                        headers: {
                            'authorization': tokenRef.current,
                            'Accept': 'application/x-protobuf'
                        },
                    }
                }
            }}
            layers={layers}
            APIGateway={APIGateways}
            {...rest}
        >
            {/* <NavigationControl showCompass={false}/> */}
            {/* <Compass 
                onClick={() => handleResetMapPosition()}
            /> */}
            {UseCaseLegend}
            {/* <div></div> */}
            
            {/* <Legend>
                <RoadPavingLegendSection/>
                <LAStreetImprovementLegendSection/>
                <CPDProjectsLegendSection/>
                <MoratoriumsLegendSection />
                <HospitalsLegendSection/>
            </Legend> */}
  
        </Map>
    )
}

export default MapboxReact;