import React from 'react';
import styled, { css } from 'styled-components';
import { theme } from '@innovation-toolkit/ui';
/* BUTTON
    - can be used as primary, secondary, or tertiary button
    - can contain icon
        * to use standard icon you have to set iconURL in parent component 
        * to use svg icon you have to import svg as ReactComponent in parent and embed it in Button
            ** Example: <Button ...props svgIcon={<SVGIcon />} />
    
    - Properties:
        -> title:       Title displayed in button
        -> type:        one of 'primary', 'secondary', 'tertiary'. Default = 'primary'
        -> size:        one of 'L', 'M', 'S', 'thin'. Default = 'M'
        -> onClick:     Callback func
        -> disabled:    true = button is disabled, false = button is enabled
        -> iconURL:     image's url, null = icon is not displayed
        -> svgIcon:     SVG Icon as ReactComponent (ability to change fill color on hover state)
                        null = svg icon is not displayed
        -> style:       Pass in any style object to apply custom css    
*/

const StyledButton = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    border-radius: 4px;
    border: none;
    cursor: pointer;

    text-transform: uppercase;
    font-family: Interstate;
    font-size: 14px;
    font-weight: 700;
    line-height: 17px;
    letter-spacing: 0.05em;
    text-align: center;

    span, img {
        height: 24px;
        max-width: 32px;
        padding-right: 8px;
    }

    ${props => props.size === 'thin' && css`
        text-transform: capitalize;
        font-family: Interstate;
        font-size: 12px;
        font-weight: 400;
        line-height: 14px;
        letter-spacing: 0em;
        text-align: center;
        padding-left: 0;
        padding-right: 0;

        height: 24px;
        width: 98px;
    `}

    ${props => props.size === 'L' && css`
        height: 40px;
        width: 190px;
    `}

    ${props => props.size === 'M' && css`
        height: 40px;
        width: 156px;
    `}

    ${props => props.size === 'S' && css`
        height: 40px;
        width: 92px;
    `}

    ${props => props.type === 'primary' && css`
        ${props => !props.disabled && css`
            color: ${theme.color.white};
            background-color: ${theme.color.dodgerBlue};

            &:hover {
                background-color: ${theme.color.blueJeans};
            }

            &:active {
                background-color: ${theme.color.blueJeans};
                border: solid 2px ${theme.color.whiteGrey};
            }

            .buttonSvg svg path {
                fill: ${theme.color.white};
            }
        `}

        ${props => props.disabled && css`
            cursor: default;

            color: ${theme.color.white50};
            background-color: ${theme.color.rainyDay};

            .buttonSvg svg path {
                fill: ${theme.color.white50};
            }
        `}
    `}

    ${props => props.type === 'secondary' && css`
        background-color: transparent;
        ${props => !props.disabled && css`
            color: ${theme.color.white};
            border: solid 2px ${theme.color.white};
            .buttonSvg svg path {
                fill: ${theme.color.white};
            }

            &:hover, &:active {
                color: ${theme.color.dodgerBlue};
                border: solid 2px ${theme.color.dodgerBlue};
                .buttonSvg svg path {
                    fill: ${theme.color.dodgerBlue};
                }
            }

            &:active {
                background-color: ${theme.color.white20};
            }

            ${props => props.size === 'thin' && css`
                color: ${theme.color.white};

                &:hover {
                    color: ${theme.color.white};
                    background: ${theme.color.dodgerBlue};
                    border: none;
                }

                &:active {
                    color: ${theme.color.white};
                    background: ${theme.color.blueJeans};
                    border: solid 2px ${theme.color.whiteGrey};
                }
            `}
        `}

        ${props => props.disabled && css`
            cursor: default;

            color: ${theme.color.rainyDay};
            border: solid 2px ${theme.color.rainyDay};
            .buttonSvg svg path {
                fill: ${theme.color.rainyDay};
            }
        `}
    `}

    ${props => props.type === 'tertiary' && css`
        background-color: transparent;
        width: fit-content;
        border-radius: 0;
        padding: 0;
        height: fit-content;
        text-transform: capitalize;
        font-size: 12px;
        font-weight: 400;

        ${props => !props.disabled && css`
            color: ${theme.color.white};
            border-bottom: 1px solid ${theme.color.white};
            .buttonSvg svg path {
                fill: ${theme.color.white};
            }

            &:hover, &:active {
                color: ${theme.color.dodgerBlue};
                border-bottom: 1px solid ${theme.color.dodgerBlue};
                .buttonSvg svg path {
                    fill: ${theme.color.dodgerBlue};
                }
            }

            &:active {
                background-color: ${theme.color.white20};
            }
        `}

        ${props => props.disabled && css`
            cursor: default;

            color: ${theme.color.rainyDay};
            border-bottom: 1px solid ${theme.color.rainyDay};
            .buttonSvg svg path {
                fill: ${theme.color.rainyDay};
            }
        `}
    `}
`

const Button = ({
        type = 'primary', 
        size = 'M', 
        title, 
        onClick, 
        disabled = false, 
        iconURL = null, 
        svgIcon = null,
        style,
        ...rest
    }) => {
    let isSmallButton = size === 'thin' || size === 'S';
    if ((isSmallButton && svgIcon) || (isSmallButton && iconURL)) throw 'Cannot have icon with thin or S button';

    return (
    <StyledButton 
        type={type}
        onClick={onClick}
        disabled={disabled}
        size={size}
        style={style}
        {...rest}
        >
            { iconURL !== null ? <img src={iconURL} alt="icon"></img> : null }
            { svgIcon !== null ? <span className='buttonSvg'>{svgIcon}</span> : null }
            { title }
        
    </StyledButton>
    );
}

export default Button;