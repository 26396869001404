import React from 'react';
import Main from "component/main";
import { createBrowserRouter } from "react-router-dom";
import { PrivateRoutes } from "./PrivateRoutes";
import { ErrorRoute } from './ErrorRoute';
import { APP_ENV } from 'config/config';
import { StreetImprovement } from 'component/apps/StreetImprovement';
import { CityWorkAggregation } from 'component/apps/CityWorkAggregation';
import { AbatementOptimization } from 'component/apps/AbatementOptimization';
import { SelfService } from 'component/apps/selfService';
import { FleetDecarbonization } from 'component/apps/FleetDecarbonization';
import { AngelesLink } from 'component/apps/AngelesLink';
import { Methane } from 'component/apps/Methane';
import { Population } from 'component/apps/Population';

//Routes available in all envs
const ProdRoutes = [
  {
    path: "street-improvement",
    Component: StreetImprovement
  },
  {
      path: "city-work-aggregation",
      Component: CityWorkAggregation
  },
  {
      path: "abatement-optimization",
      Component: AbatementOptimization
  },
  {
    path: "fleet-decarbonization",
    Component: FleetDecarbonization
  },
  {
      path: "self-service",
      Component: SelfService
  }
]

//Routes available in Dev && Test
// const TestRoutes = [
  
// ]

//Routes available in Dev only
const DevRoutes = [
  {
    path: "angeles-link",
    Component: AngelesLink
  },
  {
      path: "methane",
      Component: Methane
  },
  {
      path: "population",
      Component: Population
  }
]

export const getRouter = () => {
    const routes = [...ProdRoutes];
    // if (`${APP_ENV}` !== 'prd') routes.push(...TestRoutes);
    if (`${APP_ENV}` === 'dev') routes.push(...DevRoutes);

    return createBrowserRouter([
        {
          id: "root",
          element: <PrivateRoutes />,
          errorElement: <ErrorRoute />,
          children: [
            {
                path: "/",
                Component: Main,
                errorElement: <ErrorRoute />, 
                children: routes
              }
          ],
        }
      ]);
}
