import { Layer, Source } from "react-map-gl"
import { useEffect, useState } from "react";
import { isActiveLayer, selectIsLayerActive, useActivateMapLayer, useMapHoverEvent, selectBeforeIDs, useMapEvent, useLoadMapImage } from "@innovation-toolkit/mapbox";
import { useSelector } from "react-redux";
// import { LSAPopup } from "./popup";
// import { UNCLUTTERED_MIN_ZOOM } from "../../constants";
import cp10_symbol from './cp10.png';
import axios from 'axios'
import { APIGateways } from "config/config";
import { CP10Popup } from "./popup";

export const CP_10_LAYER = 'CP_10_LAYER';
export const CP_10_SOURCE = 'CP_10_SOURCE';

export const CP10LayerMetadata = {
    layerId: CP_10_LAYER,
    name: 'CP 10 Layer',
    color: '#8F00FF',
    layerSymbols: [
        { symbol: cp10_symbol, symbolName: 'valve-icon' },
    ]
}


const fetchCP10Data = (callBack, filterOptions) => {

    const url = `${APIGateways.streetimprovements}/reference/cp10`;

    const filter = {}

    axios.post(url, filter)
        .then((response) => {
            return fetch(response.data)
            .then(fetchResponse => fetchResponse.json())
            .then(data => {
                callBack(data, false)
            })  
        })
        .catch((err) => {
            callBack(null, true)
        })
}

export const CP10Layer = ({showLayer=false}) => {

    const [cp10Data, setCP10Data] = useState(null)
    const beforeIDs = useSelector(selectBeforeIDs)
    const [showMandIWorkLayer] = useActivateMapLayer(CP10LayerMetadata, showLayer);
    const [showPopup, setShowPopup] = useState(false)
    const [popupFeature, setPopupFeature] = useState(null);
    const [lngLat, setLatLng] = useState(null);
    const [target, setTarget] = useState(null);

    useEffect(() => {
        // Fetch LSA Data
        fetchCP10Data((data, isError) => {
            if (!isError) {
                setCP10Data(data)
            } 
        })
    },[])

    useLoadMapImage('cp10-icon', cp10_symbol)

    useMapEvent("click", CP_10_LAYER, (e)=> {

        setShowPopup(true);
        setPopupFeature(e.features[0]);
        setLatLng(e.lngLat);
        setTarget(e.target);
    })

    const sourceConfig = {
        id: CP_10_SOURCE,
        type: "geojson",
        data: cp10Data,
        cluster: true,
        clusterMaxZoom: 12,
        clusterRadius: 60,
        // clusterProperties: {
        // LeakObjectIDList: ['concat', ['concat', ['to-number', ['get', 'LeakObjectID']], ', '], ['accumulated']],
        // },
    }

    const symbolConfig = {
        id: CP_10_LAYER,
        type: 'symbol',
        source: CP_10_SOURCE,
        layout: {
        visibility: showMandIWorkLayer ? 'visible' : 'none',
        'icon-image': 'cp10-icon',
        'icon-size': 0.45,
        'icon-allow-overlap': true,
        },
        paint: {
        'icon-translate': [0,-27],
        },
        beforeId: beforeIDs.symbol
    };

    return (
        <Source {...sourceConfig}>
            <Layer {...symbolConfig}/>
            <CP10Popup 
                isOpen={showPopup}
                onClose={() => setShowPopup(false)}
                lngLat={lngLat}
                feature={popupFeature}
                target={target}
            />        
            </Source>
    )
}
