import {createSelector} from "reselect";
import {createCommunitiesOption} from "../../utils/utils";

const selectState = state => state.mapBox;

const selectRegionSource = createSelector(
    selectState,
    state => state.region
);

const selectDistrictsSource = createSelector(
    selectState,
    state => state.districts
);

const selectMethaneSource = createSelector(
    selectState,
    state => state.methane
);

const selectZoom = createSelector(
    selectState,
    state => state.zoom
);

const selectFleetImpactEmissionsSource = createSelector(
    selectState,
    state => state.fleetImpact
);

const selectImpactedCommunities = createSelector(
    selectState,
    state => state.impactCommunities
)

const selectGroupedCensusTractsByName = createSelector(
    selectRegionSource,
    state => state && createCommunitiesOption(state)
)

const selectGroupOptionsCensusTracts = createSelector(
    selectGroupedCensusTractsByName,
    state => state &&
        Object.keys(state)
            .map(item => {
                // Name the option N/A if it's an empty string
                return { label: item === '' ? 'N/A' : item, value: item === '' ? 'N/A' : item, tract: state[item] }
            })
            // Alphabetize list of communities
            .sort((a, b) => {
                if (a.label < b.label) {
                    return -1
                } else {
                    return 1
                }
            })
            // Move N/A to the end of the array
            .sort((a, b) => {
                if (a.label === 'N/A') {
                    return 1
                }
                if (b.label === 'N/A') {
                    return -1
                }
                return 0
            })
)

const selectDacCensusTracts = createSelector(
    selectRegionSource,
    state => {
        const features = state && state.filter(item => item.dac);
        const neState = features && createCommunitiesOption(features)
        return neState && Object.entries(neState).map(([name, tract]) => {
            return {label: name, value: name, tract}
        })

    }
)


export {
    selectRegionSource,
    selectDistrictsSource,
    selectMethaneSource,
    selectZoom,
    selectFleetImpactEmissionsSource,
    selectImpactedCommunities,
    selectGroupOptionsCensusTracts,
    selectDacCensusTracts,
}