import {
    SET_GEOMETRY_STATE,
    SET_GEOMETRY_STATE_ATTRIBUTE,
    SET_OPTIONAL_ATTRIBUTE,
    SET_STYLE_STATE,
    SET_STYLE_STATE_ATTRIBUTE,
    SET_SIMPLE_ANALYTICS_STATE_ATTRIBUTE,
    SET_SHOW_SIMPLE_ANALYTICS_TABLE,
    SET_SIMPLE_ANALYTICS_TABLE_DATA,
    SET_FILE_UPLOAD_RESPONSE,
    SET_FILE_COLUMNS,
    SET_FILE_METADATA,
    SET_FILE_LOADED,
    SET_FILE_UPLOAD_GEO_SOURCE,
    RESET_SELF_SERVICE_STYLE,
    RESET_FILE_CLASSIFICATION,
    RESET_SELF_SERVICE,
    SET_FILE_DESCRIPTION,
    SET_FILE_CLASSIFICATION
} from "./actionTypes"

export const initialState = {
    metadata: {
        columns: null,
        bucket: null,
        key: null,
        description: null,
        loaded: null,
    },
    geometry: {
        dataLayout: null,
        latitude: null,
        longitude: null
    },
    optionalAttributes: {
        timeFrame: null,
        censusTracts: null
    },
    style: {
        mapType: 'Point',
        dataField: null,
        color: '#112944',
        borderSize: '1px',
        borderColor: '#FFFFFF',
        heatmapAttribute: null,
        heatmapColors: []
    },
    mapSource: null,
    simpleAnalytics: {
        showTable: false,
        tableSetUp: {
            rows: 'DAC75',
            columns: '',
            values: '',
            valueType: 'Number',
            aggregation: ''
        },
        tableData: null,
    },
    fileInfo: {
        keyPath: null,
        bucketPath: null,
        responseUrl: null
    },
    classification: null
}

const SelfService = (state = initialState, { type, payload }) => {
    switch (type) {
        case SET_GEOMETRY_STATE:
            return { ...state, geometry: payload }
        case SET_GEOMETRY_STATE_ATTRIBUTE:
            return { ...state, geometry: { ...state.geometry, ...payload } }
        case SET_OPTIONAL_ATTRIBUTE:
            return { ...state, optionalAttributes: { ...state.optionalAttributes, ...payload } }
        case SET_STYLE_STATE:
            return { ...state, style: payload }
        case SET_STYLE_STATE_ATTRIBUTE:
            return { ...state, style: { ...state.style, ...payload } }
        case SET_FILE_CLASSIFICATION:
            return { ...state, classification: payload }
        case SET_FILE_COLUMNS:
            return { ...state, metadata: { ...state.metadata, columns: [...payload] } }
        case SET_FILE_METADATA:
            return { ...state, metadata: { ...state.metadata, bucket: payload.bucket, key: payload.key, description: payload.description } }
        case SET_FILE_DESCRIPTION:
            return { ...state, metadata: { ...state.metadata, description: payload.description } }
        case SET_FILE_UPLOAD_GEO_SOURCE:
            return { ...state, mapSource: payload }
        case SET_SIMPLE_ANALYTICS_STATE_ATTRIBUTE:
            return { ...state, simpleAnalytics: { ...state.simpleAnalytics, tableSetUp: { ...state.simpleAnalytics.tableSetUp, ...payload } } }
        case SET_SHOW_SIMPLE_ANALYTICS_TABLE:
            return { ...state, simpleAnalytics: { ...state.simpleAnalytics, showTable: payload } }
        case SET_SIMPLE_ANALYTICS_TABLE_DATA:
            return { ...state, simpleAnalytics: { ...state.simpleAnalytics, tableData: payload } }
        case SET_FILE_UPLOAD_RESPONSE:
            return { ...state, fileInfo: payload }
        case SET_FILE_LOADED:
            return { ...state, metadata: { ...state.metadata, loaded: payload } }
        case RESET_FILE_CLASSIFICATION:
            return {...state, classification: initialState.classification}
        case RESET_SELF_SERVICE_STYLE:
            return { 
                ...state, 
                geometry: initialState.geometry,
                optionalAttributes: initialState.optionalAttributes,
                style: initialState.style,
                mapSource: initialState.mapSource,
                simpleAnalytics: initialState.simpleAnalytics
            }
        case RESET_SELF_SERVICE:
            return {
                ...state,
                metadata: initialState.metadata,
                geometry: initialState.geometry,
                optionalAttributes: initialState.optionalAttributes,
                style: initialState.style,
                mapSource: initialState.mapSource,
                simpleAnalytics: initialState.simpleAnalytics,
                fileInfo: initialState.fileInfo
            }
        default:
            return state;
    }
}

export default SelfService;