import { Layer, Source } from "react-map-gl"
import { APIGateways } from "@config/config";
import { useEffect, useState } from "react";
import { addToMapLoadingList, removeFromMapLoadingList, selectIsLayerActive, useActivateMapLayer, selectBeforeIDs, LegendItem } from "@innovation-toolkit/mapbox";
// import { LUFTsPopup } from "./popup";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";

export const LUFTS_LAYER = 'LUFTS_LAYER';
export const LUFTS_SOURCE = 'LUFTS_SOURCE';

export const LUFTsLayerMetadata = {
    layerId: LUFTS_LAYER,
    name: 'LUFTs',
    color: '#FBA804'
}

// TODO_ST
const fetchLUFTssData = (callBack, filterOptions) => {

    const url = `${APIGateways.scgcore}/data/lufts`;

    const filter = {}

    axios.get(url, filter)
        .then((response) => {
            // Returns as a presigned url, fetch the data here
            return fetch(response.data)
                .then((fetchResponse) => fetchResponse.json())
                .then((data) => {
                    callBack(data, false)
                })
        })
        .catch((err) => {
            callBack(null, true)
        })
}

export const LUFTsLayer = ({showLayer=false}) => {

    const dispatch = useDispatch()

    const [showLUFTsLayer] = useActivateMapLayer(LUFTsLayerMetadata, showLayer)
    const beforeIDs = useSelector(selectBeforeIDs)

    const [data, setData] = useState(null);

    useEffect(() => {
        // dispatch(addToMapLoadingList('LUFTs'))
        fetchLUFTssData((data, isError) => {
            if (!isError) {
                setData(data)
            } 
            // dispatch(removeFromMapLoadingList('LUFTs'))
        })
    }, []) // Add searchDates to filter on search

    const sourceConfig = {
        id: LUFTS_SOURCE,
        type: "geojson",
        data: data,
        cluster: true,
        clusterMaxZoom: 12,
        clusterRadius: 15,
    }

    const circleConfig = {
        id: LUFTS_LAYER,
        type: "circle",
        source: LUFTS_SOURCE,
        layout: {
            'visibility': showLUFTsLayer ? 'visible' : 'none'
        },
        paint: {
            'circle-color': LUFTsLayerMetadata.color,
            'circle-stroke-color': '#AF5603',
            'circle-stroke-width': 1
        },
        beforeId: beforeIDs.circle
    }

    const innerCircleConfig = {
        id: LUFTS_LAYER + "_inner",
        type: "circle",
        source: LUFTS_SOURCE,
        layout: {
            'visibility': showLUFTsLayer ? 'visible' : 'none'
        },
        paint: {
            'circle-color': LUFTsLayerMetadata.color,
            'circle-stroke-color': '#AF5603',
            'circle-stroke-width': 1,
            'circle-radius': 2
        },
        beforeId: beforeIDs.circle
    }

    return (
        <Source {...sourceConfig}>
            <Layer {...circleConfig} />
            <Layer {...innerCircleConfig} />
        </Source>
    )
}

LUFTsLayer.layerName = 'LUFTs';
LUFTsLayer.legendColor = '#FFFFFF';

export const LUFTsLegendSection = () => {
    const showLegend = useSelector((state) => selectIsLayerActive(state, LUFTsLayerMetadata.layerId))

    if (!showLegend) {
        return <></>
    }

    return (
        <LegendItem color={LUFTsLayerMetadata.color} title={LUFTsLayerMetadata.name} small={true}/>
    )
}