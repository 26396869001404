import { DataTable, InfinityTable, Typography, NoDataInfo } from '@innovation-toolkit/ui';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from "styled-components";
import { selectVehicleReplacementTable, setVehicleReplacementTable } from 'redux/fleetDecarbonization/slice';
import { APIGateways } from 'config/config';
import axios from 'axios'
import { Spin } from 'antd';

const TableWrapper = styled.div`
    width: 920px;
`;

const TableTitleWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 24px;
    position: relative;
`
const fetchVehicleReplacementData = (callBack, filterOptions) => {

  const url = `${APIGateways.legacy}/scg_fleet/vehicle_replacement_table`;

  const filter = {}

  axios.post(url, filter)
      .then((response) => {
          // Returns as a presigned url, fetch the data here
          fetch(response.data)
              .then((fetchResponse) => fetchResponse.json())
              .then((data) => {
                  callBack(data, false)
              })
      })
      .catch((err) => {
          callBack(null, true)
      })
}

export const VehicleReplacementTable = () => {
    const dispatch = useDispatch()
    const {vehicleReplacementTable} = useSelector(selectVehicleReplacementTable)
    const [vehicleReplacementData, setVehicleReplacementData] = useState([])
    const [loading, setLoading] = useState(true)

    useEffect(()=>{
      fetchVehicleReplacementData((data, isError) => {
          if (!isError) {
            setVehicleReplacementData(data)
            setLoading(false)
          } 
      })
  }, [])

    const handleClose = () => {
        dispatch(setVehicleReplacementTable({vehicleReplacementTable: false}))
    }

    const vehicleReplacementTableColumns = [
        {
            title: <Typography variant='Heading 4' text='Ranking' />,
            dataIndex: 'rank',
            defaultSortOrder: 'ascend',
            sorter: (a, b) => a.rank - b.rank,
        },
        {
            title: <Typography variant='Heading 4' text='Vehicle' />,
            dataIndex: 'vehicle_id',
            defaultSortOrder: 'ascend',
            sorter: (a, b) => a.vehicle_id - b.vehicle_id,
        },
        {
            title: <Typography variant='Heading 4' text='DAC CO2 Emissions' />,
            dataIndex: 'DAC_emissions',
            defaultSortOrder: 'ascend',
            sorter: (a, b) => a.DAC_emissions - b.DAC_emissions,
            render(text) {
              return {
                  children: Math.round(text).toLocaleString({ maximumFractionDigits: 3 })
              }
          }
        },
        {
            title: <Typography variant='Heading 4' text='Total CO2 Emissions' />,
            dataIndex: 'total_emissions',
            defaultSortOrder: 'ascend',
            sorter: (a, b) => a.total_emissions - b.total_emissions,
            render(text) {
              return {
                  children: Math.round(text).toLocaleString({ maximumFractionDigits: 3 })
              }
          }
        },
    ];

    const CustomSectionTitle = () => {    
        return  <TableTitleWrapper style={{right: '20px', top: '5px'}}><h4>{"Showing emissions in Pounds"}</h4>
    </TableTitleWrapper>           
    }

    const tabs = [
        {
            title: "Vehicle Replacement",
            contentSections: [
                {
                    title: vehicleReplacementData.length === 0 ? null : <CustomSectionTitle />,
                    content: <Spin spinning={loading}>
                              <TableWrapper style={{width: '800px'}}>
                                  { vehicleReplacementData.length === 0
                                      ? <NoDataInfo title='No data found'/>
                                      : <InfinityTable columns={vehicleReplacementTableColumns} dataSource={vehicleReplacementData} scrollAreaHeight={224}/>
                                  }                                
                              </TableWrapper>
                            </Spin>
                }
            ]
        },
    ]
    return (
        <DataTable
            handleClose={handleClose}
            open={vehicleReplacementTable}
            tabs={tabs}
        />
    )
}