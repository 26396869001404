import { Layer, Source, useMap } from "react-map-gl"
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { isActiveLayer, useActivateMapLayer, useLoadMapImage, zoomInOnCluster, selectBeforeIDs, useMapEvent } from "@innovation-toolkit/mapbox";
import mandi_m from './MANDI_M.png';
import mandi_low from './MANDI_LOW.png';
import mandi_med from './MANDI_MED.png';
import mandi_high_med from './MANDI_HIGH_MED.png';
import mandi_high from './MANDI_HIGH.png';
import mandi_np from './MANDI_NP.png';

import { MandILeakPopup } from "./popup";
import _ from 'lodash';
import { selectMandILeakData, selectMandiLeaksFilterMap } from "redux/abatementOptimization/slice";

export const MANDI_LEAKS_LAYER = 'MANDI_LEAKS_LAYER';
export const MANDI_LEAKS_SOURCE = 'MANDI_LEAKS_SOURCE';

export const MandIWorkLayerMetadata = {
    layerId: MANDI_LEAKS_LAYER,
    name: 'M&I Leaks',
    color: '#FFFFFF',
    layerSymbols: [
        { symbol: mandi_m, symbolName: 'mandi-m-icon' },
        { symbol: mandi_low, symbolName: 'mandi-low-icon' },
        { symbol: mandi_med, symbolName: 'mandi-med-icon' },
        { symbol: mandi_high_med, symbolName: 'mandi-high-med-icon' },
        { symbol: mandi_high, symbolName: 'mandi-high-icon' },
        { symbol: mandi_np, symbolName: 'mandi_np-icon' }
    ]
}

export const MandILeakLayer = ({showLayer=true}) => {
    const { current: map } = useMap();

    const [showMandIWorkLayer] = useActivateMapLayer(MandIWorkLayerMetadata, showLayer);
    const beforeIDs = useSelector(selectBeforeIDs)
    const [mAndIDataFormatted, setMandIFormattedData] = useState(null);
    const mAndIData = useSelector(selectMandILeakData);
    const mandiLeaksFilterMap = useSelector(selectMandiLeaksFilterMap);

    useLoadMapImage('mandi-m-icon', mandi_m)
    useLoadMapImage('mandi-low-icon', mandi_low)
    useLoadMapImage('mandi-med-icon', mandi_med)
    useLoadMapImage('mandi-high-med-icon', mandi_high_med);
    useLoadMapImage('mandi-high-icon', mandi_high)
    useLoadMapImage('mandi-np-icon', mandi_np)

    const [showPopup, setShowPopup] = useState(false)
    const [popupFeature, setPopupFeature] = useState(null);
    const [lngLat, setLatLng] = useState(null);
    const [target, setTarget] = useState(null);

    useEffect(() => {
        if (!mAndIData) return;

        let newFormattedData = _.cloneDeep(mAndIData);
        // replace null values with false for Vegetation and Highway
        newFormattedData.features = newFormattedData.features.map(element => {
            return {
                ...element,
                properties: {
                    ...element.properties,
                    Vegetation: element.properties.Vegetation ?? false,
                    Highway: element.properties.Highway ?? false,
                }
                

            }
        })
        setMandIFormattedData(newFormattedData)

    }, [mAndIData])

    
    useMapEvent('click', MANDI_LEAKS_LAYER, (e) => {
        if (!isActiveLayer(e, MANDI_LEAKS_LAYER)) return;
        const feature = e.features[0];     

        if (feature.properties.cluster) {

            zoomInOnCluster(map, MANDI_LEAKS_SOURCE, e.features)
    
            return
        }

        setShowPopup(true);
        setPopupFeature(feature);
        setLatLng(e.lngLat);
        setTarget(e.target);
    });

    //Used to filter M&I Leaks based on prioritization value
    const filterMandiLeak = (filterMap) => {
        //Filter down to priority values that should be excluded from view
        //Create an expression for that specified key
        let priorityFilters = Object.keys(filterMap).filter((key) => !filterMap[key]).map((key) => ['!=', ['get', "priority_ranking"], key])

        return ["all", ...priorityFilters];
    }

    const sourceConfig = {
        id: MANDI_LEAKS_SOURCE,
        type: "geojson",
        data: mAndIDataFormatted,
        cluster: true,
        clusterMaxZoom: 12,
        clusterRadius: 60,
        clusterProperties: {
            LeakObjectIDList: ['concat', ['concat', ['to-number', ['get', 'LeakObjectID']], ', '], ['accumulated']],
            Ranking: ['concat', ['concat', ['get', 'Ranking'], ', '], ['accumulated']]
        },
    }

    // Pins
    const symbolConfig = {
        id: MANDI_LEAKS_LAYER,
        type: 'symbol',
        source: MANDI_LEAKS_SOURCE,
        layout: {
            visibility: showMandIWorkLayer ? 'visible' : 'none',
            'icon-image': 
            ['step',
                ['zoom'],
                'mandi-m-icon',
                12, [
                        'case',
                        ['==', "high_mid", ['get', 'priority_ranking']], 'mandi-high-med-icon',
                        ['in', 'high', ['get', 'priority_ranking']], 'mandi-high-icon',
                        ['in', 'mid', ['get', 'priority_ranking']], 'mandi-med-icon',
                        ['in', 'low', ['get', 'priority_ranking']], 'mandi-low-icon',
                        ['in', 'no ranking factor', ['get', 'priority_ranking']], 'mandi-np-icon',
                        'mandi-m-icon'
                    ]
            ],
            'icon-size': 1,
            'icon-allow-overlap': true,
        },
        filter: filterMandiLeak(mandiLeaksFilterMap),
        paint: {
            'icon-translate': [0,-27],
        },
        beforeId: beforeIDs.symbol
    };


    return (
        <>
        <Source {...sourceConfig}>
            <Layer {...symbolConfig} />
            <MandILeakPopup 
                isOpen={showPopup}
                onClose={() => setShowPopup(false)}
                lngLat={lngLat}
                feature={popupFeature}
                target={target}
            />
        </Source>
        </>

    )
}