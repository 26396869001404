import styled from 'styled-components'

const StyledToggleContainer = styled.div`
    .LayerToggleContainer {
        padding: 8px 0 8px 12px;
        
        // .LayerToggleIconHeading {
        //     .tk-heading-4 {
        //         font-family: 'Interstate', sans-serif;
        //         font-weight: 400;
        //         font-size: 16px;
        //         line-height: 19.2px;
        //     }
        // }
    }
`
export const ToggleContainer = ({children}) => {
    return (
        <StyledToggleContainer>{children}</StyledToggleContainer>
    )
}