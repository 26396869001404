import { SET_WORK_TYPES } from "redux/actionTypes"
import {
    SET_WORK_TABLE_TYPE,
    SET_OVERLAP_IDS,
    SET_VALUE_METRICS_TABLE_TYPE,
    SET_CPD_OPTIONS,
    SET_MANDI_OPTIONS,
    SET_SELECTED_FILTERING_LAYER,
    SET_CITY_WORK_DATA,
    SET_DRAWN_GEOMETRIES,
    SET_ROW_UPDATE_INFO,
    SET_SHOW_OVERLAP_TABLE,
    SET_PROJECT_TYPES,
    SET_SHOW_MORATORIUM_MAP_DATA,
    SET_SEARCH_PLANNER_ID,
    SET_SEARCH_NOTIFICATION_NO,
    SET_SEARCH_ORDER_NO,
    SET_IS_EDITING_ROWS,
    SET_HIGHLIGHT_MAP,
    SET_MANDI_CHECKBOX_STATES,
    SET_OPEN_ORDER_ID_TO_FILTER
} from "./actionTypes"

export const initialState = {
    workTable: {
        city: false,
        overlaps: false,
        projects: true,
        moratoriums: true,
    },
    valueMetrics : {
        toolUsage: false,
        planning: false,
    },
    overlapIds: {
        mandi: [],
        cpd: []
    },
    workFilter: {
        cpdOptions: null,
        mAndIOptions: null,
        layerSelected: 'All',
        plannerID: '',
        notificationNumber: '',
        orderNumber: ''
    },
    cityWorkData: null,
    showMoratoriumMapData: true,
    drawnGeometries: {
        type: 'FeatureCollection',
        features: []
    },
    rowUpdateInfo: {
        index: -1,
        isUpdating: false
    },
    isEditingRows: false,
    showOverlapTable: false,
    projectTypes: [],
    highlightedMap: null,
    mandiCheckboxStates: [true, true, true, true, true, true, true, true],
    openOrderIdsToFilter: [],
    workTypes: []
}

const StreetImprovements = (state = initialState, { type, payload }) => {
    switch (type) {
        case SET_WORK_TABLE_TYPE:
            if (!payload) {
                return {...state, workTable: initialState.workTable}
            }
            return {...state, workTable: {...state.workTable, ...payload}}
        // case SET_WORK_TABLE_PROJECTS:
        //     return { ...state, workTable: { ...state.workTable, projects: payload } }
        // case SET_WORK_TABLE_MORATORIUMS:
        //     return { ...state, workTable: { ...state.workTable, moratoriums: payload } }
        case SET_OVERLAP_IDS:
            return {...state, overlapIds: { ...payload}}
        case SET_VALUE_METRICS_TABLE_TYPE:
            if (!payload) {
                return {...state, valueMetrics: initialState.valueMetrics}
            }
            return {...state, valueMetrics: {...state.valueMetrics, ...payload}}
        case SET_CPD_OPTIONS:
            return {...state, workFilter: { ...state.workFilter, cpdOptions: payload}}
        case SET_MANDI_OPTIONS:
            return {...state, workFilter: { ...state.workFilter, mAndIOptions: payload}}
        case SET_SELECTED_FILTERING_LAYER:
            return {...state, workFilter: { ...state.workFilter, layerSelected: payload}}
        case SET_CITY_WORK_DATA:
            return {...state, cityWorkData: payload}
        case SET_DRAWN_GEOMETRIES:
            return { ...state, drawnGeometries: payload}
        case SET_ROW_UPDATE_INFO:
            return { ...state, rowUpdateInfo: payload}
        case SET_SHOW_OVERLAP_TABLE:
            return { ...state, showOverlapTable: payload}
        case SET_PROJECT_TYPES:
            return {...state, projectTypes: payload}
        case SET_SHOW_MORATORIUM_MAP_DATA:
            return {...state, showMoratoriumMapData: payload}
        case SET_SEARCH_PLANNER_ID:
            return {...state, workFilter: {...state.workFilter, plannerID: payload}}
        case SET_SEARCH_NOTIFICATION_NO:
            return {...state, workFilter: {...state.workFilter, notificationNumber: payload}}
        case SET_SEARCH_ORDER_NO:
            return {...state, workFilter: {...state.workFilter, orderNumber: payload}}
        case SET_IS_EDITING_ROWS:
            return { ...state, isEditingRows: payload }
        case SET_HIGHLIGHT_MAP:
            return { ...state, highlightedMap: payload }
        case SET_MANDI_CHECKBOX_STATES:
            return { ...state, mandiCheckboxStates: payload }
        case SET_OPEN_ORDER_ID_TO_FILTER:
            return { ...state, openOrderIdsToFilter: payload }
        case SET_WORK_TYPES:
            return {...state, workTypes: payload}
        default:
            return state;
    }
}

export default StreetImprovements;