import React, { useEffect } from 'react';
import { Content } from 'antd/lib/layout/layout';
import { Layout } from 'antd/lib';
import { AbatementOptimizationMapLayers } from './layers/mapLayers';
import { AbatementOptimizationMenu } from './menu';
import { useDispatch } from 'react-redux';
import { setActiveApp } from '../../../../../shared/redux/apps';
import { AbatementOptimizationSimpleAnalytics } from './SimpleAnalytics';
import LeakPrioritizationTable from './LeakPrioritizationTable';
import MapboxReact from 'component/mapbox/mapbox';

export const AbatementOptimization = () => {
    const dispatch = useDispatch();
    
    useEffect(() => {
        dispatch(setActiveApp('abatement-optimization'));
    }, [dispatch]);

    return (
        <>
            <AbatementOptimizationMenu />
            <Layout style={{ maxHeight: '100vh' }}>
                <Content style={{
                    margin: 0,
                    position: "relative",
                    color: "white"
                }}>
                    <AbatementOptimizationSimpleAnalytics/>
                    <LeakPrioritizationTable />
                    <MapboxReact layers={<AbatementOptimizationMapLayers />} />
                </Content>
            </Layout>
        </>
    )
}

