import { MapPopup } from '@innovation-toolkit/mapbox';
import { ChevronLeftIcon, ChevronRightIcon, DelimiterLine, MapPopupContents, selectActiveApp, Typography } from '@innovation-toolkit/ui';
import { useState, useEffect } from 'react';
import moment from 'moment';
import './popup.css'
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { APIGateways } from '@config/config';
import { PopupContents } from './components/PopupContents';

const toDateString = (date) => {
    if (!date) return '';

    return moment(date).format('MM/DD/YYYY');
}

// Styled Components
const StyledContainer = styled.div`
  display: flex;
  align-items: center;
`;

const ArrowContainer = styled.div`
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  height: 50px;
`;

const StyledArrow = styled.button`
  background: none;
  border: none;
  font-size: 2rem;
  cursor: pointer;
  padding: 10px;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
`;

const ContentContainer = styled.div`
  flex: 1 1 auto;
  overflow-y: scroll;
  max-height: 245px;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  padding: 10px;
  text-align: left;
`;

// Using mock data for now
const leakPrioritizationData = {
    priority: 'High',
    overlapsWithUpcomintCityWork: false,
    listedInLargePrioritization: true,
    locatedWithinRailroadGISLayer: false,
    locatedWithinNationalHighwayGISLayer: false,
    locatedWithinCNDDGISLayer: false,
    locatedWithinVegetationGISLayer: false,
}

const formatPrioritizationData = (element) => {
    const formattedInformation = {     
        priority: element.priority,
        highPriority: {
            'Overlaps with upcoming city work': element.OverlapsWithUpcomintCityWork,
            'Listed in large prioritization': element.ListedInLargePrioritization,
        },
        mediumPriority: {
            'Located within Railroad GIS layer': element.LocatedWithinRailroadGISLayer,
            'Located within National Highway GIS layer': element.LocatedWithinNationalHighwayGISLayer,
        },
        lowPriority: {
            'Located within CNDD GIS layer': element.LocatedWithinCNDDGISLayer,
            'Located within Vegetation GIS layer': element.LocatedWithinVegetationGISLayer,
        },
    }

    Object.keys(formattedInformation).forEach(key => {
        if (!formattedInformation[key]) {
            formattedInformation[key] = '-'
        }
    })

    return formattedInformation
}
// Respective attributes
const formatDetails = (element, openOrderType) => {

    const openOrderFormattedInformation = {
        'Leaks' : {     
            'Order': element.order_number,
            'District': element.district,
            'Address': element.address,
            'City': element.city,
            'Leak Code': element.openordermatcode,
            'Leak Object ID':  element.leak_object_id,
            'Required Permanent Repair Date':  element.required_permanent_repair_date,
            'MAT Code': element.matcodedetectionorder,
            'Priority': "High",
            'Work Type': element.sap_work_type,
            'Detect Date': toDateString(element.detect_date),
        },
        'LSAData' : {
            'Order Number': element.order_number,
            'District': element.district,
            'FLOC': element.functional_location,
            'Order Description': element.orderdescription,
            'Notification Required End Date': element.end_date,
            'MAT Code': element.matcode,
        },
        'SpecialSurveyData' : {
            'Order Number': element.order_number,
            'District': element.district,
            'FLOC': element.functional_location,
            'Order Description': element.orderdescription,
            'Notification Required End Date': element.end_date,
            'MAT Code': element.matcode,
        },
        'CPAreaData' : {
            'Order Number': element.order_number,
            'District': element.district,
            'Area Name': element.areaname,
            'FLOC': element.functional_location,
            'Anniversary Month': element.anniversarymonth,
            'Number of Active Points': element.activepoints,
            'Area Footage': element.areafootage,
            'Protection Type': element.protectiontype,
        },
        'ValvesData' : {
            'Order Number': element.order_number,
            'District': element.district,
            'Location': element.location,
            'City': element.city,
            'Valve Number': element.valve_number,
            'Equipment Number': element.valve_equipment_number,
            'Start Up Date': toDateString(element.startupdate),
            'Valve Material':  element.valvematerial,
            'Valve Type':  element.valvetype,
            'Position':  element.position,
            'Maintenance Plan Description':  element.maintenanceplandescription,
        },
        'CP10Data' : {
            'Order Number': element.order_number,
            'District': element.district,
            'Address': element.address,
            'City': element.city,
            'FLOC': element.functional_location,
            'Meter Number': element.meternumber,
            'Next Read Year': element.nextreadyear,
        },
    }

    const typesOpenOrder = openOrderFormattedInformation[openOrderType]

    Object.keys(typesOpenOrder).forEach(key => {
        if (!typesOpenOrder[key]) {
            typesOpenOrder[key] = '-'
        }
    })

    return typesOpenOrder
}

const openOrderTitle = {
    "LSAData" : "LSA",
    "SpecialSurveyData" : "Special Survey",
    "Leaks" : "Leak",
    "CP10Data" : "CP 10",
    "CPAreaData" : "CP Area",
    "ValvesData" : "Valve",
}

const DataRow = ({ element }) => {
    return (
      <>
        {Object.keys(element).map((key, index) => (
          <div className="MapPopupContentsAttribute" key={index}>
            <Typography text={key} variant='Heading 4' style={{paddingTop: '3px', paddingBottom: '3px', minWidth: '216px'}}/>
            <Typography text={element[key]} variant='Body 1' style={{paddingTop: '3px', paddingBottom: '3px'}}/>
          </div>
        ))}
      </>
    );
  };

const DataContents = ({ data, setArrIndex }) => {
    const isArray = Array.isArray(data);
    const [currentIndex, setCurrentIndex] = useState(0);

  
    useEffect(() => {
        setArrIndex(currentIndex)
    }, [currentIndex]);
  
    const handlePrevious = () => {
      setCurrentIndex(prevIndex => (prevIndex === 0 ? data.length - 1 : prevIndex - 1));
    };
  
    const handleNext = () => {
      setCurrentIndex(prevIndex => (prevIndex === data.length - 1 ? 0 : prevIndex + 1));
    };
  
    if (isArray) {
      return (
        <StyledContainer>
          <ArrowContainer>
            <StyledArrow onClick={handlePrevious} disabled={data.length <= 1}><ChevronLeftIcon style={{color:'white', width: '15px'}}/></StyledArrow>
          </ArrowContainer>
          <ContentContainer>
            <DataRow element={data[currentIndex]} />
            <p className='index'>{currentIndex + 1} of {data.length}</p>
          </ContentContainer>
          <ArrowContainer>
            <StyledArrow onClick={handleNext} disabled={data.length <= 1}><ChevronRightIcon style={{color:'white', width: '15px'}} /></StyledArrow>
          </ArrowContainer>
        </StyledContainer>
      );
    } else {
      return <DataRow element={data} />;
    }
  };


export const MandIWorkPopup = ({isOpen, onClose, lngLat, feature, target, openOrderType}) => {

    const activeApp = useSelector(selectActiveApp);

    const [popupState, setPopupState] = useState({
        lng: 0,
        lat: 0,
        children: null
    })
    const [leakPrioritizationData, setLeakPrioritizationData] = useState([]);

    const pointCount = feature?.properties?.point_count

    // Control whether or not the prioritization factors page of the popup is accessible
    // This should be accessible in the abatement optimization use case
    const [showPrioritizationFactors, setShowPrioritizationFactors] = useState(false);
    useEffect(() => {
        if (activeApp === "abatement-optimization") {
            setShowPrioritizationFactors(true);

            // axios.post(`${APIGateways.leakabatement}/leak_prioritization`)

        } else {
            setShowPrioritizationFactors(false);
        }
    }, [activeApp]);

    const [detailsSelected, setDetailsSelected] = useState(true)

    const [children, setChildren] = useState(null)
    const  [data, setData] = useState({})
    const [arrIndex, setArrIndex] = useState(1)

    useEffect(() => {
        if (feature === null || target === null || openOrderType === null) return

        const clusterSource = openOrderType === 'Leaks' ? target.getSource('MANDI_WORK_SOURCE') : target.getSource(openOrderType);
        const clusterId = feature.properties.cluster_id;
        const pointCount = feature.properties.point_count;

        const getChildren = async () => {
            const childrenPromise = new Promise((resolve, reject) => {
                clusterSource.getClusterLeaves(clusterId, pointCount, 0, (error, features) => {
                    resolve(features);
                })
            })
            const childrenRes = await childrenPromise;

            if (!childrenRes) return
    
            setChildren(childrenRes)
        };
        getChildren();
    }, [target, feature])


    useEffect(() => {
        if (lngLat === null || feature === null) return
        const popupFeature = feature.properties

        const pointCount = popupFeature.point_count ?? 1;

        let description = ''
        if (pointCount === 1) {
            setData(formatDetails(popupFeature, openOrderType));
        } else {
            setData(children?.map(element => formatDetails(element.properties, openOrderType)))
        }
        
        setPopupState({
            lng: lngLat.lng,
            lat: lngLat.lat,
            children: description
        })

    }, [isOpen, lngLat, feature, children, openOrderType])

    return isOpen ?
                <MapPopup closeOnClick={false} longitude={popupState.lng} latitude={popupState.lat} onClose={onClose} className='MandIPopup'>
                    <PopupContents
                        title={<LeakDetailsTitle detailsSelected={detailsSelected} setDetailsSelected={setDetailsSelected} openOrderType={openOrderType}/>}
                        footNote={<MandIFootnote detailsSelected={detailsSelected} setDetailsSelected={setDetailsSelected} showPrioritizationFactors={showPrioritizationFactors}/>}
                        data={detailsSelected ? data : null}
                        children={detailsSelected ? null : <LeakPrioritizationFactors data={leakPrioritizationData}/>}
                        index={arrIndex}
                        setArrIndex={setArrIndex}
                        pointCount={pointCount}
                        openOrderTitle={openOrderTitle[openOrderType]}
                    />
                </MapPopup>
                : null
}

const StyledFootnote = styled.div`
    font-size: 12px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    text-decoration: underline;
    cursor: pointer;
`

function MandIFootnote({ detailsSelected, setDetailsSelected, showPrioritizationFactors }) {
    const handleOnClick = () => {
        setDetailsSelected(!detailsSelected)
    }
    if (!showPrioritizationFactors) {
        return (
            <Typography variant="Body 1" text="Source: SAP HANA"/>
        )
    }

    return (
        <StyledFootnote onClick={handleOnClick}>
            {detailsSelected ? "View Leak Prioritization Factors" : "View Leak Details"}
        </StyledFootnote>
    )
}

function BackToLeakDetails({ setDetailsSelected }) {
    const handleOnClick = () => {
        setDetailsSelected(true)
    }

    return <div onClick={handleOnClick}><ChevronLeftIcon/></div>
}

function LeakDetailsTitle({ detailsSelected, setDetailsSelected, openOrderType, arrIndex, pointCount}) {
    return detailsSelected ? (
        pointCount === undefined ? `SoCalGas Work - M&I ${openOrderTitle[openOrderType]}` : <div style={{color: '#FFFFFF80'}}>{arrIndex + 1}/{pointCount} Work Orders</div>
    ) : (
        <div style={{ display: "flex", gap: "21px" }}>
            <BackToLeakDetails setDetailsSelected={setDetailsSelected} />
            <Typography variant="Heading 1" text="Leak Prioritization Factors" style={{padding: 0, margin: 0}}/>
        </div>
    )
}

function LeakPrioritizationSection({ title, data }) {
    return (
        <div>
            {/* <Typography variant="Subtitle" text="(Placeholder data: waiting on prioritization factor endpoint)"/> */}
            <Typography variant="Heading 4" text={title} style={{marginBottom: '12px'}}/>
            {Object.keys(data).map((key) => {
                return (
                    <div style={{display: 'flex', gap: '14px', marginBottom: '15px'}}>
                        <Typography variant="Heading 4" text={data[key] ? "Y" : "N"}/>
                        <Typography variant="Body 1" text={key}/>
                    </div>
                )
            })}
        </div>
    )
}

function LeakPrioritizationFactors({ data }) {
    const formattedData = formatPrioritizationData(data)
    return (
        <div>
            <div style={{display: 'flex', gap: '150px', marginBottom: '15px'}}>
                <Typography variant="Heading 4" text='Priority:'/>
                <Typography variant="Body 1" text={formattedData.priority}/>
            </div>
            <LeakPrioritizationSection title="High Priority" data={formattedData.highPriority} />
            <LeakPrioritizationSection title="Medium Priority" data={formattedData.mediumPriority} />
            <LeakPrioritizationSection title="Low Priority" data={formattedData.lowPriority} />
        </div>
    )
}