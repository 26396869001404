import React from "react";
import styled from "styled-components";

import {Switch as AntSwitch} from 'antd';

const StyledSwitch = styled(AntSwitch)`

  list-style: none;
  min-width: 40px;
  height: 24px;
  line-height: 24px;
  border-radius: 100px;
  
  border: 2px solid white !important;

  .ant-switch:focus {
    outline: 0;
    box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.1);
  }

  .ant-switch-checked:focus {
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  }

  .ant-switch:focus:hover {
    box-shadow: none;
  }

  .ant-switch-checked {
    background-color: #1890ff;
  }

  .ant-switch-loading,
  .ant-switch-disabled {
    cursor: not-allowed;
    opacity: 0.4;
  }

  .ant-switch-loading *,
  .ant-switch-disabled * {
    box-shadow: none;
    cursor: not-allowed;
  }

  .ant-switch-inner {
    display: block;
    margin: 0 7px 0 25px;
    color: #fff !important;
    font-size: 12px;
    transition: margin 0.2s;
  }

  .ant-switch-checked .ant-switch-inner {
    margin: 0 25px 0 7px;
  }

  .ant-switch-handle {
    margin-top: -1px;
    width: 18px;
    height: 18px;
    transition: all 0.2s ease-in-out;
  }

  .ant-switch-handle::before {
    position: absolute;
    top: 1px;
    right: 1px;
    bottom: 1px;
    left: 1px;
    background-color: #fff;
    border-radius: 9px;
    box-shadow: 0 2px 4px 0 rgba(0, 35, 11, 0.2);
    transition: all 0.2s ease-in-out;
    content: '';
  }

  .ant-switch-checked .ant-switch-handle {
    left: calc(100% - 18px - 2px);
  }

  .ant-switch:not(.ant-switch-disabled):active .ant-switch-handle::before {
    right: -30%;
    left: 0;
  }

  .ant-switch:not(.ant-switch-disabled):active.ant-switch-checked .ant-switch-handle::before {
    right: 0;
    left: -30%;
  }

  .ant-switch-loading-icon {
    position: relative;
    top: 2px;
    color: rgba(0, 0, 0, 0.65);
    vertical-align: top;
  }

  .ant-switch-checked .ant-switch-loading-icon {
    color: #1890ff;
  }

  .ant-switch-small {
    min-width: 28px;
    height: 16px;
    line-height: 16px;
  }

  .ant-switch-small .ant-switch-inner {
    margin: 0 5px 0 18px;
    font-size: 12px;
  }

  .ant-switch-small .ant-switch-handle {
    width: 12px;
    height: 12px;
  }

  .ant-switch-small .ant-switch-loading-icon {
    top: 1.5px;
    font-size: 9px;
  }

  .ant-switch-small.ant-switch-checked .ant-switch-inner {
    margin: 0 18px 0 5px;
  }

  .ant-switch-small.ant-switch-checked .ant-switch-handle {
    left: calc(100% - 12px - 2px);
  }

  .ant-switch-rtl {
    direction: rtl;
  }

  .ant-switch-rtl .ant-switch-inner {
    margin: 0 25px 0 7px;
  }

  .ant-switch-rtl .ant-switch-handle {
    right: 2px;
    left: auto;
  }

  .ant-switch-rtl:not(.ant-switch-rtl-disabled):active .ant-switch-handle::before {
    right: 0;
    left: -30%;
  }

  .ant-switch-rtl:not(.ant-switch-rtl-disabled):active.ant-switch-checked .ant-switch-handle::before {
    right: -30%;
    left: 0;
  }

  .ant-switch-rtl.ant-switch-checked .ant-switch-inner {
    margin: 0 7px 0 25px;
  }

  .ant-switch-rtl.ant-switch-checked .ant-switch-handle {
    right: calc(100% - 18px - 2px);
  }

  .ant-switch-rtl.ant-switch-small.ant-switch-checked .ant-switch-handle {
    right: calc(100% - 12px - 2px);
  }
`

const Switch = ({onChange, checkedValue}) => {
    if (checkedValue === undefined) {
        return <StyledSwitch onChange={onChange}/>
    }
    return <StyledSwitch onChange={onChange} checked={checkedValue}/>
}

export default Switch;