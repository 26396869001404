import { Layer, Source } from "react-map-gl"
import { APIGateways } from "@config/config"
import { useEffect, useState } from "react";
import { isActiveLayer, useActivateMapLayer, useLoadMapImage, useMapEvent } from "@innovation-toolkit/mapbox";
import axios from "axios";
import { CharitableDonationPopup } from "./popup";
import moneyIcon from './money.png'
export const CHARITABLE_DONATION_LAYER = 'CHARITABLE_DONATION_LAYER';
export const CHARITABLE_DONATION_SOURCE = 'CHARITABLE_DONATION_SOURCE';

export const CharitableDonationLayerMetadata = {
    layerId: CHARITABLE_DONATION_LAYER,
    name: 'Charitable Donation',
    color: moneyIcon,
    layerSymbols: [
        { symbol: moneyIcon, symbolName: 'money-icon' }
    ]
}

export const CharitableDonationLayer = ({showLayer=false}) => {

    const [data, setData] = useState(null);
    const [showPopup, setShowPopup] = useState(false)
    const [popupFeature, setPopupFeature] = useState(null);
    const [lngLat, setLatLng] = useState(null);
    const [target, setTarget] = useState(null);
    const [showContributionLayer] = useActivateMapLayer(CharitableDonationLayerMetadata, showLayer);

    useEffect(() => {
        axios.get(`${APIGateways.charitable}/charitable_donations`)
        .then((response) => {
            setData(
            response.data
            )
        })
    }, [])

    useMapEvent('click', CHARITABLE_DONATION_LAYER, (e) => {
        if (!isActiveLayer(e, CHARITABLE_DONATION_LAYER)) return;
        setShowPopup(true);
        setPopupFeature(e.features[0].properties);
        setLatLng(e.lngLat);
        setTarget(e.target);
    });

    useLoadMapImage('money-icon', moneyIcon);

    const sourceConfig = {
        id: CHARITABLE_DONATION_SOURCE,
        type: 'geojson',
        data: data,
        cluster: true,
        clusterMaxZoom: 12,
        clusterRadius: 60,
    }

    const fillConfig = {
        id: CHARITABLE_DONATION_LAYER,
        type: 'symbol',
        source: CHARITABLE_DONATION_SOURCE,
        layout: {
            'visibility': showContributionLayer ? 'visible' : 'none',
            'icon-image': 'money-icon',
            'icon-size': 1,
            'icon-allow-overlap': true,
        },
    }

    return (
        <Source {...sourceConfig}>
            <Layer {...fillConfig} />  
            <CharitableDonationPopup 
                isOpen={showPopup}
                onClose={() => setShowPopup(false)}
                lngLat={lngLat}
                feature={popupFeature}
                target={target}
            />
        </Source>
    )
}

