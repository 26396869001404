import {createSelector} from 'reselect';
import {ACTION_BUTTON} from "../actionTypes";

const selectState = state => state.menu;

const selectSelectedKey = createSelector(
    selectState,
    select => select.selectedKeys
)

const selectFleetComponent = createSelector(
    selectState,
    select => select && select.fleetComponent
)

const selectQualityAirComponent = createSelector(
    selectState,
    select => select && select.qualityAirComponent
)

const selectMenuImpactedCommunities = createSelector(
    selectState,
    select => select.topImpactedComponent
)

const selectSelected = createSelector(
    selectState,
    select => select.selected
)

const selectFilerComponent = createSelector(
    selectSelected,
    select => select[ACTION_BUTTON]
)

const selectLastSearch = createSelector(
    selectState,
    select => select.lastSearch
)

const selectMenuDacTracts = createSelector(
    selectState,
    select => select.censusTracts.dac
)

const selectMenuSwappingVehicles = createSelector(
    selectState,
    select => select.swappingVehicles
)

const selectMenuSwappingVehiclesTableData = createSelector(
    selectMenuSwappingVehicles,
    select => select && select.map((item) => {
        return {
            key: item.cycle_id,
            rank: item.cycle_id,
            previous_vehicle_id: item.previous_vehicle_id,
            replacement_vehicle_id: item.replacement_vehicle_id,
            replacement_emissions_dac: Math.round(item['replacement_emissions_dac(lb)'] * 10) / 10,
            percentage_reduction_in_emissions_dac: Math.round(item['percentage_reduction_in_emissions_dac'] * 10) / 10,
            percentage_reduction_dac_emissions_for_cycle: Math.round(item['percentage_reduction_dac_emissions_for_cycle'] * 10) / 10
        }
    }).sort((a, b) => a.rank - b.rank)
)

const selectMenuSwappingVehiclesId = createSelector(
    selectMenuSwappingVehiclesTableData,
    select => select && select
        .map(item => item.key).filter((e, i, a) => a.indexOf(e) !== i)
)

const selectMenuReplacementVehicles = createSelector(
    selectState,
    select => select.replacementVehicles
)

const selectMenuReplacementVehiclesTableData = createSelector(
    selectMenuReplacementVehicles,
    select => select && select
        .map(x => x)
        .sort((a,b) => a.rank - b.rank)

)

const selectMenuSwappingCsvFile = createSelector(
    selectState,
    select => select.swappingCSVFile
)

const selectMenuSwapRequest = createSelector(
    selectState,
    select => select.swapRequest
)

const selectVehiclesList = createSelector(
    selectState,
    select => select.vehicleList
)

const selectAnyContent = createSelector(
    selectState,
    select => select.content
)

const selectMenuSwapLastRequest = createSelector(
    selectState,
    select => select.swapLastRequest,
)
const selectMenuReplacementLastRequest = createSelector(
    selectState,
    select => select.replacementLastRequest,
)

export {
    selectSelectedKey,
    selectFleetComponent,
    selectQualityAirComponent,
    selectFilerComponent,
    selectLastSearch,
    selectMenuDacTracts,
    selectMenuImpactedCommunities,
    selectMenuSwappingVehicles,
    selectMenuSwappingCsvFile,
    selectMenuReplacementVehicles,
    selectVehiclesList,
    selectAnyContent,
    selectMenuSwapRequest,
    selectMenuSwappingVehiclesTableData,
    selectMenuSwapLastRequest,
    selectMenuReplacementLastRequest,
    selectMenuSwappingVehiclesId,
    selectMenuReplacementVehiclesTableData
}