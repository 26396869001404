import React, { useEffect, useState } from 'react';
import Layout from "antd/es/layout/layout";
import {
    getRegionsData
} from "redux/mapbox/action";
import { Outlet, useNavigate } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import { selectActiveApp } from 'shared/redux/apps/selectors';
import { setActiveEnv, setActiveApp } from 'shared/redux/apps/actions';
import { selectSearchDates } from '@redux/searchItems/selectors';
import { selectMapCensusTracts, selectSideBarVisibility, selectSideBarContent } from "redux/mapStateInternal/selectors";
import { selectIsEditingRows } from 'redux/streetImprovements/selector';

import scgLogo from "../assets/icons/SoCalGas.svg";
import MethaneIconOutlined from "../assets/icons/methane-app-icon-outlined.svg?react";
import MethaneIconFilled from "../assets/icons/methane-app-icon-filled.svg?react";
import StreetImprovementOutlined from '../assets/icons/street-improvement-outline.svg?react';
import StreetImprovementFilled from '../assets/icons/street-improvement-filled.svg?react';
import SelfServiceIconFilled from "../assets/icons/community-investment-filled.svg?react";
import SelfServiceIconOutlined from "../assets/icons/community-investment-outlined.svg?react";
import AbatementOptimizationFilled from "../assets/icons/abatement-optimization-filled.svg?react";
import AbatementOptimizationOutlined from "../assets/icons/abatement-optimization-outlined.svg?react";
import PopulationOutlined from '../assets/icons/population-outline.svg?react';
import PopulationFilled from '../assets/icons/population.svg?react';
import AngelesLinkOutlined from '../assets/icons/angeles-link-outlined.svg?react';
import AngelesLinkFilled from '../assets/icons/angeles-link-filled.svg?react';
import FleetIconOutlined from '../assets/icons/fleet-app-icon-outlined.svg?react';
import FleetIconFilled from '../assets/icons/fleet-app-icon-filled.svg?react';
import { CityWorkAggregationIcon } from '../assets/icons/CityWorkAggregationIcon';

import { setWebsocketUrl, clearMapLoadingList, RightSideBar } from '@innovation-toolkit/mapbox';

import { AppBar, AppLogo, UserGuideButton, openNotification } from '@innovation-toolkit/ui';
import { selectCurrentUserRoles, useUserToken} from '@innovation-toolkit/rbac'
import { PERMISSIONS } from '@config/auth.config.js';
import { APIGateways, GATEWAY_WEBSOCKET_URL, APP_ENV } from '@config/config.js';
import { setSideBarContent, setSideBarVisibility } from 'redux/mapStateInternal/action.js';
import { LAStreetImprovementSideBar } from './apps/StreetImprovement/layers/LAStreetImprovement/sideBarPopup.jsx';
import { configureRUM } from 'rumConfig.js';

const { Content } = Layout;

function Main() {
    const activeApp = useSelector(selectActiveApp);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const searchDates = useSelector(selectSearchDates);
    const selectedCensusTracts = useSelector(selectMapCensusTracts);
    const shouldShowSideBar = useSelector(selectSideBarVisibility);
    const sideBarContent = useSelector(selectSideBarContent);
    const editingRows = useSelector(selectIsEditingRows);
    const [ userGuide, setUserGuide ] = useState({app_id: "", app_title: "", app_url: "", isVisible: false});
    const [useCases, setUseCases] = useState({
        topUseCases: [],
        bottomUseCases: []
    });

    const userRoles = useSelector(selectCurrentUserRoles);

    const [userToken] = useUserToken();

    useEffect(() => {
        //Initialze RUM on initial load
        configureRUM();
    }, []);

    useEffect(() => {
        if (userToken) {
            dispatch(setWebsocketUrl(`${GATEWAY_WEBSOCKET_URL}?authorization=${userToken}`))
        }
    }, [dispatch, userToken])
    
    useEffect(() => {
        // dispatch(setActiveApp('street-improvement'))
        dispatch(setActiveEnv('scg'))
        // // Load Regions from endpoint
        // dispatch(getRegionsData())
        // // Load Methane from endpoint
    }, [dispatch])

    useEffect(() => {
        updateUserGuide(activeApp)
        dispatch(clearMapLoadingList())
        dispatch(setSideBarVisibility(false))
    }, [dispatch, activeApp])

    const updateUserGuide = (activeApp) => {
        const userGuideSetup = [
            {
                app_id: 'street-improvement',
                app_title: 'Street Improvements User Guide',
                app_url: `${APIGateways.scgcore}/data/street-improvements-user-guide`,
                isVisible: true
            },
            {
                app_id: 'abatement-optimization',
                app_title: 'Abatement Optimization User Guide',
                app_url: `${APIGateways.scgcore}/data/abatement-optimization-user-guide`,
                isVisible: true
            },
            {
                app_id: 'self-service-app',
                app_title: 'Self Service User Guide',
                app_url: `${APIGateways.dtcore}/user-guide/self-service`,
                isVisible: true
            },
        ]

        const activeUserGuide = userGuideSetup.filter( setup => setup.app_id === activeApp)
        activeUserGuide.length > 0
            ? setUserGuide(activeUserGuide[0])
            : setUserGuide({app_id: "", app_title: "", app_url: "", isVisible: false})
    }

    const handleCloseEditingSideBar = () => {
        if (editingRows) {
            openNotification({
                placement: 'bottomRight',
                header: 'Work is not saved',
                notificationMessage: 'Your latest edit was not saved properly. Please save or cancel your work before continuing.',
                type: 'warning'
            })
        } else {
            dispatch(setSideBarVisibility(false))
            dispatch(setSideBarContent({}))
        }
    }

    useEffect(() => {

        // Wait for user roles to load in before displaying the app bar
        if (!userRoles || userRoles.length === 0) return;

        let apps = [
            {
                id: 'street-improvement',
                key: 'street-improvement',
                title: 'Street Improvement',
                logo: (
                    <AppLogo
                        isActive={activeApp === 'street-improvement'}
                        activeIcon={<StreetImprovementFilled />}
                        inactiveIcon={<StreetImprovementOutlined />}
                        tooltipTitle="Street Improvement"
                    />
                ),
                allowedRoles: PERMISSIONS[APP_ENV].SI_AND_CITY_WORK_PLANNERS,
            },
            {
                id: 'city-work-aggregation',
                key: 'city-work-aggregation',
                title: 'City Work Aggregation',
                logo: (
                    <AppLogo
                        isActive={activeApp === 'city-work-aggregation'}
                        activeIcon={
                            <CityWorkAggregationIcon variant="filled" />
                        }
                        inactiveIcon={<CityWorkAggregationIcon />}
                        tooltipTitle="City Work Aggregation"
                    />
                ),
                allowedRoles: PERMISSIONS[APP_ENV].SI_AND_CITY_WORK_PLANNERS,
            },
            {
                id: 'abatement-optimization',
                key: 'abatement-optimization',
                title: 'Abatement Optimization',
                logo: (
                    <AppLogo
                        isActive={activeApp === 'abatement-optimization'}
                        activeIcon={<AbatementOptimizationFilled />}
                        inactiveIcon={<AbatementOptimizationOutlined />}
                        tooltipTitle="Abatement Optimization"
                    />
                ),
                allowedRoles: PERMISSIONS[APP_ENV].ABATEMENT_OPTIMIZATION,
            },
            {
                id: 'fleet-decarbonization',
                key: 'fleet-decarbonization',
                title: 'Fleet Decarbonization',
                logo: (
                    <AppLogo
                        isActive={activeApp === 'fleet-decarbonization'}
                        activeIcon={<FleetIconFilled />}
                        inactiveIcon={<FleetIconOutlined />}
                        tooltipTitle="Fleet Decarbonization"
                    />
                ),
                requiredPermission: PERMISSIONS[APP_ENV].SCG_FLEET_DECARBONIZATION,
            },
        ];
        let bottomApps = [
            {
                id: 'self-service-app',
                key: 'self-service-app',
                title: 'Self Service',
                logo: (
                    <AppLogo
                        isActive={activeApp === 'self-service-app'}
                        activeIcon={<SelfServiceIconFilled />}
                        inactiveIcon={<SelfServiceIconOutlined />}
                        tooltipTitle="Self Service"
                    />
                ),
                allowedRoles: PERMISSIONS[APP_ENV].SCG_SELF_SERVICE,
            },
        ];

        if (`${APP_ENV}` === 'dev') {
            apps = [
                ...apps,
                {
                    id: 'angeles-link',
                    key: 'angeles-link',
                    title: 'Angeles Link',
                    logo: (
                        <AppLogo
                            isActive={activeApp === 'angeles-link'}
                            activeIcon={<AngelesLinkFilled />}
                            inactiveIcon={<AngelesLinkOutlined />}
                            tooltipTitle="Angeles Link"
                        />
                    ),
                    allowedRoles: PERMISSIONS[APP_ENV].DEV_AND_ALL,
                },
                {
                    id: 'methane-app',
                    key: 'methane-app',
                    title: 'Methane Leaks',
                    logo: (
                        <AppLogo
                            isActive={activeApp === 'methane-app'}
                            activeIcon={<MethaneIconFilled />}
                            inactiveIcon={<MethaneIconOutlined />}
                            tooltipTitle="Leak Abatement"
                        />
                    ),
                    allowedRoles: PERMISSIONS[APP_ENV].METHANE,
                },
                {
                    id: 'population',
                    key: 'population',
                    title: 'Population',
                    logo: (
                        <AppLogo
                            isActive={activeApp === 'population'}
                            activeIcon={<PopulationOutlined />}
                            inactiveIcon={<PopulationFilled />}
                            tooltipTitle="Population"
                        />
                    ),
                    allowedRoles: PERMISSIONS[APP_ENV].DEV_AND_ALL,
                },
            ];
        }

        // if (`${APP_ENV}` !== 'prd') {
        //     apps = [
        //         ...apps,
               
        //     ];
        // }

        setUseCases({
            topUseCases: apps,
            bottomUseCases: bottomApps,
        });

        const allUseCases = [...apps, ...bottomApps]
        if (allUseCases.length === 0) {
            openNotification({
                type: 'info',
                notificationMessage: 'You are not currently granted access to any specific apps.  Please contact digitaltwin@sempra.com to request access to the specific apps needed',
                duration: 100
            })
        }
    }, [userRoles]);

    return (

        <Layout style={{ display: 'flex', backgroundColor: '#0E263B', position: "relative", flexDirection: 'row' }}>
            <AppBar
                globalLogo={scgLogo}
                logoStyle={{paddingBottom: '31px', paddingTop: '31px'}}
                activeApp={activeApp}
                onAppChange={(app) => { navigate(app.replace("-app", "")) }}
                apps={useCases.topUseCases}
                userGuideButton={
                    <UserGuideButton 
                        title={userGuide.app_title}
                        url={userGuide.app_url}
                        isVisible={userGuide.isVisible}/>
                }
                bottomApps={useCases.bottomUseCases}
                currentUserRoles={userRoles}
            ></AppBar>
            <Outlet />
            <RightSideBar 
                sidePanelTitle='Edit City Work Project' 
                showSidePanel={shouldShowSideBar} 
                onClose={handleCloseEditingSideBar} 
                children={
                    sideBarContent && sideBarContent.siPopup && 
                    <LAStreetImprovementSideBar 
                        feature={sideBarContent.siPopup.feature}
                        isOpen={sideBarContent.siPopup.isOpen}
                        setPopupFeature={sideBarContent.siPopup.setPopupFeature}
                        lngLat={sideBarContent.siPopup.lngLat}
                        target={sideBarContent.siPopup.target}
                    />}
            />
        </Layout>
    );
}

export default Main;