import { createSelector } from "reselect";

const selectSelfServiceState = state => state.SelfService;

export const selectGeometry = createSelector(
    selectSelfServiceState,
    state => state.geometry
)

export const selectOptionalAttributes = createSelector(
    selectSelfServiceState,
    state => state.optionalAttributes
)

export const selectStyle = createSelector(
    selectSelfServiceState,
    state => state.style
)

export const selectClassification = createSelector(
    selectSelfServiceState,
    state => state.classification
)

export const selectUploadColumns = createSelector(
    selectSelfServiceState,
    state => state.metadata.columns?.map(column => column.columns_name) || []
)

export const selectUploadBucket = createSelector(
    selectSelfServiceState,
    state => state.metadata.bucket
)

export const selectUploadKey = createSelector(
    selectSelfServiceState,
    state => state.metadata.key
)

export const selectUploadDescription = createSelector(
    selectSelfServiceState,
    state => state.metadata.description
)

export const selectFileLoaded = createSelector(
    selectSelfServiceState,
    state => state.metadata.loaded
)

export const selectSelfServiceMapSource = createSelector(
    selectSelfServiceState,
    state => state.mapSource
)

export const selectSimpleAnalytics = createSelector(
    selectSelfServiceState,
    state => state.simpleAnalytics
)

export const selectShowSimpleAnalytics = createSelector(
    selectSelfServiceState,
    state => state.simpleAnalytics.showTable
)

export const selectSimpleAnalyticsTableSetUp = createSelector(
    selectSelfServiceState,
    state => state.simpleAnalytics.tableSetUp
)

export const selectSimpleAnalyticsTableData = createSelector(
    selectSelfServiceState,
    state => state.simpleAnalytics.tableData
)

export const selectFileUploadInfo = createSelector(
    selectSelfServiceState,
    state => state.fileInfo
)

export const selectNumericUploadColumns = createSelector(
    selectSelfServiceState,
    state => {
        let columns = state.metadata.columns;
        if (!columns) {
            return [];
        }

        const numberDataTypes = new Set(['int64', 'float64'])

        let numericColumns = columns.filter(column => numberDataTypes.has(column.type))
        return numericColumns.map(column => column.columns_name);
    }
)