import { useDispatch, useSelector } from "react-redux"
import { BridgeLayerMetadata } from "."
import { addActiveLayer, removeActiveLayer, selectIsLayerActive } from "@innovation-toolkit/mapbox"
import { LayerToggle, LayerToggleIcon, LayerToggleIconChild } from '@innovation-toolkit/ui';
import { Bridge } from "assets/icons/bridge";

export const BridgeLayerToggle = () => {
    const BRIDGE_LAYER = BridgeLayerMetadata.layerId
    const dispatch = useDispatch()
    const shouldShowBridgeLayer = useSelector((state) => selectIsLayerActive(state, BRIDGE_LAYER))
    const handleClick = (shouldShow) => {
        if (!shouldShow) {
            dispatch(removeActiveLayer(BRIDGE_LAYER))
        } else {
            dispatch(addActiveLayer(BRIDGE_LAYER))
        }
    }

    return (
        <LayerToggle
            title='Bridge'
            componentIcon={Bridge}
            onChange={handleClick}
            currentState={shouldShowBridgeLayer}
            children={<LayerToggleIconChild 
                Icon={<LayerToggleIcon 
                    type="point" 
                    fillColor={BridgeLayerMetadata.color} 
                    outlineColor="#ffffff" 
                    strokeColor="#ffffff"
                    />
                }    
                title={<p>Bridge</p>}/>}
        />
    )
}