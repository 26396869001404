import { Layer, Source } from "react-map-gl"
import { APIGateways } from "@config/config"
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useActivateMapLayer, useMapEvent, selectBeforeIDs } from "@innovation-toolkit/mapbox";
import axios from "axios";

export const LAND_SURFACE_ESTATE_FILL_LAYER = 'LAND_SURFACE_ESTATE_FILL_LAYER';
export const LAND_SURFACE_ESTATE_FILL_SOURCE = 'LAND_SURFACE_ESTATE_FILL_SOURCE';

export const LandSurfaceEstateLayerMetadata = {
    layerId: LAND_SURFACE_ESTATE_FILL_LAYER,
    name: 'BLM Land Surface Estate',
    color: '#9749AC'
}

const fetchLandSurfaceEstateData = (callBack) => {

    axios.get(`${APIGateways.scgcore}/data/blm-land-surface-estate`)
        .then((response) => {
            return fetch(response.data)
            .then(fetchResponse => fetchResponse.json())
            .then(data => {
                callBack(data, false)
            }) 
        })
        .catch((err) => {
            callBack(null, true)
        })
}

export const LandSurfaceEstateLayer = ({showLayer=false}) => {

    const [showLandSurfaceEstateLayer] = useActivateMapLayer(LandSurfaceEstateLayerMetadata, showLayer)
    const beforeIDs = useSelector(selectBeforeIDs)
    const [data, setData] = useState(null);

    useEffect(() => {
        fetchLandSurfaceEstateData((data, isError) => {
            if (!isError) {
                setData(data)
            } 
        })
    }, [])

    // useMapEvent('click', LandSurfaceEstateLayerMetadata.layerId, (e) => {
    //     console.log(e.features)
    // })

    // const sourceConfig = {
    //     id: LAND_SURFACE_ESTATE_FILL_SOURCE,
    //     type: 'vector',
    //     tiles: [ `${APIGateways.scgcore}/tiles/BLM_Land_Surface_Estate/{z}/{x}/{y}/cip` ]
    // }

    const sourceConfig = {
        id: LAND_SURFACE_ESTATE_FILL_SOURCE,
        type: 'geojson',
        data: data
    }

    const lineConfig = {
        id: LAND_SURFACE_ESTATE_FILL_LAYER,
        type: "line",
        source: LAND_SURFACE_ESTATE_FILL_SOURCE,
        // 'source-layer': 'BLM_Land_Surface_Estate',
        layout: {
            'visibility': showLandSurfaceEstateLayer ? 'visible' : 'none'
        },
        // TODO Add filter once we know which field to filter on and display
        paint: {
            "line-color": LandSurfaceEstateLayerMetadata.color,
            // "fill-opacity": 0.6
        },
        beforeId: beforeIDs.fill
    }

    return (
        <Source {...sourceConfig}>
            <Layer {...lineConfig} />
        </Source>
    )
}