import { Layer, Source/*, useMap */} from "react-map-gl"
import { APIGateways } from "@config/config"
// import { useEffect, useState } from "react";
import { useActivateMapLayer, useLoadMapPattern, selectBeforeIDs, selectMapStyle/*, useMapHoverEvent, isActiveLayer, selectIsLayerActive*/ } from "@innovation-toolkit/mapbox";
import { useSelector } from "react-redux";

export const NATIONAL_FOREST_ADMINISTRATIVE_BOUNDARIES_LAYER = 'NATIONAL_FOREST_ADMINISTRATIVE_BOUNDARIES_LAYER';
export const NATIONAL_FOREST_ADMINISTRATIVE_BOUNDARIES_SOURCE = 'NATIONAL_FOREST_ADMINISTRATIVE_BOUNDARIES_SOURCE';

export const NFABLayerMetadata = {
    layerId: NATIONAL_FOREST_ADMINISTRATIVE_BOUNDARIES_LAYER,
    name: 'National Forest Administrative Boundaries',
    color: '#8CA382'
}

export const NFABLayer = ({showLayer=false}) => {

    const [showNFABLayer] = useActivateMapLayer(NFABLayerMetadata, showLayer)
    const beforeIDs = useSelector(selectBeforeIDs)
    const mapStyle = useSelector(selectMapStyle)

    try {
        useLoadMapPattern("nfa-pattern", "crosshatch", NFABLayerMetadata.color, 40, mapStyle)
    } catch (e){
        console.error("Failed to load map pattern for NFAB Layer: ", e);
    }

    const sourceConfig = {
        id: NATIONAL_FOREST_ADMINISTRATIVE_BOUNDARIES_SOURCE,
        type: 'vector',
        tiles: [`${APIGateways.scgcore}/tiles/national_forests/{z}/{x}/{y}`]
    }

    const fillConfig = {
        id: NATIONAL_FOREST_ADMINISTRATIVE_BOUNDARIES_LAYER,
        type: "fill",
        source: NATIONAL_FOREST_ADMINISTRATIVE_BOUNDARIES_SOURCE,
        'source-layer': 'National_Forest_Administrative_Boundaries',
        layout: {
            'visibility': showNFABLayer ? 'visible' : 'none'
        },
        paint: {
            // "fill-color": NFABLayerMetadata.color,
            "fill-opacity": 0.6,
            "fill-pattern": "nfa-pattern"
        },
        beforeId: beforeIDs.fill
    }

    return (
        <Source {...sourceConfig}>
            <Layer {...fillConfig} />
        </Source>
    )
}

