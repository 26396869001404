import { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import _ from "lodash"
import { selectCityWorkData } from "@redux/streetImprovements/selector"
import moment from 'moment';
import { DataTable, LargeTable } from "@innovation-toolkit/ui"
import { setHighlightedMap } from "redux/streetImprovements/action";

const WorkTable = ({ setWorkTable, selectedWorkTable, setMapPosition }) => {
    const [currentSorter, setCurrentSorter] = useState({})
    const [searchQuery, setSearchQuery] = useState()
    const [cityData, setCityData] = useState([])
    const [overlapsData, setOverlapsData] = useState([])
    const [displayedRows, setDisplayedRows] = useState(
        selectedWorkTable.city ? cityData : overlapsData
    )

    const cityWorkData = useSelector(selectCityWorkData);

    const dispatch = useDispatch()

    useEffect(() => {
        if (!cityWorkData) return
        let formattedCityWorkData = [];
        cityWorkData.features.forEach((element) => {
            let properties = element.properties

            const lon = properties.lon || element.geometry.coordinates.flat(4)[0];
            const lat = properties.lat || element.geometry.coordinates.flat(4)[1];

            const getType = () => {
                if (properties.is_moratorium && properties.is_project) {
                    return 'Project & Moratorium'
                } else if (properties.is_moratorium) {
                    return 'Moratorium'
                } else if (properties.is_project) {
                    return 'Project'
                }
            }

            formattedCityWorkData.push({
                type: getType(),
                region: properties.region || "-",
                district: properties.district || "-",
                address: properties.address || "-",
                representative_address: properties.representative_address || "-",
                municipality: properties.municipality || "-",
                project_start_date: moment(properties.project_start_date).format("MM/DD/YYYY"),
                project_end_date: moment(properties.project_end_date).format("MM/DD/YYYY"),
                moratorium_start_date: moment(properties.moratorium_start_date).format("MM/DD/YYYY"),
                moratorium_end_date: moment(properties.moratorium_end_date).format("MM/DD/YYYY"),
                lsa_functionallocation: properties.lsa_functionallocation || "-",
                cp_area_functional: properties.cp_area_functionallocation || "-",
                cp10_service_id: properties.cp10_service_id || "-",
                valve_equipment_number: properties.valve_equipment_number || "-",
                id: properties.id || "-",
                lon: lon,
                lat: lat,
            })
        })
        setCityData(formattedCityWorkData)

    }, [cityWorkData])

    useEffect(() => {
        let filteredRows = _.cloneDeep(selectedWorkTable.city ? cityData : overlapsData);

        if (searchQuery) {
            filteredRows = filteredRows.filter(
                (row) => {
                    return Object.keys(row).some((key) =>
                        row[key].toString().toLowerCase().includes(searchQuery.toLowerCase())
                    )
                }
            )
        } 

        if (selectedWorkTable.city) {

            // Filter based on Checkboxes
            // If the Projects checkbox is not selected filter out 
            // any row with type Project
            if (!selectedWorkTable.projects) {
                filteredRows = filteredRows.filter((row) => {
                    return row.type !== "City Project"
                })
            }
            // If Moratoriums checkbox is not selected filter out
            // any row with type project
            if (!selectedWorkTable.moratoriums) {
                filteredRows = filteredRows.filter((row) => {
                    return row.type !== "Moratorium"
                })
            }

        } 
        // If the overlap table is open
        else if (selectedWorkTable.overlaps) {
            if (!filteredRows || filteredRows.length === 0|| filteredRows[0].overlaps === undefined) return;

            let newDisplayedRows = filteredRows;
            // Remove all overlaps based on if Moratorium or Projects is selected
            for (let i = 0; i < newDisplayedRows.length; i++) {
                newDisplayedRows[i].overlaps = newDisplayedRows[i].overlaps.filter((row) => {
                    let shouldKeepRow = true;
                    // If Moratoriums checkbox is not selected filter out
                    // any row with type Moratorium
                    if (!selectedWorkTable.moratoriums && (row.type === "Moratorium" || row.type === "moratorium")) {
                        shouldKeepRow = false;
                    }
                    if (!selectedWorkTable.projects && (row.type === "City Project" || row.type === "project")) {
                        shouldKeepRow = false;
                    }

                    return shouldKeepRow;
                })
            }

            newDisplayedRows = newDisplayedRows.filter((outerRow) => {
                return outerRow.overlaps.length !== 0;
            })


            filteredRows = newDisplayedRows
            
        }

        setDisplayedRows([...filteredRows])

    }, [searchQuery, selectedWorkTable, cityData])

    useEffect(() => {
        setCurrentSorter({})
        setSearchQuery("")

    }, [selectedWorkTable])

    const columns = [
        {
            title: "Type",
            dataIndex: "type",
            key: "type",
            mapLocationButton: true,
            sortOrder: currentSorter.columnKey === "type" ? currentSorter.order : null,
            sorter: (a, b) => a.type.localeCompare(b.type),
            width: 150
        },
        {
            title: "Region",
            dataIndex: "region",
            key: "region",
            sortOrder: currentSorter.columnKey === "region" ? currentSorter.order : null,
            sorter: (a, b) => a.region.localeCompare(b.region),
            width: 150
        },
        {
            title: "District",
            dataIndex: "district",
            key: "district",
            sortOrder: currentSorter.columnKey === "district" ? currentSorter.order : null,
            sorter: (a, b) => a.district.localeCompare(b.district),
            width: 150
        },
        {
            title: "Address",
            dataIndex: "address",
            key: "address",
            width: 150
        },
        {
            title: "Supplemental Address",
            tooltip: 'Some city work does not have a specific address provided by the municipality, and instead is located using a description (e.g., moratorium located on Main St. between 1st & 2nd Ave.) The supplemental address is provided using the approximate address of the project or moratorium.',
            dataIndex: "representative_address",
            key: "representative_address",
            width: 150
        },
        {
            title: "Municipality",
            dataIndex: "municipality",
            key: "municipality",
            sortOrder: currentSorter.columnKey === "municipality" ? currentSorter.order : null,
            sorter: (a, b) => a.municipality.localeCompare(b.municipality),
            width: 160
        },
        {
            title: "Project Start Date",
            dataIndex: "project_start_date",
            key: "project_start_date",
            width: 150
        },
        {
            title: "Project End Date",
            dataIndex: "project_end_date",
            key: "project_end_date",
            width: 150
        },
        {
            title: "Moratorium Start Date",
            dataIndex: "moratorium_start_date",
            key: "moratorium_start_date",
            width: 150
        },
        {
            title: "Moratorium End Date",
            dataIndex: "moratorium_end_date",
            key: "moratorium_end_date",
            width: 150
        },
        {
            title: "Leak Survey FLOC",
            dataIndex: "lsa_functionallocation",
            key: "lsa_functionallocation",
            width: 250
        },
        {
            title: "CP Area FLOC",
            dataIndex: "cp_area_functional",
            key: "cp_area_functional",
            width: 250
        },
        {
            title: "CP10 Service ID",
            dataIndex: "cp10_service_id",
            key: "cp10_service_id",
            width: 180
        },
        {
            title: "Valve Equipment Number",
            dataIndex: "valve_equipment_number",
            key: "valve_equipment_number",
            width: 180
        },
        {
            title: "City Work ID",
            dataIndex: "id",
            key: "id",
            sortOrder: currentSorter.columnKey === "city_id" ? currentSorter.order : null,
            sorter: (a, b) => a.id.localeCompare(b.id),
            width: 180
        },

    ]

    const handleMapLocationButtonClick = (record) => {
        dispatch(setHighlightedMap(record))
        dispatch(setMapPosition({ latitude: Number(record.lat), longitude: Number(record.lon), zoom: 15 }))
    }

    return selectedWorkTable.city || selectedWorkTable.overlaps ? (
        <DataTable
            open={selectedWorkTable.city || selectedWorkTable.overlaps}
            handleClose={() => dispatch(setWorkTable(false))}
            disableExpand={true}
            tabs={[
                {
                    title: selectedWorkTable.city ? "City Work" : "Overlaps with City Work",
                    contentSections: [
                        {
                            content: (
                                <div style={{width: '100%', padding: '4px'}}>
                                <LargeTable columns={columns} data={displayedRows} onMapLocationButtonClick={handleMapLocationButtonClick}/>
                                </div> 
                            ),
                        },
                    ],
                },
            ]}
        />
    ) : null
}

export default WorkTable
