import React, { useCallback, useEffect, useState } from "react";
import { Checkbox as AntCheckbox, Spin, Tree } from 'antd';
import styled from "styled-components";
import { strings}  from "shared/utils";
import { DelimiterLine } from "shared/components/delimiter";
import { HeaderContent } from "shared/components/content";
import { FilterActionButtons } from 'shared/components/buttons';
import { compareArrays } from 'shared/utils/compareArrays';
import { FilterWrapper } from "../filterWrapper";
import { theme } from '@innovation-toolkit/ui'

const CheckboxWrapper = styled.div`
  overflow: ${({load}) => load ? 'hidden': 'scroll'};
  height: ${({load}) => load ? '100%': 'calc(100% - 52px)'} ;
  overflow-x: hidden;
  max-height: 50vh;

  display: flex;

  ::-webkit-scrollbar-track {
    background-color: transparent; 
  }

  ::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.2); 
    border-radius: 100px;
  }

  ::-webkit-scrollbar {
    height: 48px;
    width: 6px;
    padding-right: 6px;
  }

  padding-left: 8px;
`
const Wrapper = styled.div`
  padding: 16px 0 8px 1.5rem;

`

const StyledCheckbox = styled(AntCheckbox)`
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  align-items: center;
  font-weight: 300;
  color: ${theme.color.white};

  p {
    padding-left: 4px;
  }

  .ant-checkbox-inner {
    width: 20px;
    height: 20px;
  }
`

const StyledSpin = styled(Spin)`
  padding-top: ${({spinning}) => spinning ? "35px" : '0'};
  position: relative !important;
`

const getAllCauseDescriptions = (data) => {
  const checkAllArray = [];
    data.map(item => {
      item.children.map( cause => {
        cause.children.map( description => {
          checkAllArray.push(description.title)
        })
      })
    })
    return checkAllArray;
}

const OutageCauseDescriptionFilter = ({
  causesOptions,
  searchOutageCausesState,
  closeTabs = () => {},
  onSearchCauseDescriptionsSelected = () => {},
  onResetOutageCauses = () => {},
  onClose = () => {}
}) => {
  const [expandedKeys, setExpandedKeys] = useState(searchOutageCausesState);
  const [checkedKeys, setCheckedKeys] = useState(searchOutageCausesState);
  const [autoExpandParent, setAutoExpandParent] = useState(true);

  const [indeterminate, setIndeterminate] = useState(false);
  const [checkAll, setCheckAll] = useState(false)
  const [checkedCauses, setCheckedCauses] = useState(searchOutageCausesState)
  const [causesNumber, setCausesNumber] = useState(searchOutageCausesState ? searchOutageCausesState.length : 0)
  const [allCauseDescriptions, setAllCauseDescriptions] = useState([])
  const [confirmDisabled, setConfirmDisabled] = useState(true);

  const onExpand = (expandedKeysValue) => {
    setExpandedKeys(expandedKeysValue);
    setAutoExpandParent(true);
  };

  useEffect(() => {
    if (causesOptions) {
      const causeOptionsLength = getAllCauseDescriptions(causesOptions).length;
      setAllCauseDescriptions(getAllCauseDescriptions(causesOptions));
      searchOutageCausesState && searchOutageCausesState.length === causeOptionsLength
        ? setCheckAll(true)
        : ''
        searchOutageCausesState && 
          searchOutageCausesState.length > 0 && 
            searchOutageCausesState.length !== causeOptionsLength 
              ? setIndeterminate(true) 
              : setIndeterminate(false)
    }
  },[causesOptions])

  useEffect(() => {
    // Compare previously selected tracts so we can disable/enable confirm button correctly
    const updatedCauses = checkedCauses ? JSON.parse(JSON.stringify(checkedCauses)) : [];
    const prevSelectedCauses = searchOutageCausesState ? JSON.parse(JSON.stringify(searchOutageCausesState)) : [];
    setConfirmDisabled(compareArrays(updatedCauses, prevSelectedCauses));

  },[checkedCauses])

  const handleCheckAll = (e) => {
    setCheckedKeys(e.target.checked ? allCauseDescriptions : []);
    setCausesNumber(e.target.checked ? allCauseDescriptions.length : 0)
    setCheckedCauses(e.target.checked ? allCauseDescriptions : [])
    setIndeterminate(false);
    setCheckAll(e.target.checked);
  }

  const onCheck = (checkedKeysValue) => {
    setCheckedKeys(checkedKeysValue);

    let checkedArr = []
    setCheckedCauses([])
    checkedKeysValue.map(value => {
      allCauseDescriptions.includes(value) 
        ? checkedArr.push(value)
        : ""
    })
    setCheckedCauses(checkedArr)
    setCausesNumber(checkedArr.length)

    setIndeterminate(!!checkedArr.length && checkedArr.length < allCauseDescriptions.length)
    setCheckAll(checkedArr.length === allCauseDescriptions.length )
  };

  const handleClearSelection = useCallback(() => {
    closeTabs();
    onResetOutageCauses();
    setCausesNumber(0)
    onClose();
  }, [checkedCauses])

  const handleConfirmSelection = useCallback(() => {
    closeTabs();
    onSearchCauseDescriptionsSelected(checkedCauses);
    onClose();
  }, [checkedCauses])

  return (
    <HeaderContent title={strings.outageCauses.header} closeTabs={closeTabs} style={{ width: '256px' }}>
      <FilterWrapper>
        <Wrapper>
          <StyledCheckbox
            indeterminate={indeterminate}
            onChange={handleCheckAll}
            checked={checkAll}
          >
            <div class='body-primary-text'>Select All Causes</div>
          </StyledCheckbox>
        </Wrapper>
        <DelimiterLine right={0} top={8} style={{ width: '224px', marginLeft: '16px' }} />
        {causesOptions &&
          <CheckboxWrapper load={causesOptions.length === 0}>
            <StyledSpin
              spinning={causesOptions.length === 0}
              tip='Loading...'>
              <Tree
                checkable
                selectable={false}
                onExpand={onExpand}
                expandedKeys={expandedKeys}
                autoExpandParent={autoExpandParent}
                onCheck={onCheck}
                checkedKeys={checkedKeys}
                treeData={causesOptions}
              />
            </StyledSpin>
          </CheckboxWrapper>
        }
        <DelimiterLine right={0} top={8} style={{ width: '224px', marginLeft: '16px' }} />
      </FilterWrapper>

      <FilterActionButtons
        onConfirm={handleConfirmSelection}
        isConfirmDisabled={confirmDisabled}
        onClear={handleClearSelection}
        isClearDisabled={!causesNumber}
      />
    </HeaderContent>
  );
};
    
export default OutageCauseDescriptionFilter