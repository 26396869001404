import { createSelector, createSlice } from "@reduxjs/toolkit";

export const initialState = {
    leakTable: false,
    mandiLeaksData: null,
    mandiLeaksFilterMap: {
        high: true,
        high_mid: true,
        mid: true,
        low: true,
        'no ranking factor': true
    },
}

const abatementOptimization = createSlice({
    name: 'abatementOptimization',
    initialState,
    reducers: {
        setShowLeakTable(state, action) {
            if (!action.payload) {
                state.leakTable = initialState.leakTable
            } else {
                state.leakTable = action.payload
            }
        },
        setMandILeakData(state, action) {
            state.mandiLeaksData = action.payload
        },
        setMandiLeaksFilterMap(state, action) {
            const {key, value} = action.payload;
            let newVals = {...state.mandiLeaksFilterMap};
            newVals[key] = value;
            state.mandiLeaksFilterMap = {...newVals};
        }
    }
});

export const {
    setMandILeakData,
    setShowLeakTable,
    setMandiLeaksFilterMap
} = abatementOptimization.actions;
const selectAbatementOptimization = (state) => state.abatementOptimization;

export const selectShowLeakTable = createSelector(
    selectAbatementOptimization,
    select => {
        return select.leakTable
    }
);
export const selectMandILeakData = createSelector(
    selectAbatementOptimization,
    select => {
        return select.mandiLeaksData
    }
);
export const selectMandiLeaksFilterMap = createSelector(
    selectAbatementOptimization,
    select => {
        return select.mandiLeaksFilterMap
    }
);

export default abatementOptimization.reducer
