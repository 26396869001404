import {
    END_SEARCH_DATE,
    START_SEARCH_DATE,
    SET_SEARCH_ALL_CENSUS_TRACTS,
    SET_SEARCH_COMMUNITIES,
    SET_SEARCH_CENSUS_TRACTS,
    SET_SEARCH_FLEET,
    SET_PROJECT_STATUS,
    SET_CITIES,
    RESET_SEARCH_DATE,
    RESET_SEARCH_VEHICLES,
    RESET_SEARCH_COMMUNITIES,
    RESET_SEARCH_PARAMETERS,
    RESET_SEARCH_VEHICLES_PARAMETERS,
    RESET_SEARCH_CENSUS_PARAMETERS,
    RESET_CITY_FILTER,
    SET_DISTRICTS,
    RESET_DISTRICT_FILTER,
    RESET_MUNICIPALITIES_FILTER,
    SET_MUNICIPALITIES,
    SET_LEAK_STATUSES,
    SET_LEAK_CODES,
    RESET_LEAK_STATUS_FILTER,
    RESET_LEAK_CODE_FILTER
} from '../actionTypes';

const setSearchStartDate = payload => {
    return {
        type: START_SEARCH_DATE,
        payload
    };
};

const setSearchEndDate = payload => {
    return {
        type: END_SEARCH_DATE,
        payload,
    };
};

const setSearchCensusTracts = payload => {
    return {
        type: SET_SEARCH_CENSUS_TRACTS,
        payload,
    }
}

const setSearchCommunities = payload => {
    return {
        type: SET_SEARCH_COMMUNITIES,
        payload
    }
}

const setSearchAllCensusTracts = payload => {
    return {
        type: SET_SEARCH_ALL_CENSUS_TRACTS,
        payload,
    }
}

const setSearchFleet = payload => {
    return {
        type: SET_SEARCH_FLEET,
        payload
    }
}

const setProjectStatuses = payload => {
    return {
        type: SET_PROJECT_STATUS,
        payload
    }
}

const setCities = payload => {
    return {
        type: SET_CITIES,
        payload
    }
}

const setDistricts = payload => {
    return {
        type: SET_DISTRICTS,
        payload
    }
}

const setMunicipalities = payload => {
    return {
        type: SET_MUNICIPALITIES,
        payload
    }
}

const setLeakStatuses = payload => {
    return {
        type: SET_LEAK_STATUSES,
        payload
    }
}

const setLeakCodes = payload => {
    return {
        type: SET_LEAK_CODES,
        payload
    }
}

const resetSearchDate = () => {
    return {
        type: RESET_SEARCH_DATE,
    }
}

const resetSearchVehicles = () => {
    return {
        type: RESET_SEARCH_VEHICLES,
    }
}

const resetSearchCommunities = () => {
    return {
        type: RESET_SEARCH_COMMUNITIES,
    }
}

const resetSearchParameters = () => {
    return {
        type: RESET_SEARCH_PARAMETERS,
    }
}

const resetVehiclesParameters = () => {
    return {
        type: RESET_SEARCH_VEHICLES_PARAMETERS,
    }
}

const resetCensusTracts = () => {
    return {
        type: RESET_SEARCH_CENSUS_PARAMETERS
    }
}

const resetDistrictFilter = () => {
    return {
        type: RESET_DISTRICT_FILTER
    }
}

const resetCityFilter = () => {
    return {
        type: RESET_CITY_FILTER
    }
}

const resetMunicipalitiesFilter = () => {
    return {
        type: RESET_MUNICIPALITIES_FILTER
    }
}

const resetLeakStatuses = () => {
    return {
        type: RESET_LEAK_STATUS_FILTER
    }
}

const resetLeakCodes = () => {
    return {
        type: RESET_LEAK_CODE_FILTER
    }
}

export {
    setSearchStartDate,
    setSearchEndDate,
    setSearchCensusTracts,
    setSearchCommunities,
    setSearchAllCensusTracts,
    setSearchFleet,
    setProjectStatuses,
    setCities,
    setDistricts,
    setMunicipalities,
    setLeakStatuses,
    setLeakCodes,
    resetSearchDate,
    resetSearchVehicles,
    resetSearchCommunities,
    resetSearchParameters,
    resetVehiclesParameters,
    resetCensusTracts, 
    resetCityFilter,
    resetDistrictFilter,
    resetMunicipalitiesFilter,
    resetLeakStatuses,
    resetLeakCodes
};
