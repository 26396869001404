import { AsphaltTestingLayer } from './AsphaltTestingLayer';
import { VegetationLayer } from './Vegetation';
import { MandIWorkLayer } from '../../StreetImprovement/layers/MandIWork';
import { LUFTsLayer } from './LUFTsLayer';
import { NHSLayer } from './NationalHighwaySystem';
import { EagleAwarenessLayer } from './EagleAwareness';
import { AsbestosLayer } from './AsbestosLayer';
import { EnvirostarLayer } from './EnvirostarLayer';
import { NFABLayer } from './NationalForestAdministrativeBoundaries';
import { CNDDBAllLayer } from './CNDDB';
import { LandSurfaceEstateLayer } from './BLM/Land_Surface_Estate';
import CalEnviroLayer from '../../../mapbox/layers/CalEnviroLayer'
import { HospitalsLayer, TribalLandsLayer, RacialEquityLayer, EventDelegator, DisadvantagedCommunityLayer } from '@innovation-toolkit/mapbox';
import { MandILeakLayer } from './MandILeaks';
import { RailwayLayer } from './Railway';
import { BridgeLayer } from './Bridge';

export const AbatementOptimizationMapLayers = () => {
    return (
        <>
            <EventDelegator layerIds={[]}/>
            <MandILeakLayer showLayer={true}/>
            <LUFTsLayer/>
            <NHSLayer/>
            <EagleAwarenessLayer/>
            <VegetationLayer/>
            <AsbestosLayer />
            <EnvirostarLayer />
            <AsphaltTestingLayer/>
            <NFABLayer/>
            <RailwayLayer/>
            <BridgeLayer/>
            <CNDDBAllLayer/>
            <LandSurfaceEstateLayer/>
            {/* <CalEnviroLayer tractsSelectable={false} toggleToHighlight/> */}
            <DisadvantagedCommunityLayer showLayer={false} />
            <RacialEquityLayer showLayer={false}/>
            <TribalLandsLayer showLayer={false}/>
            <HospitalsLayer showLayer={false}/>
        </>
    )
}