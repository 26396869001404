import { Layer, Source } from "react-map-gl"
import { APIGateways } from "@config/config";
import { useEffect, useState } from "react";
import { addToMapLoadingList, removeFromMapLoadingList, useActivateMapLayer, selectBeforeIDs } from "@innovation-toolkit/mapbox";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";

export const AsphaltTesting_LAYER = 'AsphaltTesting_LAYER1';
export const AsphaltTesting_SOURCE = 'AsphaltTesting_SOURCE1';

export const AsphaltTestingLayerMetadata = {
    layerId: AsphaltTesting_LAYER,
    name: 'AsphaltTesting',
    color: '#B28E8C'
}

// TODO_ST
const fetchAsphaltTestingData = (callBack, filterOptions) => {

    const url = `${APIGateways.scgcore}/data/asphalt`;

    const filter = {}

    axios.get(url, filter)
        .then((response) => {
            // Returns as a presigned url, fetch the data here
            return fetch(response.data)
                .then((fetchResponse) => fetchResponse.json())
                .then((data) => {
                    callBack(data, false)
                })
        })
        .catch((err) => {
            callBack(null, true)
        })
}

export const AsphaltTestingLayer = ({showLayer=false}) => {

    const dispatch = useDispatch()
    const beforeIDs = useSelector(selectBeforeIDs)

    const [showAsphaltTestingLayer] = useActivateMapLayer(AsphaltTestingLayerMetadata, showLayer)
    const [data, setData] = useState(null);

    useEffect(() => {
        // dispatch(addToMapLoadingList('AsphaltTesting'))
        fetchAsphaltTestingData((data, isError) => {
            if (!isError) {
                setData(data)
            } 
            // dispatch(removeFromMapLoadingList('AsphaltTesting'))
        })
    }, []) // Add searchDates to filter on search

    const sourceConfig = {
        id: AsphaltTesting_SOURCE,
        type: "geojson",
        data: data,
        cluster: true,
        clusterMaxZoom: 12,
        clusterRadius: 15,
    }

    const circleConfig = {
        id: AsphaltTesting_LAYER,
        type: "circle",
        source: AsphaltTesting_SOURCE,
        layout: {
            'visibility': showAsphaltTestingLayer ? 'visible' : 'none'
        },
        paint: {
            'circle-color': AsphaltTestingLayerMetadata.color,
            'circle-stroke-color': '#835E61',
            'circle-stroke-width': 1
        },
        beforeId: beforeIDs.circle
    }

    const innerCircleConfig = {
        id: AsphaltTesting_LAYER + "_inner",
        type: "circle",
        source: AsphaltTesting_SOURCE,
        layout: {
            'visibility': showAsphaltTestingLayer ? 'visible' : 'none'
        },
        paint: {
            'circle-color': AsphaltTestingLayerMetadata.color,
            'circle-stroke-color': '#835E61',
            'circle-stroke-width': 1,
            'circle-radius': 2
        },
        beforeId: beforeIDs.circle
    }

    return (
        <Source {...sourceConfig}>
            <Layer {...circleConfig} />
            <Layer {...innerCircleConfig}/>
        </Source>
    )
}


 
