import { useDispatch, useSelector } from "react-redux"
import { LUFTsLayerMetadata } from "./layers/LUFTsLayer";
import { AsbestosLayerMetadata } from "./layers/AsbestosLayer";
import { EnvirostarLayerMetadata } from "./layers/EnvirostarLayer";
import { addActiveLayer, removeActiveLayer, selectIsLayerActive } from "@innovation-toolkit/mapbox"
import { LayerToggle, LayerToggleIconChild, LayerToggleIcon } from '@innovation-toolkit/ui';
import { HazmatIcon } from "assets/icons/HazmatIcon";
import { AsphaltTestingLayerMetadata } from "./layers/AsphaltTestingLayer";

export const HazmatToggle = () => {
    const dispatch = useDispatch()

    const layerIds = [ LUFTsLayerMetadata.layerId, AsbestosLayerMetadata.layerId, EnvirostarLayerMetadata.layerId, AsphaltTestingLayerMetadata.layerId ];
    const layerShowState = [
        useSelector((state) => selectIsLayerActive(state, LUFTsLayerMetadata.layerId)),
        useSelector((state) => selectIsLayerActive(state, AsphaltTestingLayerMetadata.layerId)),
        useSelector((state) => selectIsLayerActive(state, AsbestosLayerMetadata.layerId)),
        useSelector((state) => selectIsLayerActive(state, EnvirostarLayerMetadata.layerId))
    ];
    
    const handleClick = (shouldShow) => {
        if (!shouldShow) {
            layerIds.forEach((layerId) =>  dispatch(removeActiveLayer(layerId)))
        } else {
            layerIds.forEach((layerId) =>  dispatch(addActiveLayer(layerId)))
        }
    }

    const handleIndividualChange = (layerID, index) => {
        if (layerShowState[index]) {
            dispatch(removeActiveLayer(layerID))
        } else {
            dispatch(addActiveLayer(layerID))
        }
    }

    return (
        <LayerToggle
            title='Hazmat'
            componentIcon={HazmatIcon}
            onChange={handleClick}
            currentState={layerShowState.includes(true)}
            children={
                <section style={{display: 'flex', flexDirection: 'column'}}>
                    <LayerToggleIconChild 
                        Icon={<LayerToggleIcon type="polygon-shaded" fillColor={AsbestosLayerMetadata.color} strokeColor="white" outlineColor="white"/>} 
                        title={AsbestosLayerMetadata.name} 
                        subLayerCheckbox={{defaultChecked: layerShowState[2], onChange: () => handleIndividualChange(AsbestosLayerMetadata.layerId, 2)}}/>
                    <LayerToggleIconChild 
                        Icon={<LayerToggleIcon type="bullseye" fillColor={AsphaltTestingLayerMetadata.color} strokeColor="#031627" outlineColor="white"/>} 
                        title="Asphalt Testing"
                        subLayerCheckbox={{defaultChecked: layerShowState[1], onChange: () => handleIndividualChange(AsphaltTestingLayerMetadata.layerId, 1)}}/>
                    <LayerToggleIconChild 
                        Icon={<LayerToggleIcon type="triangle" fillColor="#CA3033" strokeColor="white" outlineColor="white"/>} 
                            title={EnvirostarLayerMetadata.name}
                            subLayerCheckbox={{defaultChecked: layerShowState[3], onChange: () => handleIndividualChange(EnvirostarLayerMetadata.layerId, 3)}}/>
                    <LayerToggleIconChild 
                        Icon={<LayerToggleIcon type="bullseye" fillColor={LUFTsLayerMetadata.color} strokeColor="#031627" outlineColor="white"/>} 
                        title={LUFTsLayerMetadata.name}
                        subLayerCheckbox={{defaultChecked: layerShowState[0], onChange: () => handleIndividualChange(LUFTsLayerMetadata.layerId, 0)}}/>
                </section>
            }
        />
    )
}