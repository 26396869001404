import React, { useCallback } from 'react'

import strings from "utils/strings";
import styled from "styled-components";
import { resetMenuVehicleList } from "../../../redux/menu/actions";
import { useDispatch, useSelector } from "react-redux";
import { selectIsClearSearchDisabled } from "../../../redux/searchItems/selectors";
import { resetMapCensusTracts } from "../../../redux/mapStateInternal/action";
import { resetCensusTracts, resetCityFilter, resetDistrictFilter, resetSearchParameters, resetVehiclesParameters } from "../../../redux/searchItems/actions";
import { restFleetVehiclesFilter } from "../../../redux/sidebar/actions";
import { Button } from '@innovation-toolkit/ui';
import { setSearchNotificationNo, setSearchOrderNo, setSearchPlannerID, setSelectedFilteringLayer, setWorkTypes } from 'redux/streetImprovements/action';

const ActionWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 0 24px;
  margin-top: 40px;
  width: 100%;
`

const MenuTabButtons = () => {

    const dispatch = useDispatch()
    const isClearSearchDisabled = useSelector(selectIsClearSearchDisabled)

    const handleReset = useCallback(() => {
        dispatch(resetSearchParameters())
        dispatch(resetVehiclesParameters())
        dispatch(restFleetVehiclesFilter())
        dispatch(resetCensusTracts())
        dispatch(resetMapCensusTracts())
        dispatch(resetMenuVehicleList())
        dispatch(resetCityFilter())
        dispatch(resetDistrictFilter())
        dispatch(setSearchPlannerID(''))
        dispatch(setSearchNotificationNo(''))
        dispatch(setSearchOrderNo(''))
        dispatch(setSelectedFilteringLayer('All'))
        dispatch(setWorkTypes([]))
    }, [dispatch])

    return (
        <ActionWrapper>
            <Button
                title={strings.tabs.actions.clear}
                size='L'
                type='secondary'
                onClick={handleReset}
                disabled={isClearSearchDisabled}
            />
        </ActionWrapper>
    )
}

export default MenuTabButtons