import '../App.css';
import React from 'react';
import Main from './main';
import * as config from '@config/config';
import {useAuth} from "@innovation-toolkit/rbac"
import { MapLoadingIndicator } from '@innovation-toolkit/mapbox';
import { RouterProvider } from 'react-router-dom';

import axios from 'axios';
import { getRouter } from './routing/Routes';

export function App() {
    // amplify debug flag
    window.LOG_LEVEL = 'DEBUG';
    const {currentUser} = useAuth({
      identityPoolId: '',
      region: config.AWS_REGION, // REQUIRED - Amazon Cognito Region
      userPoolId: config.AWS_COGNITO_USER_POOL_ID, //OPTIONAL - Amazon Cognito User Pool ID
      userPoolWebClientId: config.AWS_COGNITO_CLIENT_ID, //OPTIONAL - Amazon Cognito Web Client ID
      oauth: {
        domain: config.AWS_COGNITO_CLIENT_DOMAIN_NAME,
        scope: config.AWS_COGNITO_IDP_OAUTH_CLAIMS,
        redirectSignIn: config.AWS_COGNITO_IDP_SIGNIN_URL,
        redirectSignOut: config.AWS_COGNITO_IDP_SIGNOUT_URL,
        responseType: config.AWS_COGNITO_IDP_GRANT_FLOW,
        options: {
          AWS_COGNITO_IDP_NAME: config.AWS_COGNITO_IDP_NAME
        }
      }
    }, axios);

    return currentUser ? (
        <RouterProvider router={getRouter()} fallbackElement={<MapLoadingIndicator isLoading={true} />} />
    ) : <MapLoadingIndicator isLoading={!currentUser} />
}

export default App;