import { selectIsLayerActive, removeActiveLayer, addActiveLayer } from "@innovation-toolkit/mapbox";
import { LayerToggle, LayerToggleIconChild, HeatmapIcon, LayerToggleIcon } from "@innovation-toolkit/ui";
import CO2 from "assets/icons/co2.svg?react";

import { useDispatch, useSelector } from "react-redux";
import { selectEmissionsType, setEmissionsType } from "redux/fleetDecarbonization/slice";
import { FleetDecarbonizationLayerMetadata } from ".";


export const FleetDecarbonizationLayerToggle = () => {
    const dispatch = useDispatch()
    const FLEET_LAYER = FleetDecarbonizationLayerMetadata.layerId

    const shouldShowFleetLayer = useSelector((state) => selectIsLayerActive(state, FLEET_LAYER))
    const emissionsType = useSelector(selectEmissionsType)

    let movingType = emissionsType === 'combined' || emissionsType === 'moving' ? true : false
    let idlingType = emissionsType === 'combined' || emissionsType === 'idling' ? true : false

    
    const handleClick = (shouldShow) => {
        if (!shouldShow) {
            dispatch(removeActiveLayer(FLEET_LAYER))
        } else {
            dispatch(addActiveLayer(FLEET_LAYER))
        }
    }


    const handleIndividualChange = (type, e) => {
        let emissions = 'combined'
        switch (type) {
            case 'moving':
                movingType = !movingType
                idlingType === true
                    ? movingType === true ? emissions = 'combined' : emissions = 'idling'
                    : movingType === true ? emissions = 'moving' : emissions = 'none'
                break;
            case 'idling':
                idlingType = !idlingType
                movingType === true
                    ? idlingType === true ? emissions = 'combined' : emissions = 'moving'
                    : idlingType === true ? emissions = 'idling' : emissions = 'none'
                break;
            default:
                break;
        }
        dispatch(setEmissionsType(emissions))
    }

    return (
        <LayerToggle
            title="CO2 Emissions" 
            titleVariant="Heading 4"
            currentState={shouldShowFleetLayer}
            onChange={handleClick}
            componentIcon={CO2}
            children={
                <>
                    <LayerToggleIconChild 
                        Icon={
                            <LayerToggleIcon 
                                type="custom" 
                                Icon={HeatmapIcon} 
                                IconOffset={[0, 0]} 
                                outlineColor="white"
                            />
                        } 
                        title="Moving CO2"
                        subLayerCheckbox={{defaultChecked: movingType, onChange: (e) => handleIndividualChange('moving',e)}}
                    />
                    <LayerToggleIconChild 
                        Icon={
                            <LayerToggleIcon 
                                type="custom" 
                                Icon={HeatmapIcon} 
                                IconOffset={[0, 0]} 
                                outlineColor="white"
                            />
                        } 
                        title="Idling CO2"
                        subLayerCheckbox={{defaultChecked: idlingType, onChange: (e) => handleIndividualChange('idling', e)}}
                    />
                </>
            }
        />
    )
}