import React from 'react';
import { Layout } from "antd";
import { isRouteErrorResponse, useRouteError } from "react-router-dom";


export const ErrorRoute = ({overrideMessage = ''}) => {
    const error = useRouteError();

    const getErrorMessage = () => {
        if (isRouteErrorResponse(error)) {
            if (error.status === 404) {
              return "This page doesn't exist!";
            }
            if (error.status === 401) {
              return "You aren't authorized to see this"
            }
            if (error.status === 503) {
              return "API Error"
            }
            if (error.data) return error.data;
        } else {
            if(overrideMessage) return overrideMessage;

            return "Application Error"
        }

    }

  return (
    <Layout
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#0E263B",
        position: "relative",
        padding: "10rem"
      }}
    >
        <h1>An error has occurred on the page you tried to visit: {getErrorMessage()}</h1>
        <br />
        <h2>Please return to the home page and try again.</h2>

        
    </Layout>
  );
}