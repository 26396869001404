const BASE_FONT_FAMILY = 'Interstate, sans-serif';
const BASE_BOLD_FONT_FAMILY = 'InterstateBold, sans-serif';
const BASE_LIGHT_FONT_FAMILY = 'InterstateLight, sans-serif';

export const color = {
    white: '#ffffff',
    whiteGrey: '#FFFFFF80',
    white50: '#FFFFFF50',
    white20: '#FFFFFF20',
    richBlack: '#031627',
    drizzling: '#354552',
    drizzlingOnBlue: '#273C4F',
    grey: '#BFBFBF',
    grey2: '#00000029',
    prussianBlue: '#0E263B',
    rainyDay: '#8FA1B1',
    dodgerBlue: '#178ffe',
    purpleRain: '#984794',
    purple: '#2A1A8A',
    pink: '#DF84BF',
    snow: '#E8E6F2',
    uaRed: '#DF134C',
    blue: '#178FFE',
    blueJeans: '#40A9FF',
    mapDetail: '#3E5162',
    mapLine: '#36495A',
    fleetGrey: 'rgb(39,60,79)',
    autumnGold: '#DC7300',
    forestGreen: '#088856',
    middaySky: '#0193D5',
};

const other = {
    borderRadius: '0.25rem',
}

export const fontSize = {
        paragraph: {
            t1: '14px',
            normal: '16px'
        },
    
};

export const theme = {
    BASE_FONT_FAMILY,
    BASE_BOLD_FONT_FAMILY,
    BASE_LIGHT_FONT_FAMILY,
    color,
    other,
    fontSize
};
