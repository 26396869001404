import MethaneLeaksLayer, { METHANE_POINT_LAYER_ID } from '../../mapbox/layers/MethaneLeaks/index.jsx'
import SCGEmissionsLayer, { SCG_EMISSIONS_LAYER} from '../../mapbox/layers/scg-emissions/index.jsx';
import { TribalLandsLayer, HospitalsLayer, EventDelegator } from '@innovation-toolkit/mapbox';
import CalEnviroLayer from '../../mapbox/layers/CalEnviroLayer'

export const MethaneMapLayers = () => {
    return (
        <>
            <EventDelegator layerIds={[ METHANE_POINT_LAYER_ID, SCG_EMISSIONS_LAYER ]} eventTypes={['click', 'mousemove']}/>
            <MethaneLeaksLayer />
            <SCGEmissionsLayer/>
            <CalEnviroLayer fillPattern={true}/>
            <TribalLandsLayer showLayer={false}/>
            <HospitalsLayer showLayer={false}/>
        </>
    )
}