import LocalizedStrings from 'react-localization';

const lang = {
    en: {
        timeFrame: {
            title: 'Select Time Frame',
            startPlaceholder: 'Start',
            endPlaceholder: 'End',
        },
        censusTracts: {
            header: 'Select Census Tracts',
            communitiesSelected: '{count} Communities Selected',
            dacSwitch: 'Select All DACs',
            sdgeSwitch: 'Select All SDGE Region'
        },
        outageCauses: {
            header: 'Select Outage Cause',
            causeSelected: '{count} Cause Selected',
            causesSelected: '{count} Causes Selected',
        },
        afn: {
            toggle: {
                title: 'AFN Customers',
                subtitle: 'Show Advanced Layers',
                tooltip: 'AFN (Access and functional needs) customer data is aggregated by census tract. Census tracts with higher numbers of AFN customers are shaded darker on the map. Source: CIS',
            }
        },
        dac: {
            toggle: {
                title: 'Highlight DACs',
                subtitle: 'Show All DACs in SDG&E Territory',
                tooltip: 'Disadvantaged communities (DACs) are shaded on the map. Source: CalEnviroScreen 4.0, CA Office of Environmental Health Hazard Assessment',
            }
        },
        tribalLands: {
            toggle: {
                title: 'Tribal Lands',
                subtitle: 'Show Tribal Lands Nearby',
                tooltip: 'Tribal lands are shaded on the map. Source: Dept. of Forestry and Fire Protection',
            }
        },
        EVChargers: {
            external: {
                toggle: {
                    title: 'EV',
                    tooltip: 'External EV charging stations are mapped according to their location. Source: Dept. of Energy',
                },
                heatmapToggle: {
                    title: 'Heatmap',
                    subtitle: 'Show Charging Heatmap'
                }
            },
            internal: {
                toggle: {
                    title: 'SDG&E EV',
                    tooltip: 'SDG&E EV charging stations are mapped according to their location. Source: Internal dataset',
                }
            }
        },
        hydrogenFuelStations: {
            toggle: {
                title: 'Hydrogen',
                tooltip: 'External hydrogen fuel stations are mapped according to their location. Source: Dept. of Energy',
            }
        },
        circuitReliabilityMetrics: {
            toggle: {
                CAIDI: {
                    title: 'CAIDI Metrics',
                    tooltip: 'CAIDI Metrics are calculated for each census tract. Census tracts with a higher CAIDI are shaded darker on the map. Source: SAIDIDAT',
                }
            }
        },
        hhEmpower: {
            toggle: {
                title: 'Medical Devices',
                subtitle: 'Show All Electricity Dependent Medical Devices',
                tooltip: 'Electricity dependent medical devices are aggregated by census tract. Census tracts with higher numbers of electricity dependent medical devices are shaded darker on the map. Source: Dept. of Health & Human Services, HHS emPOWER Map'
            }
        },
        sentinal: {
            toggle: {
                title: 'Sentinal'
            }
        },
        ihss: {
            toggle: {
                title: 'IHSS',
                subtitle: 'Show All Electricity Dependent In-Home Supportive Services',
                tooltip: 'IHSS (In Home Supportive  Services) recipients are aggregated by census tract. Census tracts with higher numbers of IHSS recipients are shaded darker on the map.  Source: CA Dept. of Social Services'
            }
        },
        hospitals: {
            toggle: {
                title: 'Hospitals',
                tooltip: 'Hospitals are mapped according to their location. Source: Homeland Infrastructure Foundation',
            }
        },
        modal: {
            bodyText1: 'There are four data classifications - Public, Internal, Confidential, and Restricted. You can refer to the information classification guidelines to determine your data classification.',
            bodyText2: 'Security has approved data classifications of confidential and below for previous CARB presentations.',
            bodyText3: 'If you have a data classification of restricted, please reach out to the Cloud Office and they will set up time with your team and security to discuss.',
            bodyBoldText: 'Please select the data classification of the file you wish to upload and press I Agree to be able to view the data on the map.',
            subtitle: 'Note: At this time, we are unable to accept restricted files.'
        },
        languages: {
            'en-US': 'EN'
        }
    }
};
const strings = new LocalizedStrings(lang);
export default strings;
