import { createSelector } from "@reduxjs/toolkit";

const selectAFNState = state => state.AFN;

export const selectAFNSource = createSelector(
    selectAFNState,
    state => state.source
)

export const selectShowAFN = createSelector(
    selectAFNState,
    state => state.showAFN
)