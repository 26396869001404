import { Layer, Source, useMap } from "react-map-gl"
import { APIGateways } from "@config/config"
import { useEffect, useState } from "react";
import { isActiveLayer, selectIsLayerActive, useActivateMapLayer, useMapHoverEvent, selectBeforeIDs } from "@innovation-toolkit/mapbox";
import { useSelector } from "react-redux";

export const VEGETATION_LAYER = 'VEGETATION_LAYER';
export const VEGETATION_SOURCE = 'VEGETATION_SOURCE';

export const VegetationLayerMetadata = {
    layerId: VEGETATION_LAYER,
    name: 'Vegetation',
    color: '#ADD556'
}


export const VegetationLayer = ({showLayer=false}) => {

    const [showVegetationLayer] = useActivateMapLayer(VegetationLayerMetadata, showLayer)
    const beforeIDs = useSelector(selectBeforeIDs)

    const sourceConfig = {
        id: VEGETATION_SOURCE,
        type: 'vector',
        tiles: [`${APIGateways.scgcore}/tiles/vegetation/{z}/{x}/{y}`]
    }

    // const fillConfig = {
    //     id: VEGETATION_LAYER,
    //     type: "fill",
    //     source: VEGETATION_SOURCE,
    //     filter: ['!=', ['get', 'BESTAVAILABLEVEGDESC'], 'Urban'],
    //     'source-layer': 'Vegetation',
    //     layout: {
    //         'visibility': showVegetationLayer ? 'visible' : 'none'
    //     },
    //     paint: {
    //         "fill-color": VegetationLayerMetadata.color,
    //         "fill-opacity": 0.6
    //     },
    //     beforeId: beforeId.fill
    // }

    const lineConfig = {
        id: VEGETATION_LAYER,
        type: "line",
        source: VEGETATION_SOURCE,
        filter: ['!=', ['get', 'BESTAVAILABLEVEGDESC'], 'Urban'],
        'source-layer': 'Vegetation',
        layout: {
            'visibility': showVegetationLayer ? 'visible' : 'none'
        },
        paint: {
            "line-color": VegetationLayerMetadata.color,
            // "line-dasharray": [1, 2]
            "line-width": 3
        },
        beforeId: beforeIDs.fill
    }

    const dashedLineConfig = {
        id: VEGETATION_LAYER + "_dashed",
        type: "line",
        source: VEGETATION_SOURCE,
        filter: ['!=', ['get', 'BESTAVAILABLEVEGDESC'], 'Urban'],
        'source-layer': 'Vegetation',
        layout: {
            'visibility': showVegetationLayer ? 'visible' : 'none'
        },
        paint: {
            "line-color": "#031627",
            "line-dasharray": [4, 1],
            // "line-width": 3
        },
        beforeId: beforeIDs.fill
    }

    return (
        <Source {...sourceConfig}>
            <Layer {...lineConfig} />
            <Layer {...dashedLineConfig} />
        </Source>
    )
}

