import { useDispatch, useSelector } from "react-redux"
import { LSALayerMetadata } from "."
import { addActiveLayer, removeActiveLayer, selectIsLayerActive } from "@innovation-toolkit/mapbox"
import { LayerToggle, LayerToggleIcon, LayerToggleIconChild } from '@innovation-toolkit/ui';
import LSA_ICON from './lsa_icon.svg?react';
import { useEffect, useState } from "react";
import { APIGateways } from "config/config";
import axios from 'axios'


const fetchLSARefreshDate = (callBack, filterOptions) => {

    const url = `${APIGateways.streetimprovements}/layer_update_date`;

    const filter = {
        layer: 'lsa'
    }

    axios.post(url, filter)
        .then((response) => {
            callBack(response.data, false)
        })
        .catch((err) => {
            callBack(null, true)
        })// NotificationType for work type
}


export const LSALayerToggle = () => {
    const LSA_LAYER = LSALayerMetadata.layerId
    const dispatch = useDispatch()
    const shouldShowLSADProjects = useSelector((state) => selectIsLayerActive(state, LSA_LAYER))
    const [lastRefreshDate, setLastRefreshDate] = useState(null)

    useEffect(() => {

        fetchLSARefreshDate((data, isError) => {
            if (!isError) {
                setLastRefreshDate(data)
            } 
        })
        
    }, [])

    const handleClick = (shouldShow) => {
        if (!shouldShow) {
            dispatch(removeActiveLayer(LSA_LAYER))
        } else {
            dispatch(addActiveLayer(LSA_LAYER))
        }
    }

    const TooltipMessage = <div>
    This layer is zoom dependent. Zoom in to view this data on the map.<br/>
    <span><b style={{fontWeight: 'bold'}}>Last Refresh Date:</b> {lastRefreshDate?.last_updated}</span>
    </div>

    return (
        <LayerToggle
            title='LSA'
            bodyStyle={{backgroundColor: '#0E263B'}}
            headerStyle={{marginTop: '10px'}}
            // componentIcon={LSA_ICON}
            tooltipText={lastRefreshDate ? TooltipMessage : undefined}
            onChange={handleClick}
            currentState={shouldShowLSADProjects}
            children={
                <div
                style={{
                    position:
                        'relative' /* Position relative for the parent div */,
                    padding: '5px 0px 11px 10px',
                }}
                >
                <div
                    style={{
                        position: 'absolute',
                        left: -8 /* Position on the left */,
                        top: 9 /* Align with the top */,
                        height: '60%' /* Match the height of the parent */,
                        width: '1.5px' /* Width of the border */,
                        backgroundColor:
                            '#FFFFFF33' /* Color of the border */,
                    }}
                />
                <section style={{display: 'flex', flexDirection: 'column'}}>
                    <LayerToggleIconChild
                        Icon={
                            <LayerToggleIcon
                                type="line" 
                                fillColor={LSALayerMetadata.color} 
                                strokeColor="white" 
                                outlineColor="white"
                            />
                        } 
                        title={LSALayerMetadata.name} 
                    />
                </section>
                </div>
            }
        />
    )
}