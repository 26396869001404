import {
    DAC_DISTRICTS,
    ALL_DISTRICTS,
    ALL_VEHICLES,
    SET_HEATMAP,
    SET_EMISSION_TYPE,
    SET_ALLOWED_SEARCH,
    SET_DISTRICT,
    SET_MAP_COMMUNITIES,
    SET_MAP_SCENARIO,
    SET_MAP_TABLES,
    SET_MAP_CENSUS_TRACTS,
    SET_MAP_REPLACEMENT_TABLE,
    RESET_MAP_CENSUS_TRACTS,
    SET_MAP_QUALITY_AIR,
    SET_MAP_FLEET_IMPACT,
    RESET_MAP_POSITION,
    SET_MAP_POSITION,
    SET_SIDE_BAR_VISIBILITY,
    SET_SIDE_BAR_CONTENT
} from '../actionTypes';

const initialState = {
    dacDistricts: true,
    qualityAir: false,
    allDistricts: false,
    heatMap: false,
    emissionType: 'combined',
    allowedSearch: false,
    selectedDistrict: null,
    selectedCommunities: null,
    selectedMapScenario: null,
    mapScenario: {
        zoom: 13.5
    },
    tables: {
        replacementTable: false,
        swappingTable: false,
        fleetImpact: false
    },
    mapCensusTracts: null,
    replacementTable: null,
    position: {
        longitude: -117.6906,
        latitude: 34.0981,
        zoom: 8,
        bearing: 0,
        pitch: 0
    },
    sideBarVisibility: false,
    sideBarContent: {}
};

const mapStateInternal = (state = initialState, { type, payload }) => {
    switch (type) {
        case DAC_DISTRICTS:
            return { ...state, dacDistricts: payload };
        case SET_MAP_QUALITY_AIR:
            return { ...state, qualityAir: payload };
        case SET_MAP_FLEET_IMPACT:
            return { ...state, fleetImpact: payload };
        case SET_MAP_FLEET_IMPACT:
            return { ...state, fleetImpact: initialState.fleetImpact };
        case ALL_DISTRICTS:
            return { ...state, allDistricts: payload };
        case ALL_VEHICLES:
            return {...state, allVehicles: payload};
        case SET_HEATMAP:
            return { ...state, heatMap: payload }
        case SET_EMISSION_TYPE:
            return { ...state, emissionType: payload }
        case SET_ALLOWED_SEARCH:
            return { ...state, allowedSearch: payload }
        case SET_DISTRICT:
            return { ...state, selectedDistrict: payload }
        case SET_MAP_CENSUS_TRACTS:
            return { ...state, mapCensusTracts: payload }
        case SET_MAP_COMMUNITIES:
            return { ...state, selectedCommunities: payload }
        case SET_MAP_SCENARIO:
            return { ...state, selectedMapScenario: payload }
        case SET_MAP_TABLES: {
            if (!payload) {
                return { ...state, tables: initialState.tables }
            }
            return { ...state, tables: { ...initialState.tables, ...payload } }
        }
        case SET_MAP_REPLACEMENT_TABLE: {
            return { ...state, replacementTable: payload }
        }
        case RESET_MAP_CENSUS_TRACTS: {
            return { ...state, mapCensusTracts: initialState.mapCensusTracts }
        }
        case SET_MAP_POSITION:
            return {...state, position: {...state.position, ...payload}}
        case RESET_MAP_POSITION:
            return {...state, position: {...initialState.position}}
        case SET_SIDE_BAR_VISIBILITY:
            return {...state, sideBarVisibility: payload}
        case SET_SIDE_BAR_CONTENT:
            return {...state, sideBarContent: payload}
        default:
            return state;
    }
};

export default mapStateInternal;
