import { SET_HIGHLIGHT_DAC } from "./actionTypes"

export const initialState = {
    highlightDAC: false
}

const CalEnviro = (state = initialState, {type, payload}) => {
    switch (type) {
        case SET_HIGHLIGHT_DAC:
            return { ...state, highlightDAC: payload }
        default:
            return state
    }
}

export default CalEnviro;