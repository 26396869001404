import styled from "styled-components";
import { MapPanel } from '@innovation-toolkit/mapbox';
import { Spin } from 'antd';
import { useCallback, useEffect } from "react";
import _ from 'lodash';

import { selectSimpleAnalyticsTableData, selectShowSimpleAnalytics, selectSimpleAnalytics } from "redux/selfService/selector";
import { setShowSimpleAnalyticsTable, setSimpleAnalyticsTableData } from '@redux/selfService/action';
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useState } from "react";
import axios from "axios";
import { APIGateways, APP_ENV} from "@config/config";
import { NoDataInfo, Table } from "@innovation-toolkit/ui";

const StyledSpin = styled(Spin)`
  display: block;
  padding-top: 16px;
`

const rowHeaderMap = {
    'dac': 'DAC',
    'not_dac': 'Non-DAC',
    'no_ciscorep_value': 'N/A',
    'Total': 'Total'
}

const formatNumber = (number, format) => {
    if (!number) return ''

    switch (format) {
        case 'Dollar':
            return number.toLocaleString("en-US", {style:"currency", currency:"USD"})
        default:
            return number.toLocaleString();
    }
}

export const PopulationSimpleAnalytics = () => {
    const dispatch = useDispatch()
    const simpleAnalyticsTableData = useSelector(selectSimpleAnalyticsTableData)
    const showSimpleAnalyticsTable = useSelector(selectShowSimpleAnalytics)
    const simpleAnalyticsOptions = useSelector(selectSimpleAnalytics)

    const [formattedData, setFormattedData] = useState(null);

    useEffect(() => {
        axios.post(`${APIGateways.scgcore}/self-service/simple-analytics-table`, {
            additional_columns: "Ethnicity,grant",
            bucket_path: "self-service-raw-data-bucket-" + APP_ENV,
            key_path: "1d2943e2-47d2-4532-bd0d-163ae3c6c508_SCG 2021 Giving Updated 7-11-22 - with coords.xlsx",
            lat_column: "lat_geocoder",
            long_column: "lon_geocoder",
            pivot_agg: "sum",
            pivot_column: "Ethnicity",
            pivot_row: "DAC75",
            pivot_value: "grant"
        })
        .then((res) => {
            dispatch(setSimpleAnalyticsTableData(res.data));
        })
    }, [])

    useEffect(() => {
        if (simpleAnalyticsTableData === null) setFormattedData(null)
        if (simpleAnalyticsTableData !== null && Object.keys(simpleAnalyticsTableData).length < 1 ) setFormattedData({})
        if (!simpleAnalyticsTableData || !simpleAnalyticsTableData.row) return
        const formatted = _.cloneDeep(simpleAnalyticsTableData);
        formatted.row = formatted.row.map((row) => {
            return {
                rowHeader: rowHeaderMap[row.rowHeader],
                data: row.data.map(number => formatNumber(number, simpleAnalyticsOptions.tableSetUp.valueType))
            }
        })
        setFormattedData(formatted)
    }, [simpleAnalyticsTableData, simpleAnalyticsOptions])

    const tooltipInfo = "This table shows simple analytics of the uploaded data set.";

    const handleTableOpen = useCallback((show) => {
        dispatch(setShowSimpleAnalyticsTable(show))
    }, [dispatch])

    return (
        <MapPanel title={"Simple Analytics Table"} defaultState={showSimpleAnalyticsTable} tooltipText={tooltipInfo} openOrClose={showSimpleAnalyticsTable} handleOpenOrClose={(show) => handleTableOpen(show)}>
            { !formattedData 
                ? <StyledSpin spinning={!formattedData} tip='Loading...' />
                : Object.keys(formattedData).length > 0
                    ? <Table tableData={formattedData}/>
                    : <NoDataInfo title="No data found. Select the group attributes in the Analysis tab to show data."/>
            }
        </MapPanel>
    )
}