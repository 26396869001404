import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { DelimiterLine } from "@innovation-toolkit/ui";
import styled from "styled-components";

const StyledContainer = styled.div`
    
`
const EditHeader = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    h1 {
        padding: 0;
        font-weight: 700;
        font-size: 18px;
        margin-bottom: 0px;
    }
`

const StyledEntry = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
    h4 {
        font-weight: 700;
        font-size: 14px;
        margin-bottom: 4px;
    }
    .header-row {
        display: flex;
        justify-content: space-between;
    }
`

export const EditHistory = ({editHistory, onBackButtonClicked}) => {
    return (
        <StyledContainer>
            <EditHeader>
                <LeftOutlined onClick={onBackButtonClicked}/>
                <h1>Edit History</h1>
            </EditHeader>
            <DelimiterLine />
            {editHistory !== null && editHistory.length > 0 && editHistory.map((entry) => {
                return(
                    <StyledEntry>
                        <div className="header-row">
                            <h4>{entry.date}</h4>
                        </div>
                        <h4 style={{ marginBottom: '8px'}}>{entry.user}</h4>
                        <p className="entry-details">{entry.changes}</p>
                    </StyledEntry>
                )
            })}
        </StyledContainer>
    )
}