import { useDispatch, useSelector } from "react-redux"
import { CP10LayerMetadata } from "."
import { addActiveLayer, removeActiveLayer, selectIsLayerActive } from "@innovation-toolkit/mapbox"
import { LayerToggle, LayerToggleIcon, LayerToggleIconChild } from '@innovation-toolkit/ui';
import CP10Icon from 'assets/icons/cp10-icon.svg?react';
import CP10SidebarIcon from 'assets/icons/cp10-sidebar-icon.svg?react';
import { useEffect, useState } from "react";
import axios from 'axios'
import { APIGateways } from "config/config";

const fetchCP10RefreshDate = (callBack, filterOptions) => {

    const url = `${APIGateways.streetimprovements}/layer_update_date`;

    const filter = {
        layer: 'cp10'
    }

    axios.post(url, filter)
        .then((response) => {
            callBack(response.data, false)
        })
        .catch((err) => {
            callBack(null, true)
        })// NotificationType for work type
}

export const CP10LayerToggle = () => {
    const CP_10_LAYER = CP10LayerMetadata.layerId
    const dispatch = useDispatch()
    const shouldShowCP10 = useSelector((state) => selectIsLayerActive(state, CP_10_LAYER))
    const [lastRefreshDate, setLastRefreshDate] = useState(null)

    useEffect(() => {

        fetchCP10RefreshDate((data, isError) => {
            if (!isError) {
                setLastRefreshDate(data)
            } 
        })
        
    }, [])

    const handleClick = (shouldShow) => {
        if (!shouldShow) {
            dispatch(removeActiveLayer(CP_10_LAYER))
        } else {
            dispatch(addActiveLayer(CP_10_LAYER))
        }
    }

    const TooltipMessage = <div>
    This layer is zoom dependent. Zoom in to view this data on the map.<br/>
    <span><b style={{fontWeight: 'bold'}}>Last Refresh Date:</b> {lastRefreshDate?.last_updated}</span>
    </div>

    return (
        <LayerToggle
            title='CP10'
            bodyStyle={{backgroundColor: '#0E263B'}}
            headerStyle={{marginTop: '10px'}}
            // componentIcon={CP10SidebarIcon}
            tooltipText={lastRefreshDate ? TooltipMessage : undefined}
            onChange={handleClick}
            currentState={shouldShowCP10}
            children={
                <div
                style={{
                    position:
                        'relative' /* Position relative for the parent div */,
                    padding: '5px 0px 11px 10px',
                }}
                >
                <div
                    style={{
                        position: 'absolute',
                        left: -8 /* Position on the left */,
                        top: 9 /* Align with the top */,
                        height: '60%' /* Match the height of the parent */,
                        width: '1.5px' /* Width of the border */,
                        backgroundColor:
                            '#FFFFFF33' /* Color of the border */,
                    }}
                />
                <section style={{display: 'flex', flexDirection: 'column'}}>
                    <LayerToggleIconChild
                        Icon={
                            <LayerToggleIcon
                                type="custom" 
                                Icon={CP10Icon} 
                                IconOffset={[-6, -3]} 
                                fillColor={CP10LayerMetadata.color} 
                                strokeColor="white" 
                                outlineColor="white"
                            />
                        } 
                        title={CP10LayerMetadata.name} 
                    />
                </section>
                </div>
            }
        />
    )
}