import LocalizedStrings from 'react-localization';

const lang = {
    en: {
        applications: {},

        buttons: {},

        sidebar: {
            home: 'Home',
            map: 'Map View',
            logout: 'Sign Out',
            reports: 'Reports View'
        },

        menu: {
            header: {
                default: 'Welcome',
                title: 'Your Search '
            },
            censusTracts: {
                defaultTitle: 'Census Tracts',
                defaultValue: 'Use the map or district list to select census tracts',
                title: 'Census Tracts',
                value: '{count} districts selected',
                allSelected: 'All Districts Selected',
                dacSwitch: 'Select All DACs'
            },
            fleet: {
                defaultValue: 'Select Fleet Vehicle(s)',
                selecting: 'Selecting Fleet Vehicle(s)',
                title: 'Fleet Vehicles',
                header: 'Select Vehicles',
                value: '{count} Fleet Vehicles Selected',
                vehiclesSelected: '{count} Vehicles Selected',
                allSelected: 'All Vehicles Selected',
            },
            projectStatusFilter: {
                defaultValue: 'Select Project Status(es)',
                selecting: 'Selecting Select Project Status(es)',
                title: 'Project Status',
                value: '{count} Project Statuses Selected',
                allSelected: 'All Project Statuses Selected',
            },
            cityFilter: {
                header: 'Select Cities',
                defaultValue: 'Select City(ies)',
                selecting: 'Selecting City(ies)',
                title: 'Cities',
                value: '{count} Cities Selected',
                singleSelected: '{count} Selected',
                allSelected: 'All Cities Selected',
            },
            workType: {
                header: 'Work Type',
                defaultValue: 'Select Work ID(s)',
                selecting: 'Selecting Work ID(s)',
                value: '{count} Work IDs Selected' 
            },
            timeFrame: {
                defaultValue: 'Select Time Frame',
                selecting: 'Selecting Time Frame',
                title: 'Time Frame',
                value: '{start} - {end}'
            },
            districtFilter: {
                header: 'Select Districts',
                defaultValue: 'Select District(s)',
                selecting: 'Selecting District(s)',
                value: '{count} Districts Selected',
                singleValue: '{count} Selected'
            }
        },

        methane: {
            toggle: {
                heatmap: {
                    title: 'Heatmap'
                },
                circle: {
                    title: 'Data Points'
                }
            }
        },

        emissions: {
            toggle: {
                title: 'Emissions'
            }
        },

        tabs: {
            timeFrame: {
                title: 'Your Search Results',
                selectRange: 'Select Range',
                rangeSelected: 'Range Selected',

            },
            actions: {
                default: 'MAP UP TO DATE',
                apply: 'APPLY',
                clear: 'CLEAR ALL FILTERS'
            }
        },

        filters: {
            legend: {
                title: 'Legend'
            },
            fleetImpact: {
                title: 'Fleet Impact'
            },

            dac: {
                title: 'Highlight DACs'
            },
            airQuality: {
                title: "Current Air Quality"
            }
        },

        components: {
            impactCommunities: 'Top Impacted Communities',
            fleetImpact: {
                title: 'Showing data',
                vehiclesTitle: ' for {count} vehicles',
                communitiesTitle: ' in {count} communities',
                dateTitle: ' between {start} and {end}'
            },
            replacementTable: {
                tooltips: {
                    dacCo2: 'Emissions for this vehicle \nover selected range in DACs',
                    totalCo2: 'Emissions for this vehicle over \nselected range everywhere \n(DACs nad non DACs)'
                }
            },
            swappingTable: {
                tooltips: {
                    swapCycle: `E.G.: Suppose the recommendation has three vehicles switching places, A->B, B->C, C->A. \nThen calculate the total change in emissions from these three swaps. \nThis value is reported in this column, and will be the same value for all vehicles in the cycle (in this example, vehicles A, B, and C)`,
                    changeEmissions: `Change in emission if Replacement Vehicle \nhad driven Current Vehicles route as compared to Current Vehicles emissions`,
                    currentEmissions: `Emissions of current vehicles \nover selected range in DAC`,
                    replacementVehicle: `Vehicle that should replace the Current Vehicle. \n Replacement Vehicle would drive on the same \nroute as the Current Vehicle does now.`
                },
                defaultDate: 'between Nov 2020 and Sep 2021'
            },
            cityWorkTable: {
                title: 'City Work',
                description: 'This table lists city work. Select the city work you wish to view in the table.',
                tableTitle: 'City Work',
            },
            overlapsTable: {
                title: 'Planned Excavation',
                description: 'This table shows overlaps between SoCalGas work and city work. Select the city work you wish to see in the overlaps and view in the table.',
                tableTitle: 'Planned Excavation',
            },
            leakPrioritizationTable: {
                title: 'Prioritization',
                description: 'This table lists leaks based on how they should be prioritized. Export the table to view the full list of prioritization factors.',
                tableTitle: 'Leak Prioritization',
            },
            valueMetricsTable: {
                title: 'Value Metrics',
                description: 'These value metric tables show notable metrics the tool is tracking. To learn more about the metrics, download the User Guide and navigate to the Value Metrics section.',
                tableTitle: 'Tool Usage',
                footNote: 'Note: The tables are not affected by Filters. All available data is shown.'
            },
            fleetImpactTable: {
                title: 'Showing Data',
                description: 'The Fleet Impact table shows CO2 emissions based on the filters that have been selected.',
                tableTitle: 'Fleet Impact',
                footNote: 'Note: The tables are not affected by Filters. All available data is shown.'
            },
            vehicleReplacementTable: {
                title: 'Vehicle Replacement',
                description: 'The Vehicle Swap table shows CO2 emission reductions as a result from replacing vehicles.',
                tableTitle: 'Vehicle Replacement',
                footNote: 'Note: The tables are not affected by Filters. All available data is shown.'
            }
        },

        languages: {
            'en-US': 'EN'
        }
    }
};
const strings = new LocalizedStrings(lang);
export default strings;
