import { useState } from 'react';
import styled from 'styled-components';
import axios from "axios";
import { Tooltip } from "../tooltip";
import FileDownloadIcon from '../../style/iconography/FileDownloadIcon'
import { theme } from '../../theme/theme';

const UserGuideButtonWrapper = styled.div`
    display: flex;
    width: 80px;
    height: 80px;
    justify-content: center;
    align-items: center;
`

const HiddenUserGuideButton = styled.button`
    height: 80px;
    width: 100%;
    position: absolute;
    background: transparent;
    border: none;
    cursor: pointer;
`

const UserGuideButton = ({url, isVisible, title}) => {
    const [userGuidePressed, setUserGuidePressed] = useState(false);
    const [userGuideHovered, setUserGuideHovered] = useState(false);
    
    const handleMouseDown = () => {
        setUserGuidePressed(true);
    }

    const handleMouseUp = () => {
        setUserGuidePressed(false)
    }

    const handleUserGuideDownload = async () => {
        const response = await axios.get(url)
        window.open(response.data, "" , "noopener");
    }

    if (!isVisible) return <></>

    return (
    <Tooltip 
        text={title}
        placement="right"
        textColor={theme.color.white}
        tooltipColor={theme.color.richBlack}
    >
        <UserGuideButtonWrapper >
                {userGuidePressed 
                    ? <FileDownloadIcon color="#DF134C" filled/>
                    : userGuideHovered 
                        ? <FileDownloadIcon color="#DF134C" />
                        : <FileDownloadIcon color="#FFF"/>
                }
                <HiddenUserGuideButton
                    onClick={handleUserGuideDownload}
                    onMouseDown={handleMouseDown}
                    onMouseUp={handleMouseUp}
                    onMouseOver={() => setUserGuideHovered(true)}
                    onMouseLeave={() => setUserGuideHovered(false)}
                ></HiddenUserGuideButton>
        </UserGuideButtonWrapper>
    </Tooltip>
    )
}

export default UserGuideButton;