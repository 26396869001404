import { CalEnviroLegendSection, HHSEmpowerLegendSection, HydrogenFuelStationsLegendSection, IHSSLegendSection, IncomeLegendSection, RacialEquityLegendSection, Justice40LegendSection } from "@innovation-toolkit/mapbox"
import { TribalLandsLegendSection } from "@innovation-toolkit/mapbox"
import { HospitalsLegendSection } from "@innovation-toolkit/mapbox"
import { Legend } from "@innovation-toolkit/mapbox"
import { DelimiterLine } from "@innovation-toolkit/ui"
import { DOTLegendSection } from "./layer/DOTLayer"



export const SelfServiceLegendSection = () => {
    return (
        <Legend width='280px'>
            <div
                style={{
                    background: '#0E263B',
                    height: '125px',
                    padding: '16px',
                    paddingTop: '12px'
                }}
            >
                <h1>Map Legend</h1>
                <DelimiterLine/>
                <p className="body-primary-text">This map shows foundational datasets to compare with your custom dataset.</p>
            </div>
            <div style={{
                padding: '16px',
                maxHeight: '60vh',
                overflowY: 'scroll'
            }}>
                <h2>Layers</h2>

                <h3>Areas of Interest</h3>
                <CalEnviroLegendSection />
                <Justice40LegendSection />
                <DOTLegendSection/>
                <TribalLandsLegendSection small/>

                <h3>Electricity Dependent</h3>
                <IHSSLegendSection small/>
                <HHSEmpowerLegendSection small/>

                <h3>Fuel Stations</h3>
                <HydrogenFuelStationsLegendSection small/>
                
                <h3>Institutions</h3>
                <HospitalsLegendSection small/>

                <h3>Population</h3>
                <RacialEquityLegendSection small/>
                <IncomeLegendSection small/>

            </div>
        </Legend>
    )
}