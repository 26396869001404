import { SET_ACTIVE_APP, SET_ACTIVE_MENU_TAB, SET_ACTIVE_ENV } from './actionTypes';

const initialState = {
    list: [],
    activeApp: '',
    activeMenuTab: '',
    activeEnv: '',
};
const apps = (state = initialState, { type, payload }) => {
    switch (type) {
        case SET_ACTIVE_APP:
            return { ...state, activeApp: payload };
        case SET_ACTIVE_MENU_TAB:
            return { ...state, activeMenuTab: payload };
        case SET_ACTIVE_ENV:
            return { ...state, activeEnv: payload }
        default:
            return state;
    }
};
export default apps;