import React, { useRef } from 'react'
import { Button, DataTable, LargeTable, openNotification } from "@innovation-toolkit/ui"
import { useDispatch, useSelector } from "react-redux"
import { 
    setCityWorkAggregationMapData,
    setRowUpdateInfo,
    setShowCityWorkAggregationTable,
    selectCityWorkAggregationMapData,
    selectDrawnGeometries,
    selectRowUpdateInfo,
    selectShowCityWorkAggregationTable
} from "redux/cityWorkAggregation/slice"
import _ from 'lodash'
import { useEffect, useState } from "react"
import { setMapPosition } from "@innovation-toolkit/mapbox"

export const error_address_message = "Could not calculate address. Upon submission one will be automatically assigned."

const formatMapData = (geojsonData) => {
    let formattedData = [];
    const features = _.cloneDeep(geojsonData.features)

    for (let i = 0; i < features.length; i++) {
        let property = features[i].properties;
        let geometry = features[i].geometry;
        formattedData[i] = features[i].properties;
        formattedData[i].latlng = [ geometry.coordinates.flat(4)[1], geometry.coordinates.flat(4)[0] ]
        formattedData[i].index = i;

        if (!formattedData[i].has_error) {
            formattedData[i].error_msg = '-'
        } else {
            formattedData[i].isError = true
            formattedData[i].latlng = [ 33.7394, -118.2891]
        }
    }

    formattedData = formattedData.sort((a, b) => b.has_error - a.has_error)
    
    return formattedData;
}

export const CityWorkTable = () => {
   

    const showTable = useSelector(selectShowCityWorkAggregationTable)
    const dispatch = useDispatch();
    const mapData = useSelector(selectCityWorkAggregationMapData)

    const [tableData, setTableData] = useState([])

    const rowUpdateInfo = useSelector(selectRowUpdateInfo);
    const rowUpdateInfoRef = useRef()
    const drawnGeometries = useSelector(selectDrawnGeometries);
    const drawnGeometriesRef = useRef()

    useEffect(() => {   
        drawnGeometriesRef.current = drawnGeometries
    }, [drawnGeometries])
    
    useEffect(() => {
        rowUpdateInfoRef.current = rowUpdateInfo
    }, [rowUpdateInfo])

    // FORMAT DATA
    useEffect(() => {
        if (!mapData) return;

        let formattedTableData = [...formatMapData(mapData)]

        setTableData(formattedTableData)
    }, [mapData, rowUpdateInfo])


    const handleMapLocationButtonClick = (row) => {
        dispatch(setMapPosition({longitude: row.latlng[1], latitude: row.latlng[0], zoom: 16}))
    }

    const handleClose = () => {
        dispatch(setShowCityWorkAggregationTable(false))
    }

    const handleEditButtonClick = (row, index) => {
        dispatch(setRowUpdateInfo({
            index: index,
            isUpdating: true,
            currentFeature: mapData.features[row.index],
            isUpdatingError: row.isError
        }))
    }

    const handleSaveButtonClick = (row) => {
        const newMapData = JSON.parse(JSON.stringify(mapData))

        // Update geometry of selected value
        if (drawnGeometriesRef.current.length === 0) {
            openNotification({
                type: 'warning',
                notificationMessage: 'No new geometry entered. Old geometry will be kept',
                placement: 'top-right'
            })

            dispatch(setRowUpdateInfo({
                index: -1,
                isUpdating: false,
                currentFeature: null
            }))
            return
        } 


        // Correcting an error
        if (row.isError) {
            newMapData.features[row.index].properties =  {...row, error_msg: '-', isError: false, has_error: false}
        }

        newMapData.features[row.index].geometry = drawnGeometriesRef.current.features[0].geometry
        
        dispatch(setCityWorkAggregationMapData(newMapData))

        openNotification({
            notificationMessage: 'The project has been updated with the new geometry.',
            placement: 'top-right',
            type: 'success',
            header: 'Project Updated'
        })

        dispatch(setRowUpdateInfo({
            index: -1,
            isUpdating: false,
            currentFeature: null
        }))

        drawnGeometriesRef.current.length = 0

    }

    return <DataTable
        open={showTable}
        handleClose={handleClose}
        tabs={[
            {
                title: 'Review & Edit',
                contentSections: [
                    {
                        titleWidth: "100%",
                        titlePadding: "0",
                        content: (
                            tableData.length > 0 ?
                            <LargeTable
                                data={tableData} 
                                columns={
                                    [
                                    {
                                        title: "Geometry",
                                        key: 'edit-row',
                                        dataIndex: '',
                                        width: 120,
                                        render: (text, record, index) => {
                                            return (
                                                // If no row is being updated then show all as edit
                                                (!rowUpdateInfo.isUpdating) ?
                                                <Button dataTestId={'styled-button_' + index} title="Edit" onClick={() => handleEditButtonClick(record, index)} variant="secondary" size="s" style={{margin: 'auto'}}/>:
                                                    
                                                    // If a row is being updated show that row as save and the others as disabled
                                                    (rowUpdateInfo.index === index ) ?
                                                    <Button title="Save" dataTestId={'save-button_' + index} onClick={() => handleSaveButtonClick(record, index)} variant="secondary" size="s" style={{margin: 'auto'}}/> :
                                                    <Button disabled title="Edit" dataTestId={'edit-button_' + index} onClick={() => handleEditButtonClick(record, index)} variant="secondary" size="s" style={{margin: 'auto'}}/>
                                            )
                                        }
                                    },
                                    {
                                        title: 'Project Number',
                                        key: 'Project_Nbr',
                                        dataIndex: 'Project_Nbr',
                                        width: 200,
                                        mapLocationButton: true
                                    },
                                    {
                                        title: 'Source Sheet',
                                        key: 'Input_Sheet',
                                        dataIndex: 'Input_Sheet',
                                        width: 300
                                    },
                                    {
                                        title: 'Error',
                                        key: 'error_msg',
                                        dataIndex: 'error_msg',
                                        width: 300
                                    },
                                    {
                                        title: 'Work Type',
                                        key: 'Work_Type',
                                        dataIndex: 'Work_Type',
                                        width: 300
                                    },
                                    {
                                        title: 'Municipality',
                                        key: 'Municipality_Name',
                                        dataIndex: 'Municipality_Name',
                                        width: 300
                                    },
                                    {
                                        title: 'Address',
                                        key: 'address',
                                        dataIndex: 'address',
                                        width: 500
                                    }
                                ]
                            }
                                onMapLocationButtonClick={handleMapLocationButtonClick}
                                expandable={false}
                            />   : <></>
                        )
                    }
                ]
            }
        ]}
    />
}