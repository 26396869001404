import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import axios from 'axios'

import { setMenuSelectedKey } from '@redux/menu/actions';
import { selectSelectedKey } from '@redux/menu/selectors';

import { Typography, LayerToggleSection, Menu, MainMenu, MenuTabContents, LayerTabWrapper, TitleDivider, color } from '@innovation-toolkit/ui';
import { CalEnviroLayerToggle, ExternalChargerSymbolToggle, HydrogenFuelStationsLayerToggle } from '@innovation-toolkit/mapbox'

import { fleetDecarbonizationActions } from 'component/sidebar/menuItems';
import MenuTabButtons from 'component/sidebar/tabs/MenuTabButtons';
import { FleetDecarbonizationLayerToggle } from './layers/FleetDecarbonizationLayer';
import { FleetDecarbAnalysis } from './analysis';
import { APIGateways } from 'config/config';
import { setVehicleFilterFleet } from 'redux/sidebar/actions';
import { selectSearchDates } from 'redux/searchItems/selectors';

const fetchFleetVehicles = (callback, searchDates) => {
    const url = `${APIGateways.legacy}/scg_fleet/fleet_filter_vehicle_list`;

    const {start_date, end_date} = searchDates

    const filter = {
        "start_date": start_date,
        "end_date": end_date
    }
  
    axios.post(url, filter)
        .then((response) => {
            // Returns as a presigned url, fetch the data here
            return fetch(response.data)
                .then((fetchResponse) => fetchResponse.json())
                .then((data) => {
                    callback(data, false)
                })
        })
        .catch((err) => {
            callback(null, true)
        })
  }

export const FleetDecarbonizationMenu = () => {
    const dispatch = useDispatch()
    const activeMenuItem = useSelector(selectSelectedKey);

    const [currentItem, setCurrentItem] = useState(null);
    const [selectKey, setSelectKey] = useState(undefined);
    const searchDates = useSelector(selectSearchDates)

    const closeTabs = () => {
        dispatch(setMenuSelectedKey(undefined))
        setSelectKey(undefined)
        setCurrentItem(null)
    }

    useEffect(() => {
        fetchFleetVehicles((data, isError) => {
          if (!isError) {
            dispatch(setVehicleFilterFleet(data))
          } 
        }, searchDates)
      }, [searchDates])

    if (currentItem) return (
        <MainMenu background-color={color.prussianBlue} style={{width: '256px'}}>
            {currentItem.component(closeTabs)}
        </MainMenu>
    )
    return <Menu
        tabs={[
            {
                title: "Filters",
                content: (
                    <MenuTabContents
                        key="fleet-decarbonization"
                        menuItems={fleetDecarbonizationActions}
                        selectedMenu={activeMenuItem}
                        onSetSelectedMenu={(value) => dispatch(setMenuSelectedKey(value))}
                        currentItem={currentItem}
                        setCurrentItem={setCurrentItem}
                        selectKey={selectKey}
                        setSelectKey={setSelectKey}
                    >
                        <MenuTabButtons />
                    </MenuTabContents>
                )
            },
            {
                title: "Layers",
                content:
                    <LayerTabWrapper style={{paddingLeft: '16px'}}>
                        <Typography text='Internal Data' variant='Heading 2' style={{marginTop: '16px'}}/>
                        <TitleDivider style={{width: '224px', marginBottom: '16px'}}/>
                        <LayerToggleSection
                            title='Fleet Decarbonization'
                            toggles={[
                                <div style={{paddingTop: '7px'}}></div>,
                                <FleetDecarbonizationLayerToggle />
                            ]}
                        />
                        <Typography style={{marginTop: '32px'}} text='External Data' variant='Heading 2' />
                        <TitleDivider style={{width: '224px', marginBottom: '16px'}}/>
                        <LayerToggleSection
                            title="Areas of Interest"
                            toggles={[
                                <div style={{paddingTop: '7px'}}></div>,
                                <CalEnviroLayerToggle title='DAC 4.0 State' toggleChildrenTitle='DAC 4.0 State' showChildren/>,
                            ]}
                        />
                        <LayerToggleSection
                            title="Fuel & Charging Stations"
                            toggles={[
                                <div style={{paddingTop: '7px'}}></div>,
                                <ExternalChargerSymbolToggle showChildren />,
                                <HydrogenFuelStationsLayerToggle showChildren />
                            ]}
                        />
                    </LayerTabWrapper>
            },
            {
                title: "Analysis",
                content: <FleetDecarbAnalysis/>
            }
        ]}
    />
}