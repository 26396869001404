import styled from "styled-components";
import { Select } from "antd"
import moment from 'moment';
import dayjs from 'dayjs';

import { Input, Button, DateTimePicker } from "@innovation-toolkit/ui"
import { useSelector } from "react-redux";
import { selectRowUpdateInfo } from "redux/streetImprovements/selector";
import { APP_ENV } from "@config/config";
import { RightOutlined } from "@ant-design/icons";

const { Option } = Select;

const StyledRow = styled.div`
  width: 100%;
  display: flex;
  align-items: center; 
  gap: 8px; 
  min-height: 32px;
  margin-bottom: 8px;
  align-items: flex-start;

  h4 {
    min-width: calc(50% - 8px);
    max-width: calc(50% - 8px);
  }

  p {
    word-wrap: break-word;
    max-width: 50%;
  }

  .ant-picker {
    width: 160px;
    height: 32px;
    background-color: ${({theme}) => theme.color.white50} !important;
    border: 1px solid white !important;
    border-radius: 5px;
    max-width: 50%;
  }
  
  .ant-picker-input {
    background-color: transparent;

  }

  .input-wrapper {
    width: 160px;
    max-width: 50%;
    height: 32px;
    background-color: ${({theme}) => theme.color.white20} !important;
  }

  .buttonSvg {
    height: 14px;
    width: 14px;
    padding: 0;
    svg {
      height: 14px;
      width: 14px;
      color: white;
    }
  }
`

const StyledSelect = styled(Select)`
  width: 160px;
  max-width: 50%;
  height: 32px;
  color: white;

  .ant-select-selector {
    background-color: ${({theme}) => theme.color.white50} !important;
    border-radius: 5px !important;
  }

  .ant-select-arrow {
    color: white;
  }
`

export const toDateString = (date) => {
    if (!date) return '';

    return moment(date).format('YYYY-MM-DD');
}

export const EditFooter = ({editMode, setEditMode, isModified, isEditable, handleSave, handleCancel, handleDelete, footerStyle}) => {

  return <div style={{display: 'flex', gap: '8px', justifyContent: 'flex-end', width: '100%', paddingRight: '24px', ...footerStyle}}>
    {(editMode) ? 
      <>
        <Button 
          variant='secondary' 
          size='thin'
          title='Cancel' 
          onClick={handleCancel}
        /> 
        <Button 
          variant='primary' 
          size='thin'
          title='Save' 
          disabled={!isModified}
          onClick={handleSave}
        />
      </> : (
      <>
        <Button 
          variant='secondary' 
          size='thin'
          title='Delete'
          disabled={!isEditable}
          onClick={handleDelete}
        />
        <Button 
          variant='primary' 
          size='thin'
          title='Edit'
          disabled={!isEditable}
          onClick={() => setEditMode((isEditing) => !isEditing)}
        />
      </>)
      }
  </div>
}

const EditableRow = ({children, editMode, title, rowData}) => {
  return (
    <StyledRow>
      <h4 className="h4">{title}</h4>
      {editMode
        ? children
        : <p>{rowData}</p>
      }
    </StyledRow>
  )
}

const ActionRow = ({actionName, children}) => {
  return (
    <StyledRow>
      <h4 className="h4">{actionName}</h4>
      {children}
    </StyledRow>
  )
}

const DataRow = ({type = 'constant', rowKey, rowData, title, editMode, setData, rowId, options, setIsModified, setModifiedData}) => {

  const handleChange = (val) => {
    let isBoolean = type === 'boolean';

    setModifiedData(formData => {
      let modified = {...formData}
      modified.properties = {...modified.properties, [rowKey]: isBoolean ? val === 'true' : val};
      return modified
    })
    setData(formData => {
      let modified = {...formData}
      modified[rowId] = val
      return modified
    })
    setIsModified(true)
  }
  if (rowData === undefined) {
    return null;
  }
  if (type === 'text') {
    return (
      <EditableRow editMode={editMode} title={title} rowData={rowData}>
        <Input
          type='text'
          placeholder=''
          allowClear={true}
          value={rowData}
          onChange={(e) => handleChange(e.target.value)}
        />
      </EditableRow>
    ) 
  }
  if (type === 'select') {
    return (
      <EditableRow editMode={editMode} title={title} rowData={rowData}>
        <StyledSelect
          value={rowData}
          onChange={handleChange}
          getPopupContainer={trigger => trigger.parentNode}
          >
            {options.map((item, index) => (
              <Option key={index} value={item}>
                {item}
              </Option>
            ))}
          </StyledSelect>
      </EditableRow>
    ) 
  }
  if (type === 'boolean') {
    return (
      <EditableRow editMode={editMode} title={title} rowData={rowData}>
        <StyledSelect
          value={rowData}
          onChange={handleChange}
          getPopupContainer={trigger => trigger.parentNode}
          >
              <Option key={0} value={'true'}>
                True
              </Option>
              <Option key={1} value={'false'}>
                False
              </Option>
          </StyledSelect>
      </EditableRow>
    ) 
  }
  if (type === 'date') {
    let dateString = toDateString(rowData);

    return (
        <EditableRow editMode={editMode} title={title} rowData={dateString}>
          <DateTimePicker value={dayjs(dateString, 'YYYY/MM/DD')} onChange={(date) => handleChange(toDateString(date.toString()))} timeFormat="YYYY-MM-DD" />
        </EditableRow>
    )
  }
  if (type === 'constant') {
    return (
      <StyledRow>
        <h4 className="h4">{title}</h4>
        <p>{rowData}</p>
      </StyledRow>
    ) 
  }
}


export const DataList = ({
  rawData, 
  data, 
  setData, 
  editMode, 
  dataMap, 
  setIsModified, 
  setModifiedData, 
  handleEditGeometries, 
  handleSaveGeometries, 
  handleCancelEditGeometries, 
  isEditable,
  handleShowEditHistory,
  editHistoryData,
  customGeometryEditButtons
}) => {
  const rowUpdateInfo = useSelector(selectRowUpdateInfo);
  const desiredOrder = [
    "District",
    "Municipality",
    "Representative Address",
    "Project Category",
    "Project Start Date",
    "Project End Date",
    "Project Start Date Is Estimated",
    "Project End Date Is Estimated",
    "Moratorium Start Date",
    "Moratorium End Date",
    "Moratorium Start Date Is Estimated",
    "Moratorium End Date Is Estimated",
    "Address",
    "Dataset Name",
    "Id",
    "Edit History",
    "Ingestion Source"
  ];

  // Reorder the JSON object if data is not null or undefined
  let reorderedData = null;
  if (data) {
    reorderedData = {};
    desiredOrder.forEach(key => {
      if (data[key] !== undefined) {
        reorderedData[key] = data[key];
      }
    });
    // Handle the special case for "Representative Address"
    if (data["Representative Address"]) {
      reorderedData["Supplemental Address"] = data["Representative Address"];
      delete reorderedData["Representative Address"];
    }
  }

  return(
    <>
      {
        ((rowUpdateInfo && !rowUpdateInfo.isUpdating) && reorderedData) && Object.entries(reorderedData).map(prop => {

          const elementData = dataMap.find(mapElement => mapElement.formatted_title === prop[0])

          if (elementData === undefined) return null;
          return (
            <DataRow
              setIsModified={setIsModified}
              options={elementData.options && elementData.options} 
              rowKey={elementData.key}
              setModifiedData={setModifiedData}
              rowId={prop[0]} 
              rowData={prop[1]} 
              setData={setData} 
              title={elementData.formatted_title} 
              editMode={editMode} 
              type={elementData.type}
            />
          )  
        })
      }
      
      {rowUpdateInfo && !rowUpdateInfo.isUpdating &&
        <ActionRow actionName="Edit History">
            <Button 
              variant='tertiary'
              svgIcon={<RightOutlined style={{height: '17px'}} />}
              title={`${editHistoryData ? editHistoryData.length : 0} edits`}
              disabled={editHistoryData ? (editHistoryData.length > 0 ? false : true) : true}
              onClick={handleShowEditHistory}
              style={{display: 'flex', flexDirection: 'row-reverse', alignItems: 'flex-start', width: '128px', justifyContent: 'space-between', border: 'none', fontFamily: 'InterstateLight', marginRight: '24px'}}
            />
        </ActionRow>
      }
          {(APP_ENV !== 'prd' && isEditable) &&
        <ActionRow actionName={ rowUpdateInfo.isUpdating && customGeometryEditButtons ? "" :"Project Geometry"}>
          {rowUpdateInfo && rowUpdateInfo.isUpdating ? 
            customGeometryEditButtons ? <></>
            :<>
            <Button 
              variant='secondary' 
              size='thin'
              title='Cancel' 
              onClick={handleCancelEditGeometries}
            />
            <Button 
              variant='primary' 
              size='thin'
              title='Save Geometry' 
              onClick={handleSaveGeometries}
            />
          </>
          :
            <Button 
              variant='primary' 
              size='thin'
              title='Edit Geometry'
              disabled={!isEditable}
              onClick={handleEditGeometries}
            />
          }
        </ActionRow>
      }
    </>
  ) 
}
