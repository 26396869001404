import moment from 'moment';

// This is a placeholder for how we will decide to weight the color the heatmap.
export const getHeatmapColorInterpolation = () => {
    let valueBuckets = Array(6);

    for (let i = 0; i < valueBuckets.length; i++) {
        valueBuckets[i] = i;
    }

    const colorInterpolation = [
        valueBuckets[0],
        0,
        valueBuckets[1],
        0.1,
        valueBuckets[2],
        0.3,
        valueBuckets[3],
        0.5,
        valueBuckets[4],
        0.7,
        valueBuckets[5],
        1,
    ];

    return colorInterpolation;
};

export const heatmapColors = [
    0,
    'rgba(0, 0, 255, 0)',
    0.01,
    'rgba(0, 0, 255, 0.2)',
    0.1,
    'royalblue',
    0.3,
    'cyan',
    0.5,
    'lime',
    0.7,
    'hsl(60, 100%, 50%)',
    0.9,
    'hsl(36, 98%, 55%)',
    1,
    'red',
];

export const METHANE_HEATMAP_DATA_FIELDS = {
    Index: 'Index',
    region: 'region',
    district: 'district',
    equipment_num: 'equipment_num',
    address: 'address',
    city: 'city',
    detect_date: 'detect_date',
    leak_code: 'leak_code',
    leak_at: 'leak_at',
    leak_object_system_status: 'leak_object_system_status',
    latitude: 'latitude',
    longitude: 'longitude',
    full_address: 'full_address',
    address_geocoder: 'address_geocoder',
    lon_geocoder: 'lon_geocoder',
    lat_geocoder: 'lat_geocoder',
    tract: 'tract',
}

export const setHeatmapIntensity = (map, searchDates) => {
    let dateIntensityModifier = 1;
    let intensityModifier = 1;

    // This came from trial and error.
    if (searchDates && searchDates.start && searchDates.end) {
        let startDate = moment(searchDates.start);
        let endDate = moment(searchDates.end);
        let numDaysSelected = moment.duration(endDate.diff(startDate)).asDays()
        if (numDaysSelected <= 31) {
            dateIntensityModifier = -0.75 * numDaysSelected + 30
        } else if (numDaysSelected <= 200) {
            dateIntensityModifier = 5;
        }

        intensityModifier = 0.5 * intensityModifier + 0.5 * dateIntensityModifier;
    }

    if (intensityModifier < 1) {
        intensityModifier = 1;
    }

    const heatmapIntensity = [
        'interpolate',
        ['linear'],
        ['zoom'],
        0, (0.1 * intensityModifier),// zoom, value
        7, (0.1 * intensityModifier),
        8, (0.05 * intensityModifier),
        9, (0.07 * intensityModifier),
        10, (0.03 * intensityModifier),
        11, (0.03 * intensityModifier),
        12, (0.025 * intensityModifier),
        13, (0.01 * intensityModifier),
        14, (0.015 * intensityModifier),
        15, (0.01 * intensityModifier)
    ]

    map.setPaintProperty('methane-heatmap', 'heatmap-intensity', heatmapIntensity);
}