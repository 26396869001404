import { Layer, Source } from "react-map-gl"
import { APIGateways } from "@config/config";
import { useEffect, useState } from "react";
import { addToMapLoadingList, removeFromMapLoadingList, selectIsLayerActive, useActivateMapLayer, LegendItem, selectBeforeIDs, useLoadMapImage } from "@innovation-toolkit/mapbox";
import { useSelector } from "react-redux";
import axios from "axios";
import { useDispatch } from "react-redux";
import envirostar_pin from './EnvirostarLayer.png';


export const ENVIROSTAR_LAYER = 'ENVIROSTAR_LAYER';
export const ENVIROSTAR_SOURCE = 'ENVIROSTAR_SOURCE';

export const EnvirostarLayerMetadata = {
    layerId: ENVIROSTAR_LAYER,
    name: 'Envirostar',
    color: '#D11807',
    layerSymbols: [{symbol: envirostar_pin, symbolName: 'envirostar_pin'}]
}

const fetchAsbestosData = (callBack, filterOptions) => {

    const url = `${APIGateways.scgcore}/data/envirostar`;

    const filter = {}

    axios.get(url, filter)
        .then((response) => {
            // Returns as a presigned url, fetch the data here
            return fetch(response.data)
                .then((fetchResponse) => fetchResponse.json())
                .then((data) => {
                    callBack(data, false)
                })
        })
        .catch((err) => {
            callBack(null, true)
        })
}

export const EnvirostarLayer = ({showLayer=false}) => {

    const dispatch = useDispatch()

    const [showEnvirostarLayer] = useActivateMapLayer(EnvirostarLayerMetadata, showLayer)
    const beforeIDs = useSelector(selectBeforeIDs)

    const [data, setData] = useState(null);

    useLoadMapImage('envirostar_pin', envirostar_pin)

    useEffect(() => {
        // dispatch(addToMapLoadingList('Envirostar'))
        fetchAsbestosData((data, isError) => {
            if (!isError) {
                setData(data)
            } 
            // dispatch(removeFromMapLoadingList('Envirostar'))
        })
    }, []) // Add searchDates to filter on search

    const sourceConfig = {
        id: ENVIROSTAR_SOURCE,
        type: "geojson",
        data: data,
        cluster: true,
        clusterMaxZoom: 12,
        clusterRadius: 15,
    }

    // const circleConfig = {
    //     id: ENVIROSTAR_LAYER,
    //     type: "circle",
    //     source: ENVIROSTAR_SOURCE,
    //     layout: {
    //         'visibility': showEnvirostarLayer ? 'visible' : 'none'
    //     },
    //     paint: {
    //         'circle-color': EnvirostarLayerMetadata.color,
    //         'circle-stroke-color': '#ffffff',
    //         'circle-stroke-width': 1
    //     },
    //     beforeId: beforeId.circle
    // }

    const symbolConfig = {
        id: ENVIROSTAR_LAYER,
        type: 'symbol',
        source: ENVIROSTAR_SOURCE,
        layout: {
            visibility: showEnvirostarLayer ? 'visible' : 'none',
            'icon-image': 'envirostar_pin',
            'icon-size': 0.4,
            'icon-allow-overlap': true,
        },
        paint: {
            'icon-translate': [0,-27],
        },
        beforeId: beforeIDs.circle
    };

    return (
        <Source {...sourceConfig}>
            <Layer {...symbolConfig} />
        </Source>
    )
}

EnvirostarLayer.layerName = 'Envirostar';
EnvirostarLayer.legendColor = '#FFFFFF';

export const EnvirostarLegendSection = () => {
    const showLegend = useSelector((state) => selectIsLayerActive(state, EnvirostarLayerMetadata.layerId))

    if (!showLegend) {
        return <></>
    }

    return (
        <>
            <LegendItem color={EnvirostarLayerMetadata.color} title={EnvirostarLayerMetadata.name} small={true}/>
        </>
    )
}