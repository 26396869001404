import { useDispatch, useSelector } from "react-redux"
import { SensitiveSitesLayerMetadata } from "."
import { addActiveLayer, removeActiveLayer, selectIsLayerActive } from "@innovation-toolkit/mapbox"
import { DropDown, LayerToggle, LayerToggleIcon, LayerToggleIconChild, SmallDropDown } from '@innovation-toolkit/ui';
import SensitiveSites from "assets/icons/sensitive-sites.svg?react";
import { useEffect, useState } from "react";
import { selectSelectedBird, setSelectedBird } from "redux/angelesLinks";

export const SensitiveSitesLayerToggle = () => {
    const LAYER_ID = SensitiveSitesLayerMetadata.layerId
    const dispatch = useDispatch()
    const shouldShowLayer = useSelector((state) => selectIsLayerActive(state, LAYER_ID))

    const handleClick = (shouldShow) => {
        if (!shouldShow) {
            dispatch(removeActiveLayer(LAYER_ID))
        } else {
            dispatch(addActiveLayer(LAYER_ID))
        }
    }


    return (
        <LayerToggle
            title='Sensitive Sites'
            componentIcon={SensitiveSites}
            onChange={handleClick}
            currentState={shouldShowLayer}
            children={<><LayerToggleIconChild 
                Icon={<LayerToggleIcon 
                    type="point" 
                    fillColor={SensitiveSitesLayerMetadata.color} 
                    outlineColor="#ffffff" 
                    strokeColor="white"
                />} 
                title='Sensitive Sites'
            />
            </>}
        />
    )
}