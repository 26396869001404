import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Select } from 'antd';
import BarChart from './BarChart';
import {SmallDropDown} from '@innovation-toolkit/ui';

/* BAR CHART WITH SELECT
    - Based off of the column component from antd (https://charts.ant.design/en/examples/column/basic#basic)
    - Has a select field that allows the data being used for the Y axis to be changed
    
    - Properties:
        -> data:            Array of objects that contain the keys specified in
                            the xField and yField properties.
        -> xField:          Key to use for plotting data on the X axis
        -> yField:          Key to use for plotting data on the Y axis (also the
                            default value in the select field). Will default to 
                            the first value in yOptions if not provided.
        -> yOptions:        Array of strings that will populate the select field.
                            These should directly match keys on the objects
                            passed through the "data" property.
        -> onSelectChange:  Callback function
*/

const { Option } = Select;

const BarChartWrapper = styled.div`
    position: relative;
    width: calc(100% - 100px);
    height: 100%;
`
const SelectWrapper = styled.div`
    position: absolute;
    top: 8px;
    right: 0;
`

const BarChartWithSelect = ({
    data,
    xField,
    yField = null,
    yOptions,
    onHover = () => {},
    onSelectChange = () => {},
    ...rest
}) => {
    const [selectedOption, setSelectedOption] = useState(!!yField ? yField : yOptions[0]);
    
    const handleChange = (value) => {
        setSelectedOption(value);
        onSelectChange(value);
    }

    useEffect(() => {
        setSelectedOption(yField);
    }, [yField])

    return (
        <BarChartWrapper>
            <BarChart 
                data={data}
                xField={xField}
                yField={selectedOption}
                onHover={onHover}
                {...rest}
            />
            <SelectWrapper>
                <SmallDropDown 
                    selectedOption={selectedOption}
                    onSelectChange={handleChange}
                    type='normal'
                    options={yOptions}
                />
            </SelectWrapper>
        </BarChartWrapper>
    )
}

export default BarChartWithSelect;