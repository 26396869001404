import { useEffect, useState } from 'react';
import { Button, DropDown, FilterActionButtons, HeaderContent, SearchBar, Typography } from "@innovation-toolkit/ui"
import { FilterWrapper } from "../../../../../../shared"
import './FleetVehicleFilter.css'
import { useDispatch, useSelector } from "react-redux"
import { selectSearchFleetVehicles } from "redux/searchItems/selectors"
import { selectVehicles } from "redux/sidebar/selectors"
import { Checkbox } from 'antd';
import { VariableSizeList } from 'react-window';
import { setSearchFleet } from 'redux/searchItems/actions';

export const CheckboxOption = ({label, onChange, checked}) => {
    return (
        <div className="fleet-filter-checkbox-option">
            <Checkbox className='fleet-filter-checkbox' checked={checked} onChange={onChange} data-testid={`fleet-filter-checkbox`}/>
            <span>{label}</span>
        </div>
    )
}

export const formatNumber = (num) => {
    return Math.round(num).toLocaleString()
}

export const sortVehicleResults = (vehicles, selectedUnitNumbers) => {
    const newFilteredValues = vehicles.sort((vehicle_1, vehicle_2) => {
        const v1_checked = selectedUnitNumbers.includes(vehicle_1.unit_no)
        const v2_checked = selectedUnitNumbers.includes(vehicle_2.unit_no)

        if (v1_checked && !v2_checked) {
            return -1
        } else if (v2_checked && !v1_checked) {
            return 1
        } else {
            return vehicle_2.total_co2_pounds - vehicle_1.total_co2_pounds
        }
    })

    return newFilteredValues
}

export const FleetVehicleFilter = ({closeTabs}) => {

    const dispatch = useDispatch()

    // Selection stored in redux state
    const reduxSelectedVehicleUnitNumbers = useSelector(selectSearchFleetVehicles);

    const fleetVehicles = useSelector(selectVehicles)
    const [filteredVehicles, setFilteredVehicles] = useState(fleetVehicles)
    const [selectedVehicleUnitNumbers, setSelectedVehicleUnitNumbers] = useState([])
    const [highestEmittingVehicle, setHighestEmittingVehicle] = useState('-')
    const [isConfirmDisabled, setIsConfirmDisabled] = useState(true)

    // If unit number selection in redux state is different from the current selection
    // enable the confirm button
    useEffect(() => {
        let reduxSelection = reduxSelectedVehicleUnitNumbers ?? []
        
        const reduxAndCurrentSelectionAreTheSame = (reduxSelection.length === selectedVehicleUnitNumbers.length) 
                                                && (new Set([...reduxSelection, ...selectedVehicleUnitNumbers]).size === reduxSelection.length)

        if (reduxAndCurrentSelectionAreTheSame) {
            setIsConfirmDisabled(true)
        } else {
            setIsConfirmDisabled(false)
        }

    }, [reduxSelectedVehicleUnitNumbers, selectedVehicleUnitNumbers])

    // If the redux selection has changed and is different from the current
    // selection then set the current selection to the selection in the 
    // redux state
    useEffect(() => {
        if (reduxSelectedVehicleUnitNumbers) {
            setSelectedVehicleUnitNumbers(reduxSelectedVehicleUnitNumbers)
        }
    }, [reduxSelectedVehicleUnitNumbers])

    const [dropdownOptions, setDropdownOptions] = useState({
        "Using Department": [],
        "Major Group": [],
        "Fuel Type": [],
        "Parking Location": []
    }) 

    const [filterSelection, setFilterSelection] = useState({
        "Using Department": ' ',
        "Major Group": ' ',
        "Fuel Type": ' ',
        "Parking Location": ' ',
        "Search Bar": null
    })

    const handleSearchChange = (dropdownId, value) => {
        setFilterSelection({
            ...filterSelection,
            [dropdownId]: value
        })
    }

    const handleClearFilters = () => {
        setFilterSelection({
            "Using Department": ' ',
            "Major Group": ' ',
            "Fuel Type": ' ',
            "Parking Location": ' ',
            "Search Bar": null
        })
    }

    const handleSelectAll = () => {
        const newSelectedUnitNumbers = new Set([...selectedVehicleUnitNumbers, ...filteredVehicles.map(element => element.unit_no) ])

        setSelectedVehicleUnitNumbers([...newSelectedUnitNumbers])
    }

    useEffect(() => {
        let newFilteredValues = []
        const newDropdownOptions = {
            "Using Department": new Set(' '),
            "Major Group": new Set(' '),
            "Fuel Type": new Set(' '),
            "Parking Location": new Set(' ')
        }

        let currentVehicle;

        // Check value against each selection in dropdown.
        // Treat each input as an AND filter
        for (let i = 0; i < fleetVehicles.length; i++) {
            currentVehicle = fleetVehicles[i];

            // First check if there is a selection
            if (filterSelection["Using Department"] !== ' ') {
                // If the current vehicle does not match this using department remove this vehicle
                if (currentVehicle.using_dept_no_desc !== filterSelection["Using Department"]) {
                    continue;
                }
            }

            // Repeat for other dropdowns
            if (filterSelection["Major Group"] !== ' ') 
                if (currentVehicle.major_group !== filterSelection["Major Group"]) 
                    continue;
            if (filterSelection["Fuel Type"] !== ' ') 
                if (currentVehicle.fuel_type !== filterSelection["Fuel Type"]) 
                    continue;
            if (filterSelection["Parking Location"] !== ' ') 
                if (currentVehicle.parking_loc_name !== filterSelection["Parking Location"]) 
                    continue;

            // Check if unit number matches the value in the search bar
            if (filterSelection["Search Bar"]) {
                if (!`${currentVehicle.unit_no}`.toLowerCase().includes(filterSelection["Search Bar"].toLowerCase()) ) {
                    continue;
                }
            }

            newFilteredValues.push(currentVehicle)
            newDropdownOptions["Using Department"].add(currentVehicle.using_dept_no_desc)
            newDropdownOptions["Major Group"     ].add(currentVehicle.major_group)
            newDropdownOptions["Fuel Type"       ].add(currentVehicle.fuel_type)
            newDropdownOptions["Parking Location"].add(currentVehicle.parking_loc_name)
        }

        newFilteredValues = sortVehicleResults(newFilteredValues, selectedVehicleUnitNumbers)

        setFilteredVehicles(newFilteredValues)
        setDropdownOptions({
            "Using Department": [...newDropdownOptions["Using Department"]],
            "Major Group":      [...newDropdownOptions["Major Group"]],
            "Fuel Type":        [...newDropdownOptions["Fuel Type"]],
            "Parking Location": [...newDropdownOptions["Parking Location"]]
        })

    }, [filterSelection])

    useEffect(() => {

        const newDropdownOptions = {
            "Using Department": new Set(' '),
            "Major Group": new Set(' '),
            "Fuel Type": new Set(' '),
            "Parking Location": new Set(' ')
        }

        let currentVehicle

        for (let i = 0; i < fleetVehicles.length; i++) {
            currentVehicle = fleetVehicles[i]

            // Get all unique options for each dropdown
            newDropdownOptions["Using Department"].add(currentVehicle.using_dept_no_desc)
            newDropdownOptions["Major Group"     ].add(currentVehicle.major_group)
            newDropdownOptions["Fuel Type"       ].add(currentVehicle.fuel_type)
            newDropdownOptions["Parking Location"].add(currentVehicle.parking_loc_name)
        }

        setDropdownOptions({
            "Using Department": [...newDropdownOptions["Using Department"]],
            "Major Group":      [...newDropdownOptions["Major Group"]],
            "Fuel Type":        [...newDropdownOptions["Fuel Type"]],
            "Parking Location": [...newDropdownOptions["Parking Location"]]
        })

        setFilteredVehicles(fleetVehicles)

        if (fleetVehicles[0]) {
            setHighestEmittingVehicle(`${fleetVehicles[0].unit_no} ( ${formatNumber(fleetVehicles[0].total_co2_pounds)} lbs CO2 )`)
        }

    }, [fleetVehicles])

    useEffect(() => {

        const newSortedResults = sortVehicleResults(filteredVehicles, selectedVehicleUnitNumbers)

        setFilteredVehicles([...newSortedResults])

    }, [selectedVehicleUnitNumbers])

    const CheckboxRow = ({index, style}) => {

        const handleCheckboxChange = (checked, unit_no) => {

            const newSelectedVehicleUnitNumbers = [...selectedVehicleUnitNumbers]

            // If unit_no should be added
            if (checked) {
                newSelectedVehicleUnitNumbers.push(unit_no)
            } else {
                const indexOfUnitNo = selectedVehicleUnitNumbers.indexOf(unit_no)

                newSelectedVehicleUnitNumbers.splice(indexOfUnitNo, 1)
            }

            setSelectedVehicleUnitNumbers(newSelectedVehicleUnitNumbers)

        }

        return <div style={style}>
            <CheckboxOption 
                label={`${filteredVehicles[index].unit_no} ( ${formatNumber(fleetVehicles[index].total_co2_pounds)} lbs CO2 )`}
                checked={selectedVehicleUnitNumbers.includes(filteredVehicles[index].unit_no)}
                onChange={(e) => handleCheckboxChange(e.target.checked, filteredVehicles[index].unit_no)}
            />
        </div>
    }

    return (
        <HeaderContent title="Select Vehicles" closeTabs={closeTabs}>
            <FilterWrapper>

                {/* Filter Selections */}
                <div id="fleet-vehicle-filter-selections">

                    <Typography 
                        style={{
                            margin: '0px 0px 10px 0px'
                        }}
                        variant="Subtitle" 
                        text='The vehicle filter is dynamic and updates subsequent options and resulting vehicles based on the selections made. To add a list of vehicles resulting from the dropdown options to the filter selection, select "Add Results to Selection." Select "Reset" to reset the vehicle group options in the dropdown. When all the desired vehicles have been added to the filter selection, select "Confirm Selection" to update the map and tables with data from only the selected vehicles.'
                    />

                    <Typography text="Using Department" variant="Body 2" className="select-title"/>
                    <DropDown
                        options={dropdownOptions["Using Department"]}
                        onChange={(value) => handleSearchChange("Using Department", value)}
                        defaultValue={filterSelection["Using Department"]}
                    />

                    <Typography text="Major Group" variant="Body 2" className="select-title"/>
                    <DropDown
                        options={dropdownOptions["Major Group"]}
                        onChange={(value) => handleSearchChange("Major Group", value)}
                        defaultValue={filterSelection["Major Group"]}
                    />

                    <Typography text="Fuel Type" variant="Body 2" className="select-title"/>
                    <DropDown
                        options={dropdownOptions["Fuel Type"]}
                        onChange={(value) => handleSearchChange("Fuel Type", value)}
                        defaultValue={filterSelection["Fuel Type"]}
                    />

                    <Typography text="Parking Location" variant="Body 2" className="select-title"/>
                    <DropDown
                        options={dropdownOptions["Parking Location"]}
                        onChange={(value) => handleSearchChange("Parking Location", value)}
                        defaultValue={filterSelection["Parking Location"]}
                    />

                    <p id="selection-separator"> - or - </p>

                    <SearchBar 
                        style={{width: '216px'}}
                        placeholder="Search vehicle by name"
                        onChange={(e) => handleSearchChange("Search Bar", e.target.value)}
                        value={filterSelection["Search Bar"]}
                    />
                    <Button variant="tertiary" title="Reset Selection" id="clear-all-filters-button" onClick={handleClearFilters}/>
                </div>

                {/* Vehicle Check List */}
                <div id="fleet-vehicle-filtered-list">

                    <Typography text={`${filteredVehicles.length} Vehicles Found`} variant="Body 2" style={{alignSelf: 'center', fontSize: '13px'}}/>
                    <Typography text={`${selectedVehicleUnitNumbers.length} Vehicles Selected`} variant="Body 2" style={{alignSelf: 'center', fontSize: '13px'}}/>

                    <Button title="Add results to selection" variant='tertiary' style={{ margin: 'auto', marginTop: '10px'}} onClick={handleSelectAll}/>

                    <div id="highest-emitting-vehicles">
                        <Typography text="Highest Emitting Vehicle" variant="Body 2" />
                        <Typography text={highestEmittingVehicle} variant="Body 2" style={{ fontWeight: 700}}/>
                    </div>

                    <div id="vehicle-checkbox-list">
                        <VariableSizeList
                            height={250}
                            width={500}
                            itemCount={filteredVehicles.length}
                            itemSize={() => 30}
                        >
                            {CheckboxRow}
                        </VariableSizeList>
                    </div>

                </div>

            </FilterWrapper>
            <div id="fleet-vehicle-filter-action-buttons">
                <FilterActionButtons
                    onConfirm={() => {
                        // Save selected unit numbers to state
                        closeTabs()
                        dispatch(setSearchFleet(selectedVehicleUnitNumbers.length > 0 ? selectedVehicleUnitNumbers : null))
                    }}
                    isConfirmDisabled={isConfirmDisabled}
                    onClear={() => {
                        setSelectedVehicleUnitNumbers([])
                    }}
                    isClearDisabled={selectedVehicleUnitNumbers.length === 0}
                />
            </div>
        </HeaderContent>
    )
}
