import { Auth } from 'aws-amplify';
import { AwsRum } from 'aws-rum-web';
import { fromCognitoIdentityPool } from "@aws-sdk/credential-providers";
import { CognitoIdentityClient } from '@aws-sdk/client-cognito-identity';

import * as config from "./config/config";

export const configureRUM = async () => {
    try {
        const creds = await getRUMCredentials();
        const rumConfig = {
            sessionSampleRate: 1,
            endpoint: `https://dataplane.rum.${config.AWS_REGION}.amazonaws.com`,
            telemetries: [
                [ 'errors', { stackTraceLength: 500 } ],
                'performance',
                [ 'http', {
                    stackTraceLength: 500,
                    addXRayTraceIdHeader: true,
                    urlsToInclude: Object.values(config.APIGateways).map(url => new RegExp(`/${url}*/`))
                  }
                ]
            ],
            allowCookies: true,
            enableXRay: true
        };

        const APPLICATION_ID = config.AWS_RUM_APPLICATION_ID;
        const APPLICATION_VERSION = '1.0.0';
        const APPLICATION_REGION = config.AWS_REGION;

        const awsRum = new AwsRum(
            APPLICATION_ID,
            APPLICATION_VERSION,
            APPLICATION_REGION,
            rumConfig
        );
        if (creds !== '') {
            awsRum.setAwsCredentials(creds);
        } else {
            console.log("Failed to configure RUM, metrics will not be reported for this session");
        }
    } catch (err) {
        // Ignore errors thrown during CloudWatch RUM web client initialization
        console.error("Failed to configure RUM", err);
    }
}

export const getRUMCredentials = async () => {
    let accessToken = '';
    let currentSession
    try {
        currentSession = await Auth.currentSession();
    }
    catch (e) {
        console.error(e);
        return ''
    }

    if (!currentSession.isValid()) {
        try {
            const cognitoUser = await Auth.currentAuthenticatedUser();
            const refreshedSession = await cognitoUser.refreshSession(currentSession.refreshToken);
            // update state with new accessToken
            accessToken = refreshedSession.getIdToken().getJwtToken();
        } catch (error) {
            console.error('Error refreshing session:', error);
            // Handle the error if necessary
        }
    } else {
        accessToken = currentSession.getIdToken().getJwtToken();
    }

    //Configuration for cognito identity client
    //Points to rum cognito identity pool to get credentials for posting RUM events
    //Docs: https://docs.aws.amazon.com/sdk-for-javascript/v3/developer-guide/loading-browser-credentials-cognito.html
    const accessCreds = {
        identityPoolId: config.AWS_RUM_COGNITO_IDENTITY_POOL_ID,
        clientConfig: { region: config.AWS_REGION },
        logins: {
            [`cognito-idp.${config.AWS_REGION}.amazonaws.com/${config.AWS_COGNITO_USER_POOL_ID}`]: accessToken
        }
    }

    //Establish cognito identity client to gain access to needed credentials for specified identity pool and pass back for RUM config
    const client = new CognitoIdentityClient({
        region: config.AWS_REGION,
        credentials: fromCognitoIdentityPool(accessCreds)
    });

    return await client.config.credentials();

}