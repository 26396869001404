// assets
import MethaneIconOutlined from "../../../../assets/icons/methane-app-icon-outlined.svg?react";


// redux 
import { useSelector } from 'react-redux';
import { selectShowMethaneCircle } from "redux/methaneLeaks/selectors";
import { SET_SHOW_METHANE_CIRCLE_LAYER } from "redux/methaneLeaks/actionTypes";

// constantS
import strings from 'utils/strings';
import { SwitchAction } from "@innovation-toolkit/ui";

const MethaneCircleToggle = () => {
    const showMethaneCircle = useSelector(selectShowMethaneCircle);

    return ( 
        <SwitchAction
            componentIcon={MethaneIconOutlined}
            content={strings.methane.toggle.circle.title}
            action={SET_SHOW_METHANE_CIRCLE_LAYER}
            submenu={1}
            currentState={showMethaneCircle}
        />
    )
}

export default MethaneCircleToggle;