import { useEffect, useState } from 'react';
import { Popup } from 'react-map-gl';

const MethanePopup = ({isOpen, onClose, lngLat, feature}) => {
    const [popupState, setPopupState] = useState({
        lng: 0,
        lat: 0,
        children: null
    })

    useEffect(() => {
        if (lngLat === null || feature === null) return

        let popupFeature = feature.properties

        let description = <div>
            {Object.keys(popupFeature).map(key => <p><strong>{key}</strong>: {popupFeature[key]}</p>)}
        </div>
        
        setPopupState({
            lng: lngLat.lng,
            lat: lngLat.lat,
            children: description
        })

    }, [isOpen, lngLat, feature])



    return (
        <>
            {isOpen && 
                <Popup longitude={popupState.lng} latitude={popupState.lat} onClose={onClose}>
                    {popupState.children}
                </Popup>
            }
        </>
    )
}

export default MethanePopup;