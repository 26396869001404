import { useState } from 'react';
import { Layer, Source } from 'react-map-gl';
import { heatmapColors } from "component/mapbox/mapboxUtils";
import MethanePopup from './popup';

// redux
import { useSelector } from 'react-redux';
import { selectShowMethaneHeatap, selectShowMethaneCircle } from "redux/methaneLeaks/selectors";
import { selectMethaneSource } from "redux/mapbox/selectors";
import { selectMapCensusTracts } from "redux/mapStateInternal/selectors";
import { HEATMAP_LAYER_BEFORE_ID, censusTractLayerFilter, useMapEvent } from '@innovation-toolkit/mapbox';

export const METHANE_POINT_LAYER_ID = "methane-points";

const MethaneLeaksLayer = () => {

    const [showMethanePopup, setShowMethanePopup] = useState(false);
    const [popupFeature, setPopupFeature] = useState(null);
    const [lngLat, setLatLng] = useState(null);

    const showMethaneHeatmap = useSelector(selectShowMethaneHeatap);
    const showMethaneCircle = useSelector(selectShowMethaneCircle);
    const selectedCensusTracts = useSelector(selectMapCensusTracts);

    const methaneSource = useSelector(selectMethaneSource)

    const filterMethaneLeaks = (selectedCensusTracts) => {
        let censusTractFilter = ['literal', true];

        if (selectedCensusTracts) {
            censusTractFilter = censusTractLayerFilter(selectedCensusTracts, 'tract')
        }

        return [
            'any',
            censusTractFilter
        ]
    }

    useMapEvent('click', METHANE_POINT_LAYER_ID, (e) => {
        if (e.originalEvent.layer !== METHANE_POINT_LAYER_ID) return;

        setPopupFeature(e.features[0])
        setLatLng(e.lngLat);
        setShowMethanePopup(true);
    })
    

    const sourceConfig = {
        id: 'methane',
        type: 'geojson',
        data: methaneSource,
    };

    const pointLayerConfig = {
        id: METHANE_POINT_LAYER_ID,
        type: "circle",
        source: "methane",
        layout: {
            'visibility': showMethaneCircle ? 'visible' : 'none'
        },
        paint: {
            "circle-color": "#4ce602",
            "circle-stroke-width": 1.5,
            "circle-stroke-color": "black",
        },
        filter: filterMethaneLeaks(selectedCensusTracts)
    };

    const heatmapLayerConfig = {
        id: "methane-heatmap",
        type: "heatmap",
        source: "methane",
        layout: {
            'visibility': showMethaneHeatmap ? 'visible': 'none'
        },
        paint: {
            'heatmap-intensity': [
                'interpolate',
                ['linear'],
                ['zoom'],
                0, 0.1,// zoom, value
                7, 0.3,
                10, 1,
            ],
            'heatmap-weight': [
                'interpolate',
                ['linear'],
                ['get', 'leak_code'],
                1, 0.1,
                6, 1,
            ],
            'heatmap-color': [
                'interpolate',
                ['linear'],
                ['heatmap-density'],
                ...heatmapColors,
            ],
            'heatmap-opacity': 0.53,
            'heatmap-radius': [
                'interpolate',
                ['linear'],
                ['zoom'],
                0, 3,
                7, 3,
                10, 30,
                12, 80,
                13, 100,
            ],
        },
        filter: filterMethaneLeaks(selectedCensusTracts),
        beforeId: HEATMAP_LAYER_BEFORE_ID
    }

    return (
        <Source {...sourceConfig}>
            <Layer {...pointLayerConfig} />
            <Layer {...heatmapLayerConfig} />
            <MethanePopup 
                isOpen={showMethanePopup} 
                onClose={() => setShowMethanePopup(false)}  
                lngLat={lngLat}
                feature={popupFeature}
            />
        </Source>
    );
};

export default MethaneLeaksLayer;
