import { Tooltip as AntDTooltip} from 'antd';
import styled from 'styled-components';
import { theme } from '../../theme/theme';

const StyledTitle = styled.div`
    color: ${(props) => props.textColor};
`
const StyledTooltip = styled(AntDTooltip)`
    .ant-tooltip-arrow {
        display: ${(hasArrow) => hasArrow ? "block" : "none"} !important;
    }
`;

const Tooltip = ({placement, text, children, overlayStyle, textColor = theme.color.prussianBlue, tooltipColor = theme.color.white, setArrow = true, ...rest}) => {
    return (
        <StyledTooltip 
            placement={placement}
            title={<StyledTitle textColor={textColor}>{text}</StyledTitle>}
            color={tooltipColor}
            key={tooltipColor}
            overlayStyle={overlayStyle}
            hasArrow={setArrow}
            arrow={{pointAtCenter: true}} 
            {...rest}
        >
                {children}
        </StyledTooltip>
    )
}

export default Tooltip;