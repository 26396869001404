import { ExternalChargersLayer, HydrogenFuelStationsLayer, CalEnviroLayer, EventDelegator } from '@innovation-toolkit/mapbox';
import { FleetDecarbonizationLayer } from './FleetDecarbonizationLayer';

export const FleetDecarbonizationMapLayers = () => {
    return (
        <>
            <EventDelegator layerIds={[]}/>
            <CalEnviroLayer tractsSelectable={false} toggleToHighlight={false}/>
            <ExternalChargersLayer show={false} />
            <HydrogenFuelStationsLayer showLayer={false} />
            <FleetDecarbonizationLayer />
        </>
    )
}