import { AnalysisSection, AnalysisSectionContainer } from "@innovation-toolkit/ui"
import { useDispatch, useSelector } from "react-redux"
import { selectShowLeakTable, setShowLeakTable } from "redux/abatementOptimization/slice"
import strings from "utils/strings"
import AbatementOptimizationRank from "../../../assets/icons/abatement-optimization-rank.svg?react"

export const AbatementOptimizationAnalysis = () => {
    const dispatch = useDispatch()
    const showLeakTable = useSelector(selectShowLeakTable)

    return (
        <AnalysisSectionContainer style={{marginTop: '16px'}}>
            <AnalysisSection
                title={strings.components.leakPrioritizationTable.title}
                Icon={AbatementOptimizationRank}
                action={(active) => {
                    dispatch(setShowLeakTable(active))
                }}
                defaultState={showLeakTable}
                description={strings.components.leakPrioritizationTable.description}
            >
            </AnalysisSection>
        </AnalysisSectionContainer>
    )
}
