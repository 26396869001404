import { useDispatch, useSelector } from 'react-redux';
// redux
import { setMenuSelectedKey } from '@redux/menu/actions';
import { selectSelectedKey } from '@redux/menu/selectors';

import { Typography, LayerToggleSection, Menu, MainMenu, LayerTabWrapper, TitleDivider, color } from '@innovation-toolkit/ui';
import { useState } from 'react';
import { AngelesLinkSegmentToggle } from './layers/Angeles_Link_Segment_B/toggle';
import { BirdSpeciesOfSpecialConcernToggle } from './layers/Bird_Species_of_Special_Concern/toggle';
import { BLMLayerToggle } from '../AbatementOptimization/layers/BLM/toggle';
import { BridgeLayerToggle } from '../AbatementOptimization/layers/Bridge/toggle';
import { CNDDBLayerToggle } from '../AbatementOptimization/layers/CNDDB/toggle';
import { EagleAwarenessLayerToggle } from '../AbatementOptimization/layers/EagleAwareness/toggle';
import { HazmatToggle } from '../AbatementOptimization/sharedLayerToggles';
import { NFABLayerToggle } from '../AbatementOptimization/layers/NationalForestAdministrativeBoundaries/toggle';
import { NHSLayerToggle } from '../AbatementOptimization/layers/NationalHighwaySystem/toggle';
import { RailwayLayerToggle } from '../AbatementOptimization/layers/Railway/toggle';
import { VegetationLayerToggle } from '../AbatementOptimization/layers/Vegetation/toggle';
import { CalEnviroLayerToggle, HydrogenFuelStationsLayerToggle, TribalLandsLayerToggle } from '@innovation-toolkit/mapbox';
import { SensitiveSitesLayerToggle } from './layers/Sensitive_Sites/toggle';
import { RecentEarthquakeLayerToggle } from './layers/RecentEarthquakeLayer/toggle';
import { NoGoLayerToggle } from './layers/NoGoLayer/toggle';
import { EnergyJusticeDOELayerToggle } from './layers/EnergyJusticeDOE/toggle';

export const AngelesLinkMenu = () => {
    const dispatch = useDispatch()
    const activeMenuItem = useSelector(selectSelectedKey);

    const [currentItem, setCurrentItem] = useState(null);
    const [selectKey, setSelectKey] = useState(undefined);

    const closeTabs = () => {
        dispatch(setMenuSelectedKey(undefined))
        setSelectKey(undefined)
        setCurrentItem(null)
    }

    if (currentItem) return (
        <MainMenu background-color={color.prussianBlue} style={{width: '256px'}}>
            {currentItem.component(closeTabs)}
        </MainMenu>
    )
    return <Menu
        tabs={[
            {
                title: "Layers",
                content:
                    <LayerTabWrapper>
                        <Typography variant='Heading 2' text='Internal Data' style={{marginTop: '16px'}}/>
                        <TitleDivider />
                        <LayerToggleSection 
                            title='Angeles Link'
                            toggles={[
                                <AngelesLinkSegmentToggle/>,
                            ]}
                        />
                        <LayerToggleSection 
                            title='Animals'
                            toggles={[
                                <BirdSpeciesOfSpecialConcernToggle/>
                            ]}
                        />
                        <LayerToggleSection
                            title='GIS Prioritization Factors'
                            toggles={[
                                <SensitiveSitesLayerToggle/>,
                                <RecentEarthquakeLayerToggle/>,
                                <BLMLayerToggle/>,
                                <BridgeLayerToggle/>,
                                <CNDDBLayerToggle/>,
                                <EagleAwarenessLayerToggle/>,
                                <HazmatToggle/>,
                                <NFABLayerToggle/>,
                                <NHSLayerToggle/>,
                                <RailwayLayerToggle/>,
                                <VegetationLayerToggle/>
                            ]}
                        />

                        <Typography style={{marginTop: '32px'}} variant='Heading 2' text='External Data'/>
                        <TitleDivider />
                        <LayerToggleSection 
                            title='Areas of Interest'
                            toggles={[
                                <NoGoLayerToggle/>,
                                <EnergyJusticeDOELayerToggle/>,
                                <CalEnviroLayerToggle/>,
                                <TribalLandsLayerToggle/>
                            ]}
                        />
                        <LayerToggleSection 
                            title='Fuel and Charging Stations'
                            toggles={[
                                <HydrogenFuelStationsLayerToggle/>
                            ]}
                        />

                    </LayerTabWrapper>
            },
            // {
            //     title: "Analysis",
            //     content: <AbatementOptimizationAnalysis />
            // }
        ]}
    />
}