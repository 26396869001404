import { createSelector } from "@reduxjs/toolkit";

const selectHospitalsState = state => state.Hospitals;

export const selectHospitalsSource = createSelector(
    selectHospitalsState,
    state => state.source
)

export const selectShowHospitals = createSelector(
    selectHospitalsState,
    state => state.showHospitals
)