import { useDispatch, useSelector } from "react-redux"
import { CharitableDonationLayerMetadata } from "."
import { addActiveLayer, removeActiveLayer, selectIsLayerActive } from "@innovation-toolkit/mapbox";
import { LayerToggle } from '@innovation-toolkit/ui';
import { HeartIcon } from '../../../../../assets/icons/heartIcon'

export const CharitableDonationLayerToggle = () => {
    const CHARTIABLE_DONATION_LAYER = CharitableDonationLayerMetadata.layerId
    const dispatch = useDispatch()
    const shouldShowCharitableDonationLayer = useSelector((state) => selectIsLayerActive(state, CHARTIABLE_DONATION_LAYER))

    const handleClick = (shouldShow) => {
        if (!shouldShow) {
            dispatch(removeActiveLayer(CHARTIABLE_DONATION_LAYER))
        } else {
            dispatch(addActiveLayer(CHARTIABLE_DONATION_LAYER))
        }
    }

    return (
        <LayerToggle
            title='Charitable Giving'
            tooltipText='Charitable Giving'
            componentIcon={HeartIcon}
            onChange={handleClick}
            currentState={shouldShowCharitableDonationLayer}
        />
    )
}

