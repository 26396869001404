import { MapPopup } from '@innovation-toolkit/mapbox';
import { MapPopupContents } from '@innovation-toolkit/ui';
import { useState, useEffect } from 'react';
import moment from 'moment';
// import './popup.css'

const dataFormatMap = {
    "Description": "Description",
    "Municipality_Name": "Municipality Name",
    "Work_Type": "Work Type",
    "Start_Date": "Start Date",
    "End_Date": "End Date",
    "Dates_Are_Estimated": "Dates are Estimated",
    "Start_Date_Moratorium": "Start Date Moratorium",
    "End_Date_Moratorium": "End Date Moratorium",
    "Dates_Are_Estimated_Moratorium": "Moratorium Dates Estimated",
    "Input_Sheet": "Input Sheet",
    "Project_Nbr": "Project Number",
    "address": "Address"
}



export const UploadedDataPopup = ({isOpen, onClose, lngLat, feature}) => {
    const [popupState, setPopupState] = useState({
        lng: 0,
        lat: 0
    })
    const [data, setData] = useState(null);

    useEffect(() => {
        if (lngLat === null || feature === null) return
        const popupFeature = {}

        for (const key in dataFormatMap) {
            if (typeof feature.properties[key] === "boolean") {
                popupFeature[dataFormatMap[key]] = feature.properties[key] ? "Yes" : "No"

            } else {
                popupFeature[dataFormatMap[key]] = feature.properties[key] || "-"
            }
        }

        setData(popupFeature)

        setPopupState({
            lng: lngLat.lng,
            lat: lngLat.lat
        })

    }, [isOpen, lngLat, feature])

    return isOpen ?
                <MapPopup longitude={popupState.lng} latitude={popupState.lat} onClose={onClose} closeOnClick={false} className="StreetImprovementPopup">
                <MapPopupContents
                    title="Bulk Upload Data"
                    data={data}
                />
                </MapPopup>
                : null
}