import { useEffect, useState } from 'react';
import { useMap, Layer } from 'react-map-gl';
import { queryFeatures } from '@esri/arcgis-rest-feature-layer';
import SCGEmissionsPopup from './popup';

// redux
import { useSelector } from 'react-redux';
import { selectShowEmissionsLayer } from "redux/emissions/selectors";
import { FeatureService, HEATMAP_LAYER_BEFORE_ID, isActiveLayer, useMapEvent } from '@innovation-toolkit/mapbox';

export const SCG_EMISSIONS_LAYER = "scg-emissions";
export const HOVER_ZIP_OUTLINE_LAYER = 'hover-zip-outline'

const fsSourceId = 'scg-emissions-source'

const SCGEmissionsLayer = () => {

    const [showSCGEmissionsPopup, setShowSCGEmissionsPopup] = useState(false);
    const [popupFeature, setPopupFeature] = useState(null);
    const [lngLat, setLatLng] = useState(null);

    const [zipOutlineState, setZipOutlineState] = useState({
        visible: false,
        zip: ''
    })

    const { current: map } = useMap();
    const [featureService, setFeatureService] = useState(null);

    const showEmissionsLayer = useSelector(selectShowEmissionsLayer);

    useMapEvent('click', SCG_EMISSIONS_LAYER, async (e) => {
        if (!isActiveLayer(e, SCG_EMISSIONS_LAYER)) return;
    
        const feature = e.features[0].properties;
        const map = e.target;
    
        var bounds = map.getBounds().toArray();
        queryFeatures({
            url: "https://services1.arcgis.com/R7C5BtScqTZjM1Ab/ArcGIS/rest/services/Methane_Emissions_by_Zip_Code/FeatureServer/2",
            geometry: {
                xmin: bounds[0][0],
                ymin: bounds[1][0],
                xmax: bounds[0][1],
                ymax: bounds[1][1],
                spatialReference: {
                    wkid: 4326
                }
            },
            geometryType: 'esriGeometryEnvelope',
            spatialRel: "esriSpatialRelIntersects",
            f: "geojson",
            returnGeometry: true,
            outFields: ["*"],
            where: `ZIP=${feature.ZIP}`
        }).then((response) => {
            const emissionsByYear = response.features.map(feat => feat.properties).sort((a, b) => a.Year - b.Year)
    
            let selectedFeature = {
                feature,
                emissionsByYear
            }

            setPopupFeature(selectedFeature)
            setLatLng(e.lngLat)
            setShowSCGEmissionsPopup(true)
        });
    })

    useMapEvent('mousemove', SCG_EMISSIONS_LAYER, (e) => {
        if (!isActiveLayer(e, SCG_EMISSIONS_LAYER)) return;

        setZipOutlineState({
            visible: true,
            zip: e.features[0].properties.ZIP
        })
    })
    useMapEvent('mouseleave', SCG_EMISSIONS_LAYER, (e) => {
        setZipOutlineState({
            visible: false,
            zip: ''
        })
    })

    useEffect(() => {
        map.on('load', (e) => {
            const service = new FeatureService(fsSourceId, e.target, {
                url: 'https://services1.arcgis.com/R7C5BtScqTZjM1Ab/ArcGIS/rest/services/Methane_Emissions_by_Zip_Code/FeatureServer/2'
            })

            setFeatureService(service);
        })

        return () => {
            if (!featureService) return;
            featureService.destroySource()
        }
    }, [])
    
    const scgEmissionsLayer = {
        'id': SCG_EMISSIONS_LAYER,
        'source': fsSourceId,
        'type': 'fill',
        'paint': {
            'fill-opacity': 0.3,
            'fill-color': [
                'interpolate', ['linear'],
                ['number', ['get', 'Annual_Emissions_Mscf']],
                512, '#ffffe9',
                1024, '#b4c69e',
                2038, '#69a8b7',
                4096, '#4b7e98',
                9649, '#2e557a'
            ],
            "fill-outline-color": '#8c8c94'
        },
        beforeId: HEATMAP_LAYER_BEFORE_ID,
    }

    const zipHoverOutlineConfig = {
        id: HOVER_ZIP_OUTLINE_LAYER,
        layout: {
            visibility: zipOutlineState.visible ? "visible" : "none"
        },
        'source': fsSourceId,
        type: 'fill',
        filter: ["==", "ZIP", zipOutlineState.zip],
        paint: {
            'fill-color': 'transparent',
            'fill-opacity': 1,
            "fill-outline-color": '#000000'
        },
    }

    return (
        <>
            {showEmissionsLayer && <>
                <Layer {...scgEmissionsLayer} />
                <Layer {...zipHoverOutlineConfig} />
            </>}
            <SCGEmissionsPopup
                isOpen={showSCGEmissionsPopup} 
                onClose={() => setShowSCGEmissionsPopup(false)}  
                lngLat={lngLat}
                feature={popupFeature}
            />
        </>

    );
};

export default SCGEmissionsLayer;
