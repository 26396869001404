import { APIGateways } from "config/config";
import {
    VEHICLE_FILTER,
    FILTERED_VEHICLES,
    RESET_FLEET_VEHICLE_FILTER,
    SET_FLEET_VEHICLES_FILTER
} from "../actionTypes";

export const restFleetVehiclesFilter = () => {
    return {
        type: RESET_FLEET_VEHICLE_FILTER
    }
}

export const setFleetVehiclesFilter = payload => {
    return {
        type: SET_FLEET_VEHICLES_FILTER,
        payload
    }
}

export const SetFilteredVehicles = payload => {
    return {
        type: FILTERED_VEHICLES,
        payload
    }
}

export const setVehicleFilterFleet = (vehicles) => {
    return {
        type: VEHICLE_FILTER,
        payload: vehicles.sort((a, b) => b.total_co2_pounds - a.total_co2_pounds)
    }
}