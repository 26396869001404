import { Layer, Source } from "react-map-gl"
import { APIGateways } from "@config/config"
import { useState, useEffect } from "react";
import { isActiveLayer, selectIsLayerActive, useActivateMapLayer, useLoadMapImage, selectBeforeIDs, useMapEvent } from "@innovation-toolkit/mapbox";
import { selectSearchItems } from "redux/searchItems/selectors";
import caution_red from 'assets/pattern/caution_red.png';
import { useSelector, useDispatch } from "react-redux";
import { MoratoriumsPopup } from "./popup";
import moment from 'moment';
import axios from "axios";
import { UNCLUTTERED_MIN_ZOOM } from "../../constants";

export const MORATORIUMS_LAYER = 'MORATORIUMS_LAYER';
export const MORATORIUMS_SOURCE = 'MORATORIUMS_SOURCE';

export const MoratoriumsLayerMetadata = {
    layerId: MORATORIUMS_LAYER,
    name: 'Moratoriums',
    color: '#FF0000',
    layerSymbols: [
        { symbol: caution_red, symbolName: 'caution_red' },
    ]
}

const fetchMoratoriumData = (callBack, filterOptions) => {

    // const url = `${APIGateway}/street-improvement/cpd-projects`;
    const url = `${APIGateways.citywork}/get_moratorium_data`;

    const filter = {
        return_type: 'geojson'
    }

    // start_time and end_time
    if (filterOptions?.timeFrame?.startDate && filterOptions?.timeFrame?.endDate) {
        filter.end_date = moment(filterOptions?.timeFrame.endDate).format('YYYY-MM-DD')
        filter.start_date = moment(filterOptions?.timeFrame.startDate).format('YYYY-MM-DD')
    }

    // district
    if (filterOptions?.districts && filterOptions.districts.length > 0) {
        filter.district = filterOptions.districts;
    }

    axios.post(url, filter)
        .then((response) => {
            // Returns as a presigned url, fetch the data here
            fetch(response.data)
                .then((fetchResponse) => fetchResponse.json())
                .then((data) => {
                    callBack(data, false)
                })
        })
        .catch((err) => {
            callBack(null, true)
        })
}

export const MoratoriumsLayer = ({showLayer=false}) => {

    const dispatch = useDispatch()
    const searchItems = useSelector(selectSearchItems);
    const [showMoratoriumsLayer] = useActivateMapLayer(MoratoriumsLayerMetadata, showLayer)
    const beforeIDs = useSelector(selectBeforeIDs)
    const patterns = { red: 'caution_red' }
    
    const [showPopup, setShowPopup] = useState(false)
    const [popupFeature, setPopupFeature] = useState(null);
    const [lngLat, setLatLng] = useState(null);
    const [target, setTarget] = useState(null);
    const [data, setData] = useState(null);

    useEffect(() => {
        // dispatch(addToMapLoadingList('Moratoriums'))

        fetchMoratoriumData((data, isError) => {
            if (!isError) {
                // TODO_ST
                setData(data)
            } 
            // dispatch(removeFromMapLoadingList('Moratoriums'))
        }, searchItems)
    }, [searchItems]) // add searchItems here if want to update on search

    useLoadMapImage(patterns.red, caution_red)

    
    useMapEvent('click', MORATORIUMS_LAYER, (e) => {
        if (!isActiveLayer(e, MORATORIUMS_LAYER)) return;

        setShowPopup(true);
        setPopupFeature(e.features[0]);
        setLatLng(e.lngLat);
        setTarget(e.target);
    })

    const sourceConfig = {
        id: MORATORIUMS_SOURCE,
        type: "geojson",
        data: data,
    }

    const lineConfig = {
        id: MORATORIUMS_LAYER,
        type: "line",
        source: MORATORIUMS_SOURCE,
        minzoom: UNCLUTTERED_MIN_ZOOM,
        layout: {
            'visibility': showMoratoriumsLayer ? 'visible' : 'none'
        },
        paint: {
            'line-width': 4,
            'line-pattern': [
                'case', 
                ['==', ['get', 'STREET'], 'PICKFORD ST'], patterns.red, 
                patterns.red
            ]
        },
        beforeId: beforeIDs.line
    }

    return (
        <Source {...sourceConfig}>
            <Layer {...lineConfig} />
            <MoratoriumsPopup 
                isOpen={showPopup}
                onClose={() => setShowPopup(false)}
                lngLat={lngLat}
                feature={popupFeature}
                target={target}
            />
        </Source>
    )
}

MoratoriumsLayer.layerName = 'Moratoriums';
MoratoriumsLayer.legendColor = '#FFFFFF';

const LineComponent = ({pattern, color, label}) => {
    return (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                gap: '10px',
                // marginBottom: '14px'
            }}
        >
            <div
                style={{
                    height: '5px',
                    width: '22px',
                    backgroundImage: `url(${pattern})`,
                    backgroundRepeat: 'repeat',
                    backgroundSize: 'contain',
                    border: '1px solid #FFFFFF50',
                    marginLeft: '3px'
                }}
            ></div>            
            <p
                style={{
                    padding: '0',
                    margin: '5px'
                }}
            >
                {label}
            </p>
        </div>
        
    )
}

export const MoratoriumsLegendSection = () => {
    const showLegend = useSelector((state) => selectIsLayerActive(state, MoratoriumsLayerMetadata.layerId))

    if (!showLegend) {
        return <></>
    }

    return (
        <LineComponent pattern={caution_red} label='Moratoriums*'/>
    )
}