import React, { useCallback, useEffect, useState } from "react";
import strings from "utils/strings";


import { useDispatch, useSelector } from "react-redux";
// import { setShowAnalysisTable } from "@redux/mapStateInternal/action";
import { DataTable } from "@innovation-toolkit/ui";
import DynamicTable from "./DynamicTable";
import { selectValueMetricsTable, setValueMetricsTable } from "redux/fleetDecarbonization/slice";

const vehicleDecarbonizationsData = {
    "AFV Replacements": {
      "Over the Road": 1230,
      "Light Duty": 2310,
      "Heavy Duty": 1341,
      "Total": 3013
    },
    "ZEV Replacements": {
      "Over the Road": 1022,
      "Light Duty": 6023,
      "Heavy Duty": 2316,
      "Total": 7131
    },
    "Total": {
      "Over the Road": 2300,
      "Light Duty": 1237,
      "Heavy Duty": 1534,
      "Total": 5316
    }
  };

  const vehicleDecarbonizationsColumns = [
    {
      title: 'Over the Road',
      dataIndex: 'Over the Road',
      key: 'Over the Road',
    },
    {
      title: 'Light Duty',
      dataIndex: 'Light Duty',
      key: 'Light Duty',
    },
    {
      title: 'Heavy Duty',
      dataIndex: 'Heavy Duty',
      key: 'Heavy Duty',
    },
    {
      title: 'Total',
      dataIndex: 'Total',
      key: 'Total',
    },
  ];

  const emissionsReductionData = {
    "AFV Replacements": {
      "Year to Date": 2012,
      "Year to Year": 2020,
      "Compared to Miles Driven": 9000,
    },
    "ZEV Replacements": {
        "Year to Date": 2016,
        "Year to Year": 2020,
        "Compared to Miles Driven": 10200,
    },
    "Total": {
        "Year to Date": 2018,
        "Year to Year": 2024,
        "Compared to Miles Driven": 13020,
    }
  };

  const emissionsReductionColumns = [
    {
      title: 'Year to Date',
      dataIndex: 'Year to Date',
      key: 'Year to Date',
    },
    {
      title: 'Year to Year',
      dataIndex: 'Year to Year',
      key: 'Year to Year',
    },
    {
      title: 'Compared to Miles Driven',
      dataIndex: 'Compared to Miles Driven',
      key: 'Compared to Miles Driven',
    }
  ];

  const valueMetricsTables = {
    vehicleDecarb: {
        title: 'Vehicle Decarbonizations'
    },
    emissionReduction: {
        title: 'Emission Reductions'
    },
  }



const ValueMetricsTables = () => {
    const dispatch = useDispatch();
    const vehicleDecarbTitle = valueMetricsTables.vehicleDecarb.title
    const emissionReductionTitle = valueMetricsTables.emissionReduction.title

    const openTable = useSelector(selectValueMetricsTable)

    return (
        <DataTable
            open={openTable}
            handleClose={() => dispatch(setValueMetricsTable(false))}
            activeTabIndex={0}
            tabs={[
                {
                    title: vehicleDecarbTitle,
                    contentSections: [
                        {
                            titleWidth: '600px',
                            content: <DynamicTable title={vehicleDecarbTitle} dataSource={vehicleDecarbonizationsData} columns={vehicleDecarbonizationsColumns} />
                        }
                    ]
                },
                {
                    title: emissionReductionTitle,
                    contentSections: [
                        {
                            titleWidth: '912px',
                            content: <DynamicTable title={emissionReductionTitle} dataSource={emissionsReductionData} columns={emissionsReductionColumns} />
                        }
                    ]
                }
            ]}
        />
    )
}

export default ValueMetricsTables;